import { IComboBoxStyles, ILabelStyleProps, ILabelStyles, IStyleFunctionOrObject } from '@fluentui/react';
import * as React from 'react';
import { Tenantfaq } from '../../shared/models/Role.model';
import CheckboxTree from 'react-checkbox-tree';
import { UIConstants } from '../../shared/models/Constants';
import { Component, Fragment } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import $ from 'jquery';
import { Label } from '@fluentui/react';
import ConfigurationAPI from '../../shared/api/Configuration.api';
const labelStyles: Partial<IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>> = {
    root: { marginTop: 7 }
};
const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 350, width: 350 } };
export interface IMercuryFaqProperty {
    faqConfig: any;
    expandedItems: [];
}
interface Item {
    id: string;
    isOpen: boolean;
    Question: string;
    Answeres: string[];
}

interface Application {
    Application: string;
    isOpen: boolean;
    Items: Item[];
}

interface ExpandCollapseViewProps {
    //data: Application[];
}

interface ExpandCollapseViewState {
    expandedItems: number[];
    data: Application[];
}

class MercuryFAQ extends Component<ExpandCollapseViewProps, ExpandCollapseViewState> {
    public  urlRegex = /(https?:\/\/[^\s]+)/g;
    constructor(props: ExpandCollapseViewProps) {
        super(props);
        this.state = {
            expandedItems: [],
            data : null
        };
    }
    public componentDidMount() {
        ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.MercuryFAQConfig).then(response => {
            let data = response ? JSON.parse(response.replace(/'/g, `"`)) : null;
            this.setState({ data: data });
        })
    }

    public render(): JSX.Element {
        const { data } = this.state
        return (
            <div style={{ margin: '50px 0px 0px 10px' }}> 
                <div >
                    <Label style={{ paddingLeft: '8px', fontSize: 20 }} > <h1 role="heading" aria-level={1} className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}> {UIConstants.RouteConfig.MercuryFAQ }</h1></Label>
                </div>

                {data?.map(x=>(
                    <div className={"ms-Grid-col ms-sm4 ms-md8 ms-lg12"} style={{ marginTop: '10px' }} >
                        <div id={x.Application} style={{ marginRight: '50px', backgroundColor: '#c0c0c0', cursor: 'pointer', borderRadius: '5px' }} onClick={() => this.openPanel(x, data.indexOf(x))} >
                            {(x.isOpen) ?
                                (<Icon style={{ marginTop: '5px', paddingLeft: '5px', paddingRight: '5px' }} iconName="SkypeCircleMinus" />)
                                :
                                (<Icon style={{ marginTop: '5px', paddingLeft: '5px', paddingRight: '5px' }} iconName="CircleAdditionSolid" />)
                            }

                            <Label style={{ fontSize: 16, display: 'inline-block', cursor: 'pointer' }} >{x.Application}</Label>
                        </div>
                        <div id={x.Application + 'questions'} style={{marginLeft:'20px', marginRight: '50px', display: 'none', cursor: 'pointer', borderRadius: '5px' }} onClick={this.toggleQuestions}>


                            <ul style={{ paddingTop:'10px', listStyleType: 'none' }} >
                               {
                                   x.Items.map((item) => (

                                       <li> <div onClick={() => this.toggleAnswers(item.id, data.indexOf(x), x.Items.indexOf(item))}>{!item.isOpen ? <Icon style={{ marginLeft: '-30px', paddingRight: '10px', marginBottom: '10px' }} iconName="CircleAdditionSolid" /> : <Icon style={{ marginLeft: '-30px', paddingRight: '10px', marginBottom: '10px' }} iconName="SkypeCircleMinus" />} <b>{item.Question}</b></div>
                                           <hr></hr>
                                           <div id={item.id} style={{ display: 'none', paddingLeft:'20px' }}>
                                           {
                                                   item.Answeres.map(ans => ((<li className={"ms-Grid-col ms-sm4 ms-md8 ms-lg12"} style={{ listStyleType: 'disc', paddingLeft: '3px', paddingBottom: '10px' }} >{this.formatedAnswer(ans.split(this.urlRegex))}</li>)))
                                           }
                                           </div>
                                       </li>
                                   ))
                            }
                        </ul>
                    </div>
                </div>
               ))}
            </div>
        );

    }

    formatedAnswer(ansParts) {
        const answers = ansParts.map((part, index) => {
            if (part.match(this.urlRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            }
            return <span key={index}>{part}</span>;
        });

        return answers;
    }
    

    openPanel(subject,index) {
        this.state.data[index].isOpen = !this.state.data[index].isOpen;
        const id = '#' + subject.Application + 'questions';
        $(id).slideToggle("slow");
        this.setState({data: this.state.data})
    };

    toggleQuestions() {
        $("#content").slideToggle("slow");
    }

    toggleAnswers(id, sectionIndex, questionIndex) {
        this.state.data[sectionIndex].Items[questionIndex].isOpen = !this.state.data[sectionIndex].Items[questionIndex].isOpen;
        const divId = '#' + id;
        $(divId).slideToggle("slow");
        this.setState({ data: this.state.data })
    }

}

export default MercuryFAQ;