import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TextField, DetailsListLayoutMode, SelectionMode, Label, Link, ShimmeredDetailsList, mergeStyles, IColumn, Dropdown, IDropdownOption, Spinner, SpinnerSize, Icon, PrimaryButton, DefaultButton } from '@fluentui/react';
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import _ from "lodash";
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import RequestDetails from './RequestDetails';
import RequestViewAssetDetails from './RequestViewAssetDetails'
import { Approvers, Asset, MyAccessRequestResponse, Properties, FDLReportFilter } from '../../shared/models/UserAccess.model';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import JSONToCSVConvertor from '../../shared/functions/ExportToCSV';
import { ApprovalStatusDetails } from '../../shared/models/ApproverDetails.model';

let EnvironmentOptions: IDropdownOption[] = [];
const StatusOptions: IDropdownOption[] = [
    { key: 'all', text: 'All' },
    { key: 'inprogress', text: 'InProgress' },
    { key: 'complete', text: 'Complete' },
    { key: 'rejected', text: 'Rejected' },
    { key: 'cancelled', text: 'Cancelled' },
    { key: 'remove', text: 'Removed' }
];
export interface IAssetState {
    selectedEnvValue: string,
    tenantId: string,
    AccessRequest: MyAccessRequestResponse,
    OriginalAccessRequest: any,
    items: any,
    showModal: boolean,
    showAssets: boolean,
    selectedRequest: Properties,
    assetDetails: Asset[],
    approvers: Approvers,
    requestId: string,
    requestType: string,
    status: string,
    selectedStatusKey: string,
    mode: string,
    businessJustification: string,
    AppproverDetails: Array<ApprovalStatusDetails>,
    approvalHistory: any,
    approverNotes: any,
    approval: Array<string>,
    migratedApprovalHistory: any,
    pickerCtrlKey: number,
    searchRequestId: string,
    consumerRequestId: string,
    consumerServiceTreeId: string,
    userEmail:string
}
type AssetProps = IAssetState &
    RouteComponentProps<{}>;
export type RequestGridDetails = {
    requestId: string;
    requestorPrincipal: string;
    publisherName: string;
    consumerServiceTreeName: string;
    consumerServiceTreeId: string;
    createdDate: string;
    completedDate: string;
    properties: Properties;
    assetDetails: Asset[];
    approvers: Approvers
    status: string;
    user: string;
    mode: string;
    consumerName: string;
    consumerRequestId: string;
    requestType: string;
    businessJustification: string;
    approvalHistory: any;
    accountIdType: string;
    accountId: string;
    requestorEmail: string;
    scope: string;
    consumerOwners: string;
}
export type RequestCSVDetails = {
    RequestorEmail: string;
    RequestorPrincipal: string;
    RequestId: string;
    BusinessJustification: string;
    RequestedDate: string;
    RequestType: string;
    Status: string;
    Scope: string;
    CompletedDate: string;
    ExecutiveSponsor: string;
    ConsumerPrivacy: string;
    PublisherName: string,
    ConsumerRequestId: string,
    PpeRequestId: string,
    ConsumerServicetreeId: string,
    ConsumerServiceTreeName: string,
    IsSOX: string,
    IsCplusAI: string,
    DataContractLinks: string,
    HasClassifiedData: string,
    HasPersonalData: string,
    ApprovalHistory: any,
    AssetInfo: any,
    AccountIdType: string;
    AccountId: string;
    AssetInfo1: any,
    AssetInfo2: any,
    AssetInfo3: any,
    AssetInfo4: any,
    AssetInfo5: any,
    AssetInfo6: any,
    Notes: any,
    ConsumerOwners: any
}

export default class ConsumerAccessRequestView extends React.Component<AssetProps> {
    private tenantData: ITenant = null;

    public state: IAssetState = {
        selectedEnvValue: '',
        AccessRequest: { IsDataLoaded: true, IsSuccess: true, Data: null },
        OriginalAccessRequest: [],
        items: [],
        showModal: false,
        approvers: null,
        showAssets: false,
        status: 'All',
        mode: 'Consumer',
        selectedRequest: null,
        assetDetails:null,
        requestId: '',
        requestType: '',
        businessJustification: '',
        AppproverDetails: null,
        approvalHistory: null,
        approverNotes: null,
        approval: [],
        tenantId: '',
        migratedApprovalHistory: null,
        pickerCtrlKey: 0,
        consumerRequestId: '',
        consumerServiceTreeId: '',
        searchRequestId: '',
        userEmail: null,
        selectedStatusKey: 'all'
    };
    public constructor(props) {
        super(props);
    }

    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let tenantName = (this.props.match.params as any).tenantName;
        tenantName = tenantName.toUpperCase();
        setTimeout(() => {
            let TenantData = Utility.GetTenantData();
            this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
            EnvironmentOptions = Utility.getParsedFDLEnvironmentConfig();
            this.setState({ selectedEnvValue: EnvironmentOptions[0].text, userDetails: user, tenantData: this.tenantData, tenantId: EnvironmentOptions[0].key });
            this.getDetails();
        }, 500);
    }
    /**
     * UI Render
     */
    public render(): JSX.Element {
        const { selectedEnvValue, AccessRequest, requestType, tenantId, businessJustification, migratedApprovalHistory, approvers, status, selectedStatusKey, requestId, mode, showAssets, assetDetails, items, showModal, selectedRequest, pickerCtrlKey, consumerRequestId, consumerServiceTreeId, searchRequestId } = this.state;
        return <React.Fragment>
                <div id={'div-msg-area'} style={{ height: 50 }}></div>
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg11"}>
                        <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{'   '}{UIConstants.PageTitle.ConsumerAccessRequestView}</h1></Label>
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg1"}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <PrimaryButton style={{ width: 'max-content', position: 'absolute', marginLeft: window.innerWidth < 400 ? '-15%' : 0 }} title="Export to CSV file" onClick={() => this.getApproverDetails(tenantId)} ><Icon iconName={'ExcelLogo'} />&nbsp;Export</PrimaryButton>
                        </div>
                    </div>
                </div>
                <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg2"}>
                        <Label><Icon iconName="People" />
                            {'   '}User</Label>
                        <PeoplePicker
                            key={pickerCtrlKey}
                            type={PeoplePickerType.Normal}
                            contentType={PeoplePickerContentType.User}
                            selectionMode={PeoplePickerSelectionMode.Single}
                            onchange={(items) => { this.onPeoplePickerChange(items) }}
                            defaultSelectedItems={[]}
                            {...this.props}
                            setFocusOnPicker={false}
                        ></PeoplePicker>
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg2"} >
                        <Label >{'   '}Request Id</Label>
                        <TextField placeholder="Enter Request Id" styles={{ fieldGroup: [{ height: 40 }] }} value={searchRequestId} onChange={this._onChangeRequestId} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg2"} >
                        <Label >{'   '}Consumer Request Id</Label>
                        <TextField placeholder="Enter Consumer Request Id" styles={{ fieldGroup: [{ height: 40 }] }} value={consumerRequestId} onChange={this._onChangeConsumerRequestId} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg3"} >
                        <Label >{'   '}Consumer Service Tree Id</Label>
                        <TextField placeholder="Enter Consumer Service Tree Id" styles={{ fieldGroup: [{ height: 40 }] }} value={consumerServiceTreeId} onChange={this._onChangeConsumerServiceTreeId} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg2"} >
                        <Dropdown
                            defaultSelectedKey={StatusOptions[0].key}
                            selectedKey={selectedStatusKey}
                            placeholder={status}
                            label="Status"
                            options={StatusOptions}
                            onChange={this.onStatusChange}
                            styles={{ root: { height: 38 } }}
                        />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg1"} >
                        <Dropdown
                            defaultSelectedKey={tenantId}
                            selectedKey={tenantId}
                            placeholder={selectedEnvValue}
                            label="Environment"
                            options={EnvironmentOptions}
                            onChange={this.onEnvironmentChange}
                            styles={{ root: { height: 38 } }}
                        />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg1"} ></div>
                </div>
                <div key='grid-row-loadData' className={"ms-Grid-row"} style={{ paddingLeft: 10, paddingBottom: 10, paddingTop: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg12"}>
                        <PrimaryButton title="Search" onClick={() => { this.onSearchClick() }} ><Icon iconName={'Search'} />&nbsp;Search</PrimaryButton>
                        <span>&nbsp;</span>
                        <DefaultButton title="Reset" onClick={() => { this.getDetails() }} ><Icon iconName={'Reply'} />&nbsp;Reset</DefaultButton>
                    </div>
                </div>
                    <div key="work-area">
                        {(AccessRequest?.IsDataLoaded) ? <div key='grid-row-grid' id={'div-myaccess'} className={"ms-Grid-row"} >
                        <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg12"} style={{ width: 'fit-content' }} >
                            <span style={{ paddingLeft: 10, fontSize: 14, fontWeight: 400 }}>{"Please fill in search filters and click 'Search' button to view the results."}</span><br></br>
                            <span style={{ paddingLeft: 10, fontSize: 14, fontWeight: 400 }}>{"Please use exact values for 'Request Id', 'Consumer Request Id' and 'Consumer Service Tree Id' search filters."}</span>
                            <Label>&nbsp;&nbsp;&nbsp;Total number of Requests: {items.length}</Label>

                            <AssetViewGrid dataLoaded={AccessRequest.IsDataLoaded} items={items}
                                columnCollection={this.getColumnDefintion()}
                            > </AssetViewGrid>
                        </div>
                        {(showModal) && <RequestDetails properties={selectedRequest} approvers={approvers} requestId={requestId} mode={mode} requestType={requestType} businessJustification={businessJustification} migratedApprovalHistory={migratedApprovalHistory} isAdminScreen={true} {...this.props} onDismiss={() => { this.setState({ showModal: false }) }} />}
                        {(showAssets) && <RequestViewAssetDetails assets={assetDetails} mode={mode} isAdminScreen={true} {...this.props} onDismiss={() => { this.setState({ showAssets: false }) }}/>}
                    </div> : <><br /><br /> <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" /></>}
                </div>
                </div>
        </React.Fragment>
    };
    private onPeoplePickerChange(items: any) {
        const { OriginalAccessRequest, status, searchRequestId, consumerRequestId, consumerServiceTreeId } = this.state
        let id = null;
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            id = parsedData.id;
        }
        this.setState({
            userEmail:id
        });

    }
    private _onChangeRequestId = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ searchRequestId: text });
    };
    private _onChangeConsumerRequestId = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ consumerRequestId: text });
    };
    private _onChangeConsumerServiceTreeId = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ consumerServiceTreeId: text });
    };
    onEnvironmentChange = (event, option) => {
        this.setState({ selectedEnvValue: option.text, tenantId: option.key });
    }
    onStatusChange = (event, option) => {
        this.setState({ status: option.text, selectedStatusKey:option.key });
    }
    onSearchClick() {
        const { searchRequestId, tenantId, status, userEmail, consumerRequestId, consumerServiceTreeId, } = this.state
        this.setState({ AccessRequest: null, approvalHistory: null });
        let filter: FDLReportFilter = {
            requestId: searchRequestId,
            tenantId: tenantId,
            status: status,
            userPrincipalId: userEmail,
            consumerRequestId: consumerRequestId,
            consumerServiceTreeId: consumerServiceTreeId
        }
        AccessAPI.getFilteredFDLreport(filter).then(res => {
            let items: Array<RequestGridDetails> = [];
            res.Data && res.Data.forEach(item => {
                if ((item.requestType == "Add" || item.requestType == "Propagation" || item.requestType == "Migration") && (item.role === null) && (item?.mode == "Consumer")) {
                    items.push({
                        publisherName: item.properties?.publisherName,
                        consumerServiceTreeName: item.properties?.serviceTreeName,
                        consumerServiceTreeId: item.properties?.servicetreeId,
                        requestorPrincipal: item.requestorPrincipal,
                        requestId: item.id,
                        consumerName: item.properties?.serviceName,
                        consumerRequestId: item.properties?.consumerRequestId,
                        createdDate: new Date(item?.requestedDate).toLocaleString().split(",")[0],
                        completedDate: item.completedDate == null ? '-' : new Date(item.completedDate).toLocaleString().split(",")[0],
                        properties: item.properties,
                        assetDetails: item.assets,
                        approvers: item?.Approvers,
                        requestType: item.requestType,
                        status: item.status,
                        mode: item.mode,
                        user: item?.requestorEmail.split("@")[0],
                        businessJustification: item?.businessJustification,
                        approvalHistory: item?.ApprovalHistory,
                        accountIdType: item.properties?.accountIdType,
                        accountId: item.properties?.accountId,
                        requestorEmail: item.requestorEmail,
                        scope: item.scope,
                        consumerOwners: item.properties?.consumerOwners == null ? '' : item.properties?.consumerOwners.join("; ")
                    })
                }
            });
            let sortedItems = _copyAndSort(items, 'createdDate', true);
            this.setState({ AccessRequest: res, items: sortedItems, OriginalAccessRequest: sortedItems });
        });
    }
    private _onRenderItemColumn = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
        const itemClass = mergeStyles({
            selectors: {
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                },
            },
        });
        if (column.key === 'requestId') {
            return (
                <div className={itemClass}>
                    <Link role="button" aria-label={`${item.status}`} onClick={() => {
                        this.setState({ showModal: true, selectedRequest: item.properties, requestType: item.requestType, approvers: item.approvers, requestId: item.requestId, businessJustification: item.businessJustification, migratedApprovalHistory: item.approvalHistory})
                    }} >{item.requestId}</Link>
                </div>
            );
        }
        if (column.key === 'assetDetails') {
            return (
                <div className={itemClass}>
                    <Link role="button" aria-label={`${item.status}`} onClick={() => {
                        this.setState({ showAssets: true, assetDetails: item.assetDetails})
                    }} > View Asset List </Link>
                </div>
            );
        }
        return item[column.key];
    };
    private getApproverDetails(tenantId: string) {
        const { items } = this.state;
        AccessAPI.getApprovalStatusDetailsForTenant(tenantId).then(res => {
            let approval = [];
            let approverNotes = [];
            res.Data && res.Data.requestData.forEach(item => {
                let approvalHistory = '';
                let notes = '';
                item.approvalRequest.approvalHierarchy?.forEach(history => {
                    approvalHistory += ("Level " + history.Level) + (history.IsApproved === null ? (" Pending with " + history.AssignedTo) : ((history.IsApproved ? (" Approved by " + history.ActionByAlias) : (" Rejected by " + history.ActionByAlias)) + " Approver comments : " + history.ApproverComments) + " from " + history.LevelCategory + " on- " + history.ApprovalActionDate) + '\n'
                    notes += ("Level " + history.Level) + (history.Notes != null ? (" : " + history.Notes) : '') + '\n'
                });
                approval[item.requestId] = approvalHistory
                approverNotes[item.requestId] = notes
            });
            this.setState({ AppproverDetails: res.Data.requestData, approvalHistory: approval, approverNotes: approverNotes });
                res.Data && this.getCSVfile(items);
        });
    }
    private getCSVfile = (items: any) => {
        const { approvalHistory, approverNotes } = this.state
        let data: Array<RequestCSVDetails> = [];
        items.forEach(item => {
            let MigrationApprovalHistory = '';
            if (item.requestType == "Migration") {
                item.approvalHistory?.forEach(history => {
                    MigrationApprovalHistory += "Level " + history.Level + " Approved by " + history.ActionByAlias + " from " + history.ApprovalCategory + " on- " + history.ApprovalActionDate + '\n'
                });
            }
            data.push({
                RequestorEmail: item.requestorEmail,
                RequestorPrincipal: item.requestorPrincipal,
                RequestId: item.requestId,
                BusinessJustification: item.businessJustification,
                RequestedDate: item?.createdDate,
                RequestType: item.requestType,
                Status: item.status,
                Scope: item.scope,
                CompletedDate: item?.completedDate,
                ExecutiveSponsor: item.approvers.ExecutiveSponsor?.join(";"),
                ConsumerPrivacy: item.approvers.ConsumerPrivacy == null ? '' : item.approvers.ConsumerPrivacy?.join(";"),
                ApprovalHistory: item.requestType == "Migration" ? MigrationApprovalHistory : approvalHistory[item.requestId],
                Notes: approverNotes[item.requestId] ?? '',
                PublisherName: item.properties?.publisherName,
                ConsumerRequestId: item.properties?.consumerRequestId,
                PpeRequestId: item.properties?.ppeRequestId,
                ConsumerServicetreeId: item.properties?.servicetreeId,
                ConsumerServiceTreeName: item.properties?.serviceTreeName,
                IsSOX: item.properties?.isSOX ? "Yes" : "No",
                IsCplusAI: item.requestType == "Migration" ? "NA" : item.properties?.isCplusAI ? "Yes" : "No",
                DataContractLinks: item.requestType == "Migration" ? "NA" : '=HYPERLINK(' + '\""' + item.properties?.dataContractLinks + '\"", ' + '\""Click here to view Data Contract Links\""' + ')',
                HasClassifiedData: item.properties?.hasClassifiedData ? "Yes" : "No",
                HasPersonalData: item.properties?.hasPersonalData ? "Yes" : "No",
                AccountIdType: item.properties?.accountIdType,
                AccountId: item.properties?.accountId,
                ConsumerOwners: item.properties?.ConsumerOwners == null ? '' : item.properties.ConsumerOwners?.join(";"),
                AssetInfo: item.assetDetails.map((item, i = 0) => {
                    while (i < 100)
                        return ("Asset Name: " + item.EntityName + "; Asset Id: " + item.AssetId + '\n\n')
                    i++
                }).toString().replaceAll(',', '').replaceAll(';', ','),
                AssetInfo1: item.assetDetails.map((item, i = 0) => {
                    while (i > 99 && i < 200)
                        return ("Asset Name: " + item.EntityName + "; Asset Id: " + item.AssetId + '\n\n')
                    i++
                }).toString().replaceAll(',', '').replaceAll(';', ','),
                AssetInfo2: item.assetDetails.map((item, i = 0) => {
                    while (i > 199 && i < 300)
                        return ("Asset Name: " + item.EntityName + "; Asset Id: " + item.AssetId + '\n\n')
                    i++
                }).toString().replaceAll(',', '').replaceAll(';', ','),
                AssetInfo3: item.assetDetails.map((item, i = 0) => {
                    while (i > 299 && i < 400)
                        return ("Asset Name: " + item.EntityName + "; Asset Id: " + item.AssetId + '\n\n')
                    i++
                }).toString().replaceAll(',', '').replaceAll(';', ','),
                AssetInfo4: item.assetDetails.map((item, i = 0) => {
                    while (i > 399 && i < 500)
                        return ("Asset Name: " + item.EntityName + "; Asset Id: " + item.AssetId + '\n\n')
                    i++
                }).toString().replaceAll(',', '').replaceAll(';', ','),
                AssetInfo5: item.assetDetails.map((item, i = 0) => {
                    while (i > 499 && i < 600)
                        return ("Asset Name: " + item.EntityName + "; Asset Id: " + item.AssetId + '\n\n')
                    i++
                }).toString().replaceAll(',', '').replaceAll(';', ','),
                AssetInfo6: item.assetDetails.map((item, i = 0) => {
                    while (i > 599 && i < 700)
                        return ("Asset Name: " + item.EntityName + "; Asset Id: " + item.AssetId + '\n\n')
                    i++
                }).toString().replaceAll(',', '').replaceAll(';', ','),
            })
        });
        return JSONToCSVConvertor(data, "ConsumerAccessDetails");
    }
    /**
     * Initial onInit function - first entry point
     */
    private getDetails = () => {
        this.setState({ AccessRequest: { IsDataLoaded: true, IsSuccess: true, Data: null }, items: [], OriginalAccessRequest: [], searchRequestId: '', status: 'All', userEmail: null, consumerServiceTreeId: '', consumerRequestId: '', selectedStatusKey: StatusOptions[0].key, selectedEnvValue: EnvironmentOptions[0].text, tenantId: EnvironmentOptions[0].key, pickerCtrlKey: this.state.pickerCtrlKey + 1 });
    };

    private getColumnDefintion() {
        return [
            { key: 'consumerRequestId', name: 'Consumer Request Id', fieldName: 'consumerRequestId', minWidth: 260, maxWidth: 260, isResizable: false },
            { key: 'user', name: 'User', fieldName: 'user', minWidth: 120, maxWidth: 140, isResizable: false },
            { key: 'requestId', name: 'Request Id', fieldName: 'requestId', minWidth: 200, maxWidth: 200, isResizable: false, onRender: this._onRenderItemColumn },
            { key: 'consumerServiceTreeId', name: 'Consumer Service Tree Id', fieldName: 'consumerServiceTreeId', minWidth: 260, maxWidth: 260, isResizable: false },
            { key: 'accountIdType', name: 'Account Id Type', fieldName: 'accountIdType', minWidth: 110, maxWidth: 110, isResizable: false },
            { key: 'accountId', name: 'Account Id', fieldName: 'accountId', minWidth: 270, maxWidth: 270, isResizable: false },
            { key: 'requestType', name: 'Request Type', fieldName: 'requestType', minWidth: 120, maxWidth: 120, isResizable: false },
            { key: 'createdDate', name: 'Created Date', fieldName: 'createdDate', data: 'number', minWidth: Utility.getDynamicColumnSize(90), maxWidth: Utility.getDynamicColumnSize(120), isResizable: false },
            { key: 'completedDate', name: 'Completed Date', fieldName: 'completedDate', data: 'number', minWidth: Utility.getDynamicColumnSize(110), maxWidth: Utility.getDynamicColumnSize(120), isResizable: false },
            { key: 'status', name: 'Status', fieldName: 'status', minWidth: 80, maxWidth: 80, isResizable: false },
            { key: 'assetDetails', name: '', fieldName: 'assetDetails', minWidth: 85, maxWidth: 85, isResizable: false, onRender: this._onRenderItemColumn },
        ];
    }
}
interface AssetViewGridProps {
    items,
    dataLoaded,
    columnCollection
}
export const AssetViewGrid: React.FunctionComponent<AssetViewGridProps> = (props) => {
    const [gridData, setgridData] = React.useState<any>([]);
    const [isGridLoading, setIsGridLoading] = React.useState<boolean>(true);
    const [colCollection, setColCollection] = React.useState<any>([]);

    const fetchData = (): void => {
        setIsGridLoading(true);
        setColCollection(props.columnCollection);
        if (props.dataLoaded) {
            setIsGridLoading(false);
            // Sort the items.
            let sortedItems = _copyAndSort(props.items, 'createdDate', true);
            setgridData(sortedItems);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [props.items]);

    const _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        let isSortedDescending = column.isSortedDescending;
        let sortedItems = [];
        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(gridData, column.fieldName!, isSortedDescending);
        // Reset the items and columns to match the state.

        setgridData(sortedItems);
        let updatedColumnState = colCollection.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        setColCollection(updatedColumnState);

    };
    return (<React.Fragment>
        <div id="fdl-consumer-asset" style={{
            display: 'block',
            margin: '0 auto',
        }} >
            <ShimmeredDetailsList
                setKey="items"
                columns={props.columnCollection}
                items={gridData}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="User Management List"
                listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                enableShimmer={isGridLoading}
                onColumnHeaderClick={_onColumnClick}
            />
        </div>
    </React.Fragment>
    );
}
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    if (columnKey === 'completedDate' || columnKey === 'createdDate') {
        return items.slice(0).sort(function (a: T, b: T) {
            var c: any = new Date(a[columnKey]);
            var d: any = new Date(b[columnKey]);
            return isSortedDescending ? d - c : c - d;
        });

    } else {
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}
