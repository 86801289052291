import * as React from 'react';

// This class is used as a wrapper to catch any errors that may occur on its children.
class AppErrorBoundry extends React.Component<{}, {}> {

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fall-back UI.
    return { hasError: true };
  }
  // Catch any errors that occurred in our application
  public componentDidCatch(error: Error, info: object) {
    //TO DO : log exceptions for any javascript errors.
    error || console.error(error);
    info || console.log(info);
  }

  public render() {
    return this.props.children;
  }
}

export default AppErrorBoundry;
