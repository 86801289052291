import React from 'react';
import {
    IColumn,
    Fabric,
    DetailsListLayoutMode,
    SelectionMode,
    ShimmeredDetailsList,
    Label,
    Spinner, SpinnerSize, Icon, MessageBar, MessageBarType, TextField
} from '@fluentui/react';
import { DefaultButton, IButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { RouteComponentProps } from 'react-router-dom';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { SupplierUsers } from '../../shared/models/SupplierUser';
import { AttributePicker } from '../Request/AttributePicker';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';

export interface SupplierUserMgmtProps {
    applicationData: any;

}

export interface SupplierUserManagementState {
    userItems: [];
    userDetails: IUser;
    message: string;
    messageType: MessageBarType;
    autoHideMessage: boolean;
    showMessage: boolean;
    dataLoaded: boolean;
    allItems: SupplierUsers;
    gridDetails: any;
    columnCollection: [];
    selectedPrincipalId: string;
    selectedSupplierId: string;
    selectedUserEmail: string;
    dynamicId: number;
    pickerCtrlKey: number;
    showLastLoggedInDate: boolean;
    isValidEmail: boolean;
}

type SupplierUserManagementProps = SupplierUserMgmtProps
    & RouteComponentProps<{}>;

export type GridDetails = {
    user: string;
    supplierId: string;
    supplierRequestId: string;
    role: string;
    createdDate: any;
    isSoftBlocked: string;
    lastAccessedDate: any;
}
export class SupplierUserManagement extends React.Component<SupplierUserManagementProps>  {
    private tenantData: ITenant = null;
    allInProgressItems = [];
    allProvisionedItems = [];

    searchBoxInput: React.RefObject<unknown>;


    private alertMsgCloseBtnRef = React.createRef<IButton>();


    private getColumnDefintion = () => {
        return [
            // { key: 'supplierReqId', name: 'Supplier RequestID', fieldName: 'supplierRequestId', minWidth: 150, maxWidth: 150, isResizable: false },
            { key: 'supplier', name: 'SupplierID', fieldName: 'supplierId', minWidth: 125, maxWidth: 125, isResizable: false },
            { key: 'supplierReq', name: 'Supplier RequestID', fieldName: 'supplierRequestId', minWidth: 125, maxWidth: 125, isResizable: false },
            { key: 'user', name: 'User Email', fieldName: 'user', minWidth: 275, maxWidth: 275, isResizable: true },
            { key: 'role', name: 'Role Name', fieldName: 'role', minWidth: 150, maxWidth: 150, isResizable: false },
            { key: 'sftBlock', name: 'Soft Block', fieldName: 'isSoftBlocked', minWidth: 150, maxWidth: 150, isResizable: false },
            { key: 'createdDate', name: 'Created Date', fieldName: 'createdDate', data: 'number', minWidth: Utility.getDynamicColumnSize(60), maxWidth: Utility.getDynamicColumnSize(120), isResizable: false },
            this.state.showLastLoggedInDate && { key: 'lastAccessedDate', name: 'Last Logged in date', fieldName: 'lastAccessedDate', data: 'number', minWidth: Utility.getDynamicColumnSize(70), maxWidth: Utility.getDynamicColumnSize(140), isResizable: false },
        ];
    }

    constructor(props: any) {
        super(props);
    }
    public state: SupplierUserManagementState = {
        userDetails: null,
        userItems: [],
        message: '',
        messageType: MessageBarType.info,
        autoHideMessage: true,
        showMessage: false,
        dataLoaded: false,
        allItems: null,
        gridDetails: null,
        columnCollection: [],
        selectedPrincipalId: '',
        selectedSupplierId: '',
        selectedUserEmail: '',
        dynamicId: 0,
        pickerCtrlKey: 0,
        showLastLoggedInDate: false,
        isValidEmail: true
    }
    public async componentDidMount() {
        let tenantName = (this.props.match.params as any).tenantName;
        this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === tenantName.toLowerCase())[0];
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let columnCollection = this.getColumnDefintion();

        this.setState({ userDetails: user, columnCollection: columnCollection });
        this.onInit(this.tenantData);
    }

    private renderMessage = () => {
        let { message, messageType, autoHideMessage } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds

        setTimeout(() => {
            this.alertMsgCloseBtnRef.current.focus();
        }, 100) // set the focus to close button

        return <div role="alert" aria-label={message} >
            <MessageBar key='Msg' style={{ fontSize: '.875rem' }}
                actions={
                    <div>
                        <IconButton componentRef={this.alertMsgCloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false, setAttributeFocus: false }) }} />
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                {message}
            </MessageBar>
        </div>
    };



    public render(): JSX.Element {
        const { showMessage, dataLoaded, allItems, gridDetails, pickerCtrlKey, selectedSupplierId, selectedPrincipalId, dynamicId, showLastLoggedInDate, selectedUserEmail } = this.state;
        return (dataLoaded ? <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 50 }}>
                {(showMessage) && this.renderMessage()}
            </div>
            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg10"}>
                        <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.SupplierUserReport}</h1></Label>
                    </div>
                </div>
                <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg3"} >
                        <Label >{'   '}SupplierID</Label>
                        {/* <TextField placeholder="Search for Supplier" onChange={this._onChangeSupplierName} /> */}

                        <AttributePicker key={'div-attr-' + dynamicId } setAttributeFocus={false} roleName={'Invoice'} attributeName={'SupplierID'}
                            isDisabled={false} onItemSelected={this.onAttributePickerChange}
                            tenantData={this.tenantData} itemLimit={1}
                            isAsync={true}
                        ></AttributePicker>
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg3"} >
                        <Label>User Email</Label>
                        {/*<PeoplePicker
                            key={pickerCtrlKey}
                            type={PeoplePickerType.Normal}
                            contentType={PeoplePickerContentType.ExternalUser}
                            selectionMode={PeoplePickerSelectionMode.Single}
                            onchange={(allItems) => { this.onPeoplePickerChange(allItems) }}
                            defaultSelectedItems={[]}
                            tenantName={this.tenantData.TenantName}
                            {...this.props}
                            setFocusOnPicker={false}
                        ></PeoplePicker> */}
                        <TextField placeholder="Search for User Email" styles={{ fieldGroup: [{ height: 40 }] }} onChange={this._onUserEmailChange} value={selectedUserEmail} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg3"} >
                        <PrimaryButton style={{ marginTop: 27 }} onClick={this.onSearchClick} >{UIConstants.ButtonText.Search} </PrimaryButton>
                        <span>&nbsp;</span>
                        <DefaultButton disabled={!showLastLoggedInDate} onClick={this.onResetClick} > {UIConstants.ButtonText.Reset} </DefaultButton>
                    </div>
                </div>
                <div key="work-area" >
                    {(allItems?.IsDataLoaded) ? <div key='grid-row-grid' id={'div-myaccess'} className={"ms-Grid-row"} >
                        <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg12"}>
                            <SupplierAdminViewGrid dataLoaded={allItems.IsDataLoaded} allItems={gridDetails}
                                columnCollection={this.getColumnDefintion()}
                            > </SupplierAdminViewGrid>
                        </div>
                    </div> : <><br /><br /> <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" /></>}
                </div>
            </div>
            <br />
        </React.Fragment> : <Spinner style={{ position: 'fixed', top: 'calc(50vh - 90px)', left: 'calc(50vw - 90px)' }} size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />

        );


    }

    private onResetClick = (): void => {
        const { selectedUserEmail, selectedSupplierId, dynamicId, pickerCtrlKey } = this.state;
        let newId = dynamicId + 1;
        let newkey = pickerCtrlKey + 1;
        this.setState({ selectedUserEmail: '', selectedPrincipalId: '', selectedSupplierId: '', dynamicId: newId, pickerCtrlKey: newkey, showLastLoggedInDate:false, showMessage: false, allItems: { IsDataLoaded: false, Data: [], IsSuccess: true }, isValidEmail: true });
        this.fetchSupplierData('', '');
    };

    private onSearchClick = async (): Promise<void> => {
        const { selectedPrincipalId, selectedSupplierId, isValidEmail, selectedUserEmail } = this.state;

        if (selectedPrincipalId != '' || selectedSupplierId != '') {
            this.setState({ showLastLoggedInDate: true });
        }
        else {
            this.setState({ showLastLoggedInDate: false });
        }
        if (selectedPrincipalId.trim().length == 0 && selectedSupplierId.trim().length == 0) {
            this.setState({ showMessage: true, messageType: MessageBarType.warning, message: 'Either SupplierID or User Email is required', autoHideMessage: false });
            return;
        }
        if (selectedUserEmail.trim().length != 0 && !isValidEmail) {
            this.setState({ showMessage: true, messageType: MessageBarType.warning, message: 'User Email is invalid', autoHideMessage: false });
            return;
        }
        if (selectedSupplierId.trim().length == 0 && selectedPrincipalId.trim().length < 3) {
            this.setState({ showMessage: true, messageType: MessageBarType.warning, message: '3 or more characters required for User Email', autoHideMessage: false });
            return;
        }

        this.setState({ showMessage: false, allItems: { IsDataLoaded: false, Data: [], IsSuccess: true } });

        if (selectedPrincipalId.trim().length > 0 && selectedSupplierId.trim().length > 0) {
            this.applyFilter(selectedPrincipalId, selectedSupplierId);
        } else if (selectedPrincipalId.trim().length > 0 && selectedSupplierId.trim().length == 0) {
            this.applyFilter(selectedPrincipalId, '');
        }
        else if (selectedPrincipalId.trim().length == 0 && selectedSupplierId.trim().length > 0) {
            this.applyFilter('', selectedSupplierId);
        }
    };
    private onAttributePickerChange: any = (name, val) => {
        const { selectedPrincipalId, selectedSupplierId } = this.state;
        let supId = '';
        if (val && val.length > 0) {
            supId = val[0].code;
        }
        this.setState({ selectedSupplierId: supId});
    };
    private onPeoplePickerChange(items: any) {
        const { selectedPrincipalId, selectedSupplierId } = this.state;
        let id = '';
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            id = parsedData.id;
        }
        this.setState({ selectedPrincipalId: id, isValidEmail: true });
      //  this.applyFilter(id, selectedSupplierId);
    }

    private _onUserEmailChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        const { selectedPrincipalId, selectedSupplierId, selectedUserEmail } = this.state;
        let filter = /^\w|\d+@[a-zA-Z_.0-9]+?\.[a-zA-Z]{2,3}$/;
        let result = text.match(filter) != null
        this.setState({ selectedUserEmail: text, selectedPrincipalId: text, isValidEmail: result });
    };

    applyFilter(selectedPrincipalId: string, selectedSupplierId: string) {
        this.fetchSupplierData(selectedPrincipalId, selectedSupplierId);
    }

    /**
     * Initial onInit function - first entry point
     */
    private onInit = (tenantData: ITenant) => {
        const { selectedPrincipalId, selectedSupplierId } = this.state;
        if (tenantData) {
            this.fetchSupplierData(selectedPrincipalId, selectedSupplierId);
        } else {
            window.location.href = "/";
        }
    };

    private fetchSupplierData(selectedPrincipalId: string, selectedSupplierId: string) {
        let SupplierUserRequests = AccessAPI.SupplierUserRequests(this.tenantData.TenantId, selectedPrincipalId, selectedSupplierId);
        let SupplierUsersAuthActivity = AccessAPI.getSupplierUserAuthActivityBasedOnSearch(this.tenantData.TenantId, selectedSupplierId, selectedPrincipalId);

        Promise.all([SupplierUserRequests, SupplierUsersAuthActivity]).then((responses) => {

            let SupplierUserRequestsResponse = responses[0];
            let SupplierUsersAuthActivityResponse = responses[1];

            let items: Array<GridDetails> = [];
            SupplierUserRequestsResponse.Data && SupplierUserRequestsResponse.Data.forEach(item => {
                let filteredItem = selectedSupplierId && selectedSupplierId.trim().length > 0  ? item.supplier.filter(x => x.SupplierId == selectedSupplierId) : item.supplier;
                filteredItem.forEach(supplier => {
                    let filteredAuthActivity = [];
                    if (SupplierUsersAuthActivityResponse.Data && SupplierUsersAuthActivityResponse.Data.length > 0) {
                        filteredAuthActivity = SupplierUsersAuthActivityResponse.Data.filter(x => x.userEmail.toLowerCase() == item.userEmail.toLowerCase());
                    }

                    let lastAccessedDate = '-';
                    if (filteredAuthActivity.length > 0) {
                        lastAccessedDate = new Date(filteredAuthActivity[0].lastLoginDate).toLocaleString().split(",")[0];
                    }
                    items.push({
                        user: item.userEmail,
                        supplierId: supplier.SupplierId,
                        supplierRequestId: supplier.SupplierRequestId,
                        role: supplier.AppRole[0].RoleName ,
                        isSoftBlocked: supplier.ReadOnly ? 'Yes' : 'No',
                        createdDate: new Date(item.createdDate).toLocaleString().split(",")[0],
                        lastAccessedDate: lastAccessedDate
                    });
                });
            });

            this.setState({ allItems: SupplierUserRequestsResponse, gridDetails: items, dataLoaded: SupplierUserRequestsResponse.IsDataLoaded });
        });
    }
}
interface SupplierAdminViewGridProps {
    allItems,
    dataLoaded,
    columnCollection
}
export const SupplierAdminViewGrid: React.FunctionComponent<SupplierAdminViewGridProps> = (props) => {
    const [gridData, setgridData] = React.useState<any>([]);
    const [isGridLoading, setIsGridLoading] = React.useState<boolean>(true);
    const [colCollection, setColCollection] = React.useState<any>([]);

    const fetchData = (): void => {
        setIsGridLoading(true);
        setColCollection(props.columnCollection);
        if (props.dataLoaded) {
            setIsGridLoading(false);
            // Sort the items.
            let sortedItems = _copyAndSort(props.allItems, 'user', false);
            setgridData(sortedItems);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [props.allItems]);

    const _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        let isSortedDescending = column.isSortedDescending;
        let sortedItems = [];
        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(gridData, column.fieldName!, isSortedDescending);
        // Reset the items and columns to match the state.

        setgridData(sortedItems);
        let updatedColumnState = colCollection.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        setColCollection(updatedColumnState);

    };
    return (<React.Fragment>
        <Fabric id="admin-user-mgmt-grid" style={{
            maxHeight: '70vh',
            overflow: 'auto',
            display: 'block',
            margin: '0 auto',
        }} >
            <ShimmeredDetailsList
                setKey="items"
                columns={props.columnCollection}
                items={gridData}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="User Management List"
                listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                enableShimmer={isGridLoading}
                onColumnHeaderClick={_onColumnClick}
            />
        </Fabric>
    </React.Fragment>
    );
}
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    // if (!items) {
    //     items = [];
    // }

    if (columnKey === 'createdDate' || columnKey === 'lastAccessedDate') {
        return items.slice(0).sort(function (a: T, b: T) {
            var c: any = new Date(a[columnKey] == "-" ? "12/30/2099" : a[columnKey]);
            var d: any = new Date(b[columnKey] == "-" ? "12/30/2099" : b[columnKey]);
            return isSortedDescending ? d - c : c - d;
        });

    } else {
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}
