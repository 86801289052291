import React from 'react';
import _ from "lodash";
import { Checkbox, ComboBox, DefaultButton, DialogType, IComboBoxOption, IComboBoxStyles, Text, IDropdownOption, ILabelStyles, IStyleSet, ITextField, Label, MessageBarType, PrimaryButton, Spinner, SpinnerSize, TextField, getId, IStyle, ILabelStyleProps, IStyleFunctionOrObject, Link } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import AccessAPI from '../../store/AccessAPI';
import ITenant from '../../shared/models/Tenant.model';
import { AttributePicker } from './AttributePicker';
import { DialogModel } from '../../shared/models/Dialog.model';
import { RoleDetails, Attributes, AttributeValues, AccessRequestSubmissionModel } from '../../shared/models/UserAccess.model';
import { Role, AttributeConfig, AttributeType } from "../../shared/models/Role.model"
import { ModalDialog } from '../ModalDialog';
import { ValidationResponse } from '../../shared/models/ValidationResponse.model';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import { EmployeeDetails } from '../../shared/models/EmployeeDetails';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { Permission, PermissionGroup, PermissionLevel, PermissionRender, ApprovedScopes  } from '../../shared/models/Mercury';
import CheckboxTree from 'react-checkbox-tree';
import { MyCurrentAccessModel } from '../../shared/models/MyCurrentAccess.model';
import ConfigurationAPI from '../../shared/api/Configuration.api';


const labelStyles: Partial<IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>> = {
    root: { marginTop: 7 }
};
const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 350, width: 350 } };
export interface IMercuryRequestState {
    selectedRole?: Role;
    selectedAttributes?: Attributes[];
    userDetails: IUser;
    editRequest: boolean;
    hideDialog: boolean;
    saveInProgress: boolean;
    showMessage: boolean;
    permissionType: string;
    permissionGroup: string;
    permissionLevel: string;
    businessJustification: string;
    permissionStructure: Permission[];
    messageBarType: MessageBarType;
    message: string;
    attributes: any;
    requestType: string;
    messageType: MessageBarType;
    hideBj: boolean;
    autoHideMessage: boolean;
    requestorPrincipalId: string;
    modalDialogContent: any;
    attributeConfig: AttributeConfig[];
    setAttributeFocus: boolean;
    scompData: any;
    attributesLoaded: boolean;
    isAlternateManager: string;
    isAlternateManagerChecked: boolean;
    alternateManagerAlias: string;
    pickerCtrlKey: number;
    ManagerAlias: EmployeeDetails;
    selectedPermisisonType : Permission;
    selectedPermisisonGroup : PermissionGroup;
    selectedPermisisonLevel : PermissionLevel;
    permissionLevelList : any;
    tenantData: ITenant;
    hasGaapAccess : boolean;
    hasAttributeAccess : boolean;
    showAdditional : boolean;
    selectedAttributeKey: any;
    permissionMaster: PermissionRender[];
    regionConfig: any;
    regionChecked: any;
    regionExpanded: any;
    regionValues: any;
    flagChecked: boolean;
    editMode: boolean;
    enableRequestOnBehalf: boolean;
    requestorPickerCtrlKey: number;
    showAlternateManager: boolean;
    myaccess: MyCurrentAccessModel;
    approvedScopes: ApprovedScopes;
    scopeValue: string;
    managerCEOMinus1or2: string;
}
export interface IMercuryRequestProperty {
    tenantData: ITenant;
    toggleProgressBar? : any;
    toggleMessageBar? : any;
    isEditMode : boolean;
    editAccessData: any;
    messageBarRef: React.RefObject<HTMLDivElement>;
}

export default class MercuryRequest extends React.Component<IMercuryRequestProperty, IMercuryRequestState> {
    private refBJ = React.createRef<ITextField>();
    private scopeRef = React.createRef<HTMLSelectElement>();
    private tenantData: ITenant = null;
    private submitClicked: boolean = false;
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    }

    public state: IMercuryRequestState = {
        selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' },
        selectedAttributes: [],
        userDetails: null,
        editRequest: false,
        hideDialog: true,
        saveInProgress: false,
        showMessage: false,
        permissionType: '0',
        permissionGroup: '0',
        permissionLevel: '0',
        permissionStructure: [],
        businessJustification: '',
        messageBarType: MessageBarType.warning,
        message: '',
        attributes: null,
        requestType: 'Add',
        messageType: MessageBarType.info,
        hideBj: true,
        autoHideMessage: true,
        requestorPrincipalId: '',
        modalDialogContent: this._modalDialogContent,
        attributeConfig: [],
        setAttributeFocus: false,
        scompData: null,
        attributesLoaded: false,
        isAlternateManager: '',
        isAlternateManagerChecked: false,
        alternateManagerAlias: '',
        pickerCtrlKey: 0,
        ManagerAlias: { id: '', name: '', displayName: '', email: '', upn: '', surname: '', domain: '', jobTitle: '' },
        selectedPermisisonType : null,
        selectedPermisisonGroup : null,
        selectedPermisisonLevel : null,
        tenantData: null,
        hasGaapAccess: false,
        showAdditional : false,
        hasAttributeAccess : false,
        selectedAttributeKey : "0",
        permissionLevelList: [],
        permissionMaster: [],
        regionChecked: [],
        regionExpanded: [],
        regionConfig: [],
        regionValues: [],
        flagChecked: false,
        editMode: false,
        enableRequestOnBehalf: false,
        requestorPickerCtrlKey: 0,
        showAlternateManager: true,
        myaccess: { approvedAccess: [], pendingRequests: [] },
        approvedScopes: { scopeValues: [], hasAdditionalAccess: false },
        scopeValue: "",
        managerCEOMinus1or2:''
    };
    public constructor(props) {
        super(props);
    }
    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        const { tenantData } = this.props;
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
      
        setTimeout(() => {
            this.setState({ userDetails: user, tenantData: tenantData , editRequest: false});
        }, 500);
        let myaccess = AccessAPI.getMyAccess(tenantData.TenantId);
        let mercuryData = AccessAPI.GetMercuryData();
        Promise.all([mercuryData, myaccess]).then((responses) => {
            let permissionData: Permission[] = [...responses[0]];
            let permissionMasterList : PermissionRender[] = [];
            permissionData.forEach(permissionTypeItem => {
                permissionTypeItem.permission.forEach(permGrpItem => {
                    permGrpItem.permissionLevels.forEach(permLevelItem => {
                        permissionMasterList.push({
                            permissionTypeID: permissionTypeItem.permissionTypeID,
                            permissionType: permissionTypeItem.permissionType,
                            permissionTypesortOrder: permissionTypeItem.sortOrder,
                            permissionGroupID: permGrpItem.permissionGroupID,
                            permissionGroup: permGrpItem.permissionGroup,
                            permissionGroupDescription: permGrpItem.permissionGroupDescription,
                            permissionGroupsortOrder: permGrpItem.sortOrder,
                            permissionLevelID: permLevelItem.permissionLevelID,
                            permissionLevel: permLevelItem.permissionLevel,
                            permissionLevelSortOrder: permLevelItem.sortOrder,
                            permissionLevelDescription: permLevelItem.permissionLevelDescription,
                            allowEdit: permLevelItem.allowEdit,
                            approvalPolicy: permLevelItem.approvalPolicy,
                            attribute: permLevelItem.attribute,
                            isActive: permLevelItem.isActive,
                            role: permLevelItem.role
                        })
                    });
                })
            });
            permissionMasterList = _.orderBy(permissionMasterList, ['permissionTypesortOrder', 'permissionGroupsortOrder', 'permissionLevelSortOrder']);
            this.setState({ permissionStructure: responses[0], permissionMaster: permissionMasterList, myaccess: responses[1].Data });
        });

        AccessAPI.AllowUserToRequestOnBehalf(tenantData.TenantId).then(res => {
            this.setState({ enableRequestOnBehalf: res })
        })

        
        ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.MercuryConfig).then(response => {
            let configValue = response ? JSON.parse(response.replace(/'/g, `"`)) : null;
            if (configValue != null && configValue?.EnableCEOMinus1 && configValue?.testRequestor.includes(this.state.userDetails?.Alias.split("@")[0])) {
                AccessAPI.searchUser(PeoplePickerContentType.User, configValue.staticCEOMinus1RequestorAlias).then(searchResponse => {
                    let user = searchResponse[0];
                    this.setState({ userDetails: { PrincipalId: user.id, Alias: user.upn, Name: user.name } })
                    AccessAPI.GetManager(user.upn).then(res => {
                        res && this.setState({ ManagerAlias: res, userDetails: { PrincipalId: user.id, Alias: user.upn, Name: user.name } });
                        if (res && res.jobTitle?.toLowerCase() == 'ceo') {
                            this.setState({ managerCEOMinus1or2: "Warning: Access request cannot be routed to CEO. Please choose an alternate FTE approver." });
                        }
                        else {
                            res && res.name != "N/A" && res.name != '' && AccessAPI.ValidateUserJobTitle(res.upn).then(res => {
                                if (res) {
                                    this.setState({ managerCEOMinus1or2: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
                                }
                                else {
                                    this.setState({ managerCEOMinus1or2: '' });
                                }
                            });
                        }
                    });
                })
            }
            else {
                AccessAPI.GetManager(user.Alias).then((res => {
                    res && this.setState({ ManagerAlias: res });
                    if (res && res.jobTitle?.toLowerCase() == 'ceo') {
                        this.setState({ managerCEOMinus1or2: "Warning: Access request cannot be routed to CEO. Please choose an alternate FTE approver." });
                    }
                    else {
                        res && res.name != "N/A" && res.name != '' && AccessAPI.ValidateUserJobTitle(res.upn).then((res => {
                            if (res) {
                                this.setState({ managerCEOMinus1or2: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
                            }
                            else {
                                this.setState({ managerCEOMinus1or2: '' });
                            }
                        }));
                    }
                }));
            }
            this.setState({ scopeValue: configValue?.ScopeValue });
        });
        
    }

    public componentWillReceiveProps(newProps){
        const { permissionStructure, requestorPickerCtrlKey } = this.state
        const { isEditMode, editAccessData } = newProps;
        if (!this.submitClicked && isEditMode && editAccessData) {
            this.getApprovedAccessOnEdit(editAccessData)         
            this.setState({ selectedAttributes: [], editMode: true, editRequest: true, requestorPickerCtrlKey: requestorPickerCtrlKey + 1 })
            let permissionGroup = _.flatten(permissionStructure.map(x => { return x.permission }));
            let permissionLvl = _.flatten(permissionGroup.map(x => { return x.permissionLevels }));
            let selectedPl = permissionLvl.find(x => x.permissionLevelID == editAccessData.accessItem.PermissionLevelID);
            let selectedPermGrp = permissionGroup.find(x => x.permissionGroupID == editAccessData.accessItem.PermissionGroupID);
            //  permissionGroup.Where(x => x.permissionLevels.filter(y => y.permissionLevelID == selectedPl.permissionLevelID));
            //let selectedPermType = permissionStructure.filter(x => x.permission.filter(y => y.permissionGroupID == selectedPermGrp.permissionGroupID));
            if (editAccessData.multiAttributes!=null && editAccessData.multiAttributes.length>0) {
                editAccessData.multiAttributes.forEach(attribute => {
                    if (attribute.attributeName == "External Reporting" && attribute.attributeValueText.includes("True")) {
                        this.setState({ flagChecked: true, selectedAttributes: [] });
                        let attributeValue = [{ "code": "true", "description": "true" }];
                        this.updateAttributeValues(attribute.attributeName, attributeValue, true);
                    }
                    else if (attribute.attributeName == "Statutory" && attribute.attributeValueText.includes("True")) {
                        this.setState({ flagChecked: true, selectedAttributes: [] });
                        let attributeValue = [{ "code": "true", "description": "true" }];
                        this.updateAttributeValues("Statutory reporting access to Shadow Company data for legal entity reporting (includes access to the Statutory Balance Sheet and Statutory P&L perspectives)", attributeValue, true);
                    }
                });
            }
            let plList = selectedPermGrp.permissionLevels.map(x=> {return {key: x.permissionLevelID, text: x.permissionLevel}});

            let hasAttributeAccess = false;

            if (selectedPl.attribute?.requireApprovedAccess) {
                hasAttributeAccess = true;
            }
            this.setState({ permissionGroup : selectedPermGrp.permissionGroupID , 
                permissionLevel : selectedPl.permissionLevelID , permissionLevelList : plList , hasAttributeAccess : hasAttributeAccess });

            setTimeout(() => {
                this.loadAttributes(true,selectedPl);
            }, 200);
        }
    }
    /**
     * UI Render
     */
    public render(): JSX.Element {
        const { saveInProgress,permissionMaster, permissionGroup, permissionLevel, permissionStructure, businessJustification, isAlternateManagerChecked, requestorPrincipalId,
            isAlternateManager, pickerCtrlKey, selectedPermisisonGroup, selectedPermisisonLevel, permissionLevelList, editMode, showAlternateManager, ManagerAlias, managerCEOMinus1or2 } = this.state
  
        
        return <React.Fragment>
            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                {permissionStructure ? <div key='grid-row-role' className={"ms-Grid-row"}>
                    {/* <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                        <div>
                            <Label required={true} styles={labelStyles}>{UIConstants.PermissionType}</Label>
                            <select id={'drpGrp-pt'} value={permissionType}
                                title={UIConstants.PermissionType} required={true}
                                onChange={this.onPermissionTypeDrpDwnChange}
                                style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                <option value="0">-- Select --</option>
                                {this.getPermissionTypes()?.map(x => { return <option key={'drpGrp-pt' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                            </select>
                        </div>
                    </div> */}
                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                        <div>
                            <Label required={true} styles={labelStyles}>{UIConstants.Role}</Label>
                            <select id={'drpGrp-pg'} value={permissionGroup}
                                title={UIConstants.PermissionGroup} required={true}
                                onChange={this.onPermissionGroupDrpDwnChange}
                                disabled={editMode}
                                style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                <option value="0">-- Select --</option>
                                {this.getPermissionGroups()?.map(x => { return <option key={'drpGrp-pg' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                            </select>
                            {(selectedPermisisonGroup && typeof selectedPermisisonGroup.permissionGroupDescription != 'undefined' && selectedPermisisonGroup.permissionGroupDescription != null) && <div><Text>{selectedPermisisonGroup.permissionGroupDescription}</Text></div>}
                        </div>
                    </div>
                    {permissionGroup != "0" && permissionLevelList != null && permissionLevelList.length>0 && <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                        <div>
                            <Label required={true} styles={labelStyles}>{UIConstants.PermissionScope}</Label>
                            <select id={'drpGrp-pl'} value={permissionLevel}
                                title={UIConstants.Role} required={true}
                                disabled={editMode}
                                onChange={(event) => { this.onRoleChange(event) }}
                                style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                <option value="0">-- Select --</option>
                                {permissionLevelList && permissionLevelList.map((x, index) => { return <option key={'drpGrp-pl' + index + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                            </select>
                            {/*Written specific logic to display the link based on some special charecters for a given description*/}
                            {(selectedPermisisonLevel?.permissionLevelDescription) && <div><Text>{selectedPermisisonLevel.permissionLevelDescription.split("\n").map(x => x.includes("<<") && x.includes(">>") && x.split("<<")[1].includes("::") ? <>{x.split("<<")[0]}<Link href={x.split("<<")[1].split("::")[1].split(">>")[0]} target="_blank" underline>{x.split("<<")[1].split("::")[0]}</Link>{x.split(">>")[1]} < br /></> : <>{x} < br /></>)}</Text></div>}
                        </div>
                    </div>}
                    < div key='grid-row-attr' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: '15px', paddingTop: '10px' }}>
                            {permissionLevel != '0' && permissionLevelList != null && permissionLevelList.length > 0 && this.renderPermissionLevels()}
                        </div>
                    </div>
                    {(this.state.enableRequestOnBehalf) &&
                        <div key='grid-row-requestor' className={"ms-Grid-row"}>
                            <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"} style={{ paddingLeft: '16px' }}>
                                {this.renderOnbehalfCtrl()}
                            </div>
                        </div>}
                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                        <TextField
                            componentRef={this.refBJ}
                            styles={{ root: { marginTop: 10 } }} label={UIConstants.BusinessJustfication} multiline rows={3} maxLength={1000} value={businessJustification}
                            onChange={this.onJustificationChange} required
                            description={(1000 - businessJustification.length) + ' / 1000 Characters left'}
                        />
                        {!showAlternateManager && requestorPrincipalId!='' && <span style={{ width: '40%' }}>
                            <Label style={{ fontWeight: "normal", marginTop: 10, width: '100%' }}>
                                <span style={{ fontWeight: "bold", fontSize: 13 }}>Note: </span>
                                <span style={{ fontSize: 13 }}>Access will be provisioned without any approval</span>
                            </Label>
                        </span>}
                        {showAlternateManager && <span style={{ width: '40%' }}>
                            <Label style={{ fontWeight: "normal", marginTop: 10, width: '100%' }}>
                                Your request will be routed to your manager for review
                                <br />
                                &nbsp;&nbsp;&nbsp;Manager alias for approval: <span style={{ color: "#0064BF", fontWeight: "bold", fontSize: 13 }}>{this.state.ManagerAlias.upn.split('@')[0]}</span>
                                <br />
                                &nbsp;&nbsp;&nbsp;Manager name: <span style={{ color: "#0064BF", fontWeight: "bold", fontSize: 13 }}>{this.state.ManagerAlias?.name?.split('(')[0]}</span>
                                <br />
                            </Label>
                            {ManagerAlias && (ManagerAlias.jobTitle != null && ManagerAlias.jobTitle.toUpperCase() == 'CEO' && <div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right', marginBottom: '2px' }}>{UIConstants.Messages.preventedRoutetoCEO}</div>)}
                            {(managerCEOMinus1or2 && managerCEOMinus1or2 != '' && ManagerAlias && (ManagerAlias.jobTitle != null && ManagerAlias.jobTitle.toUpperCase() != 'CEO')) && <div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right', marginBottom: '2px' }}>{managerCEOMinus1or2}</div>}
                        </span>}
                        {showAlternateManager && <div style={{ display: 'inline-flex', "alignItems": 'baseline' }}>
                            <span>
                                <Checkbox styles={{ root: { paddingBottom: 10, width: 20, marginTop: 4 } }} checked={isAlternateManagerChecked}
                                    onChange={(ev, checked) => { this.setState({ isAlternateManagerChecked: checked, isAlternateManager: '', alternateManagerAlias: '', pickerCtrlKey: this.state.pickerCtrlKey + 1 }) }} />
                            </span>
                            <span>
                                <Label style={{ fontWeight: "normal" }}>
                                    &nbsp;  Select an alternate FTE approver for request review:  &nbsp;
                                </Label>
                            </span>
                            <span>
                                {!isAlternateManagerChecked ? <TextField styles={{ root: { width: 275 }, field: { height: 38 } }} placeholder={"Search for an alternate FTE approver"} disabled={!this.state.isAlternateManagerChecked} /> :
                                    <div style={{ width: 275 }}><PeoplePicker
                                        key={pickerCtrlKey}
                                        type={PeoplePickerType.Normal}
                                        contentType={PeoplePickerContentType.FTEUser}
                                        selectionMode={PeoplePickerSelectionMode.Single}
                                        onchange={(items) => { this.onPeoplePickerChange(items) }}
                                        defaultSelectedItems={[]}
                                        {...this.props}
                                        setFocusOnPicker={false}
                                        hideDirectFTE={false}
                                    ></PeoplePicker></div>}
                            </span>
                        </div>
                        }
                        {isAlternateManager.includes("Validating Data") ? <><br /><Spinner size={SpinnerSize.small} label="Validating..." ariaLive="assertive" labelPosition="right" style={{ display: 'inline-flex' }} /></> : <div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right' }}>{isAlternateManager ?? ''}</div>}
                        <div className="float-right">
                            <br />
                            <div>
                                <DefaultButton onClick={this.onResetClick} disabled={saveInProgress == true} > {UIConstants.ButtonText.Reset} </DefaultButton>
                                <span>&nbsp;</span>
                                <PrimaryButton disabled={(ManagerAlias?.jobTitle != null && ManagerAlias.jobTitle.toUpperCase() == 'CEO' && !isAlternateManagerChecked) || (saveInProgress == true)} onClick={this.onSubmitClick} >{UIConstants.ButtonText.Submit} </PrimaryButton>
                            </div>
                            <br />
                            <div>
                                <ModalDialog hidden={this.state.hideDialog}
                                    onDismiss={this.closeDialog}
                                    dialogContentProps={this.state.modalDialogContent}
                                    modalProps={{
                                        titleAriaId: this._labelId,
                                        subtitleAriaId: this._subTextId,
                                        isBlocking: false,
                                        styles: { main: { maxWidth: 450 } },
                                    }}
                                >
                                </ModalDialog>
                            </div>
                        </div>
                    </div>
                </div> : <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />}
            </div>
        </React.Fragment>
    };


    // private getPermissionTypes = (): IDropdownOption[] => {
    //     const { permissionStructure } = this.state;
    //     let permissionGroupTypes = permissionStructure?.map(x => { return { key: x.permissionTypeID, text: x.permissionType } });
    //     return permissionGroupTypes;
    // };

    private validateManager = () => {
        AccessAPI.ValidateUserJobTitle(this.state.ManagerAlias.upn).then(res => {
            if (res) {
                this.setState({ managerCEOMinus1or2: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
            }
            else {
                this.setState({ managerCEOMinus1or2: '' })
            }
        });
    }

    private getApprovedAccessOnEdit = (editAccessData) => {
        const { approvedScopes} = this.state
        let accessItem = editAccessData.accessItem;
        if (accessItem.PermissionGroup == UIConstants.PermissionGroups.PLPlanGroup) {

            if (accessItem.PermissionLevel.toLowerCase() != UIConstants.PermissionLevels.ConsolidatedGaap.toLowerCase()) {
                approvedScopes.scopeValues = editAccessData.attributes?.split(";").map(x => x.trim())
            }
            //consolidated gaap
            else {
                approvedScopes.scopeValues = []
                approvedScopes.hasAdditionalAccess = accessItem.HasAdditionalAccess
            }

        }

        else if (accessItem.PermissionGroup == UIConstants.PermissionGroups.TrialBalanceGroup) {
            if (accessItem.PermissionLevel == UIConstants.PermissionLevels.SCOMP) {
                approvedScopes.scopeValues = accessItem.Attribute.SCOMP
                approvedScopes.hasAdditionalAccess = accessItem.HasAdditionalAccess
            }
            //All Companies
            else {
                approvedScopes.scopeValues = []
                approvedScopes.hasAdditionalAccess = accessItem.HasAdditionalAccess
            }
        }

    }

    private getPermissionGroups = (): IDropdownOption[] => {
        const { permissionStructure, permissionMaster } = this.state;
        // let permissionGroup = _.flatten(_.orderBy(permissionStructure,'sortOrder').map(x => { return x.permission }));
        // let sortedPermGroups = _.orderBy(permissionGroup,'sortOrder');
        // let permissionGroupList = sortedPermGroups.map(y => { return { key: y.permissionGroupID, text: y.permissionGroup } })
        let permissionGroupList = permissionMaster.map(y => { return { key: y.permissionGroupID, text: y.permissionGroup } });
        permissionGroupList = _.uniqBy(permissionGroupList, 'key');
        //if (permissionGroups?.length == 1) {
        //    this.setState({ permissionGroup: permissionGroups[0]?.key })
        //}
        return permissionGroupList;
    };
    
    // private getPermissionLevels = (): IDropdownOption[] => {
    //     const { permissionStructure, permissionGroup, permissionType } = this.state;
    //     let permissionLevels = [];
    //     if (permissionType != '0' && permissionGroup != '0') {
    //         permissionLevels = permissionStructure?.filter(x => x.permissionTypeID == permissionType)[0]?.permission.filter(x => x.permissionGroupID == permissionGroup)[0]?.permissionLevels.map(y => { return { key: y.permissionLevelID, text: y.permissionLevel } })
    //     }
    //     //if (permissionLevels?.length == 1) {
    //     //    this.setState({ permissionLevel: permissionLevels[0]?.key })
    //     //}
    //     return permissionLevels;
    // };

    // private onPermissionTypeDrpDwnChange = (event) => {
    //     const { permissionStructure  } = this.state;
    //     let selectedPerm: Permission = null;
    //     selectedPerm = permissionStructure?.filter(x => x.permissionTypeID == event.target.value)[0];
    //     this.setState({ permissionType: event.target.value, permissionGroup: '0', permissionLevel: '0', selectedAttributes: [], 
    //     selectedPermisisonType: selectedPerm , selectedPermisisonGroup : null })
    // };
    // private onPermissionGroupDrpDwnChange = (event) => {
    //     const { permissionStructure, permissionMaster } = this.state;
    //     let selectedPermGrp: PermissionGroup = null;
    //     let plList: any = [];

    //     let selectedPermisison = permissionMaster.filter(x => x.permissionTypeID == event.target.value);
    //     selectedPermGrp = selectedPermisison.filter(x => x.permissionGroupID == event.target.value)[0];
    //     plList = selectedPermGrp.permissionLevels.map(x => { return { key: x.permissionLevelID, text: x.permissionLevel } });


    //     this.setState({
    //         permissionGroup: event.target.value, permissionLevel: '0', selectedAttributes: [],
    //         selectedPermisisonGroup: selectedPermGrp, selectedPermisisonLevel: null, permissionLevelList: plList
    //     })
    // };


    private onPermissionGroupDrpDwnChange = (event) => {
        const { permissionStructure, permissionMaster } = this.state;
        let plList: any = [];
        if (event.target.value == '0') {
            this.setState({
                permissionGroup: event.target.value, permissionLevel: '', selectedAttributes: [], regionChecked: [], regionExpanded: [], flagChecked:false,
                selectedPermisisonLevel: null, permissionLevelList: plList, selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }
            })
        }
        else {
            let selectedPermGrp = permissionMaster.filter(x => x.permissionGroupID == event.target.value);
            if (selectedPermGrp.length != 1) {
                plList = selectedPermGrp.map(x => { return { key: x.permissionLevelID, text: x.permissionLevel } });
                plList = _.uniqBy(plList, 'key');
                let currentPermissionGroup = permissionStructure?.filter(x => x.permission.filter(y => y.permissionGroupID == selectedPermGrp[0].permissionGroupID).length > 0)[0].permission.filter(x => x.permissionGroup == selectedPermGrp[0].permissionGroup)[0];
                this.setState({
                    selectedPermisisonGroup: currentPermissionGroup, permissionGroup: event.target.value, permissionLevel: '0', selectedAttributes: [], regionChecked: [], regionExpanded: [], showAlternateManager: true,
                    selectedPermisisonLevel: null, permissionLevelList: plList, selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }
                })
            }
            else {
                plList = [];
                let currentPermissionGroup = permissionStructure?.filter(x => x.permission.filter(y => y.permissionGroupID == selectedPermGrp[0].permissionGroupID).length > 0)[0].permission[0];
                this.setState({
                    selectedPermisisonGroup: currentPermissionGroup, permissionGroup: event.target.value, permissionLevel: permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionLevelID, selectedAttributes: [], permissionLevelList: plList, regionChecked: [], regionExpanded: [], showAlternateManager: true,
                    selectedRole: {
                        typeId: permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionTypeID, type: permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionType, groupId: permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionGroupID, group: permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionGroup, id: permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionLevelID, name: permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionLevel, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].approvalPolicy
                    }
                })
                if (permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionLevel.toLowerCase() == "support" || permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionLevel.toLowerCase() == "auditor" || permissionMaster.filter(x => x.permissionGroupID == event.target.value)[0].permissionLevel.toLowerCase() == "administrator") {
                    this.setState({ showAlternateManager: false, isAlternateManagerChecked: false, isAlternateManager: '', alternateManagerAlias: '', pickerCtrlKey: this.state.pickerCtrlKey + 1 });
                }
            }
        }
    };

    private onRoleChange(event) {
        const { permissionStructure, permissionGroup, permissionType, permissionMaster } = this.state;
        this.setState({ permissionLevel: event.target.value, selectedAttributes: [], regionChecked: [], regionExpanded: [], flagChecked: false })
        let selectedPermissionLevel = permissionMaster.filter(x => x.permissionLevelID == event.target.value)[0];
        let permissionLevels = permissionStructure?.filter(x => x.permissionTypeID == selectedPermissionLevel.permissionTypeID)[0]?.permission
            .filter(x => x.permissionGroupID == selectedPermissionLevel.permissionGroupID)[0]?.permissionLevels
            .filter(x => x.permissionLevelID == selectedPermissionLevel.permissionLevelID)[0]

        if (event.target.value != '0') {
            this.loadAttributes(false, permissionLevels);
        }
        else {
            this.setState({
                selectedRole: {
                    // typeId: permissionType,
                    // type: permissionStructure.filter(x => x.permissionTypeID == permissionType)[0].permissionType,
                    // groupId: permissionGroup,
                    // group: permissionStructure.filter(x => x.permissionTypeID == permissionType)[0]?.permission.filter(x => x.permissionGroupID == permissionGroup)[0].permissionGroup,
                    id: event.target.value,
                     name: null, scopes: [], description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: permissionLevels.approvalPolicy
                },
                selectedAttributes: []
            });
        }
    }
    private loadAttributes(isEditMode : boolean , permissionLevelInput : PermissionLevel) {
        const { permissionStructure, permissionGroup, permissionType, permissionMaster, regionExpanded } = this.state;
        const {editAccessData} = this.props;

        let selectedPermissionLevel = permissionMaster.filter(x => x.permissionLevelID == permissionLevelInput.permissionLevelID)[0];

        let role = {
            typeId: permissionType,
            type: selectedPermissionLevel.permissionType,
            groupId: permissionGroup,
            group: selectedPermissionLevel.permissionGroup,
            id: permissionLevelInput.permissionLevelID,
            name: permissionLevelInput.permissionLevel,
            scopes: [], description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: permissionLevelInput.approvalPolicy
        }

        this.setState({
            selectedRole: role,
            selectedPermisisonLevel: permissionLevelInput,
            showAdditional: true
        });

        if (permissionLevelInput && permissionLevelInput.attribute && permissionLevelInput.attribute.name) {
            this.setState({
                attributesLoaded: false, attributeConfig: [{
                    attributeName: permissionLevelInput.attribute.name, 
                    attributeDisplayName: '',
                    attributeType: AttributeType[permissionLevelInput.attribute.type], attributeValue: null, selectedAttributeValue: []
                }]
            });
            let selectedAttributeKey = [];
            //API Calling
            if (permissionLevelInput.attribute.name == "SCOMP") {
                if (permissionLevelInput.attribute.type == AttributeType.Tree) {
                    AccessAPI.getRegionCountryCompanyMapping().then(response => {
                        let searchResult = [];
                        let regions = [];
                        if (response && response.length > 0) {
                            regions = response.filter(region => region.country.length > 0).map((x) => {
                                return {
                                    value: "Region-" + x.areaCode.toString(),
                                    label: x.areaName,
                                    children: x.country.sort((a, b) => ((a.countryName < b.countryName) ? -1 : 1)).map(y => { return { value: "Country-" + y.countryId.toString(), label: y.countryName, children: y.company.sort((a, b) => ((a.code < b.code) ? -1 : 1)).map(z => { return { value: "Company-" + z.code.toString() + ":" + z.description, label: z.code.toString() + " : " + z.description } }) } }),
                                    showCheckbox: false
                                }
                            });
                            //searchResult = response.map(item => ({ key: item.code, name: item.description }));
                            if (isEditMode && editAccessData.accessItem.PermissionLevel == "Specific Companies") {
                                editAccessData.multiAttributes.forEach(x => {
                                    if (x.attributeName == "Statutory") {
                                        return this.state.flagChecked = true;
                                    }
                                    else {
                                        this.state.flagChecked = false; 
                                    }
                                   
                                })
                                selectedAttributeKey = editAccessData.accessItem.Attribute.SCOMP.map(y => { return (y.split(":")[0].trim()) });
                                let regionChecked = editAccessData.accessItem.Attribute.SCOMP.map(y => { return ("Company-" + y) });
                                let checkedResult = [];
                                let checkedRegions = [];
                                for (var item of regionChecked) {
                                    let count = 0;
                                    regions.map(x => {
                                        x.children.map(y => {
                                            let children = y.children.filter(z => z.value.split(":")[0] == item.split(":")[0]);
                                            if (children.length > 0) {
                                                checkedResult.push(children[0].value);
                                            }
                                        })
                                    });
                                };
                                checkedResult.forEach(x => checkedRegions.push(x));
                                let result = [];
                                for (var item of regionChecked) {
                                    let count = 0;
                                    regions.map(x => {
                                        x.children.map(y => {
                                            count = y.children.filter(z => z.value.split(":")[0] == item.split(":")[0]).length;
                                            if (count > 0) {
                                                result.push(y.value);
                                                result.push(x.value);
                                            }
                                        })
                                    });
                                };

                                let uniqueNodes = new Set();
                                result.forEach(item => {
                                    uniqueNodes.add(item)
                                });
                                result = Array.from(uniqueNodes).sort();
                                result.forEach(x => regionExpanded.push(x));
                                let attributeValue = [];
                                editAccessData.accessItem.Attribute.SCOMP.forEach(item => {
                                    attributeValue.push({ "code": item.split(":")[0].toString(), "description": item.includes(":") ? item.split(":")[1].toString() : item.split(":")[0].toString() });
                                });
                                let selectedAttributeValue = attributeValue;
                                this.updateAttributeValues(editAccessData.accessItem.PermissionLevel, attributeValue, false);
                                this.setState({ editRequest: true, regionChecked: checkedRegions, regionExpanded: regionExpanded });
                            }
                        }
                        response.sort((a, b) => ((a.RegionName < b.RegionName) ? -1 : 1));
                        regions.sort((a, b) => ((a.label < b.label) ? -1 : 1));
                        this.setState({ attributes: searchResult, regionConfig: regions, scompData: response, attributesLoaded: true, selectedAttributeKey: selectedAttributeKey });
                    });
                }
                else {
                    AccessAPI.getCountryCompanyMapping().then(response => {
                        let searchResult = [];
                        if (response && response.length > 0) {
                            searchResult = response.map(item => ({ key: item.code, name: item.description }));
                            if (isEditMode && editAccessData.accessItem.PermissionLevel == "Specific Companies") {
                                selectedAttributeKey = editAccessData.accessItem.Attribute.SCOMP.map(y => { return (y.split(":")[0].trim()) });
                                this.setState({ editRequest: true });
                            }
                        }
                        response.sort((a, b) => ((a.countryName < b.countryName) ? -1 : 1));
                        this.setState({ attributes: searchResult, scompData: response, attributesLoaded: true, selectedAttributeKey: selectedAttributeKey });
                    });
                }
            }
            else {
                AccessAPI.getAttributeData('', this.props.tenantData.TenantId, permissionLevelInput.attribute.name).then(searchResponse => {
                    let searchResult = [];
                    if (searchResponse && searchResponse.length > 0) {
                        searchResult = searchResponse.map(item => ({ key: item.code, name: item.description }));
                    }
                    if (isEditMode) {
                        if (editAccessData.accessItem.Attribute) {
                            let selectedAttributeValue;
                            if (editAccessData.accessItem.PermissionLevel == "Geography") {
                                selectedAttributeKey = editAccessData.accessItem.Attribute.MWWAR.map(y => { return (y.split(":")[0].trim()) });
                                let attributeValue = [];
                                editAccessData.accessItem.Attribute.MWWAR.forEach(item => {
                                    attributeValue.push({ "code": item.split(":")[0].toString(), "description": item.split(":").length > 1 ? item.split(":")[1].toString() : item.split(":")[0].toString() });
                                });
                                selectedAttributeValue = attributeValue;
                                this.updateAttributeValues(editAccessData.accessItem.PermissionLevel, attributeValue, false);
                            }
                            else if (editAccessData.accessItem.PermissionLevel == "Functional / Exec org Summary") {
                                selectedAttributeKey = editAccessData.accessItem.Attribute.MXOS.map(y => { return (y.split(":")[0].trim()) });
                                let attributeValue=[];
                                editAccessData.accessItem.Attribute.MXOS.forEach(item => {
                                    attributeValue.push({ "code": item.split(":")[0].toString(), "description": item.includes(":") ? item.split(":")[1].toString() : item.split(":")[0].toString() });  
                                });
                                selectedAttributeValue = attributeValue;
                                this.updateAttributeValues(editAccessData.accessItem.PermissionLevel, attributeValue, false);
                            }
                            this.setState({
                                attributeConfig: [{
                                    attributeName: permissionLevelInput.attribute.name,
                                    attributeDisplayName: '',
                                    attributeType: AttributeType[permissionLevelInput.attribute.type], attributeValue: null, selectedAttributeValue: selectedAttributeValue
                                }]
                            });
                        }
                         //if (editAccessData.multiAttributes && editAccessData.multiAttributes.length > 0) {
                           
                         //    if (editAccessData.multiAttributes[0].attributeVale && editAccessData.multiAttributes[0].attributeVale.length >0) {
                         //        selectedAttributeKey = editAccessData.multiAttributes[0].attributeVale.map(x=> x.code);
                         //    }
                         //}
                        this.setState({ editRequest: true });                        
                    }
                    searchResult.sort((a, b) => ((a.name < b.name) ? -1 : 1));
                    this.setState({ attributes: searchResult, attributesLoaded: true, selectedAttributeKey: selectedAttributeKey});
                });
            }

            ////Mock Data
            //if (permissionStructure?.filter(x => x.permissionTypeID == permissionType)[0]?.permission.filter(x => x.permissionGroupID == permissionGroup)[0]?.permissionLevels.filter(x => x.permissionLevelID == event.target.value)[0].permissionLevel == UIConstants.PermissionLevels.Geography)
            //    this.setState({ attributes: Geography.sort((a, b) => a.description == "All" ? -1 : b.description == "All" ? 1 : ((a.description < b.description) ? -1 : 1)).map(item => ({ key: item.code, name: item.description })) })
            //else if (permissionStructure?.filter(x => x.permissionTypeID == permissionType)[0]?.permission.filter(x => x.permissionGroupID == permissionGroup)[0]?.permissionLevels.filter(x => x.permissionLevelID == event.target.value)[0].permissionLevel == UIConstants.PermissionLevels.FunctionalExeOrgSummary)
            //    this.setState({ attributes: Functional.sort((a, b) => a.description == "All" ? -1 : b.description == "All" ? 1 : ((a.description < b.description) ? -1 : 1)).map(item => ({ key: item.code, name: item.description })) })
        }
        else {
            this.setState({ attributes: null, attributesLoaded: true });
        }
    }

    private onPeoplePickerChange(items: any) {
        this.setState({ isAlternateManager: '', alternateManagerAlias: '' });
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            this.setState({ alternateManagerAlias: parsedData.upn, isAlternateManager: 'Validating Data' });
            AccessAPI.ValidateUserJobTitle(parsedData.upn).then(res => {
                if (res) {
                    this.setState({ isAlternateManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
                }
                else {
                    this.setState({ isAlternateManager: '' });
                }
            });
        }
    }
    private onJustificationChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue.length <= 1000) {
            this.setState({ businessJustification: newValue || '' });
        } else {
            this.setState({ message: UIConstants.Messages.JustificationMaxLength, messageBarType: MessageBarType.warning })
        }
    };
    private renderPermissionLevels = () => {
        let { approvedScopes, permissionLevel, permissionStructure, permissionType, permissionGroup, setAttributeFocus, attributesLoaded, editMode, hasAttributeAccess, showAdditional, selectedAttributeKey, regionConfig, regionChecked, regionExpanded, flagChecked, permissionMaster, selectedAttributes } = this.state;
        if (permissionLevel == '') {
            return;
        }
        let selectedPermissionLevel = permissionMaster.filter(x => x.permissionLevelID == permissionLevel)[0];
        let permissionLevelAttributes = selectedPermissionLevel.attribute;
        let showFlag = (permissionLevelAttributes && permissionLevelAttributes.flag != null) && !permissionLevelAttributes.requireApprovedAccess;
        if (attributesLoaded) {
            switch (permissionLevelAttributes && permissionLevelAttributes.type) {
                case AttributeType.Checkbox:
                    return (
                        <div>
                            {permissionLevelAttributes?.displayName != null && permissionLevelAttributes?.displayName != '' && <Label required={true} styles={labelStyles}>{permissionLevelAttributes?.displayName}</Label>}
                            {(permissionLevelAttributes.showAttribute) && <Checkbox styles={{ root: { paddingBottom: 10 } }}
                                onRenderLabel={() => {
                                    return (<span>
                                        {permissionStructure?.filter(x => x.permissionTypeID == permissionType)[0]?.permission.filter(x => x.permissionGroupID == permissionGroup)[0]?.permissionLevels.filter(x => x.permissionLevelID == permissionLevel)[0]?.permissionLevel}
                                    </span>
                                    );
                                }}
                                onChange={(ev, checked) => {
                                    // this.setState({hasAttributeAccess : checked});
                                    let attributeName = permissionStructure?.filter(x => x.permissionTypeID == permissionType)[0]?.permission.filter(x => x.permissionGroupID == permissionGroup)[0]?.permissionLevels.filter(x => x.permissionLevelID == permissionLevel)[0]?.permissionLevel;
                                    let attributeValue = [{ "code": checked.toString(), "description": checked.toString(), isRemove: editMode ? !checked : false }];
                                    this.updateAttributeValues(attributeName, attributeValue, false);
                                }}
                            />}
                            {permissionLevelAttributes?.flag != null && showAdditional && (permissionLevelAttributes.showAttribute || hasAttributeAccess || showFlag) && <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                return (<span>
                                    {permissionLevelAttributes?.flag.name}
                                </span>
                                );
                            }}
                                defaultChecked={flagChecked}
                                onChange={(ev, checked) => {
                                    if (!checked && (!editMode || !approvedScopes.hasAdditionalAccess)) {
                                        selectedAttributes.splice(selectedAttributes.findIndex(x => x.attributeName.includes(UIConstants.Attribute.StatutoryReporting)), 1)
                                    }
                                    else {
                                        let attributeName = permissionLevelAttributes?.flag.name;
                                        this.setState({ flagChecked: checked })
                                        let attributeValue = [{ "code": checked.toString(), "description": checked.toString(), isRemove: editMode ? !checked : null  }];
                                        this.updateAttributeValues(attributeName, attributeValue, true);
                                    }
                                }}
                            />}
                        </div>
                    );
                case AttributeType.CheckboxList:
                    let options: IComboBoxOption[] = []
                    this.state.attributes?.forEach(attribute => {
                        options.push({
                            key: attribute.key, text: attribute.name, id: attribute.key
                        });
                    });
                    options.sort((a, b) => ((a.text < b.text) ? -1 : 1));
                    return (
                        <div>
                            {permissionLevelAttributes?.displayName != null && permissionLevelAttributes?.displayName != '' && <Label key={'lbl-' + permissionLevelAttributes?.displayName} required={true} styles={labelStyles}>{permissionLevelAttributes?.displayName}</Label>}
                            <ComboBox
                                key={'attr' + permissionLevelAttributes.name}
                                defaultSelectedKey={selectedAttributeKey}
                                multiSelect
                                options={options}
                                allowFreeform={true}
                                styles={comboBoxStyles}
                                onChange={(event, option, index, value) => {
                                    let attributeName =selectedPermissionLevel.permissionLevel ;
                                    this.onComboboxChange(event, option, attributeName);
                                }
                                }
                            />
                            {(permissionLevelAttributes.attributeDescription) && <div><Text>{permissionLevelAttributes.attributeDescription}</Text></div>}
                            <br />
                            {permissionLevelAttributes?.flag != null && <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                return (<span>
                                    {permissionLevelAttributes?.flag.name}
                                </span>
                                );
                            }}
                                defaultChecked={flagChecked}
                                onChange={(ev, checked) => {
                                    if (!checked && (!editMode || !approvedScopes.hasAdditionalAccess)) {
                                        selectedAttributes.splice(selectedAttributes.findIndex(x => x.attributeName.includes(UIConstants.Attribute.StatutoryReporting)), 1)
                                    }
                                    else {
                                        this.setState({ flagChecked: checked })
                                        let attributeName = permissionLevelAttributes?.flag.name;
                                        let attributeValue = [{ "code": checked.toString(), "description": checked.toString(), isRemove: editMode ? !checked : false }];
                                        this.updateAttributeValues(attributeName, attributeValue, true);
                                    }

                            }}
                            />}
                        </div>
                    );
                case AttributeType.Search:
                    return (
                        <div>
                            {permissionLevelAttributes?.displayName != null && permissionLevelAttributes?.displayName != '' && <Label required={true} styles={labelStyles}>{permissionLevelAttributes?.displayName}</Label>}
                            <AttributePicker setAttributeFocus={setAttributeFocus} 
                            roleName={''} 
                            attributeName={permissionLevelAttributes?.name}
                                isDisabled={false} 
                                onItemSelected={(name, val) => {
                                    let attributeName = selectedPermissionLevel.permissionLevel;
                                    this.onAttributePickerChange(attributeName, val)
                                }}
                                 placeHolder={permissionLevelAttributes.attributeDescription != null ? permissionLevelAttributes.attributeDescription : 'Search'} 
                                 Data={this.state.scompData}
                                tenantData={this.props.tenantData} 
                                suggestionText={permissionLevelAttributes?.displayName != null ? 'Suggested ' + permissionLevelAttributes?.displayName : null}
                                noResultsFoundText = {permissionLevelAttributes?.displayName != null ? 'No ' + permissionLevelAttributes?.displayName + ' Found' : null}
                                isAsync={true}
                            ></AttributePicker>
                                {(permissionLevelAttributes.attributeDescription) && <div><Text>{permissionLevelAttributes.attributeDescription}</Text></div>}
                            {/* {permissionLevelAttributes?.displayName == 'Company' && <><Text> {'(search either by country name or company code)'}</Text><br /></>} */}
                            <br />
                            {permissionLevelAttributes?.flag != null && <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                return (<span>
                                    {permissionLevelAttributes?.flag.name}
                                </span>
                                );
                            }} defaultChecked={flagChecked}
                                onChange={(ev, checked) => {
                                this.setState({ flagChecked: checked })
                                let attributeName = permissionLevelAttributes?.flag.name;
                                    let attributeValue = [{ "code": checked.toString(), "description": checked.toString(), isRemove: editMode ? !checked : false }];
                                this.updateAttributeValues(attributeName, attributeValue, true);
                            }
                            } />}
                        </div>
                    );
                case AttributeType.Tree:
                    return (
                        <div>
                        <div key = 'tree-ctrl-row' className = { "ms-Grid-row" } style = {{ paddingLeft: 0, minHeight: 100, maxHeight: 300, overflowY: 'auto', visibility: 'visible' }}>
                        {permissionLevelAttributes?.displayName != null && permissionLevelAttributes?.displayName != '' && <Label required={true} styles={labelStyles}>{permissionLevelAttributes?.displayName}</Label>}
                        <CheckboxTree
                        nodes={regionConfig}
                        checked={regionChecked}
                        expanded={regionExpanded}
                        onCheck={(checked, node) => {
                            if (node.checked) {
                                let _regionChecked = regionChecked.map(region => region.replace(':Removed',''))
                                if (node['isParent']) {
                                    node.children.slice().reverse().forEach(item => {
                                        if (item.children != null) {
                                            item.children.forEach(child => {
                                                if (_regionChecked.indexOf(child.value) == -1) {
                                                    regionChecked.push(child.value)
                                                }
                                            });
                                        }
                                        if (_regionChecked.indexOf(item.value) == -1) {
                                            regionChecked.push(item.value)
                                        } else {
                                            let index = _regionChecked.indexOf(item.value)
                                            regionChecked.splice(index, 1, item.value)// push updated child at same index
                                           // regionChecked.push(item.value,) 
                                        }

                                    }); // push all children
                                    if (_regionChecked.indexOf(node.value) == -1) {
                                        regionChecked.push(node.value) // push parent
                                    }
                                } else {
                                    let index = _regionChecked.indexOf(node.value)
                                    if (index == -1) {
                                        regionChecked.push(node.value) // push child
                                    } else {
                                        regionChecked.splice(index,1)
                                        regionChecked.push(node.value) // push child
                                    }
                                }
                            } else {
                                if (node['isParent']) {
                                    const index = regionChecked.indexOf(node.value);
                                    if (index > -1) {
                                        regionChecked.splice(index, 1);
                                        if (regionChecked.indexOf(node.value) == -1) {
                                            regionChecked.push(node.value+":Removed") // push child
                                        }
                                    }
                                    node.children.forEach(item => {
                                        const index = regionChecked.indexOf(item.value);
                                        if (index > -1) {
                                            regionChecked.splice(index, 1);
                                            if (regionChecked.indexOf(item.value) == -1) {
                                                regionChecked.push(item.value + ":Removed") // push child
                                            }
                                        }
                                        if (item.children != null) {
                                            item.children.forEach(child => {
                                                const index = regionChecked.indexOf(child.value);
                                                if (index > -1) {
                                                    regionChecked.splice(index, 1);
                                                    if (regionChecked.indexOf(node.value) == -1) {
                                                        regionChecked.push(node.value + ":Removed") // push child
                                                    }
                                                }

                                            });
                                        }

                                    });
                                } else {
                                    const index = regionChecked.indexOf(node.value);
                                    if (index > -1) {
                                        regionChecked.splice(index, 1);
                                        if (regionChecked.indexOf(node.value) == -1 && editMode) {
                                            regionChecked.push(node.value + ":Removed") // push child
                                        }
                                    }
                                }

                                for (let i = regionChecked.length - 1; i >= 0; i--) {
                                    if (regionChecked[i].includes('Removed')) {
                                        if (!approvedScopes.scopeValues.includes(regionChecked[i].substring(regionChecked[i].indexOf('-') + 1, (regionChecked[i].lastIndexOf(':'))))) {
                                            regionChecked.splice(i, 1)
                                        }
                                    }
                                }
                            }
                            this.setState({ regionChecked: regionChecked })
                        }}
                        onExpand={(expanded, node) => {
                            if (node.expanded) {
                                regionExpanded.push(node.value);
                            } else {
                                const index = regionExpanded.indexOf(node.value);
                                if (index > -1) {
                                    regionExpanded.splice(index, 1);
                                }
                            }
                            this.setState({ regionExpanded: regionExpanded })
                        }}
                        nativeCheckboxes={true}
                        icons={{
                            expandClose: <i className="fa fa-plus-square" aria-hidden="false"></i>,
                            expandOpen: < i className="fa fa-minus-square" aria-hidden="true"></i>,
                            parentClose: null,
                            parentOpen: null,
                            leaf: null
                        }}
                            />
                            </div>
                            {/*(permissionLevelAttributes.attributeDescription) && <div><Text>{permissionLevelAttributes.attributeDescription}</Text></div>*/}
                            {/* {permissionLevelAttributes?.displayName == 'Company' && <><Text> {'(search either by country name or company code)'}</Text><br /></>} */}
                        <br />
                            {
                        permissionLevelAttributes?.flag != null && <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                            return (<span>
                                {permissionLevelAttributes?.flag.name}
                            </span>
                            );
                                }}
                                    defaultChecked={flagChecked}
                                    onChange={(ev, checked) => {
                                        if (!checked && (!editMode || !approvedScopes.hasAdditionalAccess)) {
                                            selectedAttributes.splice(selectedAttributes.findIndex(x => x.attributeName.includes(UIConstants.Attribute.StatutoryReporting)), 1)
                                        }
                                        else
                                        {
                                            let attributeName = permissionLevelAttributes?.flag.name;
                                            this.setState({ flagChecked: checked })
                                            let attributeValue = [{ "code": checked.toString(), "description": checked.toString(), isRemove: editMode ? !checked : false }];
                                            this.updateAttributeValues(attributeName, attributeValue, true);
                                        }
                        }
                        } />
                            }
                        </div>
                    );
                default:
                    break;
            };
        } else {
            return <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />;
        }
    };
    private renderOnbehalfCtrl = () => {
        const { requestorPickerCtrlKey, editMode } = this.state;
        return <div>
            <Label styles={labelStyles}>Request on behalf of User</Label>
            <div style={{ width: window.innerWidth < 400 ? 250 : 350 }}>
                    <PeoplePicker
                        key={requestorPickerCtrlKey}
                        type={PeoplePickerType.Normal}
                        contentType={PeoplePickerContentType.User}
                        selectionMode={PeoplePickerSelectionMode.Single}
                        isPickerDisabled={editMode}
                        onchange={(items) => { this.onRequestorPeoplePickerChange(items) }}
                        defaultSelectedItems={[]}
                        {...this.props}
                        setFocusOnPicker={false}
                ></PeoplePicker>
            </div>
        </div>
    }
    private onRequestorPeoplePickerChange(items: any) {
        this.setState({ requestorPrincipalId: '', showAlternateManager: true });
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            this.setState({ requestorPrincipalId: parsedData.id, showAlternateManager: false });    
            //AccessAPI.GetManager(parsedData.upn).then(res => {
            //    res && res.upn != '' && this.setState({ alternateManagerAlias: res?.upn?.split('@')[0], ManagerAlias: res });
            //    if (res.upn == '') {
            //        this.setState({ alternateManagerAlias: '', ManagerAlias: { id: '', name: '', displayName: '', email: '', upn: 'Manager not found', surname: '', domain: '', jobTitle: '' } });
            //    }
            //});
        }
    };
    private setInitialState() {
        this.setState({
            selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' },
        })
    };
    private getRolesDetails = (): RoleDetails => {
        let { selectedRole, selectedAttributes } = this.state;
        let role: RoleDetails = {
            typeId: selectedRole.typeId,
            type: selectedRole.type,
            groupId: selectedRole.groupId,
            group: selectedRole.group,
            roleId: selectedRole.id,
            roleName: selectedRole.name,
            roleDescription: '',
            attributes: selectedAttributes,
            actions: selectedRole.permissions && selectedRole.permissions.length > 0 ? selectedRole.permissions[0].actions : [],
            approvalPolicy: selectedRole.approvalPolicy
        };
        return role;
    };
    private saveAccessRequest = (): void => {
        const { scopeValue, businessJustification, requestType, alternateManagerAlias, isAlternateManagerChecked, requestorPrincipalId, editRequest, myaccess } = this.state;
        const { toggleProgressBar, toggleMessageBar } = this.props;
        let t = this;
        let data: AccessRequestSubmissionModel = {
            tenantId: this.props.tenantData.TenantId,
            tenantName: this.props.tenantData.TenantName,
            businessJustification: businessJustification,
            scope: scopeValue,
            role: this.getRolesDetails(),
            requestType: requestType,
            requestorPrincipalId: requestorPrincipalId,
            signatureVerified: false,
            termsAndConditionsApplied: false,
            alternateMangerAlias: isAlternateManagerChecked ? alternateManagerAlias?.split('@')[0] : null,
            managerAlias: this.state.ManagerAlias?.upn?.split('@')[0],
            editRequest: editRequest
        };
        toggleProgressBar(true);
        this.setState({ hideDialog: true, saveInProgress: true, showMessage: false });
        window.scrollTo(0, 0);
        this.props.messageBarRef.current.scrollIntoView({ behavior: 'smooth' });
        this.props.messageBarRef.current.focus();
        AccessAPI.saveUserAccessAsync(data).then(async res => {
            if (res.status && res.status === 200) {
                this.submitClicked = false;
                toggleProgressBar(false);
                t.setState({
                    saveInProgress: false,
                    messageType: MessageBarType.success,
                    message: UIConstants.Messages.SaveSuccess,
                    showMessage: false,
                    hideBj: true,
                    businessJustification: '',
                    autoHideMessage: false,
                    requestorPrincipalId: '',
                    permissionLevel: '',
                    permissionGroup: '',
                    permissionType: '',
                    selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' },
                    hideDialog: true,
                    attributesLoaded: true,
                    pickerCtrlKey: this.state.pickerCtrlKey + 1,
                    alternateManagerAlias: '',
                    isAlternateManagerChecked: false,
                    isAlternateManager: '',
                    editRequest: false,
                    selectedAttributes: [],
                    selectedAttributeKey: "0",
                    selectedPermisisonLevel: null,
                    regionChecked: [],
                    regionExpanded: [],
                    flagChecked: false,
                    editMode: false,
                    permissionLevelList: [],
                    requestorPickerCtrlKey: this.state.requestorPickerCtrlKey + 1,
                    selectedPermisisonGroup:null
                });
                if (myaccess != null && myaccess.approvedAccess?.length > 0) {
                    let approvedAccess: any = myaccess.approvedAccess;
                    let groupValue = approvedAccess?.filter(x => x?.PermissionGroup == data.role?.group);
                    if (groupValue != null && groupValue.length > 0) {
                        let permissionValue = groupValue?.filter(x=> x.PermissionLevel != data.role.roleName);
                        if (permissionValue != null && permissionValue.length>0) {
                            if (data.role?.group == UIConstants.PermissionGroups.PLPlanGroup) {
                                toggleMessageBar(UIConstants.Messages.SaveSuccessForDifferentPL, MessageBarType.success);
                            }
                            else if (data.role?.group == UIConstants.PermissionGroups.TrialBalanceGroup) {
                                toggleMessageBar(UIConstants.Messages.SaveSuccessForDifferentTrail, MessageBarType.success);
                            }
                            else {
                                toggleMessageBar(UIConstants.Messages.SaveSuccess, MessageBarType.success);
                            }
                        }
                        else {
                            toggleMessageBar(UIConstants.Messages.SaveSuccess, MessageBarType.success);
                        }
                    }
                    else {
                        toggleMessageBar(UIConstants.Messages.SaveSuccess, MessageBarType.success);
                    }
                }
                else {
                    toggleMessageBar(UIConstants.Messages.SaveSuccess, MessageBarType.success);
                }
                this.setInitialState(); // to reset the state of request screen
            } else if ((res.status && res.status !== 200) || res.message) {
                t.setState({
                    messageType: MessageBarType.warning,
                    message: res.message,
                    showMessage: true,
                    saveInProgress: false,
                });
                this.submitClicked = false;
                toggleProgressBar(false);
                toggleMessageBar(res.message, MessageBarType.warning);
            }
        });
    };
    private getCheckBoxTreeAttributes(config, regioncheck) {
        const { regionValues, selectedAttributes } = this.state
        for (var value in regioncheck) {
            for (var x in config) {
                for (var child in config[x].children) {
                    for (var innerChild in config[x].children[child].children) {
                        if (config[x].children[child].children[innerChild].value === regioncheck[value].replace(":Removed", '') && regionValues?.filter(item => item.code == config.label).length < 1) {
                            this.setState({ regionValues: regionValues.push({ code: config[x].children[child].children[innerChild].label.split(" : ")[0], description: config[x].children[child].children[innerChild].label.split(" : ")[1], isRemove: regioncheck[value].includes(":Removed") }) })
                        }
                    }
                }
            }
        }
        let attributes: Attributes[] = JSON.parse(JSON.stringify(selectedAttributes));
        if (regionValues.length != 0) {
            let newCompanyAttributeValues: Attributes = { attributeName: "Specific Companies", attributeValues: regionValues };
            if (attributes.find(x => x.attributeName == "Specific Companies")) {
                attributes.find(x => x.attributeName == "Specific Companies").attributeValues = regionValues;
            }
            else {
                attributes.push(newCompanyAttributeValues);
            }
        }
        this.setState({ selectedAttributes: attributes });
    };
    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };
    private onResetClick = (): void => {
        this._modalDialogContent = {
            subText: UIConstants.Messages.ResetConfirmation,
            title: UIConstants.MessageBoxTitle.Reset,
            okButtonText: UIConstants.ButtonText.Reset,
            okAction: this.ResetUI,
            cancelAction: this.closeDialog,
            type: DialogType.normal,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }
        this.setState({ editRequest: false, hideDialog: false, modalDialogContent: this._modalDialogContent });
    };
    private ResetUI = (): void => {
        this.setState({ 
            selectedPermisisonGroup: null, permissionLevel: '', permissionGroup: '', permissionType: '', selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }, businessJustification: '', hideDialog: true, attributesLoaded: true, pickerCtrlKey: this.state.pickerCtrlKey + 1, alternateManagerAlias: '', isAlternateManagerChecked: false, isAlternateManager: '', showMessage: false, selectedAttributes: [], selectedAttributeKey: "0", selectedPermisisonLevel: null, flagChecked: false, regionChecked: [], regionExpanded: [], editMode: false, permissionLevelList: [], requestorPickerCtrlKey: this.state.requestorPickerCtrlKey + 1
        });
        this.submitClicked = false;
        this.props.toggleMessageBar('', MessageBarType.warning);
    };
    private ValidateData = (): ValidationResponse => {
        const { selectedRole, businessJustification, isAlternateManagerChecked, alternateManagerAlias, selectedAttributes, permissionGroup, permissionMaster, permissionLevel, regionChecked } = this.state;
        const { toggleMessageBar } = this.props;
        let selectedPermissionLevel = permissionMaster.filter(x => x.permissionLevelID == permissionLevel)[0];
        let response: ValidationResponse = { isValid: true };
        let errorMessage = null;
        // if (permissionType == '0' || permissionType == null) {
        //     errorMessage = errorMessage == null ? UIConstants.PermissionType : `${errorMessage}, ${UIConstants.PermissionType}`
        // }
        if (permissionGroup == '0' || permissionGroup == null || permissionGroup =='') {
            errorMessage = errorMessage == null ? UIConstants.Role : `${errorMessage}, ${UIConstants.Role}`
        }
        else if (selectedRole.id == '0' || selectedRole.id == null) {
            errorMessage = errorMessage == null ? UIConstants.PermissionScope : `${errorMessage}, ${UIConstants.PermissionScope}`
        }
        if ((selectedRole.id != '0' || selectedRole.id != null) && selectedRole.type == UIConstants.PermissionTypes.MercuryAdvanced) {
            let primaryAttributes = selectedAttributes.filter(x => x.attributeValues.filter(y => !y.isFlag).length > 0); ``
            let updatedAttributes = selectedAttributes[0]?.attributeValues.filter(x => !x.isRemove);
            if (selectedPermissionLevel.attribute.showAttribute && (primaryAttributes.length <= 0 ||  updatedAttributes.length == 0 )) {
                if (selectedRole.group == 'P&L and P&L Plan' && selectedRole.name.toLowerCase() != "consolidated gaap") {
                    errorMessage = errorMessage == null ? selectedRole.name : `${errorMessage}, ${UIConstants.Attributes}`
                }
            }
            else if (selectedRole.name.toLowerCase() == "geography" && selectedAttributes[0].attributeValues.filter(x => x.isRemove != true).length > 7) {
                errorMessage = 'If you need to access more than 7 Geographies, please submit a request for Consolidated GAAP access.'
                toggleMessageBar(` ${errorMessage}`, MessageBarType.warning);
                return { isValid: false, validationMessage: ` ${errorMessage}` }
            }
        }
        if ((selectedRole.id != '0' || selectedRole.id != null) && selectedRole.type == UIConstants.PermissionTypes.MercuryAdvanced && selectedPermissionLevel.attribute.showAttribute) {
            if (selectedRole.group == 'Trial Balance & Statutory' && regionChecked.filter(x=> !x.includes('Remove')).length == 0) {
                errorMessage = errorMessage == null ? UIConstants.Attribute.Company : `${errorMessage}, ${UIConstants.Attributes}`
            }
        }
        // Validation case : check if Business Justification is empty
        if (businessJustification.trim().length === 0) {
            errorMessage = errorMessage == null ? UIConstants.BusinessJustfication : `${errorMessage}, ${UIConstants.BusinessJustfication}`
        }
        // Validation case : check if alternate Manager is correct
        if (isAlternateManagerChecked) {
            if (alternateManagerAlias == '') {
                errorMessage = errorMessage == null ? UIConstants.AlternateManager : `${errorMessage}, ${UIConstants.AlternateManager}`
            }
        }
        if (errorMessage) {
            toggleMessageBar(` ${errorMessage} is required.`, MessageBarType.warning);
            return { isValid: false, validationMessage: ` ${errorMessage} is required.` }
        }
        else {
            toggleMessageBar('', MessageBarType.warning);
            return response; // Validation Success , Allow user to Submit
        }
    }
    private onSubmitClick = async (): Promise<void> => {
        this.setState({ regionValues: [] }, () => {
            if (this.state.regionValues.length === 0) {
                this.getCheckBoxTreeAttributes(this.state.regionConfig, this.state.regionChecked)
            } 
        }
        );        

        this.submitClicked = true;
        let validationResponse = this.ValidateData();
        if (validationResponse.isValid) {
            this._modalDialogContent = {
                subText: UIConstants.Messages.SaveConfirmation,
                title: UIConstants.MessageBoxTitle.SaveAccess,
                okButtonText: UIConstants.ButtonText.Submit,
                type: DialogType.normal,
                okAction: this.saveAccessRequest,
                cancelAction: this.closeDialog,
                closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
            }
            this.setState({ showMessage: false, hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent});// Show confirmation pop-up   
           
        } else {
            // Show Error message and skip submission
            this.setState({ showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
        }
            
    }
    private onAttributeDropDownChange = (name, val): void => {
        this.updateAttributeValues(name, val, false);
    };
    private onAttributePickerChange: any = (name, val) => {
        this.updateAttributeValues(name, val , false);
    };
    private onComboboxChange: any = (event, val, attributeName) => {
        const { approvedScopes,attributeConfig , selectedAttributeKey } = this.state;

        let attributeConfigData = [...attributeConfig];
        let existingValue = selectedAttributeKey;
        attributeConfigData.forEach(x => {
            if (val.selected) {
                let index = x.selectedAttributeValue.findIndex(x => x.description == val.text)
                if (index >= 0) {
                    x.selectedAttributeValue.splice(index, 1)
                }
                x.selectedAttributeValue.push({ code: val.key, description: val.text});
                existingValue.concat(val.key);
            } else {
                _.remove(x.selectedAttributeValue, (y) => { return y.code === val.key });
                existingValue = existingValue.filter(e => e !== val.key);
                if (this.props.isEditMode) {
                    let index = x.selectedAttributeValue.findIndex(x => x.description == val.text)
                    if (index >= 0) {
                        x.selectedAttributeValue.splice(index, 1)
                    }
                    if (approvedScopes?.scopeValues?.includes(val.text)) {
                        x.selectedAttributeValue.push({ code: val.key, description: val.text, isRemove: true });
                        existingValue.concat(val.key);
                    }
                }
            }
            this.onAttributeDropDownChange(attributeName, x.selectedAttributeValue);
        });
        this.setState({ attributeConfig: attributeConfigData });
    };
    private updateAttributeValues = (attributeName: string, attributeValue: AttributeValues[] , isFlag : boolean) => {
        const { selectedAttributes } = this.state;
       
            let updatedAttributes: Attributes[] = JSON.parse(JSON.stringify(selectedAttributes));
            let filteredAttribute: Attributes[] = updatedAttributes.filter(x => x.attributeName.includes(attributeName));

            attributeValue.forEach(x=> x.isFlag = isFlag);

            if (filteredAttribute.length > 0) { // If present update existing value
                if (attributeValue.length == 0 || (attributeValue.length > 0 && attributeValue[0].code == '0')) { // reset attribute if -- Select -- 
                    updatedAttributes = updatedAttributes.filter(x => x.attributeName != attributeName);
                } else {
                    updatedAttributes.forEach(x => {
                        if (x.attributeName === attributeName) {
                            x.attributeValues = attributeValue
                        }
                    });
                }

            } else {// else add new value
                let newAttributeValues: Attributes = { attributeName: attributeName, attributeValues: attributeValue };
                updatedAttributes.push(newAttributeValues);
            }

            let selectedKeyValue = _.flatten(updatedAttributes.map(x=> x.attributeValues.map( y => y.code)));
            this.setState({ selectedAttributes: updatedAttributes, showMessage: false, setAttributeFocus: false , selectedAttributeKey :  selectedKeyValue});
            if (this.props.tenantData.TenantName == UIConstants.Tenant.Mercury && this.state.message != '') {
                this.setState({ showMessage: true });
            }
    };
}
