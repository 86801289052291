import * as React from 'react';
import { TagPicker, IBasePicker, ITag } from '@fluentui/react/lib/Pickers';
import { AttributeValue, CountryCompanyAttribute, CountryCompanySearchModel } from '../../shared/models/Role.model';
import { v4 as uuidv4 } from 'uuid';
import { AttributeValues } from '../../shared/models/UserAccess.model';
import { UIConstants } from '../../shared/models/Constants';
import ITenant from '../../shared/models/Tenant.model';
import AccessAPI from '../../store/AccessAPI';
import { Specific } from '../../shared/mock/AttributeMockData';
const attributeKeyValueSeperator = ' : ';

export interface IAttributePickerState {
    isPickerDisabled?: boolean;
    attributeList?: any;
    attributeMaster: AttributeValues[];
    key: string;
}

export class AttributePicker extends React.Component<{
    attributeName: string,
    suggestionText?: string,
    noResultsFoundText?:string,
    attributeList?: AttributeValue[],
    isDisabled: boolean,
    itemLimit?: number,
    onItemSelected?: any,
    defaultSelectedItems?: any,
    roleName?: string,
    tenantData: ITenant,
    setAttributeFocus : boolean,
    isAsync?: boolean,
    Data?: any,
    placeHolder?: string
}, IAttributePickerState> {
    private _picker = React.createRef<IBasePicker<ITag>>();
    constructor(props) {
        super(props);
        
        this.state = {
            isPickerDisabled: this.props.isDisabled,
            attributeMaster: [],
            key: uuidv4(),
        };
    }
    componentDidUpdate(){
        if (this.props.setAttributeFocus) {
            this._picker.current.focus()
        }
    }

    public render() {
        return (
            <div>
                <TagPicker
                    key={this.props.roleName + this.props.attributeName}
                    componentRef={this._picker}
                    removeButtonAriaLabel={`Remove ${this.props.attributeName}`}
                    onResolveSuggestions={this.onFilterChanged}
                    getTextFromItem={this.getTextFromItem}
                    onChange={this.OnChange}
                    onItemSelected={this.onItemSelected}
                    pickerSuggestionsProps={{
                        suggestionsHeaderText: this.props.suggestionText ? this.props.suggestionText : 'Suggested ' + this.props.attributeName,
                        noResultsFoundText: this.props.noResultsFoundText ? this.props.noResultsFoundText : `No ${this.props.attributeName} Found`
                    }}
                    inputProps={{
                        placeholder: this.props.placeHolder != null ? this.props.placeHolder : UIConstants.PlaceHolderText.SearchAttribute + this.props.attributeName,
                        role: 'searchbox',
                        "aria-label" : this.props.attributeName,
                        required : true
                    }}
                    itemLimit={this.props.itemLimit ? this.props.itemLimit : 100}
                    disabled={this.state.isPickerDisabled}
                    styles={{ input: { height:38 } }}
                />
            </div>
        );
    }

    private getTextFromItem(item: ITag): string {
        return item.name;
    }

    private onFilterChanged = (filterText: string, tagList: ITag[]): PromiseLike<ITag[]> => {
        const { tenantData, attributeName, isAsync , Data } = this.props;
        if (filterText && filterText.trim().length >= 2) {
            if (isAsync) {
                if (attributeName == 'SupplierID') {
                    return AccessAPI.searchSupplier(filterText).then(searchResponse => {
                        let searchResult = [];
                        if (searchResponse && searchResponse && searchResponse.length > 0) {
                            searchResult = searchResponse.map(item => ({ key: item, name: item }));
                        }
                        searchResult = searchResult.filter(tag => !this.isItemExist(tag, tagList))
                        this.setState({ attributeList: searchResult, attributeMaster: searchResponse });
                        return searchResult;
                    });
                } else if (attributeName == "SCOMP") {
                    let fiteredData = [];
                    let searchText = filterText.toLowerCase();
                    let listData: CountryCompanyAttribute[] = [...Data];
                    let formattedData: CountryCompanySearchModel[] = [];
                    for (const countryItem of listData) {
                        for (const companyItem of countryItem.company) {
                            if (formattedData.filter(x => x.code == companyItem.code).length == 0) {
                                formattedData.push({
                                    code: companyItem.code,
                                    description: companyItem.description,
                                    countryId: countryItem.countryId,
                                    countryName: countryItem.countryName
                                })
                            }
                        }
                    }

                    const isCompanyCode = /^[0-9]+$/.test(searchText);
                    if (isCompanyCode) {
                        // company code
                        fiteredData = formattedData.filter(x => x.code.indexOf(searchText) == 0);
                    } else {
                        fiteredData = formattedData.filter
                            (x =>
                                x.countryName.toLowerCase().indexOf(searchText) > -1
                                || x.description.toLowerCase().indexOf(searchText) > -1
                            );
                    }

                    let tagData: ITag[] = fiteredData.map(x => {
                        return {
                            key: x.code,
                            name: x.code !== x.description ? x.code + attributeKeyValueSeperator + x.description : x.code,
                        }
                    });

                    return tagData as unknown as PromiseLike<ITag[]>;
                }
                else {
                    return AccessAPI.getAttributeData(filterText,tenantData.TenantId,attributeName).then(searchResponse => {
                        let searchResult = [];
                        if (searchResponse && searchResponse && searchResponse.length > 0) {
                            searchResult = searchResponse.map(item => ({ key: item.code, name: item.code !== item.description ? item.code + attributeKeyValueSeperator + item.description :  item.code }));
                        }
                        searchResult = searchResult.filter(tag => !this.isItemExist(tag, tagList))
                        this.setState({ attributeList: searchResult, attributeMaster: searchResponse });
                        return searchResult;
                    });
                }

            } else {
                let searchText = filterText.toLowerCase();
                let fiteredData = Data.filter(x => x.text.toLowerCase().indexOf(searchText) > -1);
                let tagData: ITag[] = fiteredData.map(x => {
                    return {
                        key: x.key,
                        name: x.text,
                    }
                })
            return tagData as unknown as PromiseLike<ITag[]>;
            }
        }
        else {
            return [] as unknown as PromiseLike<ITag[]>;
        }
    }

    private onItemSelected = (item: ITag): ITag | null => {
        const { _picker } = this;
        if (_picker.current && this.isItemExist(item, _picker.current.items)) {
            return null;
        }
        return item;
    };

    private isItemExist(tag: ITag, tagList?: ITag[]) {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.filter(compareTag => compareTag.key === tag.key).length > 0;
    };
    private OnChange = (tagList?: ITag[]) => {
        const { onItemSelected, attributeName } = this.props;
        let attributes = tagList.map(x => {
            return {
                code: x.key,
                description: x.key !== x.name  ? x.name.split(attributeKeyValueSeperator)[1] : x.name
            }
        })
        if (onItemSelected) {
            onItemSelected(attributeName, attributes); // post back the list
        }
    }
}