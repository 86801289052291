import React from 'react';
import {
    Label, Icon, MessageBar, MessageBarType, TextField, IButton, DefaultButton, IconButton, PrimaryButton, ProgressIndicator
} from '@fluentui/react';
import { RouteComponentProps } from 'react-router-dom';
import ITenant from '../../shared/models/Tenant.model';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { SupplierUsers } from '../../shared/models/SupplierUser';
export interface SupplierUserManagementState {
    userEmail: string;
    userGuid: string;
    userDetails: IUser;
    message: string;
    messageType: MessageBarType;
    autoHideMessage: boolean;
    showMessage: boolean;
    isInprogress: boolean;
}
export interface IUserUpdate {
    id: string;
    UserEmail: string;
    OperationBy: string;
    Operation: string;
    TenantId: string;
    TenantName: string;
}
export class SupplierUserUpdate extends React.Component<RouteComponentProps<{}>>  {
    private tenantData: ITenant = null;
    private alertMsgCloseBtnRef = React.createRef<IButton>();
    constructor(props: any) {
        super(props);
    }
    public state: SupplierUserManagementState = {
        userEmail: '',
        userGuid: '',
        userDetails: null,
        message: '',
        messageType: MessageBarType.info,
        autoHideMessage: true,
        showMessage: false,
        isInprogress: false
    }
    public async componentDidMount() {
        let tenantName = (this.props.match.params as any).tenantName;
        this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === tenantName.toLowerCase())[0];
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        this.setState({ userDetails: user });
        this.onInit(this.tenantData);
    }
    public render(): JSX.Element {
        const { userEmail, userGuid, showMessage, isInprogress } = this.state;
        return (<React.Fragment>
            <div id={'div-msg-area'} style={{ height: 50 }}>
                {(showMessage) && this.renderMessage()}
                {(isInprogress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
            </div>
            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg10"}>
                        <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.SupplierUserUpdate}</h1></Label>
                    </div>
                </div>
                <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 30 }}>
                    <div className={"ms-Grid-row ms-sm6 ms-md4 ms-lg4"} >
                        <Label required>{'   '}User Email</Label>
                        <TextField placeholder="Enter User Email" value={userEmail} onChange={this._onUserEmailChange} />
                    </div>
                    <div className={"ms-Grid-row ms-sm6 ms-md4 ms-lg4"} >
                        <Label required>{'   '}User Object Id</Label>
                        <TextField placeholder="Enter User Object Id" value={userGuid} onChange={this._onUserGuidChange} />
                    </div>
                    <div className={"ms-Grid-row ms-sm6 ms-md4 ms-lg4"} >
                        <div className={"float-right"}>
                            <DefaultButton onClick={this.onResetClick} > {UIConstants.ButtonText.Reset} </DefaultButton>
                            <span>&nbsp;</span>
                            <PrimaryButton style={{ marginTop: 27 }} onClick={this.onSubmit} >{UIConstants.ButtonText.Update} </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </React.Fragment>
        );
    }
    private _onUserEmailChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ userEmail: text, showMessage: false });
    };
    private _onUserGuidChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ userGuid: text, showMessage: false });
    };
    private onResetClick = (): void => {
        this.setState({ userEmail: '', userGuid: '', showMessage: false });
    };
    private renderMessage = () => {
        let { message, messageType, autoHideMessage } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds

        setTimeout(() => {
            this.alertMsgCloseBtnRef.current.focus();
        }, 100) // set the focus to close button

        return <div role="alert" aria-label={message} >
            <MessageBar key='Msg' style={{ fontSize: '.875rem' }}
                actions={
                    <div>
                        <IconButton componentRef={this.alertMsgCloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false }) }} />
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                {message}
            </MessageBar>
        </div>
    };
    private onSubmit = async (): Promise<void> => {
        const { userEmail, userGuid, userDetails } = this.state;
        if (userEmail.length == 0 || userGuid.length == 0) {
            this.setState({ showMessage: true, isInprogress: false, messageType: MessageBarType.warning, message: 'UserEmail/UserID is invalid, please provide a valid Data.', autoHideMessage: false });
            return;
        }
        this.setState({ isInprogress: true });
        AccessAPI.getUserGuid(this.tenantData.TenantId, userEmail).then(res => {
            if (res == userGuid) {
                let Data: IUserUpdate = {
                    UserEmail: userEmail,
                    id: userGuid,
                    OperationBy: userDetails.Alias,
                    Operation: UIConstants.ButtonText.Update,
                    TenantId: this.tenantData.TenantId,
                    TenantName: this.tenantData.TenantName
                };
                AccessAPI.updateUserObjectId(Data).then(response => {
                    if (response == true) {
                        this.setState({ showMessage: true, isInprogress: false, messageType: MessageBarType.success, message: 'User object ID is successfully updated.', autoHideMessage: false });
                    }
                    else if (response == false) {
                        this.setState({ showMessage: true, isInprogress: false, messageType: MessageBarType.warning, message: 'User email does not exist in the system.', autoHideMessage: false });
                    }
                    else {
                        this.setState({ showMessage: true, isInprogress: false, messageType: MessageBarType.warning, message: 'There is an internal server error occoured while processing update. Please contact EUA Engineering Team', autoHideMessage: false  });
                    }
                });
            }
            else {
                this.setState({ showMessage: true, isInprogress: false, messageType: MessageBarType.warning, message: 'User ID is invalid, please provide a valid ID.', autoHideMessage: false });
                return;
            }
        });
    };
    /**
     * Initial onInit function - first entry point
     */
    private onInit = (tenantData: ITenant) => {
        if (tenantData) {
            //this.fetchSupplierData(selectedPrincipalId, selectedSupplierId);
        } else {
            window.location.href = "/";
        }
    };
}
