import React from 'react'
import { Modal, IconButton, Icon, mergeStyleSets, getTheme, getId, FontSizes, FontWeights, Label } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import _ from 'lodash';
import { ReviewRequestSubmissionModel, ReviewResponse } from '../../shared/models/UserAccess.model';
import JSONToCSVConvertor from '../../shared/functions/ExportToCSV';

const theme = getTheme();
const iconButtonStyles = mergeStyleSets({
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
});
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 8px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            }
        }
    }
});

const classNames = mergeStyleSets({
    activityRoot: {
        marginTop: "20px"
    },
    nameText: {
        fontSize: 14,
        fontWeight: 600,

    }, actionText: {
        fontSize: 14,
    }, actionByText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium,
        color: '#0064BF'
    },
    lblText: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        // -webkit-font-smoothing: antialiased;
        fontSize: 14,
        fontWeight: 600,
        color: 'rgb(23, 23, 23)',
        boxSizing: 'border-box',
        boxShadow: 'none',
        margin: 0,
        display: 'block',
        padding: '5px 0px',
        overflowWrap: 'break-word',
    }
});

interface IReviewUsersDetailsProps {
    isAdminScreen: boolean;
    onDismiss: Function;
    selectedReview: ReviewRequestSubmissionModel;

}
interface IAccessRequestDetailState {
    showUserModal: boolean,
    userDetails: any,
}
export type ReviewCSVDetails = {
    ReviewName: string;
    BusinessJustification: string;
    UserUPN: string;
    Scope: string;
    UserName: string;
    RoleName: string;
    Decision: string;
    ReviewedBy: string;
    DesignatedReviewers: string;
    DecisionJustification: string;   
    ReviewDateTime: string;   
}

export default class ReviewUsersDetails extends React.Component<IReviewUsersDetailsProps, IAccessRequestDetailState> {
    private _titleId: string = getId('title');
    constructor(props: any) {
        super(props);
        this.state = {
            showUserModal: true,
            userDetails: [{ UserName: ' User Name ', Access: ' Access ', Decision: ' Decision ', Justification: ' Justification ', ReviewedBy: ' ReviewedBy', ReviewedDate: ' ReviewedDate ' }]
        }
    }
    public render() {
        const { showUserModal, userDetails } = this.state;
        const { selectedReview } = this.props
        let totalApprovedUsers = 0; let totalRejectedUsers = 0;
        { selectedReview[0].users.forEach(user => { if (user.reviewDecision!=null && user.reviewDecision.decision == "Approve") totalApprovedUsers++; }); }
        { selectedReview[0].users.forEach(user => { if (user.reviewDecision != null && user.reviewDecision.decision == "Deny") totalRejectedUsers++; }); }
        return <React.Fragment>
            <Modal titleAriaId={this._titleId}
                isOpen={showUserModal} onDismiss={this._closeModal}
                isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ position: 'absolute', right: 10, paddingTop: 20, paddingBottom: 12, marginTop: 20 }} > <IconButton tabIndex={0} styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close User Details dialog" onClick={this._closeModal as any} /> </span>
                    <span style={{ position: 'absolute', left: 25, paddingTop: 20, paddingBottom: 12, marginTop: 20 }}   ><h2 role='heading' className={"h2"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.MessageBoxTitle.UserDetails}</h2>
                    </span>
                </div>
                <div role='presentation' className={contentStyles.body}>
                    <div style={{
                        height: '60vh',
                        width: '70vw',
                        overflow: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        marginTop: 30
                    }}
                    >
                        {(selectedReview[0].status == 'InProgress' || selectedReview[0].status == 'Complete') && selectedReview[0].users.length> 0 && < div id={'dv-user-details'}>

                            <Label>Total users to be Reviewed: {selectedReview[0].users.length}</Label>
                            <Label>Total approved: {totalApprovedUsers}</Label>
                            <Label>Total rejected: {totalRejectedUsers}</Label>
                            <Label>Total number of Pending Users : {(selectedReview[0].users.length) - (totalApprovedUsers + totalRejectedUsers)}</Label>
                            <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg1"}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <PrimaryButton  title="Export to CSV file" onClick={() => this.getCSVfile(selectedReview[0].users, selectedReview[0])} ><Icon iconName={'ExcelLogo'} />&nbsp;Export</PrimaryButton>
                                </div>
                            </div>
                            
                            {/*<table className="tb-user-detail" style={{ lineHeight: 1, width: 1000 }}>*/}
                            {/*    <thead className="tb-user-detail">*/}
                            {/*        {userDetails.map(item => {*/}
                            {/*            return <div >*/}
                            {/*                <tr key={'tr-ast'}>*/}
                            {/*                    <td key={'td-ast-name-key'} style={{ width: 1500 }}><span className={classNames.nameText}>{item.UserName}</span></td>*/}
                            {/*                    <td key={'td-ast-ename-key'} style={{ width: 1500 }}><span className={classNames.nameText}>{item.Access}</span></td>*/}
                            {/*                    <td key={'td-ast-ename-key'} style={{ width: 4500 }}><span className={classNames.nameText}>{item.Decision}</span></td>*/}
                            {/*                    <td key={'td-ast-ename-key'} style={{ width: 1500 }}><span className={classNames.nameText}>{item.Justification}</span></td>*/}
                            {/*                    <td key={'td-ast-ename-key'} style={{ width: 1500 }}><span className={classNames.nameText}>{item.ReviewedBy}</span></td>*/}
                            {/*                    <td key={'td-ast-ename-key'} style={{ width: 1500 }}><span className={classNames.nameText}>{item.ReviewedDate}</span></td>*/}
                            {/*                </tr>*/}
                            {/*            </div>*/}
                            {/*        })*/}
                            {/*        }*/}
                            {/*    </thead>*/}
                            {/*    <tbody>*/}
                            {/*        {selectedReview[0].users.map(item => {*/}
                            {/*            if (item.reviewDecision != null && item.reviewDecision.decision == "Deny") {*/}
                            {/*                return <div style={{ wordBreak: 'break-word' }}>*/}

                            {/*                    <tr key={'tr-ast'}>*/}
                            {/*                        <td key={'td-ast-name-key'} style={{ width: 1000 }}><span className={classNames.actionText}>{item.principal.displayName}</span></td>*/}
                            {/*                        <td key={'td-ast-ename-key'} style={{ width: 1500 }}><span className={classNames.actionText}>{item.resource.displayName}</span></td>*/}
                            {/*                        <td key={'td-ast-ename-key'} style={{ width: 1000 }}><span className={classNames.actionText}>{item.reviewDecision != null ? item.reviewDecision.decision : ''}</span></td>*/}
                            {/*                        <td key={'td-ast-ename-key'} style={{ width: 1000 }}><span className={classNames.actionText}>{item.reviewDecision != null ? item.reviewDecision.justification : ''}</span></td>*/}
                            {/*                        <td key={'td-ast-ename-key'} style={{ width: 1000 }}><span className={classNames.actionText}>{item.reviewedBy != null ? item.reviewedBy.displayName : ''}</span></td>*/}
                            {/*                        <td key={'td-ast-ename-key'} style={{ width: 1000 }}><span className={classNames.actionText}>{item.reviewDecision != null ? new Date(item.reviewDecision.reviewedDateTime).toLocaleString().split(",")[0] : ''}</span></td>*/}
                            {/*                    </tr>*/}

                            {/*                </div>*/}
                            {/*            }*/}
                            {/*        })*/}
                            {/*        }*/}
                            {/*    </tbody>*/}
                            {/*</table>*/}

                        </div>
                        }

                        {(selectedReview[0].status == 'Invalid') && selectedReview[0].users.length==0 && < div id={'dv-user-details'}>

                            <Label>No users are available for this review</Label>                      
                           
                        </div>
                        }
                        {/*{(selectedReview[0].status == 'InProgress') && selectedReview[0].users.length > 0 && <div id={'dv-user-details'}>*/}
                        {/*    <Label>Total number of Pending Users : {(selectedReview[0].users.length) - (totalApprovedUsers + totalRejectedUsers)}</Label>*/}
                        {/*    <table className="tb-user-detail" style={{ lineHeight: 1, width: 1000 }}>*/}
                        {/*        <thead className="tb-user-detail">*/}
                        {/*            {userDetails.map(item => {*/}
                        {/*                return <div >*/}
                        {/*                    <tr key={'tr-ast'}>*/}
                        {/*                        <td key={'td-ast-name-key'} style={{ width: 1500 }}><span className={classNames.nameText}>{item.UserName}</span></td>*/}
                        {/*                        <td key={'td-ast-ename-key'} style={{ width: 1500 }}><span className={classNames.nameText}>{item.Access}</span></td>*/}
                        {/*                    </tr>*/}
                        {/*                </div>*/}
                        {/*            })*/}
                        {/*            }*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*            {selectedReview[0].users.map(item => {*/}
                        {/*                if (item.reviewDecision == null) {*/}
                        {/*                    return <div style={{ wordBreak: 'break-word' }}>*/}
                        {/*                        <tr key={'tr-ast'}>*/}
                        {/*                            <td key={'td-ast-name-key'} style={{ width: 1000 }}><span className={classNames.actionText}>{item.principal.displayName}</span></td>*/}
                        {/*                            <td key={'td-ast-ename-key'} style={{ width: 1500 }}><span className={classNames.actionText}>{item.resource.displayName}</span></td>*/}
                        {/*                        </tr>*/}
                        {/*                    </div>*/}
                        {/*                }*/}
                        {/*            })*/}
                        {/*            }*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}

                        {/*</div>*/}
                        {/*}*/}
                    </div>

                </div>
            </Modal>
        </React.Fragment>
    };

    private getCSVfile = (items: any, review: ReviewRequestSubmissionModel) => {
        let data: Array<ReviewCSVDetails> = [];
        let approvers = '';
        review['approvers'].forEach(approver => { approvers += approver + "; " });
        items.forEach(item => {
            data.push({
                ReviewName: review.reviewName,
                BusinessJustification: review.businessJustification,             
                UserName: item?.principal.displayName,
                UserUPN: item?.principal.userPrincipalName,
                RoleName: item?.resource.displayName,
                Scope: item?.resource.roleType,
                Decision: item?.reviewDecision?.decision === undefined ? "" : item?.reviewDecision?.decision,
                ReviewedBy: item?.reviewDecision === undefined ? "" : item?.reviewedBy?.displayName,
                DesignatedReviewers: approvers,
                DecisionJustification: item?.reviewDecision?.justification === undefined ? "" : item?.reviewDecision?.justification,
                ReviewDateTime: item?.reviewDecision?.reviewedDateTime === undefined ? "" : item?.reviewDecision?.reviewedDateTime               
            })
        });
        return JSONToCSVConvertor(data, "ReviewDetails_" + review.tenantName+"_"+new Date());
    }
    private _closeModal = (): void => {
        this.setState({ showUserModal: false });
        this.props.onDismiss();
    };
}

