import React, { useRef } from 'react';
import {
    DetailsListLayoutMode,
    SelectionMode,
    ShimmeredDetailsList,
    Spinner, SpinnerSize, Modal, IconButton, mergeStyleSets, FontSizes, FontWeights, getTheme,
} from '@fluentui/react';
import AccessAPI from '../../store/AccessAPI';
import {ExternalAADRolePermissions} from '../models/ExternalAADRolePermissions'
import { UIConstants } from '../models/Constants';
import { create } from 'istanbul-reports';
import { ModalDialog } from '../../components/ModalDialog';

const theme = getTheme();
const iconButtonStyles = mergeStyleSets({
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
});
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 8px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            }
        }
    }
});

const classNames = mergeStyleSets({
    activityRoot: {
        marginTop: "20px"
    },
    nameText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium
    }, actionText: {
        fontSize: FontSizes.medium
    }, actionByText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium,
        color: '#0064BF'
    },
    lblText: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        // -webkit-font-smoothing: antialiased;
        fontSize: 14,
        fontWeight: 600,
        color: 'rgb(23, 23, 23)',
        boxSizing: 'border-box',
        boxShadow: 'none',
        margin: 0,
        display: 'block',
        padding: '5px 0px',
        overflowWrap: 'break-word',
    }
});
interface IViewPermissionsProps {
    businessGroupName: string;
    roleName: string
    roleId: string
    tenantId: string;
    onDismiss:Function
}

interface IVewPermissionsState{
    tenantID: string;
    role: string;
    roleId: string;
    permissions: Array<string>;
    isPermissionsLoaded: boolean;
    showModal: boolean;
    businessGrpName: string;
    gridHeight: string;
}

export default class ViewPermissions extends React.Component<IViewPermissionsProps, IVewPermissionsState> {

    private modalRef = React.createRef<any>();
    private headerRef = React.createRef<HTMLDivElement>();
    public state ={
        tenantID: null,
        role: null,
        roleId: null,
        permissions: null,
        isPermissionsLoaded: false,
        showModal: true,
        businessGrpName: null,
        gridHeight:'0px'
    }

    public constructor(props: IViewPermissionsProps) {
        super(props);
        this.state.tenantID = props.tenantId;
        this.state.role = props.roleName;
        this.state.roleId = props.roleId;
        this.state.businessGrpName = props.businessGroupName
    }

    componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched = () => {
        const { tenantID, roleId } = this.state;

        let roleBasedPermissions: ExternalAADRolePermissions;
        AccessAPI.GetRolePermissions(tenantID, roleId).then(response => { 
            if (response) {
                roleBasedPermissions = response;
                this.setState({ permissions: roleBasedPermissions?.permissionValues || null, isPermissionsLoaded:true })
            }
            else {
                this.setState({ permissions: [], isPermissionsLoaded:true })
            }
        });
    }

    render(): JSX.Element {
        const { permissions, isPermissionsLoaded, businessGrpName, role } = this.state
       
        return <React.Fragment>

            <Modal ref={this.modalRef} titleAriaId={'title'}
                isOpen={this.state.showModal} onDismiss={this._closeModal}
                isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ position: 'absolute', right: 10, paddingTop: 20, paddingBottom: 12, marginTop: 20 }} > <IconButton tabIndex={0} styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close Access Details dialog" onClick={this._closeModal as any} /> </span>
                    <span style={{ position: 'absolute', left: 25, paddingTop: 20, paddingBottom: 12, marginTop: 20 }}   ><h2 role='heading' className={"h2"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.MessageBoxTitle.Permissions}</h2>
                    </span>
                </div>
                <div role='presentation' className={contentStyles.body}>
                    <div tabIndex={0} style={{
                        height: '60vh',
                        width: '70vw',
                        overflow: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        marginTop: 30
                    }}
                    >
                        <div  className={"ms-Grid"} dir="ltr">
                            <div className={"ms-Grid-row"}>
                                <div ref={this.headerRef} className={"ms-Grid-col ms-sm12 ms-md12 ms-lg6"}>
                                    <label>
                                        <div className={classNames.lblText} >Business Group Name: {businessGrpName}</div>
                                    </label>

                                    <label>
                                        <div className={classNames.lblText} >Role Name: {role}</div>
                                    </label>
                                </div>
                            </div>                     
                            {<div style={{ height: '50vh', overflow: 'auto' }} key="work-area">
                                        {isPermissionsLoaded ? permissions && <ShimmeredDetailsList
                                            setKey="permission-items"
                                            columns={this.getColumnDefinitions()}
                                            items={permissions.length > 0 ? this.getPermissions() : []}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            selectionMode={SelectionMode.none}
                                            enableShimmer={false}
                                            ariaLabelForShimmer="Permissions are being fetched"
                                            ariaLabelForGrid="Role permissions"
                                            listProps={{ renderedWindowsAhead: 10, renderedWindowsBehind: 10 }}
                                        /> : <Spinner size={SpinnerSize.large} style={{ paddingLeft: 120, paddingTop: 30, alignItems: 'start' }} label="loading..." ariaLive="assertive" labelPosition="bottom" />}
                            </div>}
                        </div>
                    </div>
                </div>
            </Modal>

        </React.Fragment>       
    }


    private getColumnDefinitions = () => {
        let columnDef = [{ key: 'permissions', name: 'Permissions', fieldName: 'permissions', minWidth: 500, maxWidth: 500, isResizable: false }];
        return columnDef;
    }

    //private getGridHeight=() => {
    //    if (this.modalRef.current && this.headerRef.current) {
    //        const modalHeight = this.modalRef.current.getBoundingClientRect().height;
    //        const headerHeight = this.headerRef.current.getBoundingClientRect().height;
    //        const resultingHeight = ((modalHeight - headerHeight) - 20).toString() + 'px';
    //        this.setState({ gridHeight: resultingHeight })
    //    }
    //}

    private getPermissions = () => {
        const { permissions } = this.state;

        let permissionsData = [];
        if (permissions.length > 0) {
            permissions.forEach(item => {
                permissionsData.push({ permissions: item })
            })
        }
        else {
            permissionsData = [{ permissions: '' }]
        }
        return permissionsData;
    }

    private _closeModal = (): void => {
        this.setState({ showModal: false });
        this.props.onDismiss();
    };
}

