import React from 'react'
import { Modal, IconButton, mergeStyleSets, getTheme, getId, FontSizes, FontWeights, Link } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import _ from 'lodash';
import { ReviewRequestSubmissionModel } from '../../shared/models/UserAccess.model';

const theme = getTheme();
const iconButtonStyles = mergeStyleSets({
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
});
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 8px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            }
        }
    }
});

const classNames = mergeStyleSets({
    activityRoot: {
        marginTop: "20px"
    },
    nameText: {
        fontSize: 14,
        fontWeight: 600,

    }, actionText: {
        fontSize: 14,
    }, actionByText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium,
        color: '#0064BF'
    },
    lblText: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        // -webkit-font-smoothing: antialiased;
        fontSize: 14,
        fontWeight: 600,
        color: 'rgb(23, 23, 23)',
        boxSizing: 'border-box',
        boxShadow: 'none',
        margin: 0,
        display: 'block',
        padding: '5px 0px',
        overflowWrap: 'break-word',
    }
});

interface IReviewDetailsProps {
    isAdminScreen: boolean;
    onDismiss: Function;
    selectedReview: ReviewRequestSubmissionModel;
}
interface IAccessRequestDetailState {
    showModal: boolean,
}
export default class ReviewDetails extends React.Component<IReviewDetailsProps, IAccessRequestDetailState> {
    private _titleId: string = getId('title');
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: true,
        };

    }
    public render() {
        const { selectedReview } = this.props;
        let approvers = '';
        let roles = '';
        let groups = '';
        let mercuryRoles = [];
        let finalMercuryRole = '';
        selectedReview[0]['approvers'].forEach(approver => { approvers += approver + "; " });
        if (selectedReview[0]['role'] != null) selectedReview[0]['role'].forEach(role => { roles += role.name + "; " }); else roles = 'All';
        if (selectedReview[0]['businessGroup'] != null) selectedReview[0]['businessGroup'].forEach(bg => { groups += bg.name + "; " });
        if (selectedReview[0]['tenantName'] == UIConstants.Tenant.Mercury) {
            selectedReview[0]['role'].forEach(role => {
                mercuryRoles.push(role.name.split('/')[0])
            });
            mercuryRoles = _.uniq(mercuryRoles);
            mercuryRoles.forEach(role => { finalMercuryRole += role + "; " });
        }
        return <React.Fragment>
            <Modal titleAriaId={this._titleId}
                isOpen={this.state.showModal} onDismiss={this._closeModal}
                isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ position: 'absolute', right: 10, paddingTop: 20, paddingBottom: 12, marginTop: 20 }} > <IconButton tabIndex={0} styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close Access Details dialog" onClick={this._closeModal as any} /> </span>
                    <span style={{ position: 'absolute', left: 25, paddingTop: 20, paddingBottom: 12, marginTop: 20 }}   ><h2 role='heading' className={"h2"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.MessageBoxTitle.ReviewDetails}</h2>
                    </span>
                </div>
                <div role='presentation' className={contentStyles.body}>
                    <div style={{
                        height: '60vh',
                        width: '70vw',
                        overflow: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        marginTop: 30
                    }}
                    >
                        <div id={'dv-asset-details'}>
                            <table className="tb-asset-detail">
                                <tbody>

                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' RequestedBy '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedReview[0]['requestedBy']}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-desc'} >
                                        <td key={'td-ast-desc-key'} > <span className={classNames.nameText}>{' BusinessJustification '} </span></td>
                                        <td key={'td-ast-desc-val'} > <span className={classNames.actionText}>{selectedReview[0]['businessJustification']}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Role '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{mercuryRoles.length==0 ? roles : finalMercuryRole}</span></td>    </tr>
                                     {selectedReview[0]['businessGroup'] != null && <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' BusinessGroup '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{groups}</span></td>   </tr>}
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' FiscalYear '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedReview[0]['fiscalYear']}</span></td>     </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Quarter '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedReview[0]['quarter']}</span></td>   </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' StartDate '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{new Date(selectedReview[0]['startDate']).toLocaleString().split(",")[0]}</span></td>   </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' EndDate '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{new Date(selectedReview[0]['endDate']).toLocaleString().split(",")[0]}</span></td>   </tr>

                                     <tr key={'tr-ast-do'} >
                                        <td key={'td-ast-do-key'} > <span className={classNames.nameText}>{' Need Manager Approval '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedReview[0]['isManagerchecked'] == true ? 'Yes' : 'No'}</span></td>   </tr>   
                                    <tr key={'tr-ast-id'} >
                                        <td key={'td-ast-id-key'} ><span className={classNames.nameText}>{' Approvers '} </span></td>
                                        <td key={'td-ast-id-val'} ><span className={classNames.actionText}>{approvers}</span></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    };

    private _closeModal = (): void => {
        this.setState({ showModal: false });
        this.props.onDismiss();
    };
}

