import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Checkbox, Link, Label, MessageBarType, PrimaryButton, TextField, ChoiceGroup, MessageBar, IconButton, IButton, ProgressIndicator, getId, DialogType, DefaultButton } from '@fluentui/react';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import { UIConstants } from '../../shared/models/Constants';
import { DialogModel } from '../../shared/models/Dialog.model';
import { ValidationResponse } from '../../shared/models/ValidationResponse.model';


export interface IMercuryDRSFormState {
    Environment: any;
    ServiceAccountProd: string;
    ServiceAccountUAT: string;
    PrimaryOwner: string;
    SecondaryOwner: string;
    AppID: any;
    BusinessJustification: string
    isChecked: boolean;
    UserDetails: string;
    pickerCtrlKey: number;
    selectedChoiceGroup: string;
    showMessage: boolean;
    message: string;
    messageType: MessageBarType;
    submitInProgress: boolean;
}
export interface IMercuryDRSFormProperty {
}

type MercuryDRSFormProps = IMercuryDRSFormState & IMercuryDRSFormProperty &
    RouteComponentProps<{}>;
export default class MercuryDRSForm extends React.Component<MercuryDRSFormProps> {
    private alertMsgCloseBtnRef = React.createRef<IButton>();
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    }
    public state: IMercuryDRSFormState = {
        Environment: null,
        ServiceAccountProd: null,
        ServiceAccountUAT: null,
        PrimaryOwner: null,
        SecondaryOwner: null,
        AppID: null,
        BusinessJustification: null,
        isChecked: false,
        UserDetails: null,
        pickerCtrlKey: 0,
        selectedChoiceGroup: 'Both',
        showMessage: false,
        message: '',
        messageType: MessageBarType.info,
        submitInProgress: false
    }


    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { Name: userAccount.name };
        this.setState({ UserDetails: user.Name });
    }

    public render(): JSX.Element {
        const { showMessage, submitInProgress, ServiceAccountProd, ServiceAccountUAT, PrimaryOwner, SecondaryOwner, AppID, BusinessJustification, isChecked, UserDetails, pickerCtrlKey, selectedChoiceGroup } = this.state;
        return <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 25 }}>
                {(showMessage) && this.renderMessage2()}
                {(submitInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
            </div>
            <div id={'div-req-section'} style={{ padding: '1%', paddingTop: '2%' }} >
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg12"}><Label><h1 role="heading" aria-label={UIConstants.PageTitle.AccessRequest} aria-level={1} className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.DRSAccessRequest}</h1></Label></div>
                    </div>
                </div>
            </div>
            <div key="grid-request" className={"ms-Grid"} dir="ltr"><div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                            <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg2"}>
                                <Label required={true}>Requester Alias: </Label></div>
                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg4"}>{UserDetails} </div><br /><br />
                            <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg2"}>
                                <Label required={true}>Environment: </Label></div>
                            <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg4"}><ChoiceGroup selectedKey={selectedChoiceGroup} options={[
                                { key: 'Prod', text: 'Prod' },
                                { key: 'UAT', text: 'UAT' },
                                { key: 'Both', text: 'Both' },
                            ]} onChange={this.onChoiceGroupChange}
                                styles={{
                                    flexContainer: [
                                        {
                                            display: "inline-flex",
                                            selectors: {
                                                ".ms-ChoiceField": {
                                                }
                                            }
                                        }
                                    ]
                    }} /></div><br /><br /><br />
                        {this.state.selectedChoiceGroup != 'UAT' && <div style={{ paddingTop: 5 }}>
                                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg2"}><Label required={true}>Service Account - Prod: </Label></div>
                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg4"}><TextField placeholder="Domain/Account"></TextField></div><br /><br />
                            </div>
                            }
                        {this.state.selectedChoiceGroup != 'Prod' && <div style={{ paddingTop: 15 }}>
                                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg2"}><Label required={true}>Service Account - UAT: </Label></div>
                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg4"}><TextField placeholder="Domain/Account"></TextField></div><br /><br /></div>
                        }
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg2"}><Label required={true}>Primary Owner: </Label></div>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg4"}>
                            <PeoplePicker
                                key={pickerCtrlKey}
                                type={PeoplePickerType.Normal}
                                contentType={PeoplePickerContentType.FTEUser}
                                selectionMode={PeoplePickerSelectionMode.Single}
                                onchange={(items) => { this.onPeoplePickerItemChange(items) }}
                                defaultSelectedItems={[]}
                                {...this.props}
                                hideCEO={true}
                                hideDirectFTE={false}
                                setFocusOnPicker={false}
                    ></PeoplePicker></div>
                <br /><br /><br />
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg2"}><Label required={true}>Secondary Owner: </Label></div>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg4"}>
                            <PeoplePicker
                                key={pickerCtrlKey}
                                type={PeoplePickerType.Normal}
                                contentType={PeoplePickerContentType.FTEUser}
                                hideCEO={true}
                                hideDirectFTE={false}
                                selectionMode={PeoplePickerSelectionMode.Single}
                                onchange={(items) => { this.onPeoplePickerItemChange(items) }}
                                defaultSelectedItems={[]}
                                {...this.props}
                                setFocusOnPicker={false}
                    ></PeoplePicker></div>
                <br /><br /><br />
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg2"}><Label required={true}>Business Justification: </Label></div>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg4"}><TextField multiline rows={4} placeholder="Enter Justification"></TextField></div>
                <br /><br /><br /><br />
                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg8"} style={{ display: 'inline-flex', "alignItems": 'baseline', float: 'left' }} >
                    <span><Checkbox styles={{ root: { paddingBottom: 20, width: 20, marginTop: 4 } }} checked={isChecked} onChange={(ev,checked) => { this.setState({ isChecked: checked }) }} />
                        </span>
                    <span style={{ paddingLeft: 5 }}>
                                I have read the {' '}
                                <Link href="https://privacy.microsoft.com/en-US/data-privacy-notice">
                                    Privacy Statement
                                </Link>{' '}
                                and agree to the {' '}
                                <Link href="https://microsoft.sharepoint.com/teams/mercury/DRS/Pages/DRSAccess.aspx">
                                    Terms and Conditions
                                </Link>
                            </span>
                </div>
                <br /><br />
                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}> <div className="float-right">
                            <br />
                            <div>
                                <DefaultButton onClick={this.onResetClick} > {UIConstants.ButtonText.Reset} </DefaultButton>
                                <span>&nbsp;</span>
                                <PrimaryButton onClick={this.onSubmitClick} >{UIConstants.ButtonText.Submit} </PrimaryButton>
                            </div>
                </div>
                </div>
            </div>
            </div>
        </React.Fragment>
    };
    private onPeoplePickerItemChange(items: any) {
        if (items && items.length > 0) {
            let userProperties = items.map(x => x.tertiaryText);
            let list = [];
            for (const userItem of userProperties) {
                let parsedData = JSON.parse(userItem);
                list.push(parsedData.email);
            }
            this.setState({ selectedUser: list, defaultSelectedItems: items, showMessage: false });
        }
    }
    private onChoiceGroupChange = (ev, options) => {
        this.setState({ selectedChoiceGroup: options.key });
    };
    private renderMessage2 = () => {
        let { message, messageType } = this.state;

        setTimeout(() => {
                this.alertMsgCloseBtnRef.current.focus();
        }, 100) // set the focus to close button
        return <div role="alert" aria-label={message} >
            <MessageBar key='SaveMsg' style={{ fontSize: '.875rem' }}
                actions={
                    <div>
                        <IconButton componentRef={this.alertMsgCloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false, setAttributeFocus: false }) }} />
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                {message}
            </MessageBar>
        </div>
    };
    private onResetClick = (): void => {
        this._modalDialogContent = {
            subText: UIConstants.Messages.ResetConfirmation,
            title: UIConstants.MessageBoxTitle.Reset,
            okButtonText: UIConstants.ButtonText.Reset,
            okAction: this.ResetUI,
            cancelAction: this.closeDialog,
            type: DialogType.normal,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }

        this.setState({ hideDialog: false, modalDialogContent: this._modalDialogContent });
    };
    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };
    private ResetUI = (): void => {
        this.setState({
            Environment: null,
            ServiceAccountProd: null,
            ServiceAccountUAT: null,
            PrimaryOwner: null,
            SecondaryOwner: null,
            AppID: null,
            BusinessJustification: null,
            isChecked: false,
            pickerCtrlKey: 0,
            selectedChoiceGroup: 'Both',
            showMessage: false,
            message: '',
            messageType: MessageBarType.info,
            submitInProgress: false
        });
    };
    private onSubmitClick = async (): Promise<void> => {
        const {  } = this.state;
        let validationResponse = this.ValidateData();
        if (validationResponse.isValid) {
            this._modalDialogContent = {
                subText: UIConstants.Messages.SaveConfirmation,
                title: UIConstants.MessageBoxTitle.SaveAccess,
                okButtonText: UIConstants.ButtonText.Submit,
                type: DialogType.normal,
                okAction: this.saveDRSForm,
                cancelAction: this.closeDialog,
                closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
            }
            this.setState({ hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent });// Show confirmation pop-up      
        } else {
            // Show Error message and skip submission
            this.setState({ showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
        }
    };
    private saveDRSForm = (): void => {


    }
    private ValidateData = (): ValidationResponse => {
        const { isChecked } = this.state;
        let response: ValidationResponse = { isValid: true };
        let errorMessage = null;
        // Validation case 8 : check if alternate Manager is correct
        if (!isChecked) {
                errorMessage = errorMessage == null ? UIConstants.AlternateManager : `${errorMessage}, ${UIConstants.AlternateManager}`
        }

        if (errorMessage) {
            return { isValid: false, validationMessage: ` ${errorMessage} is required.` }
        }
        else {
            return response; // Validation Success , Allow user to Submit
        }
    };
}