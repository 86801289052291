import React from 'react';
import { UIConstants } from '../../shared/models/Constants';
import { render } from 'react-dom';
import { Label, ILabelStyles, Spinner, SpinnerSize, IStyleFunctionOrObject, ILabelStyleProps, ShimmeredDetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import AccessAPI from '../../store/AccessAPI';
import { ExternalAADRoles } from '../../shared/models/Role.model';
import ITenant from '../../shared/models/Tenant.model';
import { ExternalAADRolePermissions } from '../../shared/models/ExternalAADRolePermissions';

const labelStyles: Partial<IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>> = {
    root: { marginTop: 7 }
};
export interface IpermissionsState {
    cosmosRoleDefinitions: ExternalAADRoles[];
    selectedBgName: string;
    rolesBasedOnBg: ExternalAADRoles[];
    selectedRoleName: string;
    selectedRoleId:string
    tenantName: string;
    tenantId: string;
    roleBasedpermissions: ExternalAADRolePermissions;
    isPermissionsLoaded:boolean
}

export default class Permissions extends React.Component<IpermissionsState> {

    public state: IpermissionsState = {
        cosmosRoleDefinitions: [],
        selectedBgName: '',
        rolesBasedOnBg: [],
        selectedRoleName: '',
        selectedRoleId: '',
        tenantName: '',
        tenantId: '',
        roleBasedpermissions: null,
        isPermissionsLoaded:false
    }

    public constructor(props) {
        super(props)
    }

    public componentDidMount() {
        let _tenantName = window.location.pathname.split('/').reverse()[0];
        let tenantConfig: ITenant[] = JSON.parse(sessionStorage.getItem('TenantConfig').replaceAll("\'","\""));
        let tenantData = tenantConfig.filter(x => x.TenantName.toLowerCase() == _tenantName.toLowerCase())[0];
        this.setState({ tenantName: tenantData.TenantName, tenantId: tenantData.TenantId }, () => {
            AccessAPI.getCosmosRoleDefinitions(this.state.tenantId).then(res => {
                let roleDefinitions: ExternalAADRoles[] = res;
                this.setState({ cosmosRoleDefinitions: roleDefinitions })
            });
        })
    }

    public render(): JSX.Element {
        const { cosmosRoleDefinitions, selectedBgName, rolesBasedOnBg, selectedRoleId, selectedRoleName }=this.state
        return <React.Fragment>
            <div id={'div-req-section'} style={{ marginTop: '25px', padding: '1%', paddingTop: '2%' }} >
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-role' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg10"}>
                            <Label style={{ paddingLeft: '5px', fontSize: 20 }} > <h1 role="heading" aria-level={1} className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}> {UIConstants.PageTitle.Permissions}</h1></Label>
                        </div>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                            <div style={{ paddingLeft: '5px' }}>
                                <Label required={true} styles={labelStyles}>{UIConstants.Group}</Label>
                                <select id={'drpGrp'} value={selectedBgName }
                                    title={UIConstants.Group} required={true}
                                    onChange={this.onBusinessGroupChange}
                                    style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                    <option value="0">-- Select --</option>
                                    {cosmosRoleDefinitions.length > 0 && this.getBusinessGroups().map(role => {
                                        if (role.businessGroupId != null) {
                                            { return <option value={role.businessGroupName}>{role.businessGroupName}</option> }
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div key='grid-row-role' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                            <div style={{ paddingLeft: '5px' }}>
                                <Label required={true} styles={labelStyles}>{UIConstants.Role}</Label>
                                <select id={'drpGrp'} value={selectedRoleId}
                                    title={UIConstants.Group} required={true}
                                    onChange={this.onRoleDrpDownChange}
                                    style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                    <option value="0">-- Select --</option>
                                    {rolesBasedOnBg.length > 0 && rolesBasedOnBg.map(role => { return <option value={role.roleId}>{role.roleName}</option> })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div style={{ paddingLeft: '5px' }} key="work-area">
                        {this.state.isPermissionsLoaded ? this.state.roleBasedpermissions && <ShimmeredDetailsList
                            setKey="permission-items"
                            columns={this.ColumnHeaders()}
                            items={this.state.roleBasedpermissions.permissionValues.length > 0 ? this.getPermissions() : []}
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            enableShimmer={false}
                            ariaLabelForShimmer="Permissions are being fetched"
                            ariaLabelForGrid="Role permissions"
                            listProps={{ renderedWindowsAhead: 10, renderedWindowsBehind: 10 }}
                        /> : selectedRoleName == '' ? null : <Spinner size={SpinnerSize.large} style={{ paddingLeft: 120, paddingTop: 30, alignItems: 'start' }} label="loading..." ariaLive="assertive" labelPosition="bottom" />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    private getBusinessGroups = () => {
        const { cosmosRoleDefinitions } = this.state;
        let globalRoles = null;
        let businessGroups: ExternalAADRoles[] = [{ roleId: '', roleName: '', businessGroupId: null, businessGroupName: '', tenantId: '', tenantName: '', policyType: '', allowSkipLevel: '' }]
        cosmosRoleDefinitions.forEach(x => {
            if (businessGroups.filter(y => y.businessGroupName == x.businessGroupName).length == 0) {
                businessGroups.push(x);
            }
        })
        if (businessGroups.findIndex(x => x.businessGroupName == 'Global_Roles') != -1) {
            globalRoles = businessGroups.splice(businessGroups.findIndex(x => x.businessGroupName == 'Global_Roles'), 1)[0];
        }
        businessGroups.sort((a, b) => {
            if (a.businessGroupName.toUpperCase() < b.businessGroupName.toUpperCase()) {
                return 1
            }
            else if (a.businessGroupName.toUpperCase() > b.businessGroupName.toUpperCase()) {
                return -1
            }
            else {
                return 0
            }
        })
        if (globalRoles) {
            businessGroups.push(globalRoles);
        }
        businessGroups.reverse();
        return businessGroups;
    }

    private onBusinessGroupChange = (event: any): void => {
        if (this.state) {
            const { cosmosRoleDefinitions } = this.state;

            let selectedBusinessGroupName = event.target.value;
            let rolesBesedOnSelectedBg = cosmosRoleDefinitions.filter(x => x.businessGroupName == selectedBusinessGroupName);
            rolesBesedOnSelectedBg.sort((a, b) => {
                if (a.roleName.toUpperCase() > b.roleName.toUpperCase()) {
                    return 1
                }
                else if (a.roleName.toUpperCase() < b.roleName.toUpperCase()) {
                    return -1
                }
                else {
                    return 0
                }
            })
            this.setState({ rolesBasedOnBg: rolesBesedOnSelectedBg, selectedBgName: selectedBusinessGroupName, isPermissionsLoaded: false, selectedRoleName: '' });
        }
    }

    private onRoleDrpDownChange = (event: any):void => {
        const { tenantId, cosmosRoleDefinitions } = this.state;
        if (event.target.value == '0') {
            this.setState({ isPermissionsLoaded: false, selectedRoleName: '', selectedRoleId:'0' })
        }
        else {
            this.setState({ isPermissionsLoaded: false, selectedRoleName: '0' })
            let roleId = event.target.value;
            let roleName = cosmosRoleDefinitions.filter(x => x.roleId == roleId)[0].roleName;
            let permissionsforSelectedRole;
            AccessAPI.GetRolePermissions(tenantId, roleId).then(permissions => {
                if (permissions) {
                    permissionsforSelectedRole = permissions;
                }
                else {
                    permissionsforSelectedRole = { roleId: null, roleName: '', permissionValues: [] };
                }
                this.setState({ isPermissionsLoaded: true, roleBasedpermissions: permissionsforSelectedRole })
            });
            this.setState({ selectedRoleId: roleId, selectedRoleName: roleName });
        }
    }

    private ColumnHeaders = () => {
        let columnDef = [{ key: 'permissions', name: 'Permissions', fieldName: 'permissions', minWidth: 500, maxWidth: 500, isResizable: true }];
        return columnDef;
    }

    private getPermissions = () => {
        const { roleBasedpermissions } = this.state;

        let permissionsData = [];
        if (roleBasedpermissions.roleId) {
            roleBasedpermissions.permissionValues.forEach(item => {
                permissionsData.push({ permissions: item })
            })
            permissionsData.sort((a, b) => {
                if (a.permissions.toUpperCase() < b.permissions.toUpperCase()) {
                    return -1
                }
                else if (a.permissions.toUpperCase() > b.permissions.toUpperCase()) {
                    return 1
                }
                else {
                    return 0
                }
            })
        }
        else {
            permissionsData = [{ permissions :''}]
        }
        return permissionsData;
    }
}


