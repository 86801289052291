import * as React from 'react';
import { CoherenceNav, INavLinkGroup, INavLink } from '@coherence-design-system/controls';
import { telemetryHook, Utility } from '../../shared/models/Helper';
import { UIConstants } from '../../shared/models/Constants';
import _ from "lodash";
import ITenant from '../../shared/models/Tenant.model';
import { Navigation, NavigationHamburger} from '@coherence-design-system/navigation'

export interface SideNavProps {
    onNavCollapsed: (isCollapsed: boolean) => void;
    isAdmin?: boolean;
}

export interface SideNavState {
    collapseSideNav: boolean;
    isCollapsed: boolean;
    width: any;
}

export class SideNav extends React.Component<SideNavProps, SideNavState> {
    private navLinkGroups: INavLinkGroup[];
    private childNavLinkGroups: INavLinkGroup[];
    private tenantName = '';
    private navKey;
    private tenantData: ITenant;
    constructor(props: any) {
        super(props);
        this.navKey = "TestKey" + Date.now().toString();
        this.state = {
            collapseSideNav: false,
            isCollapsed: false,
            width: 228
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        let TenantData = Utility.GetTenantData();
        let urlValues = window.location.pathname.split('/');
        this.tenantName = _.last(urlValues);
        let tenantName = this.tenantName.toUpperCase();
        this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
    }
    public render(): JSX.Element {
        this.navLinkGroups = [
            {
                key: 'group1',
                links: (this.tenantName.toUpperCase() == "EBS" && process.env.REACT_APP_ENVIRONMENT == 'dev') ? [
                    {
                        name: UIConstants.PageTitle.AccessRequest,
                        icon: 'SecurityGroup',
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.RequestAccess}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.RequestAccess,
                        'data-id': 'automation_id_22230',
                    },
                    {
                        name: UIConstants.PageTitle.MyRequestHistory,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.MyAccessRequest}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.MyAccessRequest,
                        icon: 'Signin',
                    },
                    {
                        name: UIConstants.PageTitle.MyPendingApprovals,
                        href: sessionStorage.getItem(UIConstants.Configuration.MSApprovalLink),
                        target: "_blank",
                        key: UIConstants.RouteConfig.MSApprovals,
                        icon: 'Documentation',
                    }
                ] : [
                    {
                        name: UIConstants.PageTitle.AccessRequest,
                        icon: 'SecurityGroup',
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.RequestAccess}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.RequestAccess,
                        'data-id': 'automation_id_22230',
                    },
                    {
                        name: UIConstants.PageTitle.InviteUser,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.InviteUser}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.InviteUser,
                        icon: 'PeopleAdd',
                    },
                    {
                        name: UIConstants.PageTitle.MyRequestHistory,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.MyAccessRequest}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.MyAccessRequest,
                        icon: 'Signin',
                    },
                    {
                        name: UIConstants.PageTitle.MyPendingApprovals,
                        href: sessionStorage.getItem(UIConstants.Configuration.MSApprovalLink),
                        target: "_blank",
                        key: UIConstants.RouteConfig.MSApprovals,
                        icon: 'Documentation',
                    }
                ]
            },
        ];
        if (this.tenantData?.EnableDelegation) {
            // Enable Delegation redirection link in side-nav only if tenant is enabled for delegation
            this.navLinkGroups[0].links.push({
                name: UIConstants.PageTitle.Delegation,
                href: `${process.env.REACT_APP_DELEGATIONURL}/${this.tenantName}`,
                key: UIConstants.PageTitle.Delegation,
                icon: 'FollowUser'
            })
        }
        let isAdmin = sessionStorage.getItem('isAdmin');
        if (isAdmin) {
            this.appendAdminNav();
        }
        let enableDRSForm = sessionStorage.getItem('enableDRSForm');
        if (enableDRSForm === "true") {
            this.appendDRSNav();
        }
        let showReports = sessionStorage.getItem('showReports');
        if (showReports === "true" && sessionStorage.getItem('isFDL') === "true") {
            this.showReports();
        }
        let path = window.location.pathname;
        this.navLinkGroups[0].links.forEach(element => {
            if (path.indexOf(element.key) !== -1) {
                element.isSelected = true;
            } else {
                element.isSelected = false;
            }
        });


        return (
            // <CoherenceNav
            //     appName={'EUA'}
            //     key={this.navKey}
            //     styles={{ root: { overflow: "hidden" } }}
            //     groups={this.getnavlinksgroup()}
            //     onNavCollapsed={(isCollapsed) => {
            //         this.setState({ isCollapsed: isCollapsed })
            //         this.props.onNavCollapsed(isCollapsed)
            //     }}
            //     defaultIsNavCollapsed={this.state.collapseSideNav ? true : false}
            // />

            <>
                <div className='side-nav-expand-collapse' style={{ background: '#e1dfdd', width: this.state.width }}>
                    <NavigationHamburger
                        key={this.navKey}
                        isNavCollapsed={this.state.isCollapsed}
                        toggleNavCollapsed={() => {
                            this.setState({ isCollapsed: !this.state.isCollapsed, width: this.state.isCollapsed ? 228 : 'fit-content' })
                            this.props.onNavCollapsed(!this.state.isCollapsed)
                        }}
                        styles={{ root: { width: this.state.width, color: 'black !important', display: 'flex', paddingLeft: 12, paddingRight: 12 } }}
                    />
                </div>
                <div className='side-nav' style={{ background: '#e1dfdd', width: this.state.width, height: '100%' }}>
                    <Navigation
                        key={this.navKey}
                        styles={{ root: { overflow: 'hidden', width: this.state.isCollapsed ? 48 : 228 }, navContainer: { width: 'calc(248px + var(--scrollbar-width))' } }}
                        groups={this.getnavlinksgroup()}
                        onNavCollapsed={(isCollapsed) => {
                            this.setState({ isCollapsed: isCollapsed })
                            this.props.onNavCollapsed(isCollapsed)
                        }}
                        defaultIsNavCollapsed={this.state.collapseSideNav ? true : false}
                        isNavCollapsed={this.state.isCollapsed}
                    />
                </div>
            </>
        );
    };
    getnavlinksgroup() {
        let isAdmin = sessionStorage.getItem('isAdmin');
        if (!this.props.isAdmin && isAdmin === "false") {
            _.remove(this.navLinkGroups[0].links, function (x) { return x.name === UIConstants.PageTitle.Administration; })
        }
        if (sessionStorage.getItem('isFDL') === "false" || sessionStorage.getItem('showReports') === "false") {
            _.remove(this.navLinkGroups[0].links, function (x) { return x.name === UIConstants.PageTitle.Reports; })
        }
        if (sessionStorage.getItem('appendDRSNav') === "false") {
            _.remove(this.navLinkGroups[0].links, function (x) { return x.name === UIConstants.PageTitle.MercuryDRSForm; })
        }
        if (this.props.isAdmin) {
            this.appendAdminNav();
        }
        if (this.tenantData != null && this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Mercury.toUpperCase()) {
            this.navLinkGroups.push({
                key: 'privacy',
                links: [
                    {
                        name: "Contact Support",
                        key: "contactSupport",
                        href: "https://onefinance.microsoftcrmportals.com/finsuphome/",
                        target: "_blank",
                        icon: "ContactCardSettingsMirrored",
                    },
                    {
                        name: "FAQs",
                        key: "FAQs",
                        href: `https://microsoft.sharepoint.com/teams/FinAccessMercury/Pages/FAQ.aspx`,
                        target: "_blank",
                        icon: "SurveyQuestions",
                    },
                    {
                        name: "Data Privacy Notice",
                        key: "privacy",
                        href: "https://go.microsoft.com/fwlink/?LinkId=518021",
                        target: "_blank",
                        icon: "Info",
                    }
                ]
            })
        }
        else if (this.tenantData != null && this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Alfred.toUpperCase()) {
            this.navLinkGroups.push({
                key: 'privacy',
                links: [
                    {
                        name: "Alfred Training link",
                        key: "traininglink",
                        href: "https://microsoft.sharepoint.com/teams/FDM/SitePages/FDMTraining.aspx?xsdata=MDV8MDJ8fDcyNjNjZDk5ZDQ0NzRjZmQ2ZjFlMDhkY2NlYTdmMWI5fDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDB8MHw2Mzg2MTI0Njc4OTAwODk3NTh8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPakpsWkRoa01URTVMV05oWWpZdE5ETTNZUzFoTkRoaUxURm1ZakZtT0RoaU4yRTJOMTlpTkRGak1EQTBNUzFqWlRCa0xUUXhOMk10WWpSbU55MWlZemcxTW1VeE5tSmlaRFZBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN5TlRZME9UazROemcxTUE9PXw2NGMyM2I5YmYzNjg0NjUwNmYxZTA4ZGNjZWE3ZjFiOXxjY2MzZTllMDViZWY0MjEwOTI5MmYwYmNjMjI0YzY0OA%3D%3D&sdata=aU9FTE1ScHJXeGJ0SXhGZW9QWlYvV3dYK1BNdVZyek1GL3NCNmE5Ung0MD0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2cnadyam%40microsoft.com",
                        target: "_blank",
                        icon: "D365TalentLearn",
                    },
                    {
                        name: "Data Privacy Notice",
                        key: "privacy",
                        href: "https://go.microsoft.com/fwlink/?LinkId=518021",
                        target: "_blank",
                        icon: "Info",
                    }
                ]
            })
        }
        else if (this.tenantData != null && this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.MDS.toUpperCase()) {
            this.navLinkGroups.push({
                key: 'privacy',
                links: [
                    {
                        name: "MDS Tool Training Link",
                        key: "traininglink",
                        href: "https://microsoft.sharepoint.com/:p:/t/FDM/EXHvhImodS1Pn5XgqTqp4lgBJ2WVTb7-wUW2wuvjEZ4Yag?e=7Py2ge",
                        target: "_blank",
                        icon: "D365TalentLearn",
                    },
                    {
                        name: "Pfam Governance Training Link (Revenue Only)",
                        key: "traininglink",
                        href: "https://microsoft.sharepoint.com/:p:/t/FDM/EVs11bHtKf5OsqlzXpJXG8YBsAHHQundzqXHMVweQ-CHSA?e=VZStYV",
                        target: "_blank",
                        icon: "D365TalentLearn",
                    },
                    {
                        name: "Data Privacy Notice",
                        key: "privacy",
                        href: "https://go.microsoft.com/fwlink/?LinkId=518021",
                        target: "_blank",
                        icon: "Info",
                    }
                ]
            })
        }
        else if (this.tenantData != null && this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.MSSales.toUpperCase()) {
            this.navLinkGroups.push({
                key: 'privacy',
                links: [
                    {
                        name: "Contact Support",
                        key: "finsupSupport",
                        href: "https://onefinance.microsoftcrmportals.com/finsuphome/",
                        target: "_blank",
                        icon: "ContactCardSettingsMirrored",
                    },
                    {
                        name: "Data Privacy Notice",
                        key: "privacy",
                        href: "https://go.microsoft.com/fwlink/?LinkId=518021",
                        target: "_blank",
                        icon: "Info",
                    }
                ]
            })
        }
        else if (this.tenantData != null && this.tenantData.AdditionalLinks != null && this.tenantData.AdditionalLinks.length > 0) {
            let sideNavLinks: INavLink[] = [];
            this.tenantData.AdditionalLinks.forEach((link) => {
                sideNavLinks.push(link);
            })
            sideNavLinks.push({
                name: "Data Privacy Notice",
                key: "privacy",
                href: "https://go.microsoft.com/fwlink/?LinkId=518021",
                target: "_blank",
                icon: "Info",
            });
            this.navLinkGroups.push({
                key: 'privacy',
                links: sideNavLinks
            })
        }
        else {
            this.navLinkGroups.push({
                key: 'privacy',
                links: [
                    {
                        name: "Data Privacy Notice",
                        key: "privacy",
                        href: "https://go.microsoft.com/fwlink/?LinkId=518021",
                        target: "_blank",
                        icon: "Info",
                    }
                ]
            })
        }

        if (sessionStorage.getItem('EnableFAQPage') === "false") {
            _.remove(this.navLinkGroups[1].links, function (x) { return x.name === 'FAQs' })
        }

        let path = window.location.pathname;
        this.navLinkGroups[0].links.forEach(element => {
            if (path.indexOf(element.key) !== -1) {
                element.isSelected = true;
                if (element.links && element.links.length > 0) {
                    element.links.forEach(subElements => {
                        if (path.indexOf(subElements.key) !== -1) {
                            subElements.isSelected = true;
                        } else {
                            subElements.isSelected = false;
                        }
                    });
                }
            } else {
                element.isSelected = false;
                if (element.links && element.links.length > 0) {
                    element.links.forEach(subElements => {
                        subElements.isSelected = false;
                    });
                }
            }
        });
        return this.navLinkGroups;
    }


    resize() {
        let widnowSize = window.innerWidth;
        let hide = widnowSize < 900; // hide search bar for smaller window size
        if (hide || widnowSize > 1000) {
            this.navKey = "TestKey" + Date.now().toString();
        }
        this.setState({ collapseSideNav: hide, isCollapsed: hide, width: !hide ? 228 : 'fit-content' });
    }
    showReports() {
        this.navLinkGroups[0].links.push({
            name: UIConstants.PageTitle.Reports,
            key: UIConstants.RouteConfig.Reports,
            icon: 'Documentation',
            links: [
                {
                    name: UIConstants.PageTitle.AssetView,
                    href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Reports}/${UIConstants.RouteConfig.AssetView}/${this.tenantName}`,
                    key: UIConstants.RouteConfig.AssetView,
                    isSelected: false
                },
                {
                    name: UIConstants.PageTitle.RegistrationRequestView,
                    href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Reports}/${UIConstants.RouteConfig.RegistrationRequestView}/${this.tenantName}`,
                    key: UIConstants.RouteConfig.RegistrationRequestView,
                    isSelected: false
                },
                {
                    name: UIConstants.PageTitle.ConsumerAccessRequestView,
                    href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Reports}/${UIConstants.RouteConfig.ConsumerAccessRequestView}/${this.tenantName}`,
                    key: UIConstants.RouteConfig.ConsumerAccessRequestView,
                    isSelected: false
                },
                {
                    name: UIConstants.PageTitle.ConsumerAssetMappingView,
                    href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Reports}/${UIConstants.RouteConfig.ConsumerAssetMappingView}/${this.tenantName}`,
                    key: UIConstants.RouteConfig.ConsumerAssetMappingView,
                    isSelected: false
                }
            ],
        }
        );
    }

    appendAdminNav() {
        let adminNav = this.navLinkGroups[0].links.filter(x => x.name === UIConstants.PageTitle.Administration)
        if (this.tenantName.toUpperCase() == UIConstants.Tenant.FDL) {
            sessionStorage.setItem('isFDL', "true");
            this.navLinkGroups[0].links.push({
                name: UIConstants.PageTitle.Administration,
                key: UIConstants.RouteConfig.Administration,
                icon: 'PartyLeader',
                links: [
                    {
                        name: UIConstants.PageTitle.UserManagement,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.UserManagement}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.UserManagement,
                        isSelected: false,
                    },
                    {
                        name: UIConstants.PageTitle.AssetView,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.AssetView}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.AssetView,
                        isSelected: false
                    },
                    {
                        name: UIConstants.PageTitle.RegistrationRequestView,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.RegistrationRequestView}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.RegistrationRequestView,
                        isSelected: false
                    },
                    {
                        name: UIConstants.PageTitle.ConsumerAccessRequestView,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ConsumerAccessRequestView}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ConsumerAccessRequestView,
                        isSelected: false
                    },
                    {
                        name: UIConstants.PageTitle.ConsumerAssetMappingView,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ConsumerAssetMappingView}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ConsumerAssetMappingView,
                        isSelected: false
                    },
                    {
                        name: UIConstants.PageTitle.ApprovalPolicy,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ApprovalPolicy}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ApprovalPolicy,
                        isSelected: true,
                    }
                ],

            }
            );

        }
        else if (this.tenantName.toUpperCase() === UIConstants.Tenant.Invoice.toUpperCase()) {
            sessionStorage.setItem('isFDL', "false");
            sessionStorage.setItem('mssalesAdmin', "false");
            this.navLinkGroups[0].links.push({
                name: UIConstants.PageTitle.Administration,
                key: UIConstants.RouteConfig.Administration,
                icon: 'PartyLeader',
                links: [
                    {
                        name: UIConstants.PageTitle.SupplierUserReport,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.UserManagement}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.UserManagement,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.SupplierUserUpdate,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.SupplierUserUpdate}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.SupplierUserUpdate,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.ApprovalPolicy,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ApprovalPolicy}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ApprovalPolicy,
                        isSelected: true,
                    }
                ],

            }
            );
        }
        else if (this.tenantName.toUpperCase() === UIConstants.Tenant.MSSales.toUpperCase()) {
            sessionStorage.setItem('isFDL', "false");
            let isMssalesAdmin = sessionStorage.getItem('mssalesAdmin') === "true"
            this.navLinkGroups[0].links.push({
                name: UIConstants.PageTitle.Administration,
                key: UIConstants.RouteConfig.Administration,
                icon: 'PartyLeader',
                links: (isMssalesAdmin) ? [
                    {
                        name: (this.tenantName.toUpperCase() === UIConstants.Tenant.Invoice.toUpperCase() ? UIConstants.PageTitle.SupplierUserReport : UIConstants.PageTitle.UserManagement),
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.UserManagement}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.UserManagement,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.BulkRemoval,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.BulkRemoval}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.BulkRemoval,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.ApprovalPolicy,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ApprovalPolicy}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ApprovalPolicy,
                        isSelected: true,
                    }
                ] : [
                    {
                        name: (this.tenantName.toUpperCase() === UIConstants.Tenant.Invoice.toUpperCase() ? UIConstants.PageTitle.SupplierUserReport : UIConstants.PageTitle.UserManagement),
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.UserManagement}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.UserManagement,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.ApprovalPolicy,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ApprovalPolicy}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ApprovalPolicy,
                        isSelected: true,
                    }
                ]
            });
        }
        else if (this.tenantData?.isExternalAADTenant) {
            this.navLinkGroups[0].links.push({
                name: UIConstants.PageTitle.Administration,
                key: UIConstants.RouteConfig.Administration,
                icon: 'PartyLeader',
                links: [
                    {
                        name: UIConstants.PageTitle.UserManagement,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.UserManagement}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.UserManagement,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.Permissions,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.Permissions}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.Permissions,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.ApprovalPolicy,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ApprovalPolicy}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ApprovalPolicy,
                        isSelected: true,
                    }
                ]
            })
        }
        else {
            sessionStorage.setItem('isFDL', "false");
            sessionStorage.setItem('mssalesAdmin', "false");
            let showAccessReview = sessionStorage.getItem(UIConstants.Configuration.DisplayAccessReview) === "true"
            this.navLinkGroups[0].links.push({
                name: UIConstants.PageTitle.Administration,
                key: UIConstants.RouteConfig.Administration,
                icon: 'PartyLeader',
                links: showAccessReview ? [
                    {
                        name: (this.tenantName.toUpperCase() === UIConstants.Tenant.Invoice.toUpperCase() ? UIConstants.PageTitle.SupplierUserReport : UIConstants.PageTitle.UserManagement),
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.UserManagement}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.UserManagement,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.AccessReviewManagement,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.Review}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.Review,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.ApprovalPolicy,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ApprovalPolicy}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ApprovalPolicy,
                        isSelected: true,
                    }

                ] : [
                    {
                        name: (this.tenantName.toUpperCase() === UIConstants.Tenant.Invoice.toUpperCase() ? UIConstants.PageTitle.SupplierUserReport : UIConstants.PageTitle.UserManagement),
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.UserManagement}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.UserManagement,
                        isSelected: true,
                    },
                    {
                        name: UIConstants.PageTitle.ApprovalPolicy,
                        href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ApprovalPolicy}/${this.tenantName}`,
                        key: UIConstants.RouteConfig.ApprovalPolicy,
                        isSelected: true,
                    }
                ],

            }
            );
        }
    }
    appendDRSNav() {
        sessionStorage.setItem('isFDL', "false");
        this.navLinkGroups[0].links.push({
            name: UIConstants.PageTitle.MercuryDRSForm,
            key: UIConstants.RouteConfig.DRSForm,
            icon: 'Documentation',
            links: [
                {
                    name: UIConstants.PageTitle.MercuryDRSForm,
                    href: `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.DRSForm}/${this.tenantName}`,
                    key: UIConstants.RouteConfig.DRSForm,
                    isSelected: true,
                },
            ],
        });
    }
}
