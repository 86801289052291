import React from 'react';
import { Modal, IconButton, SelectionMode, mergeStyleSets, getTheme, getId, FontSizes, FontWeights, Link, DetailsListLayoutMode, ShimmeredDetailsList, Label, IColumn, IPlainCardProps, HoverCardType, HoverCard, mergeStyles, DirectionalHint, MessageBarType, MessageBar, ProgressIndicator, IButton, DefaultButton, MessageBarButton } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import MyAccessStatus from './MyAccessStatus';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { MyAccessResponse } from '../../shared/models/MyCurrentAccess.model';
import _ from 'lodash';
import { AccessRequestSelfRemoval, MyAccessRequestResponse } from '../../shared/models/UserAccess.model';
import { Permission } from '../../shared/models/Mercury';
import ConfigurationAPI from '../../shared/api/Configuration.api';
import ITenant from '../../shared/models/Tenant.model';
import { AllowedAccount } from '../../shared/models/Role.model';
import ViewPermissions from '../../shared/components/ViewPermissions';
interface ICurrentAccessProps {
    roleData: any,
    onDismiss: Function;
    tenantId: string;
}
interface ICurrentAccessState {
    showModal: boolean,
    showRemoveConfirmation: boolean,
    showRemoveRequestConfirmation: boolean,
    roleToRemove: string,
    requestIdToRemove: string,
    removeInProgress: boolean,
    userDetails: IUser,
    MyAccess: MyAccessResponse,
    MercuryAccess: MyAccessRequestResponse,
    showErrorMessage: boolean,
    message: string,
    messageType: MessageBarType,
    autoHideMessage: boolean,
    mercuryPermissionData?: Permission[],
    permissionLevel: string,
    currentApprovedAccessItems: any[],
    hasOEMAccess: boolean,
    OEMSecurityTypeName: string,
    tenantName: string;
    permissionGroup: string;
    allowedAccountTypes: AllowedAccount[];
    hasRolePermissions: boolean;
    clickedRoleName: string;
    clickedBusinessGrp: string;
    clickedRoleId: string;
    showPermissionsModal: boolean;
}


const theme = getTheme();
const iconButtonStyles = mergeStyleSets({
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
});
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 8px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            }
        }
    }
});


type MyAccessRequestProps = ICurrentAccessProps;

export default class MyAccess extends React.Component<MyAccessRequestProps, ICurrentAccessState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private sapRequestStatusEndPoint: string;
    private removeBtnRefList = [];
    private removeBtnRef;
    private removeRequestBtnRefList = [];
    private removeRequestBtnRef;
    private tenantData: ITenant = null;
    private _onRenderPlainCard = (item: any): JSX.Element => {
        return <MyAccessStatus requestItem={item} {...this.props} ></MyAccessStatus>
    };
    private _onRenderItemColumn = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
        const itemClass = mergeStyles({
            selectors: {
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                },
            },
        });
        const hoverCardClass = mergeStyles({
            selectors: {
                '&:focus': {
                    outline: '-webkit-focus-ring-color auto 1px'
                },
            },
        });
        const plainCardProps: IPlainCardProps = {
            onRenderPlainCard: this._onRenderPlainCard,
            renderData: item,
            directionalHint: DirectionalHint.bottomCenter,
            calloutProps: {
                isBeakVisible: true
            }

        };
        if (column.key === 'action') {
            let hasPendingRequests = this.state.MyAccess.Data.pendingRequests.filter(x => x.role?.roleName == item.role).length > 0
            var todayDate: any = new Date();
            var endDate: any = new Date(item.endDate)
            var diff = Math.abs(todayDate - endDate);
            if (this.state.tenantName == UIConstants.Tenant.Mercury) {
                hasPendingRequests = this.state.MyAccess.Data.pendingRequests.filter(x => x.role?.group + "-" + x.role?.roleName == item.role).length > 0
            }
            return (<>
                {item.allowEdit && <span className={itemClass} aria-label={`${"Edit"}`} hidden={hasPendingRequests} style={{ paddingRight: 10 }}>
                    <Link role="button" aria-label={`${UIConstants.ButtonText.Edit} ${item.role}`} onClick={() => this.onEditBtnClick(item)} >Edit</Link>
                </span>}
                {item.allowRemove && <span>
                    <Link role="button" componentRef={(componentRef) => {
                        this.removeBtnRefList[index] = componentRef
                    }} aria-label={`${UIConstants.ButtonText.Remove} ${item.role}`} onClick={() => this.onRemoveApprovedReqBtnClick(item, this.removeBtnRefList[index])} >{UIConstants.ButtonText.Remove}</Link>
                </span>
                }
            </>
            );
        }
        if (column.key === 'status') {
            if (item.accessType && item.accessType.toUpperCase() === "SAP") {
                return (
                    <div className={itemClass}>
                        <Link role="link" aria-label={`${item.status} ${item.requestId}`} target="_blank" href={this.sapRequestStatusEndPoint} > {item.status} </Link>
                    </div>
                );
            } else {
                return (
                    <HoverCard className={hoverCardClass} plainCardProps={plainCardProps} instantOpenOnClick={true} type={HoverCardType.plain}>
                        <div role="button" className={itemClass} style={{ 'color': '#0078d4' }} aria-label={`${item.status} ${item.requestId}`} >
                            {item.status}
                        </div>
                    </HoverCard>
                );
            }

        }
        if (column.key === 'remove') {
            return (
                <div>
                    <Link role="button" componentRef={(componentRef) => {
                        this.removeBtnRefList[index] = componentRef
                    }} aria-label={`${UIConstants.ButtonText.Remove} ${item.role}`} onClick={() => this.onRemoveApprovedReqBtnClick(item, this.removeBtnRefList[index])} >{UIConstants.ButtonText.Remove}</Link>
                </div>

            );
        } else if (column.key === 'attributes') {
            return (
                <>
                    <span role="note" aria-roledescription={item.attributes ? item.attributes : 'Not Applicable'}>
                        {/* {item.attributes ? <span> <strong> {`${item.attributeName}: `}</strong>  {item.attributes} </span> : 'Not Applicable'} */}
                        {this.renderScope(item)}
                    </span>

                </>

            );
        } else if (column.key === 'removeRequest') {
            return (
                <div>
                    <Link role="button" componentRef={(componentRef) => {
                        this.removeRequestBtnRefList[index] = componentRef
                    }} aria-label={`${UIConstants.ButtonText.Remove} ${item.requestId}`} onClick={() => this.onRemoveInprogressReqBtnClick(item, this.removeRequestBtnRefList[index])} >{UIConstants.ButtonText.Remove}</Link>
                </div>

            );
        } else if (column.key == 'requested for' && (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase())) {
            return (<div>{(!item.role.includes('Bulk Request') && item.requestedFor == null) ? this.state.userDetails.Alias.split('@')[0] + '@microsoft.com' : (item.requestedFor != null) ? item.requestedFor : 'On Behalf (Bulk Request)'}</div>)
        }
         
        else if (column.key === 'permissions') {
            if (item.multiAttributes[0].attributeValueText != UIConstants.Global_Roles && item.role != UIConstants.BusinessOwner) {
                let bgName = item.multiAttributes[0].attributeValueText;
                return (
                    <div className={itemClass}>
                        {item.role.includes('Bulk Request') ? 'NA' :
                            < Link role="button" aria-label={'permissions'} onClick={() => {
                                this.setState({ showPermissionsModal: true, clickedBusinessGrp: bgName, clickedRoleName: item.role, clickedRoleId: item.roleId });
                            }} > View Permissions </Link>}
                    </div>
                );
            }
            else {
                return <div>NA</div>
            }
        }

        return item[column.key];
    };

    private renderScope = (item) => {
        if (item.attributes) {
            //TO DO : Generalize for both status
            if (item.multiAttributes && item.multiAttributes.length > 0) { // In Progress status
                return item.multiAttributes.map(attrItem => {
                    return <><span> <strong> {`${attrItem.attributeName}: `}</strong>  {attrItem.attributeValueText} </span> <br /></>
                })
            } else { // Approved Status
                return <span> <strong> {`${item.attributeName}: `}</strong>  {item.attributes} </span>
            }
        } else {
            return <span>Not Applicable</span>
        }
    }
    private renderAttributes = (rawScope, attrName, multiAttributes) => {
        let attributes = multiAttributes;
        attributes.push({
            attributeName: attrName, attributeValueText: rawScope.map(x => {
                let spliValue = x.split(':')
                if (_.last(window.location.pathname.split('/')) == "MSSales") {
                    return spliValue.length > 1 ? spliValue[1] : spliValue[0];
                } else if (_.last(window.location.pathname.split('/')) == UIConstants.Tenant.Mercury) {
                    if (attrName == "Company")
                        return spliValue[0] + ":" + spliValue[1];
                    return spliValue.length > 1 ? spliValue[1] : spliValue[0];
                } else {
                    return attrName == "Region" ? x : spliValue[0] === spliValue[1] ? spliValue[0] : x;
                }
            }).join('; ')
        })
        return attributes
    }
    private onRemoveApprovedReqBtnClick(item, currentRowRemoveBtnRef) {
        this.removeBtnRef = currentRowRemoveBtnRef;
        let _permissionGroup = item.role.includes('-') ? item.role.split('-')[0] : '';
        this.setState({ showRemoveConfirmation: true, roleToRemove: item, permissionLevel: item.permissionLevel, permissionGroup: _permissionGroup });
    }
    private removeApprovedRole(roleId, attributes, permissionLevel, roleAssignmentId) {
        this.setState({ showRemoveConfirmation: false, removeInProgress: true });
        const { tenantId } = this.props;
        if (roleId) {
            let requestData: AccessRequestSelfRemoval =
            {
                tenantId: tenantId,
                roleId: roleId,
                businessGroup: attributes,
                permissionLevel: permissionLevel,
                roleAssignmentId: this.tenantData.isExternalAADTenant ? roleAssignmentId : null
            };
            AccessAPI.removeRoleAccess(requestData).then(async data => {
                if (data.status && data.status === 200) {
                    this.setState({ removeInProgress: false });
                    this.loadRequestData(tenantId);
                } else {
                    if (data.message) {
                        this.setState({
                            removeInProgress: false, message: data.message,
                            messageType: MessageBarType.error, showErrorMessage: true
                        });
                    }
                    else {
                        this.setState({ removeInProgress: false, message: UIConstants.Messages.RemoveFailure,
                            messageType: MessageBarType.error, showErrorMessage: true
                        });
                    }
                }
            });
        }
    };

    private onRemoveInprogressReqBtnClick(item, currentRowRemoveBtnRef) {
        this.removeRequestBtnRef = currentRowRemoveBtnRef;
        this.setState({ showRemoveRequestConfirmation: true, requestIdToRemove: item.requestId });
    }

    private removeInprogressRequest(requestId) {
        this.setState({ showRemoveRequestConfirmation: false, removeInProgress: true });
        const { tenantId } = this.props;
        if (requestId) {

            AccessAPI.RemoveInProgressAccessRequest(requestId).then((data) => {
                if (data.status && data.status === 200) {
                    this.setState({ removeInProgress: false });
                    this.loadRequestData(tenantId);
                    // AccessAPI.getMyAccess(tenantId).then(res => {
                    //     this.setState({ MyAccess: res , MercuryAccess : res });
                    // });
                } else {
                    this.setState({
                        removeInProgress: false, message: UIConstants.Messages.RemoveFailure,
                        messageType: MessageBarType.error, showErrorMessage: true
                    });
                }
            });
        }
    };

    private onCancelClick() {
        if (this.removeBtnRef) {
            this.removeBtnRef.focus();
        }
        else if (this.removeRequestBtnRef) {
            this.removeRequestBtnRef.focus();
        }

        this.setState({ showRemoveConfirmation: false, showRemoveRequestConfirmation: false, roleToRemove: null, requestIdToRemove: null });
    }
    private ApprovedAccessColumns = () => {
        let columnDef = [
            { key: 'role', name: 'Role', fieldName: 'role', minWidth: 250, maxWidth: 250, isResizable: false, isMultiline: false },
            { key: 'attributes', name: 'Scope', fieldName: 'attributes', minWidth: 400, maxWidth: 400, isResizable: true, isMultiline: true, onRender: this._onRenderItemColumn }
        ];
        if (this.state.tenantName != UIConstants.Tenant.MSSales && this.state.tenantName != UIConstants.Tenant.Mercury) {
            columnDef.push({ key: 'endDate', name: 'Expiry Date', fieldName: 'endDate', minWidth: Utility.getDynamicColumnSize(100), maxWidth: Utility.getDynamicColumnSize(200), isResizable: false, isMultiline: false });
        }

        if (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) {
            let requestdForColumn = { key: 'requested for', name: 'Requested For', fieldName: 'requested for', minWidth: 300, maxWidth: 300, isResizable: true, isMultiline: true, onRender: this._onRenderItemColumn };
            columnDef.splice(2, 0, requestdForColumn);
        }
        columnDef.push({ key: 'action', name: 'Action', fieldName: 'action', minWidth: 100, maxWidth: 100, isResizable: false, onRender: this._onRenderItemColumn, isMultiline: false });
        if (this.state.hasRolePermissions) {
            columnDef.push({ key: 'permissions', name: 'Permissions', fieldName: 'permissions', minWidth: 100, maxWidth: 100, isResizable: false, onRender: this._onRenderItemColumn, isMultiline: false });
        }
        return columnDef;
    }

    private PendingAccessColumns = () => {
        let columnDefs = [
            { key: 'requestId', name: 'Request ID', fieldName: 'requestId', minWidth: 220, maxWidth: 220, isResizable: false },
            { key: 'role', name: 'Role', fieldName: 'role', minWidth: 180, maxWidth: 300, isResizable: true, isMultiline: true },
            { key: 'attributes', name: 'Scope', fieldName: 'attributes', minWidth: 200, maxWidth: 200, isResizable: true, isMultiline: true, onRender: this._onRenderItemColumn },
            { key: 'requestType', name: 'Request Type', fieldName: 'requestType', minWidth: 110, maxWidth: 110, isResizable: false },
            { key: 'requestedBy', name: 'Requested By', fieldName: 'requestedBy', minWidth: 110, maxWidth: 110, isResizable: false },
            { key: 'requestDate', name: 'Requested Date', fieldName: 'requestDate', minWidth: Utility.getDynamicColumnSize(180), maxWidth: Utility.getDynamicColumnSize(180), isResizable: false },
            { key: 'status', name: 'Status', fieldName: 'status', minWidth: 100, maxWidth: 100, isResizable: false, onRender: this._onRenderItemColumn },
            { key: 'removeRequest', name: 'Action', fieldName: 'requestId', minWidth: 100, maxWidth: 100, isResizable: false, onRender: this._onRenderItemColumn }
        ];

        if (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) {
            let requestdForColumn = { key: 'requested for', name: 'Requested For', fieldName: 'requested for', minWidth: 300, maxWidth: 300, isResizable: true, isMultiline: true, onRender: this._onRenderItemColumn };
            columnDefs.splice(3, 0, requestdForColumn);
        }
        if (this.state.hasRolePermissions) {
            columnDefs.push({ key: 'permissions', name: 'Permissions', fieldName: 'permissions', minWidth: 100, maxWidth: 100, isResizable: false, onRender: this._onRenderItemColumn, isMultiline: false });
        }
        return columnDefs;
    }
    private confirmMsgYesBtnRef = React.createRef<IButton>();
    private alertMsgCloseBtnRef = React.createRef<IButton>();

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: true,
            showRemoveConfirmation: false,
            showRemoveRequestConfirmation: false,
            roleToRemove: null,
            requestIdToRemove: null,
            removeInProgress: false,
            userDetails: null,
            MyAccess: null,
            MercuryAccess: null,
            showErrorMessage: false,
            message: '',
            messageType: MessageBarType.info,
            autoHideMessage: false,
            permissionLevel: '',
            currentApprovedAccessItems: [],
            hasOEMAccess: false,
            tenantName: '',
            OEMSecurityTypeName: '',
            permissionGroup: '',
            allowedAccountTypes: [],
            hasRolePermissions: false,
            showPermissionsModal:false,
            clickedBusinessGrp: null,
            clickedRoleName: null,
            clickedRoleId: null
        };
    }
    public componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let urlValues = window.location.pathname.split('/');
        let tenantName = _.last(urlValues);
        this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toUpperCase() == tenantName.toUpperCase())[0];
        this.setState({ userDetails: user, tenantName: tenantName, hasRolePermissions: this.tenantData?.HasRolePermissions || false });
        this.ensureDataFetched(this.tenantData);
    }
    private ensureDataFetched(tenantData) {
        const { tenantId } = this.props;
        if (tenantData.TenantName == "MSSales") {
            AccessAPI.GetMSRUserData().then(res => {
                this.setState({ hasOEMAccess: res?.msSalesOEMAccessFlag == 1 ? true : false, OEMSecurityTypeName: res?.oemSecurityTypeName });
                this.loadRequestData(tenantId);
            });
        }
        else {
            if (tenantData.isExternalAADTenant) {
                ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.AllowedAccountTypes).then(response => {
                    let config = response ? JSON.parse(response.replace(/'/g, `"`)) : null;
                    if (config != null && config.length > 0) {
                        let allowedTypes = config.filter(x => x.TenantName?.toLowerCase() == this.tenantData?.TenantName.toLowerCase())
                        this.setState({ allowedAccountTypes: allowedTypes?.length > 0 ? allowedTypes[0].AllowedDomains : [] })
                    }
                })
            }
            this.loadRequestData(tenantId);
        }// Call API to Get User Access
        // ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.SAPRequestStatusEndPoint)// Call API to Get SAP Endpoint from config
        // 	.then(response => {
        // 		this.sapRequestStatusEndPoint = response;
        // 	});        
    }
    private renderMessage = () => {
        let { message, messageType, autoHideMessage } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showErrorMessage: false }) }, 3000); // hide message after 3 seconds
        setTimeout(() => {
            this.alertMsgCloseBtnRef.current.focus();
        }, 100) // set the focus to close button
        return <div>
            <MessageBar key='FetchMsg'
                actions={
                    <div>
                        <MessageBarButton componentRef={this.alertMsgCloseBtnRef} onClick={() => { this.setState({ showErrorMessage: false }) }} >{UIConstants.ButtonText.Ok}</MessageBarButton>
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{message}</span>
            </MessageBar>
        </div>
    };
    private loadRequestData(tenantId: string) {
        let urlValues = window.location.pathname.split('/');
        let tenantName = _.last(urlValues);
        this.setState({ tenantName: tenantName });
        if (tenantName == UIConstants.Tenant.Mercury) {
            //    AccessAPI.getMyAccessRequest(tenantId).then(res => {
            //        let MercuryInprogressData = { IsDataLoaded: true, IsSuccess: true, Data: { approvedAccess: null, pendingRequests: res.Data.filter(x => x.status == "InProgress") } };
            //        this.setState({ MercuryAccess: res, MyAccess: MercuryInprogressData });
            //    }); // Call API to Get User Access for Mercury
            AccessAPI.GetMercuryData().then(res => {
                this.setState({ mercuryPermissionData: res });
            });
        }
        //else {
        AccessAPI.getMyAccess(tenantId).then(res => {
            this.setState({ MyAccess: res, currentApprovedAccessItems: [] });
        });
        //}
    }

    public render() {
        const { showPermissionsModal, showRemoveConfirmation, showRemoveRequestConfirmation, roleToRemove, requestIdToRemove,
            removeInProgress, MyAccess, showErrorMessage, tenantName, permissionLevel, permissionGroup } = this.state;
        const RemoveConfirmation = (props) => {
            let approvedConfirmationMessage = "";
            if (tenantName == UIConstants.Tenant.Mercury && permissionLevel.includes(UIConstants.PermissionTypes.MercuryBasicAccess)) {
                approvedConfirmationMessage = UIConstants.Messages.BasicRoleRemovalNote;
            }

            else if (tenantName == UIConstants.Tenant.Mercury && permissionGroup == UIConstants.PermissionGroups.PLPlanGroup) {
                approvedConfirmationMessage = UIConstants.Messages.PLPlanRemovalNote;
            }

            else if (tenantName == UIConstants.Tenant.Mercury && permissionLevel.includes(UIConstants.PermissionTypes.MercuryAdvanced)) {
                approvedConfirmationMessage = UIConstants.Messages.AdvanceRoleRemovalNote;
            }

            setTimeout(() => {
                this.confirmMsgYesBtnRef.current.focus();
            }, 200); // set the focus to close button

            return (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    actions={
                        <div>
                            <DefaultButton style={{ height: 25 }} componentRef={this.confirmMsgYesBtnRef} role="button" onClick={() => {
                                if (tenantName == UIConstants.Tenant.Mercury) {
                                    this.removeApprovedRole(props.role.role, props.role.attributes, permissionLevel, null);
                                }
                                else if (this.tenantData.isExternalAADTenant){
                                    this.removeApprovedRole(props.role.role, props.role.attributes, permissionLevel, props.role.roleAssignmentId)
                                }
                                else {
                                    props.role.attributeName == "BusinessGroup" ? this.removeApprovedRole(props.role.roleId, props.role.attributes,null,null) : this.removeApprovedRole(props.role.roleId, null, null,null)
                                }
                            }
                            } >Yes</DefaultButton>
                            <DefaultButton style={{ height: 25 }} role="button" onClick={() => this.onCancelClick()}>Cancel</DefaultButton>
                        </div>
                    }
                >
                    <div role="alert" >
                        {approvedConfirmationMessage + " " + UIConstants.Messages.RemoveRoleConfirmation.replace('#RoleName#', props.role.role)}
                    </div>
                    {/* <Announced message={UIConstants.Messages.RemoveRoleConfirmation.replace('#RoleName#', props.role.role)} ></Announced> */}
                </MessageBar>)
        };
        const RemoveInprogressReqConfirmation = (props) => {

            setTimeout(() => {
                this.confirmMsgYesBtnRef.current.focus();
            }, 200); // set the focus to close button

            return (<MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
                actions={
                    <div>
                        <DefaultButton style={{ height: 25 }} componentRef={this.confirmMsgYesBtnRef} role="button" onClick={() => this.removeInprogressRequest(props.requestId)} >Yes</DefaultButton>
                        <DefaultButton style={{ height: 25 }} role="button" onClick={() => this.onCancelClick()}>Cancel</DefaultButton>
                    </div>
                }
            >
                <div role="alert" >
                    {UIConstants.Messages.RemoveRequestConfirmation.replace('#Request#', props.requestId)}
                </div>
            </MessageBar>)
        };

        return <React.Fragment>

            <Modal titleAriaId={this._titleId} subtitleAriaId={this._subtitleId}
                isOpen={this.state.showModal} onDismiss={() => { this._closeModal(null); }}
                isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span id={this._titleId}><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.MessageBoxTitle.MyAccessEdit}</h1></span>
                    <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close My Access dialog" onClick={() => { this._closeModal(null); }} />
                </div>
                <div id={this._subtitleId} className={contentStyles.body}>
                    <div style={{
                        height: '60vh',
                        width: '90vw',
                        overflow: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        marginTop: -15
                    }} >
                        <br />
                        <Label><h2 className={"h2"} style={{ paddingLeft: 0, fontSize: 14, margin: 0 }}>{UIConstants.Labletext.MyApprovedRequest} </h2></Label>
                        {(showRemoveConfirmation) && <RemoveConfirmation role={roleToRemove}></RemoveConfirmation>}
                        {(showErrorMessage) && this.renderMessage()}
                        {(removeInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
                        <ShimmeredDetailsList
                            setKey="approved-items"
                            columns={this.ApprovedAccessColumns()}
                            items={this.getApprovedItems()}
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!MyAccess?.IsDataLoaded}
                            ariaLabelForShimmer="My approved request details is being fetched"
                            ariaLabelForGrid="My approved request details"
                            listProps={{ renderedWindowsAhead: 10, renderedWindowsBehind: 10 }}
                            detailsListStyles={{ root: { overflow: 'visible' } }}
                        />
                        <br />
                        <br />
                        {(showRemoveRequestConfirmation) && <RemoveInprogressReqConfirmation requestId={this.state.requestIdToRemove}></RemoveInprogressReqConfirmation>}
                        <Label><h2 className={"h2"} style={{ paddingLeft: 0, fontSize: 14, margin: 0 }}>{UIConstants.Labletext.MyPendingAccess}</h2></Label>
                        <ShimmeredDetailsList
                            setKey="pending-items"
                            columns={this.PendingAccessColumns()}
                            items={this.getPendingItems()}
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            enableShimmer={!MyAccess?.IsDataLoaded}
                            ariaLabelForShimmer="My pending request details is being fetched"
                            ariaLabelForGrid="My pending request details"
                            listProps={{ renderedWindowsAhead: 10, renderedWindowsBehind: 10 }}
                            detailsListStyles={{ root: { overflow: 'visible' } }}
                        />
                    </div>
                </div>
                {showPermissionsModal && <ViewPermissions businessGroupName={this.state.clickedBusinessGrp} roleName={this.state.clickedRoleName} roleId={this.state.clickedRoleId} tenantId={this.tenantData?.TenantId} {...this.props} onDismiss={() => { this.setState({ showPermissionsModal: false }) }} />}
            </Modal>
        </React.Fragment>
    };

    private _closeModal = (data: any = null): void => {
        this.setState({ showModal: false, showErrorMessage: false });
        let urlValues = window.location.pathname.split('/');
        let tenantName = _.last(urlValues);

        if (data && tenantName == UIConstants.Tenant.Mercury || tenantName == UIConstants.Tenant.Alfred || tenantName == UIConstants.Tenant.MDS) {
            this.props.onDismiss(data);
        } else if (data != null && data.length > 0 && data.length != undefined) {
            this.props.onDismiss(data);
        }
        else {
            this.props.onDismiss();
        }

    };

    private getApprovedItems = () => {
        var { currentApprovedAccessItems, MyAccess, MercuryAccess, mercuryPermissionData, hasOEMAccess, OEMSecurityTypeName } = this.state;
        const { roleData, tenantId } = this.props;
        let myAccessData = null;
        let urlValues = window.location.pathname.split('/');
        let tenantName = _.last(urlValues);
        let approvedAccess = [];
        if (tenantName == UIConstants.Tenant.Mercury && MyAccess && MyAccess.Data && mercuryPermissionData) {
            myAccessData = MyAccess.Data.approvedAccess;
            if (myAccessData) {
                for (const myAccessItem of myAccessData) {
                    let attributeValueString = '';
                    let attrName = '';
                    let rawScope = [];
                    let multiAttributes = [];
                    if (myAccessItem.Attribute || myAccessItem.PermissionGroup == 'Trial Balance & Statutory' && myAccessItem.HasAdditionalAccess) {

                        if (myAccessItem.PermissionGroup == 'Trial Balance & Statutory' && myAccessItem.HasAdditionalAccess) {

                            if (myAccessItem.Attribute && myAccessItem.Attribute['SCOMP']) {
                                rawScope = myAccessItem.Attribute['SCOMP'];
                                attrName = 'Company';
                                multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                            }
                            if (myAccessItem.HasAdditionalAccess) {
                                rawScope = ["Statutory: True"];
                                attrName = 'Statutory';
                                multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                            }

                        }
                        else if (myAccessItem.PermissionLevel.toString().toLowerCase() == UIConstants.PermissionLevels.ConsolidatedGaap.toLowerCase() && myAccessItem.HasAdditionalAccess) {
                            rawScope = ["External Reporting: True"];
                            attrName = 'External Reporting';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        else {
                            if (myAccessItem.Attribute['SCOMP']) {
                                rawScope = myAccessItem.Attribute['SCOMP'];
                                attrName = 'Company';
                                multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                            }
                            if (myAccessItem.Attribute['MWWAR']) {
                                rawScope = myAccessItem.Attribute['MWWAR'];
                                attrName = 'Geography';
                                multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                            }
                            if (myAccessItem.Attribute['PXOS'] || myAccessItem.Attribute['MXOS']) {
                                rawScope = myAccessItem.Attribute['PXOS'] ? myAccessItem.Attribute['PXOS'] : myAccessItem.Attribute['MXOS'];
                                attrName = 'Functional / Exec org Summary';
                                multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                            }
                        }

                        if (myAccessItem.PermissionLevel == UIConstants.PermissionLevels.All) {
                            attributeValueString = rawScope && rawScope.length > 0 ? rawScope[0] : null;
                        }

                        else {
                            attributeValueString = rawScope && rawScope.length > 0 ? rawScope.map(x => {
                                let spliValue = x.split(':')
                                return myAccessItem.Attribute && myAccessItem.Attribute['SCOMP'] ? x : spliValue[1];
                            }).join('; ').padEnd(attributeValueString.length+1,";") : null;
                        }
                    }

                    let allowEdit = myAccessItem.AllowEdit;
                    let endDate = new Date(myAccessItem.EndDate).toLocaleDateString();
                    let roleName = myAccessItem.PermissionLevel != myAccessItem.PermissionGroup ? myAccessItem.PermissionGroup + "-" + myAccessItem.PermissionLevel : myAccessItem.PermissionLevel;
                    let roleDefinitionId = myAccessItem.RoleDefinitionId ? myAccessItem.RoleDefinitionId : myAccessItem['role'] ? myAccessItem['role'].RoleId : "";
                    if ((roleName == UIConstants.PermissionTypes.MercuryInforma && myAccessData.filter(x => x.PermissionGroup.includes("P&L") || x.PermissionLevel.includes("Basic")).length > 0) || (roleName == "Basic Access" && myAccessData.filter(x => x.PermissionGroup.includes("P&L")).length > 0)) {
                        approvedAccess.push({
                            role: roleName,
                            attributes: attributeValueString,
                            attributeName: attrName,
                            endDate: endDate,
                            roleId: roleDefinitionId,
                            multiAttributes: multiAttributes,
                            allowEdit: allowEdit,
                            permissionLevel: myAccessItem.PermissionLevel,
                            accessItem: myAccessItem,
                            allowRemove: false
                        });
                    }

                    else {
                        approvedAccess.push({
                            role: roleName,
                            attributes: attributeValueString,
                            attributeName: attrName,
                            endDate: endDate,
                            roleId: roleDefinitionId,
                            multiAttributes: multiAttributes,
                            allowEdit: allowEdit,
                            permissionLevel: myAccessItem.PermissionLevel,
                            accessItem: myAccessItem,
                            allowRemove: true
                        });
                    }
                }

                approvedAccess.forEach(x => currentApprovedAccessItems.push(x));
            }
        }
        else if (this.tenantData?.isExternalAADTenant && MyAccess && MyAccess.Data) {
            myAccessData = MyAccess.Data.approvedAccess;
            let multiAttributes = [];
            for (const accessItem of myAccessData) {
                let attr = null;
                let attrName = '';
                if (accessItem.role !== null) {
                    if (accessItem.role.attributes?.length > 0) {
                        let attributes = accessItem.role.attributes.filter(x => x.attributeName === 'BusinessGroup');
                        attrName = attributes.length > 0 ? attributes[0].attributeName : '';
                        let scope = attributes.map(x => { return x.attributeValues.filter(y => y.isRemove != true)?.map(y => { return y.code !== y.description ? y.code + ':' + y.description : y.code }) })
                        multiAttributes = attributes.map(x => {
                            return {
                                attributeName: x.attributeName,
                                attributeVale: x.attributeValues,
                                attributeValueText: x.attributeValues.filter(y => y.isRemove != true)?.map(y => { return (y.code !== y.description ? y.code + ':' + y.description : y.code) }).join('; ')
                            }
                        })
                        attr = scope && scope.length > 0 ? scope.map(x => x).join('; ') : null;
                    }
                }
                approvedAccess.push({
                    role: accessItem.role.roleName,
                    attributes: attr,
                    attributeName: attrName,
                    endDate: new Date(accessItem.endDate).toLocaleString().split(",")[0],
                    roleId: accessItem.role.roleId,
                    multiAttributes: multiAttributes,
                    roleAssignmentId: accessItem.id,
                    allowEdit: false,
                    allowRemove: true,
                    requestedFor: (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) ? accessItem.requestorUPN : null
                });
            }
        }
        else if (MyAccess && MyAccess.Data) {
            myAccessData = MyAccess.Data.approvedAccess;
            if (myAccessData) {
                for (const myAccessItem of myAccessData) {
                    let attributeValueString = '';
                    let attrName = '';
                    let rawScope = [];
                    let multiAttributes = [];
                    if (myAccessItem.attributes) {
                        let t = myAccessItem.attributes;
                        if (myAccessItem.attributes['FiscalCycles']) {
                            rawScope = myAccessItem.attributes['FiscalCycles'];
                            attrName = 'Fiscal Cycles';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Company']) {
                            rawScope = myAccessItem.attributes['Company'];
                            attrName = 'Company';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Type']) {
                            rawScope = myAccessItem.attributes['Type'];
                            attrName = 'Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['System']) {
                            rawScope = myAccessItem.attributes['System'];
                            attrName = 'System';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['LineOfBusiness']) {
                            rawScope = myAccessItem.attributes['LineOfBusiness'];
                            attrName = 'Line Of Business';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Area']) {
                            rawScope = myAccessItem.attributes['Area'];
                            attrName = 'Area';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Country']) {
                            rawScope = myAccessItem.attributes['Country'];
                            attrName = 'Country';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AccessType']) {
                            rawScope = myAccessItem.attributes['AccessType'];
                            attrName = 'Access Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AccessTypeEU']) {
                            rawScope = myAccessItem.attributes['AccessTypeEU'];
                            attrName = 'Access Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AccessTypeBU']) {
                            rawScope = myAccessItem.attributes['AccessTypeBU'];
                            attrName = 'Access Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['OperationsDetail']) {
                            rawScope = myAccessItem.attributes['OperationsDetail'];
                            attrName = 'Operations Detail';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Operation']) {
                            rawScope = myAccessItem.attributes['Operation'];
                            attrName = 'Operation';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Region']) {
                            rawScope = myAccessItem.attributes['Region'];
                            attrName = 'Region';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['DefaultReportingSubsidiary']) {
                            rawScope = myAccessItem.attributes['DefaultReportingSubsidiary'];
                            attrName = 'Default Reporting Subsidiary';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Subsidiary']) {
                            rawScope = myAccessItem.attributes['Subsidiary'];
                            attrName = 'Subsidiary';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Business']) {
                            rawScope = myAccessItem.attributes['Business'];
                            attrName = 'Business';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['BusinessGroup']) {
                            rawScope = myAccessItem.attributes['BusinessGroup'];
                            attrName = 'BusinessGroup';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['ProgramFamily']) {
                            rawScope = myAccessItem.attributes['ProgramFamily'];
                            attrName = 'ProgramFamily';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Program']) {
                            rawScope = myAccessItem.attributes['Program'];
                            attrName = 'Program';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AccessTypeBI']) {
                            rawScope = myAccessItem.attributes['AccessTypeBI'];
                            attrName = 'Access Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Function']) {
                            rawScope = myAccessItem.attributes['Function'];
                            attrName = 'Function';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AccessTypeData']) {
                            rawScope = myAccessItem.attributes['AccessTypeData'];
                            attrName = 'Access Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AccessTypeFinance']) {
                            rawScope = myAccessItem.attributes['AccessTypeFinance'];
                            attrName = 'Access Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AccessTypeRelia']) {
                            rawScope = myAccessItem.attributes['AccessTypeRelia'];
                            attrName = 'Access Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AreaRelia']) {
                            rawScope = myAccessItem.attributes['AreaRelia'];
                            attrName = 'Area';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['AccessTypeSO']) {
                            rawScope = myAccessItem.attributes['AccessTypeSO'];
                            attrName = 'Access Type';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Geography']) {
                            rawScope = myAccessItem.attributes['Geography'];
                            attrName = 'Geography';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['Functional / Exec org Summary']) {
                            rawScope = myAccessItem.attributes['Functional / Exec org Summary'];
                            attrName = 'Functional / Exec org Summary';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['MSEC']) {
                            rawScope = ["External Reporting: True"];
                            attrName = 'External Reporting';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['BusinessSummary']?.length > 0) {
                            rawScope = myAccessItem.attributes['BusinessSummary'];
                            attrName = 'Business Summary';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['SuperDivision']?.length > 0) {
                            rawScope = myAccessItem.attributes['SuperDivision'];
                            attrName = 'Super Division';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['ReportingSummaryRevSumDivision']?.length > 0) {
                            rawScope = myAccessItem.attributes['ReportingSummaryRevSumDivision'];
                            attrName = 'Reporting Summary Rev Sum Division';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['PricingLevel']?.length > 0) {
                            rawScope = myAccessItem.attributes['PricingLevel'];
                            attrName = 'Pricing Level';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['ChannelOrgSummary']) {
                            rawScope = myAccessItem.attributes['ChannelOrgSummary'];
                            attrName = 'Channel Org Summary';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['ExecOrgSummary']) {
                            rawScope = myAccessItem.attributes['ExecOrgSummary'];
                            attrName = 'Exec Org Summary';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        if (myAccessItem.attributes['MDSModel']) {
                            rawScope = myAccessItem.attributes['MDSModel'];
                            attrName = 'MDS Model';
                            multiAttributes = this.renderAttributes(rawScope, attrName, multiAttributes);
                        }
                        attributeValueString = rawScope && rawScope.length > 0 ? rawScope.map(x => {
                            let spliValue = x.split(':')
                            return myAccessItem.attributes['Region'] ? x : spliValue[0] === spliValue[1] ? spliValue[0] : x;
                        }).join('; ') : null;
                    }

                    let allowEdit = false;
                    let selectedRole = roleData.find(x => x.id == myAccessItem.roleDefinitionId);
                    if (selectedRole) {
                        let permissionAction = selectedRole.permissions[0].actions;
                        if (permissionAction) {
                            allowEdit = permissionAction.find(x => x.indexOf(UIConstants.RoleDefinition.AllowEditFlag) > -1) ? true : false;
                        }
                    }


                    let endDate = new Date(myAccessItem.endDate).toLocaleDateString();
                    let roleName = myAccessItem.roleName ? myAccessItem.roleName : myAccessItem['role'] ? myAccessItem['role'].roleName : "";
                    let roleDefinitionId = myAccessItem.roleDefinitionId ? myAccessItem.roleDefinitionId : myAccessItem['role'] ? myAccessItem['role'].roleId : "";
                    if (multiAttributes.length == 1 && multiAttributes[0].attributeName == "BusinessGroup") {
                        attributeValueString?.split("; ").forEach(attribute => {
                            let _multiAttributes = [{ attributeName: attrName, attributeValueText: attribute }]
                            approvedAccess.push({
                                role: roleName,
                                attributes: attribute,
                                attributeName: attrName,
                                endDate: endDate,
                                roleId: roleDefinitionId,
                                multiAttributes: _multiAttributes,
                                allowEdit: allowEdit,
                                allowRemove: true
                            });
                        });
                    }
                    else {
                        approvedAccess.push({
                            role: roleName,
                            attributes: attributeValueString,
                            attributeName: attrName,
                            endDate: endDate,
                            roleId: roleDefinitionId,
                            multiAttributes: multiAttributes,
                            allowEdit: allowEdit,
                            allowRemove: true
                        });
                    }
                    if (hasOEMAccess && roleName == UIConstants.RoleDefinition.MSSalesBasicRole) {
                        approvedAccess.push({
                            role: "OEM User",
                            attributes: OEMSecurityTypeName,
                            attributeName: "OEMSecurityType",
                            endDate: "Not Applicable",
                            roleId: '',
                            multiAttributes: [],
                            allowEdit: false,
                            allowRemove: false
                        })
                    }
                }
            }
        } else {

        }
        return approvedAccess;
    }

    private getPendingItems = () => {
        const { MyAccess, MercuryAccess } = this.state;
        let pendingAccess = [];
        let myAccessData = null;
        let urlValues = window.location.pathname.split('/');
        let tenantName = _.last(urlValues);
        if (tenantName == UIConstants.Tenant.Mercury && MercuryAccess?.Data) {
            myAccessData = MercuryAccess.Data.filter(item => item.status == 'InProgress')
        }
        else if (MyAccess && MyAccess?.Data) {
            myAccessData = MyAccess.Data.pendingRequests;
        }
        if (myAccessData) {
            let multiAttributes = [];

            for (const accessItem of myAccessData) {
                let attr = null;
                let attrName = '';
                if (accessItem.role !== null) {
                    if (accessItem.role.attributes.length > 0) {
                        let attributes = accessItem.role.attributes.filter(x => x.attributeName === 'Company'
                            || x.attributeName === 'Type'
                            || x.attributeName === 'System'
                            || x.attributeName === 'LineOfBusiness'
                            || x.attributeName === 'Area'
                            || x.attributeName === 'AccessType'
                            || x.attributeName === 'AccessTypeEU'
                            || x.attributeName === 'AccessTypeBU'
                            || x.attributeName === 'Country'
                            || x.attributeName === 'OperationsDetail'
                            || x.attributeName === 'Operation'
                            || x.attributeName === 'Region'
                            || x.attributeName === 'Subsidiary'
                            || x.attributeName === 'Business'
                            || x.attributeName === 'Default Reporting Subsidiary'
                            || x.attributeName === 'BusinessGroup'
                            || x.attributeName === 'ProgramFamily'
                            || x.attributeName === 'Program'
                            || x.attributeName === 'AccessTypeBI'
                            || x.attributeName === 'Function'
                            || x.attributeName === 'AccessTypeData'
                            || x.attributeName === 'AccessTypeFinance'
                            || x.attributeName === 'AccessTypeRelia'
                            || x.attributeName === 'AreaRelia'
                            || x.attributeName === 'AccessTypeSO'
                            || x.attributeName === 'Functional / Exec org Summary'
                            || x.attributeName === 'Geography'
                            || x.attributeName === 'External Reporting'
                            || x.attributeName === 'Specific Companies'
                            || x.attributeName === 'Fiscal Cycles'
                            || x.attributeName === 'Statutory reporting access to Shadow Company data for legal entity reporting (includes access to the Statutory Balance Sheet and Statutory P&L perspectives)'
                            || x.attributeName === 'BusinessSummary'
                            || x.attributeName === 'SuperDivision'
                            || x.attributeName === 'ReportingSummaryRevSumDivision'
                            || x.attributeName === 'PricingLevel'
                            || x.attributeName === 'ExecOrgSummary'
                            || x.attributeName === 'ChannelOrgSummary'
                            || x.attributeName === 'MDSModel'
                        );
                        attrName = attributes.length > 0 ? attributes[0].attributeName : '';
                        let scope = attributes.map(x => { return x.attributeValues.filter(y => y.isRemove!=true)?.map(y => { return y.code !== y.description ? y.code + ':' + y.description : y.code }) })
                        multiAttributes = attributes.map(x => {
                            var isStatutory = (accessItem.role.roleName == 'All companies' || (accessItem.role.roleName == 'Specific Companies' && x.attributeName != 'Specific Companies'));
                            if (tenantName == UIConstants.Tenant.Mercury && x.attributeName == 'External Reporting') {
                                return {
                                    attributeName: x.attributeName,
                                    attributeVale: x.attributeValues,
                                    attributeValueText: x.attributeValues[0].code == "true"? "True" : "False"
                                }
                            }
                            else if (tenantName == UIConstants.Tenant.Mercury && attrName != 'Specific Companies' && x.attributeName != 'Specific Companies') {
                                return {
                                    attributeName: isStatutory ? "Statutory" : x.attributeName,
                                    attributeVale: x.attributeValues,
                                    attributeValueText: x.attributeValues.filter(y => y.isRemove != true)?.map(y => { return isStatutory ? "True" : y.description }).join('; ')
                                }
                            }
                            else {
                                return {
                                    attributeName: isStatutory ? "Statutory" : x.attributeName,
                                    attributeVale: x.attributeValues,
                                    attributeValueText: x.attributeValues.filter(y => y.isRemove != true)?.map(y => { return attributes[0].attributeName == "Region" ? y.code + ':' + y.description : (isStatutory ? "True" : (y.code !== y.description ? y.code + ':' + y.description : y.code)) }).join('; ')
                                }
                            }
                        })
                        attr = scope && scope.length > 0 ? scope.map(x => x).join('; ') : null;
                    }
                    pendingAccess.push({
                        requestId: accessItem.id,
                        role: (accessItem.tenantName == UIConstants.Tenant.Mercury && accessItem.role.group != accessItem.role.roleName) ? (accessItem.role.group + "-" + accessItem.role.roleName) : accessItem.role.roleName,
                        roleId: accessItem.role.roleId,
                        attributes: attr,
                        attributeName : attrName,
                        multiAttributes: multiAttributes,
                        requestedBy: this.tenantData?.isExternalAADTenant ? (accessItem.requestedBy.split("@")[0] != accessItem.requestorUPN.split("@")[0] ? accessItem.requestedBy.split("@")[0]+' (On Behalf - Bulk)' : accessItem.requestedBy.split("@")[0]) : (accessItem.requestedBy ?? accessItem.requestorUPN).split("@")[0],
                        requestType: (accessItem.tenantName == UIConstants.Tenant.Mercury) ? (accessItem.properties?.editRequest? "Update" : "Add") : accessItem.requestType,
                        requestDate: new Date(accessItem.requestedDate).toLocaleString(),
                        status: accessItem.status,
                        requestedFor: (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) ? accessItem.properties.onBehalfExternalUser : null
                    });
                }
            }
        }
        return pendingAccess;
    }

    private onEditBtnClick = (item: any) => {
        let urlValues = window.location.pathname.split('/');
        let tenantName = _.last(urlValues);
        if (tenantName == UIConstants.Tenant.Mercury || tenantName == UIConstants.Tenant.Alfred || tenantName == UIConstants.Tenant.MDS) {
            this._closeModal(item)
        } else {
            this._closeModal(this.state.MyAccess.Data.approvedAccess.filter(x => x.attributes != null && x.attributes.DefaultReportingSubsidiary != null))
        }
    }
}