import React, { Component } from 'react';
import { Header } from './Header';
import { UIConstants } from '../shared/models/Constants';
import { PrimaryButton, IconButton, Spinner, SpinnerSize } from '@fluentui/react';
import { Utility } from '../shared/models/Helper';
import ITenant from '../shared/models/Tenant.model';
import ConfigurationAPI from "../shared/api/Configuration.api";
import EUAScreenSpinner from './EUAScreenSpinner';
//declare var OpenFDA: any;

function loadDynamicScript(url: string, loaded?: () => void) {
    const mainElement: HTMLScriptElement = document.createElement('script');
    mainElement.src = url;
    mainElement.type = 'text/javascript';
    mainElement.async = false;
    document.getElementsByTagName('head')[0].appendChild(mainElement);

    if (loaded) {
        mainElement.onload = () => {
            loaded();
        };
    }
}

/*function openFDA() {
    // Load the finance bot.
    var fdaBotHostURL: string;
    var fdaBotHostAppId: string;
    var loadFDAScript = false;

    console.log("Loading bot");

    if (fdaBotHostAppId == undefined || fdaBotHostAppId == null) {
        fdaBotHostAppId = localStorage.getItem("FDABotHostID");
    }

    if (fdaBotHostURL == undefined || fdaBotHostURL == null) {
        fdaBotHostURL = localStorage.getItem("FDABotHostURL");
    }
    try {
        loadDynamicScript(fdaBotHostURL, () => {
            OpenFDA(`hostappid=${fdaBotHostAppId}`, { top: (window.screen.availHeight / 2) - 300, left: (window.screen.availWidth / 2) - 300 });
            loadFDAScript = true;
            console.log("Loading bot complete");
        });
    } catch (ex) {
        console.log("Something went wrong while loading the bot" + ex);
    }
}*/

interface ILayoutProps { auth: any, onSignOut: any, onSignIn: any}
type LayoutProps = ILayoutProps;

export default class Layout extends Component<LayoutProps, { hideHelpButton: boolean, loadPage: boolean }>  {
    static displayName = Layout.name;
    private tenantList: ITenant[] = null;

    public state = {
        hideHelpButton: false,
        loadPage : false
    }
    render() {
        const { loadPage } = this.state;
        return (loadPage ? <React.Fragment>
            <Header isAdmin={this.props.auth.isAuthenticated}
                    isExternalUser={this.props.auth.isExternalUser}
                    currentUser={this.props.auth.user}
                    logoutHandler={this.props.onSignOut}
            ></Header>
            <main id='main' tabIndex={-1}>{this.props.children}
                {/*this.renderFDAHelpBtn()*/}
            </main>
        </React.Fragment> : <EUAScreenSpinner message={'loading'}></EUAScreenSpinner>)
    }
    /*renderFDAHelpBtn() {
        return <React.Fragment>{(this.state.hideHelpButton) ? <IconButton className="IconFDA" role="button" title="Get Help" onClick={openFDA} onKeyPress={openFDA}
            iconProps={{ iconName: 'help' }} />
            : <div className="DivFDA">
                <PrimaryButton className="FDAAnchor" onClick={openFDA} onKeyPress={openFDA}
                    text={'Get Help'} />
            </div>}</React.Fragment>
    }*/
   
    async componentDidMount() {
        /*ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.FDABotHostURL)// Call API to Get Bot Host URL from config
            .then(response => {
                localStorage.setItem("FDABotHostURL", response);
            });

        ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.FDABotHostID)// Call API to Get BOT HostID from config
            .then(response => {
                localStorage.setItem("FDABotHostID", response);
            });
        */
            ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.FlightConfig)// Call API to Get BOT HostID from config
            .then(response => {
                let tenantFlighting = response ? JSON.parse(response.replace(/'/g, `"`)) : [];
                localStorage.setItem("tenantFlighting",  JSON.stringify(tenantFlighting));
                localStorage.setItem("currentUser",JSON.stringify(this.props.auth.user))
                ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.TenantConfig).then((res) => {
                    sessionStorage.setItem(UIConstants.Configuration.TenantConfig, res);
                    this.tenantList = Utility.getParsedTenantList(res);
                    this.setState({ loadPage: true });
                  })
            });

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        let widnowSize = window.innerWidth;
        let hide = widnowSize < 500; // hide search bar for smaller window size
        this.setState({ hideHelpButton: hide });
    }
}