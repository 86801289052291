import React, { Component } from 'react';
import { Route } from 'react-router';
import  Layout  from './components/Layout';
import Home from './components/Home';
import RequestHome from './components/Request/RequestHome';
import AppErrorBoundry from './components/AppErrorBoundry';
import { withAuth } from './shared/auth/MsalAuthProvider';

import './custom.css'
import '@fluentui/react/dist/css/fabric.min.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import AccessDenied from './components/AccessDenied';

class RootApp extends Component<{ auth: any, onSignOut: any, onSignIn: any }, {}>  {
    render() {
        return (
            <AppErrorBoundry>
                <Layout {...this.props} >
                    {this.props.auth.isExternalUser ?
                        <div>
                            <Route exact path='/' component={AccessDenied} />
                            <Route path='/request' component={AccessDenied} />
                        </div>
                        :
                        <div>
                            <Route exact path='/' component={Home} />
                            <Route path='/request' component={RequestHome} />
                        </div>
                    }
                </Layout>
            </AppErrorBoundry>

        );
    }
}
export const App = withAuth(RootApp);