import * as React from 'react';
import { TagPicker, IBasePicker, ITag } from '@fluentui/react/lib/Pickers';
import { mergeStyleSets, IStyle, IconButton } from '@fluentui/react';
import { UIConstants } from '../shared/models/Constants';
import { Utility } from '../shared/models/Helper';
import ITenant, { TenantFlighting } from '../shared/models/Tenant.model';
import ConfigurationAPI from '../shared/api/Configuration.api';
import { msalAuth } from '../shared/auth/MsalAuthProvider';
import { IUser } from '../shared/models/User.model';

export interface ISearchServiceState {
    isPickerDisabled?: boolean;
    hideSearchBar?: boolean;
    applyMarginLeft?:boolean;
    margin?:number;
    widnowSize?: number;
    user: IUser;
}

export class SearchService extends React.Component<{}, ISearchServiceState> {
    private _picker = React.createRef<IBasePicker<ITag>>();
    TenantData: ITenant[] = [];
    constructor(props: {}) {
        super(props);
        this.state = {
            isPickerDisabled: false,
            hideSearchBar: false,
            applyMarginLeft: false,
            margin: 0,
            widnowSize: 1000,
            user: null
        };
    }
    public render() {
        const contentStyles = mergeStyleSets({
            root: {
                width: this.state.widnowSize < 350 ? 300 : 465  ,
            } as IStyle,
        });
        
        return (
            <form id="search" role="search">
                <div aria-label="Search for a service" aria-live="assertive" >
                    {(this.state.hideSearchBar) ? <div><IconButton role="button" style={{backgroundColor:'#0078D4', color:'white'}} className={'searchIconBackgroud'} iconProps={{ iconName: 'Search' }} title="Search" onClick={() => { this.setState({ hideSearchBar:false }) }} /></div> : 
                    <TagPicker
                        pickerCalloutProps={{ styles: { root: { top: '40' }}}}
                        styles={{ root: { background: 'white', borderRadius: 2, marginLeft: this.state.margin}}}
                        componentRef={this._picker}
                        onResolveSuggestions={this._onFilterChanged}
                        onItemSelected={this._onItemSelected}
                        getTextFromItem={this._getTextFromItem}
                        onBlur={() => { this.setState({ hideSearchBar: this.handleBlur() }) }}
                        inputProps={{
                            placeholder: UIConstants.PlaceHolderText.SearchService,
                            className: 'searchInput',
                            "aria-label": UIConstants.PlaceHolderText.SearchService,
                            style: { backgroundColor: 'white' }
                        }}
                        pickerSuggestionsProps={{
                            className: contentStyles.root,
                            suggestionsHeaderText: 'Suggested service',
                            noResultsFoundText: 'No Service Found',
                        }}
                    />}
                </div>
            </form>
        );
    }

    private handleBlur() {
        let widnowSize = window.innerWidth;
        return widnowSize < 700;
    }

    private _getTextFromItem(item: ITag): string {
        return item.name;
    }

    private _onFilterChanged = async (filterText: string): Promise<ITag[]> => {
        let tenantFlighting: TenantFlighting[] = []; 
        let hideTenant = false;
        return  ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.TenantConfig).then(res=>{
            sessionStorage.setItem(UIConstants.Configuration.TenantConfig, res);
            let tenantData = Utility.getParsedTenantList(res);
            let tenantService: ITag[] =  tenantData.map(item => { return { key: item.TenantName, name: item.TenantDisplayName } });

            //Custom Feature flight Validation
            //if (!Utility.isFlightUser('MS Sales UI POC')) {
            //    tenantService = tenantService.filter(x => x.key !== "MSSales")
            //}

            return filterText && tenantService ? tenantService.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
                || tag.key.toString().toLowerCase().indexOf(filterText.toLowerCase()) === 0
            ) : [];
        })
    };

    private _onItemSelected = (item: ITag) => {
        sessionStorage.setItem('isAdmin', 'false');
        sessionStorage.setItem('isInvoice', 'false');
        window.location.href = `/${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.RequestAccess}/${item.key}`;
        return null;
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        this.setState({ user: user });
        this.resize();
    }

    resize() {
        let widnowSize = window.innerWidth;
        let hide = widnowSize < 700; // hide search bar for smaller window size
        let margin = 0

        if (widnowSize > 350 && widnowSize < 400) {
            margin = -210
        }
        else if (widnowSize > 250 && widnowSize < 300) {
            margin = -185
        }
        else if (widnowSize < 350) {
            margin = -170
        }
        this.setState({ hideSearchBar: hide, margin: margin , widnowSize : widnowSize});

    }

}
