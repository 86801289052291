import { BusinessGroups } from "./BusinessGroup.model";

export default interface IRole {
    roleId: string;
    roleName: string;
    description: string;
    isApprovalRequired: boolean;
    numberOfApprovalLevels: number;
    approvalLevels?: IApproval[];
    scope: Scope;
    roleAttributes?: IAttribute[];
    policies: Array<string>;
}

interface IApproval {
    levelId: string;
    type: number;
    value?: string;
}
interface IAttribute {
    id: string;
    name: string;
    displayName: string;
    sourceType: number;
    value?: any;
    dependentAttribute?: any;
}
export class RoleResponse {
    IsSuccess: boolean = true;
    IsDataLoaded: boolean = false;
    Data: Role[];
}

export class AttributeValue {
    code: string;
    description: string = '';
    GroupCode?: string;
    GroupDescription?: string;
    isRemove?: boolean;
}
export class CountryCompanyAttribute {
   countryId: string;
   countryName: string;
   company : AttributeValue[];
}

export class CountryCompanySearchModel{
    countryId: string;
    countryName: string;
    code: string;
    description: string;
}
export class Scope {
    name: string;
    value: Array<string>;
}
export class Role {
    typeId?: string;
    type?: string;
    groupId?: string;
    group?: string;
    id: string;
    name: string;
    description: string;
    permissions: Array<Permissions>;        
    scopes: Array<string>;
    sortOrder : string;
    attributes: Array<string>;
    approvalPolicy?: string;
}

export class MercuryRole {
    permissionGroupID: string;
    permissionGroup: string;
    permissionLevelID: string;
    permissionLevel: string;
    role: Array<RoleDetail>;
}

export class RoleDetail {
    roleId: string;
    roleName: string;
    attribute: Array<RoleAttributes>;
    isAdditional: boolean;
}

export class RoleAttributes {
    name: string;
    value: string;
}

class Permissions {
    actions: Array<string>;
    notActions: Array<string>;
    condition: string;
}

export class AttributeConfig {
    attributeDisplayName: string;
    attributeName: string;
    attributeType: AttributeType;
    attributeValue: AttributeValue[];
    selectedAttributeValue: AttributeValue[];
}

export enum AttributeType{
    Lookup = 'Lookup',
    List = 'List',
    MultiSelectList = 'MultiSelectList',
    Text = 'Text',
    Checkbox = 'Checkbox',
    CheckboxList = 'Checkbox-List',
    Search = 'Search',
    Tree = 'Tree'
}
export class ExternalAADRoles {
    roleId: string;
    roleName: string;
    tenantName: string;
    tenantId: string;
    policyType: string;
    allowSkipLevel: string;
    businessGroupId: string;
    businessGroupName: string;
}
export class Faq {
    Questions: string;
    Answers: Array<string>;
}
export class Tenantfaq {
    Application: string;
    Items: Array<Faq>;
} 

export class BulkRequest {
    UserUPN: string;
    RoleName: string;
} 
export class AllowedAccount {
    AllowedType: string;
    DomainSuffix: string;
    IsDynamic: boolean;
}
export class CheckBoxTreeModel {
    Name: string;
    Values: any;
}
