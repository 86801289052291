import { AuthAcitvityMockData,InprogressMockData,ProvisionedMockData } from '../shared/mock/AdminViewMockData';
import { delay } from 'q';
import { URLHelper } from '../shared/models/Helper';
import { axioClient } from '../shared/api/axios'
import { ApproverDetailsMockData, myAccessMockData, MigrationRequestMockData, userAccessMockData, consumerAssetMapMockData, RequestStatusMockData, adminAccessHistoryMockData, fdlAccessRequestMockData, supplierAccessMockData, supplierAuthActivityMockData, mssalesuserAccessMockData, AssetsMockData, mercuryUserAccessMockData } from '../shared/mock/UserAccessMockData';
import { ApproverDetailsResponse } from '../shared/models/ApproverDetails.model';
import { AccessRequestRemovalByAdminModel, BulkRemoval, AccessRequestSelfRemoval, AccessRequestSubmissionModel, FDLReportFilter, MyAccessRequestResponse, RequestDetailsResponse, ReviewReportFilter, ReviewRequestSubmissionModel, UpdateApprover, Policy } from '../shared/models/UserAccess.model';
import { ConsumerAssetMappingModel, AssetMappingResponse } from '../shared/models/ConsumerAssetMap.model';
import { peopleMockdata } from '../shared/mock/PeopleMockData';
import { MyAccessResponse } from '../shared/models/MyCurrentAccess.model';
import { AttributeMockData, companyMockData, Specific } from '../shared/mock/AttributeMockData';
import { ExternalAADRoles, RoleResponse } from '../shared/models/Role.model';
import { SupplierUsers } from '../shared/models/SupplierUser';
import { adminRoleMockData, roleMockData } from '../shared/mock/RoleMockData';
import { AdminViewFilter } from '../shared/models/AdminViewFilter.model';
import { ConfigMockData } from '../shared/mock/ConfigMockData';
import { SubsidiarySelection, BusinessSelection, MSRUser } from '../shared/models/MSSales';
import { UIConstants } from '../shared/models/Constants';
import { MercuryData } from '../shared/mock/MercuryMockData';
import { Permission } from '../shared/models/Mercury';
import { ExternalAADRolePermissions } from '../shared/models/ExternalAADRolePermissions';
import { Cycles } from '../shared/models/Alfred';
export default class AccessAPI {

    static async GetCyclesBasedonFiscalYear(tenantId, fiscalYear) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            let mockCycles = [{
                "code": "003",
                "name": "WW Stock Comp Cost Pool - Stage 0",
                "isActive": true
            },
            {
                "code": "009",
                "name": "WW Payroll Tax Cost Pool - Stage 0",
                "isActive": true
            }];
            return delay(2000).then(() => {
                return mockCycles as unknown as Promise<Array<Cycles>>;
            });
        }
        else {
            return axioClient.get<Array<Cycles>>(URLHelper.GetCycles(tenantId, fiscalYear)).then(res => {
                let cycles: Array<Cycles>;
                if (res.status == 200) {
                    cycles = res.data;
                }
                else {
                    cycles = null;
                }
                return cycles;
            }).catch(err => {
                return null;
            })
        }
    }


    static async GetRolePermissions(tenantId, roleId) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<Array<ExternalAADRolePermissions>>;
            });
        }
        else {
            return axioClient.get<ExternalAADRolePermissions>(URLHelper.GetPermissions(tenantId, roleId)).then(res => {
                let rolePersmissions: ExternalAADRolePermissions;
                if (res.status == 200) {
                    rolePersmissions = res.data;
                }
                else {
                    rolePersmissions = null;
                }
                return rolePersmissions;
            }).catch(err => {
                return null;
            })
        }
    }

    static async CheckUserIsAdmin(tenantId: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.get<any>(URLHelper.IsTenantAdmin(tenantId)).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return false;
            });
        }
    }
    static async CheckUserhasUserManagementAccess(tenantId: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.get<any>(URLHelper.AllowUserManagement(tenantId)).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return false;
            });
        }
    }
    static async AllowUserToRequestOnBehalf(tenantId: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.get<any>(URLHelper.AllowRequestOnBehalf(tenantId)).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return false;
            });
        }
    }
    static async GetPolicyBasedOnPolicyId(policyId: string) {
        let result : Policy = null;
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { "id": "Karnak_ApprovalPolicyGTFS", "name": "Karnak_ApprovalPolicyGTFS", "tenantId": "db5f732c-ecdb-4f16-8456-26c7bbd0cbed", "approval": { "levels": 2, "levelInfo": [{ "levelId": 1, "levelType": "Level 1", "type": 2, "value": "$Manager$", "levelScope": null, "backUpApprovers": null, "externalUsers_ApproversList": null, "external_Users": null }, { "levelId": 2, "levelType": "Level 2", "type": 1, "value": "devnat;v-vieal;lovmak;v-arugupta;dameu;panema", "levelScope": null, "backUpApprovers": null, "externalUsers_ApproversList": null, "external_Users": null }] } } as unknown as Promise<Policy>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetPolicyBasedOnPolicyId(policyId)).then(response => {
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return null;
            });
        }
    }
    static async GetMSRUserData() {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return null as unknown as Promise<MSRUser>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetMSRUserData()).then(response => {
                let result = null;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return null;
            });
        }
    }
    static async CheckUserIsAuditor(tenantId: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.get<any>(URLHelper.IsTenantAuditor(tenantId)).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return false;
            });
        }
    }

    static async CheckDisplayReviewPage(tenantId: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetDisplayAccessReviewConfig(tenantId)).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return false;
            });
        }
    }
    static async CheckBulkUploadAccess(tenantId: string, businessGroup: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.get<any>(URLHelper.CheckBulkUploadAccess(tenantId, businessGroup)).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return false;
            });
        }
    }
    static async getUserGuid(tenantId: string, userEmail: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return '1234-1234-1234' as unknown as Promise<string>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetUserData(tenantId, userEmail)).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return null;
            });
        }
    }
    static async updateUserObjectId(data?: any) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.post<any>(URLHelper.UpdateSupplierUserObjectId(), data).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return null;
            });
        }
    }
   static async getInprogressRequestData(tenantId: string, filter : AdminViewFilter) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return InprogressMockData as unknown as Promise<any>;
            });
        } else {
            return axioClient.post<any>(URLHelper.GetInProgressUsers(tenantId),filter).then(response => {
                let result = [];
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async getInprogressRequestCount(tenantId: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return InprogressMockData as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetInProgressUserRequestCount(tenantId)).then(response => {
                let result = [];
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async getProvisionedRequestData(tenantId: string, filter : AdminViewFilter) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return ProvisionedMockData as unknown as Promise<any>;
            });
        } else {
            return axioClient.post<any>(URLHelper.GetProvisionedUsers(tenantId), filter).then(response => {
                let result = [];
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async getApprovalStatusDetails (requestId?: string) {
        let result: ApproverDetailsResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = RequestStatusMockData
                return result as unknown as Promise<ApproverDetailsResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetApprovalStatusDetails(requestId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data) {
                        result.Data = { migrationData: null, requestData: response.data } 
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }


    static async getApprovalStatusDetailsForTenant(tenantId?: string) {
        let result: ApproverDetailsResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = RequestStatusMockData
                return result as unknown as Promise<ApproverDetailsResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetApprovalStatusDetailsForTenant(tenantId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data) {
                        result.Data = { migrationData: null, requestData: response.data }
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async GetPolicyInfoFromPolicyServiceByRole(userId?: string, roleId?: string, tenantId?: string) {
        let result: ApproverDetailsResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data =  MigrationRequestMockData
                return result as unknown as Promise<ApproverDetailsResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetPolicyInfoFromPolicyServiceByRole(userId, roleId, tenantId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async GetProvisionedRequestStatus(requestId : string) {
        let result: ApproverDetailsResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data =  MigrationRequestMockData
                return result as unknown as Promise<ApproverDetailsResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetProvisionedRequestatus(requestId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getMyAccessRequest (tenantId?: string) {
        let result: MyAccessRequestResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data =  mercuryUserAccessMockData
                return result as unknown as Promise<MyAccessRequestResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetMyAccessRequest(tenantId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async SupplierUserRequests(tenantId: string, userId : string, supplierId: string) {
        let result: SupplierUsers = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = supplierAccessMockData
                return result as unknown as Promise<SupplierUsers>;
            });
        } else {
            let url = URLHelper.GetAllSupplierUserRequests(tenantId);
            if (userId && supplierId) {
                url = URLHelper.GetSupplierUserRequests(tenantId, userId, supplierId);
            }
            else if (userId) {
                url = URLHelper.GetSupplierUserRequestsByUserEmail(tenantId, userId);
            } else if (supplierId) {
                url = URLHelper.GetSupplierUserRequestsBySupplierId(tenantId, supplierId);
            } else {
                url = URLHelper.GetAllSupplierUserRequests(tenantId);
            }
            return axioClient.get<any>(url).then(response => {
                if (response.status && response.status === 200) {
                    typeof response.data !== "object" 
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        result.Data = response.data;
                    }else{
                        result.Data = [response.data];
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getSupplierUserAuthActivityBasedOnSearch(tenantId: string, supplierId: string, userId: string) {
        let result: any = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = supplierAuthActivityMockData
                return result as unknown as Promise<SupplierUsers>;
            });
        } else {
            let url = URLHelper.GetSupplierUserAuthActivityBasedOnSearch(tenantId, supplierId, userId);
            if (userId && supplierId) {
                url = URLHelper.GetSupplierUserAuthActivityBasedOnSearch(tenantId, supplierId, userId);
            }
            else if (userId) {
                url = URLHelper.GetSupplierUserAuthActivityBasedOnSearch(tenantId, null, userId);
            } else if (supplierId) {
                url = URLHelper.GetSupplierUserAuthActivityBasedOnSearch(tenantId, supplierId, null);
            } else {
                return { IsDataLoaded: true, IsSuccess: true, Data: null };
            }
            return axioClient.get<any>(url).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getAccessRequests(tenantId: string) {
        let result: MyAccessRequestResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = fdlAccessRequestMockData
                return result as unknown as Promise<MyAccessRequestResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetAccessRequestsForTenant(tenantId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getExternalAADAccessRequest(userId?: string, roleId?: string, tenantId?: string, attribute?: string) {
        let result: ApproverDetailsResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = fdlAccessRequestMockData
                return result as unknown as Promise<ApproverDetailsResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetExternalAADMappingAccessRequest(userId, roleId, tenantId, attribute)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getFilteredFDLreport(filter: FDLReportFilter) {
        let result: MyAccessRequestResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = fdlAccessRequestMockData
                return result as unknown as Promise<MyAccessRequestResponse>;
            });
        } else {
            return axioClient.post<any>(URLHelper.GetFilteredFDLreport(), filter).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getAccessReviewreport(filter: ReviewReportFilter) {
        let result: MyAccessRequestResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = fdlAccessRequestMockData
                return result as unknown as Promise<MyAccessRequestResponse>;
            });
        } else {
            return axioClient.post<any>(URLHelper.GetFilteredReviewreport(), filter).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getConsumerAssetMappings(filter: FDLReportFilter) {
        let result: AssetMappingResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = consumerAssetMapMockData
                return result as unknown as Promise<AssetMappingResponse>;
            });
        } else {
            return axioClient.post<any>(URLHelper.GetAssetMappingsForTenant(),filter).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getRequestHistory(tenantId: string, userId: string) {
        let result: MyAccessRequestResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data =  adminAccessHistoryMockData
                return result as unknown as Promise<MyAccessRequestResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetRequestHistory(tenantId, userId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getMyAccess (tenantId?: string) {
        let result: MyAccessResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data =  myAccessMockData
                return result as unknown as Promise<MyAccessResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetMyAccess(tenantId)).then(response => {
                if (response.status && response.status === 200) {
                        result.Data = response.data;
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async requestRoleByTenant (tenantId: string , isAdminView : boolean = false) {
        let result: any = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = roleMockData
                return result as unknown as Promise<RoleResponse>;
            });
        } else {
            let url = isAdminView ? URLHelper.GetAdminViewRoles(tenantId) : URLHelper.GetRoles(tenantId);
            return axioClient.get<any>(url).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async getCosmosRoleDefinitions(tenantId: string) {
        let result: ExternalAADRoles[] = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return JSON.parse(ConfigMockData.CosmosRoles.replace(/'/g, `"`)) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetCosmosRoleDefinitions(tenantId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data != null) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }

    static async getMercuryRoles() {
        let result: any;

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = roleMockData;
                return result as unknown as Promise<RoleResponse>;
            });
        } else {
            let url = URLHelper.GetMercuryRoles() 
            return axioClient.get<any>(url).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async IsDuplicateReviewName(tenantId: string, reviewName: string) {
        let result: any;
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return false as unknown as Promise<boolean>;
            });
        } else {
            let url = URLHelper.IsDuplicateReviewName(tenantId, reviewName)
            return axioClient.get<any>(url).then(response => {
                if (response.status && response.status === 200) {
                    result = response.data;
                }
                return result;
            }).catch(err => {
                return false;
            });
        }
    }

    static async getAssetsfromAuthz(filter: FDLReportFilter) {
        let result: any = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data = AssetsMockData;
                return result as unknown as Promise<RoleResponse>;
            });
        } else {
            let url = URLHelper.GetAssetsFromAuthZ();
            return axioClient.post<any>(url, JSON.stringify(filter)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }

    static async saveUserAccessAsync(data?: AccessRequestSubmissionModel) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 , message: 'fail'} as unknown as Promise<any>;
            });
        } else {
            return axioClient.post<any>(URLHelper.SaveAccessRequest(),JSON.stringify(data)).then(response => {
                console.log("success",response);
                return response;
            }).catch(err => {
                console.log("catch",err);
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async saveAccessRenewalAsync(data?: AccessRequestSubmissionModel) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.post<any>(URLHelper.SaveAccessRenewal(), JSON.stringify(data)).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async BulkRemoval(removalUsersData?: BulkRemoval) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.delete<any>(URLHelper.BulkRemoval(), { data: removalUsersData }).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }

    static async saveReviewCreationAsync(data?: ReviewRequestSubmissionModel) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.post<any>(URLHelper.SaveReviewCreationRequest(), JSON.stringify(data)).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async updateMSSalesApprover(data?: UpdateApprover) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.post<any>(URLHelper.UpdateMSSalesApprover(), JSON.stringify(data)).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async removeRoleAccess(requestData?: AccessRequestSelfRemoval) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.delete<any>(URLHelper.RemoveRoleAccess(), { data: requestData }).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async RemoveAccessRequestByAdmin(requestData?: AccessRequestRemovalByAdminModel) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.delete<any>(URLHelper.RemoveAccessRequestByAdmin(), { data: requestData } ).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async RemoveInProgressAccessRequest(requestId: string) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.delete<any>(URLHelper.RemoveInProgressAccessRequest(requestId)).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async CancelReview(reviewId: string) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.delete<any>(URLHelper.CancelReview(reviewId)).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async RemoveInProgressAccessRequestAdmin(tenantId:string, requestId: string, userPrincipalId: string) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.delete<any>(URLHelper.RemoveInProgressAccessRequestAdmin(tenantId, requestId, userPrincipalId)).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }
    static async inviteUsersAsync(data?: any) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return "Success" as unknown as Promise<any>;
            });
        } else {
            return axioClient.post<any>(URLHelper.InviteUser(), JSON.stringify(data));
        }
    }

    static async searchUser(contentType: string, searchText: string, hideCEO: boolean = false) {
        let result = [];

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return peopleMockdata.filter( x=> x.name.toLowerCase().indexOf(searchText) > 0 || x.upn.toLowerCase().indexOf(searchText) > 0 ) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.SearchUser(contentType, searchText, hideCEO)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }

    static async validateFile(fileInfo: FormData) {

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { status: 200 } as unknown as Promise<any>;
            });
        } else {
            return axioClient.post<any>(URLHelper.ValidateFile(), fileInfo).then(response => {
                return response;
            }).catch(err => {
                return { status: err.response.status, message: err.response.data };
            });
        }
    }

    static async searchAllUser(searchText: string) {
        let result = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return peopleMockdata.filter(x => x.name.toLowerCase().indexOf(searchText) > 0 || x.upn.toLowerCase().indexOf(searchText) > 0) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.SearchAllUser(searchText)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async searchNonSCUser(searchText: string) {
        let result = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return peopleMockdata.filter(x => x.name.toLowerCase().indexOf(searchText) > 0 || x.upn.toLowerCase().indexOf(searchText) > 0) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.SearchNonSCUser(searchText)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async searchExternalUser(searchText: string) {
        let result = [];

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return peopleMockdata as unknown as Promise<any>
                //.filter(x => x.name.toLowerCase().indexOf(searchText) > 0 || x.upn.toLowerCase().indexOf(searchText) > 0) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.SearchExternalUser(searchText)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async searchSupplier(searchText: string) {
        let result = [];

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {

                return ["0002026196", "0002050274", "0003018714"] as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.SearchSupplier(searchText)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async getCountryCompanyMapping() {
        let result = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return Specific as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetCountryCompanyMapping()).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async getRegionCountryCompanyMapping() {
        let result = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return ConfigMockData.RegionMapping as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetRegionCountryCompanyMapping()).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async getAttributeData (filterText , tenantId,attributeName) {
        let result = [];
        let attributeSourceName = attributeName === 'Company' ? 'CO' : attributeName;
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return AttributeMockData.Get(attributeName) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetAttributeValues(tenantId,attributeSourceName, filterText)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }

    static async getAuthActivityLog(tenantId) {
        let result = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return AuthAcitvityMockData as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetAuthActivityLog(tenantId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = JSON.parse(response.data);
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }

    }
    static async getSubsidiaries() {
        let result: SubsidiarySelection[] = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return JSON.parse(ConfigMockData.MSSalesSubsidiaries.replace(/'/g, `"`)) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetSubsidiaries()).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async getBusiness() {
        let result: BusinessSelection[] = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return JSON.parse(ConfigMockData.MSSalesBusiness.replace(/'/g, `"`)) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetBusiness()).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async getBusinessGroupByTenant(tenantId) {
        let result: any = { IsDataLoaded: true, IsSuccess: true, Data: null };
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return JSON.parse(ConfigMockData.VLAppsBGMapping.replace(/'/g, `"`)) as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetBusinessByTenant(tenantId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data != null) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { IsDataLoaded: true, IsSuccess: false, Data: null };
            });
        }
    }
    static async GetFTEManager(userUpn) {
        let result = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return [{ id: '', name: '', displayName: '', email: '', upn: '', surname: '', domain: '', jobTitle: '' }] as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetFTEManager(userUpn)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                else if (response.status && response.status === 404) {
                    return [{ id: '', name: 'N/A', displayName: 'N/A', email: 'N/A', upn: 'N/A', surname: '', domain: '', jobTitle: '' }]
                }
                return result;
            }).catch(err => {
                if (err.response.status && err.response.status === 404) {
                    return [{ id: '', name: 'N/A', displayName: 'N/A', email: 'N/A', upn: 'N/A', surname: '', domain: '', jobTitle: '' }]
                }
                else {
                    return [];
                }
            });
        }
    }
    static async GetManager(userUpn) {
        let result = { id: '', name: '', displayName: '', email: '', upn: '', surname: '', domain: '', jobTitle: '' };
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return { id: '', name: '', displayName: '', email: '', upn: '', surname: '', domain: '', jobTitle: '' } as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetManager(userUpn)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data != null) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return { id: '', name: '', displayName: '', email: '', upn: '', surname: '', domain: '', jobTitle: '' };
            });
        }
    }
    static async GetMercuryData() {
        let result : Permission[] = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return MercuryData as unknown as Promise<Permission[]>;
            });
        } else {
            return axioClient.get<Permission[]>(URLHelper.GetMercuryData()).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data != null) {
                        result = response.data as unknown as Permission[];
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
    static async ValidateUserJobTitle(userUpn) {
        let result = false;
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.get<any>(URLHelper.ValidateUserJobTitle(userUpn)).then(response => {
                let result = false;
                if (response.status && response.status === 200) {
                    result = response.data;
                 }
                return result;
            }).catch(err => {
                return false;
            });
        }
    }
    static async CheckManagerAlias(searchText,hideDirectFTE) {
        let result = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return peopleMockdata as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.CheckManagerAlias(searchText, hideDirectFTE)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }

    static async GetRequest(requestId : string) {
        let result: RequestDetailsResponse = { IsDataLoaded: true, IsSuccess: true, Data: null };

        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                result.Data =  mssalesuserAccessMockData
                return result as unknown as Promise<RequestDetailsResponse>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetRequestData(requestId)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data) {
                        result.Data = response.data;
                    }
                }
                return result;
            }).catch(err => {
                result.Data = null;
                result.IsSuccess = false;
                return result;
            });
        }
    }

    static async HasConsolidatedGaapAccess(tenantId: string, roleId: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return true as unknown as Promise<boolean>;
            });
        } else {
            return axioClient.get<any>(URLHelper.HasConsildatedGaapAccess(tenantId, roleId)).then(response => {
                if (response.status && response.status === 200) {
                    let result = false;
                    if (response.status && response.status === 200) {
                        result = response.data;
                    }
                    return result;
                }
            }).catch(err => {
                return false;
            });
        }
    }

    //GetMDSRoles&Scopes - 8-07-2024 #11020063
    static async getMDSRoleAndScopes(role : string) {
        let result = [];
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(2000).then(() => {
                return ConfigMockData.MDSRoles as unknown as Promise<any>;
            });
        } else {
            return axioClient.get<any>(URLHelper.GetMDSRolesAndScopes(role)).then(response => {
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return [];
            });
        }
    }
}