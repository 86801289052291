import * as React from 'react';
import { IPersonaProps, PersonaPresence } from '@fluentui/react/lib/Persona';
import {
    IBasePickerSuggestionsProps,
    IBasePicker,
    NormalPeoplePicker,
    ValidationState
} from '@fluentui/react/lib/Pickers';
import { PeoplePickerType, PeoplePickerSelectionMode, PeoplePickerContentType } from '../shared/models/PeoplePicker.enum';
import AccessAPI from '../store/AccessAPI';
import { msalAuth } from '../shared/auth/MsalAuthProvider';
import { IUser } from '../shared/models/User.model';
import { UIConstants } from '../shared/models/Constants';

export interface IPeoplePickerState {
    currentPicker?: number | string;
    delayResults?: boolean;
    defaultSelectedItems?: IPersonaProps[];
    isPickerDisabled?: boolean;
}
export interface IPeoplePickerprops {
    type?: PeoplePickerType,
    contentType: PeoplePickerContentType,
    selectionMode?: PeoplePickerSelectionMode,
    isPickerDisabled?: boolean,
    onchange?: any,
    setFocusOnPicker: boolean,
    defaultUserAlias?: string,
    tenantName?: string;
    hideCEO?: boolean;
    hideDirectFTE?: boolean
}
const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: false,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested People',
    resultsMaximumNumber: 10,
    searchingText: 'Searching..',
};

type PeoplePickerProp = IPeoplePickerprops & IPeoplePickerState;

export default class PeoplePicker extends React.Component<PeoplePickerProp> {
    // All pickers extend from BasePicker specifying the item type.
    private _picker = React.createRef<IBasePicker<IPersonaProps>>();

    public state = {
        currentPicker: 1,
        delayResults: false,
        currentSelectedItems: [],
        isPickerDisabled: false,
        userDetails: null,
        isdataLoaded : false,
    };
    public render() {
        let currentPicker: JSX.Element | undefined = undefined;

        switch (this.props.type) {
            case PeoplePickerType.Normal:
                currentPicker = this._renderNormalPicker();
                break;
            default:
                currentPicker = this._renderNormalPicker();
                break;
        }
        return (
            <div>
                {currentPicker}
            </div>
        );
    }
    componentDidUpdate(){
        this.setFocusonPeoplePicker();
    }
    componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        if (this.props.defaultUserAlias && this.props.contentType == PeoplePickerContentType.FTEUser) {
            AccessAPI.CheckManagerAlias(this.props.defaultUserAlias, this.props.hideDirectFTE)
            .then(searchResponse => {
                let searchResult = [];
                if (searchResponse) {
                    searchResult = this._mapUsersToPersonas(searchResponse);
                }
                this.setState({ userDetails: user, currentSelectedItems: searchResult.length>0 ? [searchResult[0]]:[] , isdataLoaded: true });
            });
        } else {
            this.setState({ userDetails: user , isdataLoaded : true });
        }
       
       this.setFocusonPeoplePicker();
    }
    setFocusonPeoplePicker(){
        if (this.props.setFocusOnPicker) {
            this._picker.current.focus()
        }
    }
    private _getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }
    private removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
        return personas.filter(persona => !this.listContainsPersona(persona, possibleDupes));
    }
    private listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter(item => item.text === persona.text).length > 0;
    }
    private _renderNormalPicker() {
        return (
            <form id="search-picker" role="search" aria-roledescription="required">
                <div aria-label="Users" aria-live="assertive" >
                    {this.state.isdataLoaded && <NormalPeoplePicker
                        itemLimit={this.props.selectionMode === PeoplePickerSelectionMode.Single ? 1 : undefined}
                        onResolveSuggestions={this._onFilterChanged}
                        onChange={this.onchange}
                        getTextFromItem={this._getTextFromItem}
                        pickerSuggestionsProps={suggestionProps}
                        className={'ms-PeoplePicker'}
                        onValidateInput={this._validateInput}
                        inputProps={{
                            'aria-label': 'Users',
                            required: true
                        }}
                        componentRef={this._picker}
                        onInputChange={this._onInputChange}
                        resolveDelay={300}
                        disabled={this.props.isPickerDisabled}
                        removeButtonAriaLabel = "Remove user"
                        styles={{input:{height : 38}}}
                        defaultSelectedItems = {this.state.currentSelectedItems}//{[{title : 'tit' , text : 'txt'}]}
                    />}
                </div>
            </form>
        );
    }

    private _onFilterChanged = (
        filterText: string, currentPersonas: IPersonaProps[], ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        const appScope = this;

        if (filterText && filterText.trim().length >= 2) {
            if (this.props.contentType == PeoplePickerContentType.ExternalUser) {
                return AccessAPI.searchExternalUser(filterText)
                    .then(searchResponse => {
                        let searchResult = [];
                        if (searchResponse) {
                            searchResult = appScope._mapUsersToPersonas(searchResponse);
                            if (this._picker.current.items.length > 0) {
                                searchResult = this.removeDuplicates(searchResult, currentPersonas);
                            }
                        }
                        return searchResult;
                    })
            } else if (this.props.contentType == PeoplePickerContentType.FTEUser) {
                return AccessAPI.CheckManagerAlias(filterText, this.props.hideDirectFTE).then(searchResponse => {
                    let searchResult = [];
                    if (searchResponse) {
                        searchResult = appScope._mapUsersToPersonas(searchResponse);
                        if (this._picker.current.items.length > 0) {
                            searchResult = this.removeDuplicates(searchResult, currentPersonas);
                        }
                    }
                    return searchResult;
                })
            } else if (this.props.contentType == PeoplePickerContentType.AllUser) {
                return AccessAPI.searchAllUser(filterText)
                    .then(searchResponse => {
                        let searchResult = [];
                        if (searchResponse) {
                            searchResult = appScope._mapUsersToPersonas(searchResponse);
                            if (this._picker.current.items.length > 0) {
                                searchResult = this.removeDuplicates(searchResult, currentPersonas);
                            }
                        }
                        return searchResult;
                    })
            } else if (this.props.contentType == PeoplePickerContentType.NonSCUser) {
                return AccessAPI.searchNonSCUser(filterText)
                    .then(searchResponse => {
                        let searchResult = [];
                        if (searchResponse) {
                            searchResult = appScope._mapUsersToPersonas(searchResponse);
                            if (this._picker.current.items.length > 0) {
                                searchResult = this.removeDuplicates(searchResult, currentPersonas);
                            }
                        }
                        return searchResult;
                    })
            } else {
                return AccessAPI.searchUser(this.props.contentType, filterText, this.props.hideCEO)
                    .then(searchResponse => {
                        let searchResult = [];
                        if (searchResponse) {
                            searchResult = appScope._mapUsersToPersonas(searchResponse);
                            if (this._picker.current.items.length > 0) {
                                searchResult = this.removeDuplicates(searchResult, currentPersonas);
                            }
                        }
                        return searchResult;
                    })
            }
        }
        else {
            return [];
        }
    };

    private _validateInput = (input: string): ValidationState => {
        if (input.indexOf('@') !== -1) {
            return ValidationState.valid;
        } else {
            return ValidationState.invalid;
        }
    };
    private onchange = (items: IPersonaProps[]): void => {
        const { onchange } = this.props;
        onchange(items);
    }
    /**
     * Takes in the picker input and modifies it in whichever way
     * the caller wants, i.e. parsing entries copied from Outlook (sample
     * input: "Aaron Reid <aaron>").
     *
     * @param input The text entered into the picker.
     */
    private _onInputChange(input: string): string {
        const outlookRegEx = /<.*>/g;
        const emailAddress = outlookRegEx.exec(input);

        if (emailAddress && emailAddress[0]) {
            return emailAddress[0].substring(1, emailAddress[0].length - 1);
        }

        return input;
    }

    private _mapUsersToPersonas = (users) => {
        return users.map((p) => {
            let persona: IPersonaProps = {
                secondaryText: this.props.tenantName == UIConstants.Tenant.Invoice ? p.email : p.upn,
                presence: PersonaPresence.none,
                initialsColor: Math.floor(Math.random() * 15) + 0,
                title: this.props.tenantName == UIConstants.Tenant.Invoice ? p.email : p.upn,
                text: p.name,
                tertiaryText: JSON.stringify(p),
                showSecondaryText: true,
            };
            return persona;
        });
    }
}
