import {
    FeedbackAgeGroup,
    FeedbackAuthenticationType,
    FeedbackHostPlatformType,
    FeedbackPolicyValue,
    FeedbackUiType,
    IFeedbackInitOptions,
    OCVInitialization
} from '@coherence-design-system/controls';
import { CoherenceTheme } from '@coherence-design-system/styles';

function onDismissPrint(isFeedbackSent?: boolean) {
    console.log('isFeedbackSent: ', isFeedbackSent);
}

function onErrorPrint(errorMessage?: string) {
    console.log('error in sending feedback: ', errorMessage);
}

function onSuccessPrint(clientFeedbackId?: string) {
    console.log('clientFeedbackId : ', clientFeedbackId);
}

function attachDiagnosticsLogs(diagnosticsUploadId: string, _diagnosticsEndpoint: string) {
    console.log('diagnosticsUploadId: ', diagnosticsUploadId);
}

function supportCallback() {
    console.log('supportCallback');
}

const env = process.env.NODE_ENV === 'production' ? 'Prod' : 'Int';
export const ocvInit = (handleOcvButtonFocus: () => void) => {
    return {
        appId: 2412,
        env: env,
        scriptUrl: '/officebrowserfeedback.min.js',
        stylesUrl: '/officebrowserfeedback.min.css',
        intlUrl: '/intl/',
        onError: (error: string) => {
            alert('An error has occured during submission: ' + error);
            handleOcvButtonFocus();
        },
        telemetryGroup: { featureArea: 'Portal site' },
        onDismiss: (submitted: boolean) => {
            if (submitted) {
                alert('Your feedback has been submitted');
            }
            handleOcvButtonFocus();
        }
    } as OCVInitialization;
};

export const InitialFeedbackData: IFeedbackInitOptions = {
    appId: 2412,
    clientName: 'OcvFeedbackDemoHostingApp',
    isProduction: process.env.NODE_ENV === 'production',
    authenticationType: FeedbackAuthenticationType.AAD,
    ageGroup: FeedbackAgeGroup.Adult,
    callbackFunctions: {
        attachDiagnosticsLogs: attachDiagnosticsLogs,
        onDismiss: onDismissPrint,
        onSuccess: onSuccessPrint,
        onError: onErrorPrint,
        supportCallback: supportCallback
    },

    feedbackConfig: {
        appData: '{"AppData1":"AppData1 Value", "AppData2":"AppData2 Value"}',
        email: 'euaengineering@microsoft.com',
        featureAreas: ['Features', 'Controls', 'Other'],
        feedbackUiType: FeedbackUiType.SidePane,
        hostPlatform: FeedbackHostPlatformType.Web,
        isDisplayed: false,
        isEmailCollectionEnabled: true,
        isFileUploadEnabled: true,
        isFeedbackForumEnabled: false,
        isMyFeedbackEnabled: false,
        isScreenshotEnabled: true,
        isScreenRecordingEnabled: true,
        myFeedbackUrl: 'https://euaaccessportaldev.microsoft.com/',
        privacyUrl: 'https://privacy.microsoft.com/en-us/privacystatement',
        retentionDurationDays: 30,
        complianceChecks: {
            connectedExperiences: FeedbackPolicyValue.Enabled,
            policyAllowFeedback: FeedbackPolicyValue.Enabled,
            policyAllowSurvey: FeedbackPolicyValue.Enabled,
            policyAllowScreenshot: FeedbackPolicyValue.Disabled,
            policyAllowContact: FeedbackPolicyValue.Disabled,
            policyAllowContent: FeedbackPolicyValue.Disabled
        }
    },
    telemetry: {
        audience: 'Microsoft',
        audienceGroup: 'TestAudienceGroup',
        channel: 'CC',
        clientCountryCode: 'GB',
        deviceId: 'deviceId',
        deviceType: 'deviceType',
        errorClassification: 'errorClassification',
        errorCode: 'errorCode',
        errorName: 'errorName',
        featureArea: 'FeatureArea',
        flights: 'flights',
        flightSource: 'flightSource',
        fundamentalArea: 'fundamentalArea',
        installationType: 'installationType',
        isLogIncluded: false,
        isUserSubscriber: true,
        officeArchitecture: 'x64',
        officeBuild: 'officeBuild',
        officeEditingLang: 1,
        officeUILang: 2,
        osBitness: 64,
        osBuild: '10.1.23.5',
        osUserLang: 3,
        platform: 'string',
        processSessionId: 'processSessionId',
        ringId: 4,
        sku: 'server',
        sourceContext: 'Source',
        systemManufacturer: 'Micro',
        systemProductName: 'Test Productname',
        diagnosticsUploadId: 'd0000000-0000-0000-0000-000000000000'
    },
    userId: ' loggableUserId',
    tenantId: 'd0000000-0000-0000-0000-000000000000',
    themeOptions: { baseTheme: CoherenceTheme }
};
