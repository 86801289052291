import React from 'react';
import {
    IColumn,
    Fabric,
    DetailsListLayoutMode,
    SelectionMode,
    ShimmeredDetailsList,
    Label,
    Link,
    mergeStyles,
    IDropdownOption,
    ProgressIndicator,
    Spinner, SpinnerSize, Icon, DialogType, getId, MessageBar, MessageBarType, Dropdown, TextField,
} from '@fluentui/react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { DefaultButton, IButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { RouteComponentProps } from 'react-router-dom';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import AccessRequestDetails from '../Request/AccessRequestDetails';
import AccessHistory from '../Request/AccessHistory';
import { ExternalAADRoles, MercuryRole, Role, RoleDetail, RoleResponse } from '../../shared/models/Role.model';
import { AdminViewFilter } from '../../shared/models/AdminViewFilter.model';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import { AccessRequestModel, AccessRequestRemovalByAdminModel, RequestType } from '../../shared/models/UserAccess.model';
import { ModalDialog } from '../ModalDialog';
import { DialogModel } from '../../shared/models/Dialog.model';
import { AttributePicker } from '../Request/AttributePicker';
import ConfigurationAPI from '../../shared/api/Configuration.api';
import JSONToCSVConvertor from '../../shared/functions/ExportToCSV';
import { BusinessGroupResponse } from '../../shared/models/BusinessGroup.model';
import { roleMockData } from '../../shared/mock/RoleMockData';
import ViewPermissions from '../../shared/components/ViewPermissions'
enum GridType {
    Approved = 'Approved',
    InProgress = 'InProgress'
}
export interface UserAccessList {
    key: number;
    userName: string;
    role: string;
    requestedOn: string;
    status: string;
    remarks: string;
}

export type CSVDetails = {
    RequestorAlias: string;
    RequestorPrincipal: string;
    RequestId: string;
    StartDate: string;
    EndDate: string;
    RequestDate: string;
    Status: string;
    ApprovalHistory: any;
    Notes: any;
    Role: string;
    RoleId: string;
    AttributeName: any;
    AttributeValue: any;
    RequestedBy: string;
    UserEmail: string;
    UserUPN: string;
}

export type ExternalAADCSVDetails = {
    RoleAssignmentID?: string;
    RequestId?: string;
    UserAlias: string;
    UserEmail: string;
    RoleId: string;
    Role: string;
    AttributeName: any;
    AttributeValue: any;
    RequestDate?: string;
    StartDate?: string;
    EndDate?: string;
    RequestedBy: string;
    ApprovalHistory: any;
    Notes: any;
}
export interface AdminProps {
    applicationData: any;
    getAppData: any;
}

export interface AdministrationState {
    userItems: [];
    selectedPageIndex: number;
    userDetails: IUser;
    showModal: boolean;
    showHistoryModal: boolean;
    requestId: string;
    allItems: any;
    originalAllItems: any;
    dataLoaded: boolean;
    columnCollection: any;
    filterText: string;
    data: any;
    RoleDetails: RoleResponse;
    selectedRole?: Role;
    selectedMercuryRoleId: string;
    approvalHistory: [];
    approverNotes: [];
    searchRequest: [];
    searchBoxInput: string;
    searchIcon: string;
    pickerCtrlKey: number;
    selectedPrincipalId: string;
    showRemoveConfirmation: boolean;
    removeInProgress: boolean;
    roleToRemove: any;
    modalDialogContent: any;
    message: string,
    messageType: MessageBarType,
    autoHideMessage: boolean,
    showMessage: boolean,
    showRemoveRequestConfirmation: boolean,
    requestIdToRemove: string,
    RoleOption: any,
    showAssets : boolean,
    selectedRoleValue: string,
    isAdmin: boolean,
    vlAppsRoleGroupConfig: BusinessGroupResponse;
    enableRoleGrouping: boolean;
    selectedGroup: string;
    exportedItems: any;
    status: string;
    requestedBy: string;
    accessRequests: AccessRequestModel[];
    MercuryRoles: MercuryRole[];
    tenantName: string;
    cosmosRoleDefinitions: ExternalAADRoles[];
    isValidEmail: boolean;
    isCSVExport: boolean;
    hasRoleBasedPermissions: boolean;
    clickedBusinessGrp: string;
    clickedRoleName: string;
    clickedRoleId: string;
    showPermissionsModal: boolean;
}

type AdministrationProps = AdminProps
    & RouteComponentProps<{}>;

export class Administration extends React.Component<AdministrationProps>  {
    private tenantData: ITenant = null;
    allInProgressItems = [];
    allProvisionedItems = [];
    private removeBtnRefList = [];
    private removeBtnRef;
    private _roleDropdownItems: IDropdownOption[] = [];
    searchBoxInput: React.RefObject<unknown>;
    dafaultFilter: AdminViewFilter = { principalId: null, roleId: null, userCount:1000 };
    private removeRequestBtnRefList = [];
    private removeRequestBtnRef;

    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    };
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private alertMsgCloseBtnRef = React.createRef<IButton>();


    constructor(props: any) {
        super(props);
    }
    public state: AdministrationState = {
        userDetails: null,
        userItems: [],
        selectedPageIndex: 0,
        showModal: false,
        showHistoryModal: false,
        requestId: '',
        allItems: [],
        originalAllItems: [],
        dataLoaded: true,
        columnCollection: [],
        data: null,
        filterText: GridType.Approved,
        RoleDetails: { Data: [], IsSuccess: false, IsDataLoaded: true },
        selectedRole: { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] },
        selectedMercuryRoleId: '',
        searchBoxInput: '',
        approvalHistory: null,
        approverNotes: null,
        searchRequest: null,
        searchIcon: 'Search',
        pickerCtrlKey: 0,
        selectedPrincipalId: null,
        showRemoveConfirmation: false,
        removeInProgress: false,
        roleToRemove: null,
        modalDialogContent: this._modalDialogContent,
        message: '',
        messageType: MessageBarType.info,
        autoHideMessage: true,
        showMessage: false,
        showRemoveRequestConfirmation: false,
        requestIdToRemove: null,
        RoleOption: [],
        showAssets : true,
        selectedRoleValue: '0',
        isAdmin: false,
        enableRoleGrouping: false,
        vlAppsRoleGroupConfig: { Data: null, IsSuccess: false, IsDataLoaded: false },
        selectedGroup: '0',
        exportedItems: [],
        status: GridType.Approved,
        requestedBy: '',
        accessRequests: null,
        MercuryRoles: [],
        tenantName: null,
        cosmosRoleDefinitions: [],
        isValidEmail: true,
        isCSVExport: false,
        hasRoleBasedPermissions: false,
        clickedBusinessGrp: '',
        clickedRoleName: '',
        clickedRoleId: '',
        showPermissionsModal:false
    }
    private getProvisionedColumnDefintion = () => {
        let provisionColumnDef = [{ key: 'user', name: 'User', fieldName: 'user', minWidth: 120, maxWidth: 170, isResizable: true, onRender: this._onRenderItemColumn }]
        if (this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) {
            provisionColumnDef.push({ key: 'businessGroup', name: 'Business Group', fieldName: 'businessGroup', minWidth: 300, maxWidth: 300, isResizable: false, onRender: this._onRenderItemColumn });
        }
        provisionColumnDef.push({ key: 'role', name: 'Role', fieldName: 'role', minWidth: 300, maxWidth: 300, isResizable: false, onRender: this._onRenderItemColumn });
        provisionColumnDef.push({ key: 'startDate', name: 'Start Date', fieldName: 'startDate', minWidth: Utility.getDynamicColumnSize(80), maxWidth: Utility.getDynamicColumnSize(150), isResizable: false, onRender: this._onRenderItemColumn });
        if (this.tenantData?.TenantName != UIConstants.Tenant.MSSales && this.tenantData?.TenantName != UIConstants.Tenant.Mercury) {
            provisionColumnDef.push({ key: 'expiryDate', name: 'Expiry Date', fieldName: 'expiryDate', minWidth: Utility.getDynamicColumnSize(60), maxWidth: Utility.getDynamicColumnSize(120), isResizable: false, onRender: this._onRenderItemColumn });
        }
        if (!this.state.enableRoleGrouping && this.tenantData?.TenantName != UIConstants.Tenant.MSSales && this.tenantData?.TenantName != UIConstants.Tenant.Mercury && !this.tenantData?.isExternalAADTenant) {
            provisionColumnDef.push({ key: 'lastAccessedDate', name: 'Last Accessed Date', fieldName: 'lastAccessedDate', minWidth: Utility.getDynamicColumnSize(70), maxWidth: Utility.getDynamicColumnSize(140), isResizable: false, onRender: this._onRenderItemColumn });
        }
        provisionColumnDef.push({ key: 'status', name: '', fieldName: 'status', minWidth: 60, maxWidth: 100, isResizable: false, onRender: this._onRenderItemColumn });
        if (this.state.hasRoleBasedPermissions) {
            provisionColumnDef.push({ key: 'permissions', name: '', fieldName: 'permissions', minWidth: 100, maxWidth: 100, isResizable: false, onRender: this._onRenderItemColumn })
        }
        if (this.state.isAdmin) {
            provisionColumnDef.push({ key: 'remove', name: '', fieldName: 'remove', minWidth: 50, maxWidth: 70, isResizable: false, onRender: this._onRenderItemColumn });
        }
        provisionColumnDef.push({ key: 'accessHistory', name: '', fieldName: 'accessHistory', minWidth: 135, maxWidth: 135, isResizable: false, onRender: this._onRenderItemColumn });
        return provisionColumnDef;
    }
    private getInProgressColumnDefintion = () => {
        let inProgressColumnDef = [{ key: 'user', name: 'User', fieldName: 'user', minWidth: 180, maxWidth: 220, isResizable: true, onRender: this._onRenderItemColumn }]
        inProgressColumnDef.push({ key: 'role', name: 'Role', fieldName: 'role', minWidth: 300, maxWidth: 300, isResizable: false, onRender: this._onRenderItemColumn });
        if (this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) {
            inProgressColumnDef.push({ key: 'businessGroup', name: 'Business Group', fieldName: 'businessGroup', minWidth: 300, maxWidth: 300, isResizable: false, onRender: this._onRenderItemColumn });
            if (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) {
                inProgressColumnDef.splice(3, 0, { key: 'requested for', name: 'Requested For', fieldName: 'requested for', minWidth: 200, maxWidth: 200, isResizable: true, onRender: this._onRenderItemColumn });
            }
        }
        inProgressColumnDef.push({ key: 'requestDate', name: 'Requested Date', fieldName: 'requestDate', minWidth: Utility.getDynamicColumnSize(100), maxWidth: Utility.getDynamicColumnSize(200), isResizable: false, onRender: this._onRenderItemColumn });
        inProgressColumnDef.push({ key: 'status', name: '', fieldName: 'status', minWidth: 100, maxWidth: 100, isResizable: false, onRender: this._onRenderItemColumn });
        if (this.state.isAdmin) {
            inProgressColumnDef.push({ key: 'removeRequest', name: '', fieldName: 'requestId', minWidth: 50, maxWidth: 70, isResizable: false, onRender: this._onRenderItemColumn });
        }
        inProgressColumnDef.push({ key: 'accessHistory', name: '', fieldName: 'accessHistory', minWidth: 135, maxWidth: 135, isResizable: false, onRender: this._onRenderItemColumn });
        return inProgressColumnDef;
    }
    public async componentDidMount() {
        let tenantName = (this.props.match.params as any).tenantName;
        this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === tenantName.toLowerCase())[0];
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let columnCollection = this.getProvisionedColumnDefintion();

        this.setState({ userDetails: user, columnCollection: columnCollection });
        this.onInit(this.tenantData);
    }
    private async getProvisionedUsersForTenant(filter?: AdminViewFilter) {
        const { enableRoleGrouping, selectedGroup, vlAppsRoleGroupConfig, cosmosRoleDefinitions } = this.state;
        let tenantId = this.tenantData?.TenantId;
        if (tenantId) {
            filter = filter ? filter : this.dafaultFilter;
            filter.userCount = 1000;
            let provisionedUsers = AccessAPI.getProvisionedRequestData(tenantId, filter);
            let authActivity = enableRoleGrouping || this.tenantData?.TenantName == UIConstants.Tenant.MSSales || this.tenantData?.TenantName == UIConstants.Tenant.Mercury || this.tenantData?.isExternalAADTenant ? null : AccessAPI.getAuthActivityLog(tenantId);
            this.allProvisionedItems = [];
            Promise.all([provisionedUsers, authActivity]).then((responses) => {
                let authActivityResponse = (responses[1] == "null" || responses[1] == null) ? [] : responses[1];
                let provisionedUserDetails = (responses[0] == "null" || responses[0] == null) ? [] : responses[0];

                let provisioned = this.getTransformedData(provisionedUserDetails, authActivityResponse)
                if (this.state.tenantName == UIConstants.Tenant.Mercury) {
                    provisioned = this.updateRoleName(provisioned)
                }

                this.allProvisionedItems.push(...provisioned);
                if (this.state.enableRoleGrouping) {
                    let provisionedItems = [];
                    let businessGroupName = filter.roleId != null && selectedGroup != '0' ? vlAppsRoleGroupConfig?.Data.businessGroups?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName : null;
                    let data = filter.roleId != null ? this.allProvisionedItems?.filter(x => x.roleId == filter.roleId) : this.allProvisionedItems;
                    data.forEach(item => {
                        item.businessGroup.split(",").forEach(attribute => {
                            if (businessGroupName == null || attribute == businessGroupName)
                                provisionedItems.push({
                                    user: item.user,
                                    userId: item.userId,
                                    roleAssignmentId: item.roleAssignmentId,
                                    role: item.role,
                                    nextPageUrl: item.nextPageUrl,
                                    businessGroup: attribute,
                                    startDate: item.startDate,
                                    expiryDate: item.expiryDate,
                                    status: 'Complete',
                                    requestType: RequestType.Add,
                                    roleId: item.roleId,
                                    attributes: item.attributes
                                })
                        });
                    });
                    provisionedItems = filter.roleId != null && businessGroupName != null ? provisionedItems?.filter(x => x.businessGroup == businessGroupName) : provisionedItems;
                    this.setState({
                        allItems: provisionedItems,
                        originalAllItems: provisionedItems,
                        dataLoaded: true,
                        columnCollection: this.getProvisionedColumnDefintion(),
                        totalCount: provisionedItems.length
                    });
                }
                else if (this.tenantData?.isExternalAADTenant) {
                    let provisionedItems = [];
                    let businessGroupName = filter.roleId != null && selectedGroup != '0' ? cosmosRoleDefinitions?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName : null;
                    let data = filter.roleId != null ? this.allProvisionedItems?.filter(x => x.roleId == filter.roleId) : this.allProvisionedItems;
                    data.forEach(item => {
                        
                            if (businessGroupName == null || item.businessGroup == businessGroupName)
                                provisionedItems.push({
                                    user: item.user,
                                    userId: item.userId,
                                    roleAssignmentId: item.roleAssignmentId,
                                    role: item.role,
                                    nextPageUrl: item.nextPageUrl,
                                    businessGroup: item.businessGroup,
                                    startDate: item.startDate,
                                    expiryDate: item.expiryDate,
                                    status: 'Complete',
                                    requestType: RequestType.Add,
                                    roleId: item.roleId,
                                    attributes: item.attributes
                                })
                    });
                    provisionedItems = filter.roleId != null && businessGroupName != null ? provisionedItems?.filter(x => x.businessGroup == businessGroupName) : provisionedItems;
                    this.setState({
                        allItems: provisionedItems,
                        originalAllItems: provisionedItems,
                        dataLoaded: true,
                        columnCollection: this.getProvisionedColumnDefintion(),
                        totalCount: provisionedItems.length
                    });
                }
                else {
                    this.setState({
                        allItems: this.allProvisionedItems,
                        originalAllItems: this.allProvisionedItems,
                        dataLoaded: true,
                        columnCollection: this.getProvisionedColumnDefintion(),
                        totalCount: this.allProvisionedItems.length
                    });
                }
            });
        }
        else {
            //error
        }
    }

    private updateRoleName(provisioned:any) {
        let { MercuryRoles,selectedRole } = this.state;
        let rolesToDisplay;
        let MercuryRole;
        MercuryRoles.forEach(x => x.role.forEach((y, index) => {
            if (y.roleName == UIConstants.PermissionTypes.MercuryInforma && x.permissionGroup != UIConstants.PermissionTypes.MercuryInforma ) {
                x.role.splice(index,1)
            }
        }))

        let userIDs = provisioned.map(x => x.userId);
        userIDs = userIDs.filter((y, index) => userIDs.indexOf(y) == index);

        userIDs.forEach(userID => {

            if (provisioned.filter(x => x.role == 'Statutory Shadow All' && x.userId == userID ).length > 0) {
                provisioned.splice(provisioned.findIndex(x => x.role == 'Statutory ALL Reporting' && x.userId == userID), 1)
            }

            if (provisioned.filter(x => x.role == 'Statutory Shadow' && x.userId == userID).length > 0) {
                provisioned.splice(provisioned.findIndex(x => x.role == 'Statutory Reporting' && x.userId == userID), 1)
            }

            if (provisioned.filter(x => x.role == 'SMSG Area FP&A PLAN' && x.userId == userID).length > 0) {
                provisioned.splice(provisioned.findIndex(x => x.role == 'SMSG Area FP&A' && x.userId == userID), 1)
            }

            if (provisioned.filter(x => x.role == 'BG FP&A PLAN' && x.userId == userID).length > 0) {
                provisioned.splice(provisioned.findIndex(x => x.role == 'BG FP&A' && x.userId == userID), 1)
            }

            if (provisioned.filter(x => x.role == 'Consolidated FP&A PLAN' && x.userId == userID).length > 0) {
                provisioned.splice(provisioned.findIndex(x => x.role == 'Consolidated' && x.userId == userID), 1)
            }

            if (provisioned.filter(x => x.role == 'External Reporting' && x.userId == userID).length > 0) {
                provisioned.splice(provisioned.findIndex(x => x.role == 'Consolidated FP&A PLAN' && x.userId == userID), 1);
            }
        })

        provisioned.forEach(x => {
            if (x.role == UIConstants.PermissionTypes.MercuryInforma) {
                MercuryRole = MercuryRoles.filter(y => y.role.filter(z => z.roleId == x.roleId).length > 0).find(x => x.permissionGroup == UIConstants.PermissionTypes.MercuryInforma);
            }
            else
            {
                MercuryRole = MercuryRoles.filter(y => y.role.filter(z => z.roleId == x.roleId).length > 0)[0];
            }

            if (x.role == 'Statutory Shadow All' || x.role == 'Statutory Shadow') {
                x.businessGroup = 'Statutory: True';
                if (x.role == 'Statutory Shadow') {
                    x.attributes[0].attributeName = 'Specific Companies'
                    x.attributes.push({
                        attributeName: 'Statutory reporting access to Shadow Company data for legal entity reporting (includes access to the Statutory Balance Sheet and Statutory P&L perspectives)',
                        attributeValues: [{ code: 'True', description: 'True', isRemove: false }]
                    })
                }
                else{
                    x.attributes.push({
                        attributeName: 'Statutory reporting access to Shadow Company data for legal entity reporting (includes access to the Statutory Balance Sheet and Statutory P&L perspectives)',
                        attributeValues: [{ code: 'True', description: 'True', isRemove: false }]
                    });
                }
            }
            else if (x.role == 'BG FP&A PLAN') {
                x.attributes[0].attributeName = 'Functional/Exec Org Summary'
            }
            else if (x.role == 'SMSG Area FP&A PLAN') {
                x.attributes[0].attributeName = 'Geography'
            }
            else if (x.role == 'Statutory Reporting') {
                x.attributes[0].attributeName = 'Specific Companies'
            }
            else if (x.role == 'External Reporting') {
                x.attributes.push({
                    attributeName: 'External Reporting',
                    attributeValues: [{ code: 'True', description: 'True', isRemove: false }]
                }); 
            }

            x.role = MercuryRole.permissionGroup != MercuryRole.permissionLevel ? MercuryRole.permissionGroup + '-' + MercuryRole.permissionLevel : MercuryRole.permissionGroup;
            x.roleId = MercuryRole.permissionGroup + '-' + MercuryRole.permissionLevel;
            x.roleId = x.roleId.replaceAll('/', '|');
        })

        rolesToDisplay = provisioned.filter((provisionedRole, index) => provisioned.findIndex(x => x.role == provisionedRole.role && x.userId == provisionedRole.userId) === index);
        if (selectedRole?.id && selectedRole?.id != '0') {
           rolesToDisplay = rolesToDisplay.filter(x => x.role == selectedRole.id)
        }

        return rolesToDisplay
    }
    private getTransformedData(provisionedUserDetails: any, authActivity: any) {
        return provisionedUserDetails.map((x) => {
            if ( !x?.condition?.startsWith('(') && !x?.condition?.endsWith(')') ) {
                let wrappedCondition = x.condition?.padStart(x.condition.length + 1, '(')
                x.condition = wrappedCondition?.padEnd(wrappedCondition.length + 1, ')')
            }
            var startSubString = this.tenantData?.isExternalAADTenant ? "" : x?.condition?.split("@Environment.CurrentUTCDateTime")[1]?.substring(
                x?.condition.split("@Environment.CurrentUTCDateTime")[1].indexOf("'") + 1,
                x?.condition.split("@Environment.CurrentUTCDateTime")[1].lastIndexOf("'")).replaceAll("\'", "");
            var endSubString = this.tenantData?.isExternalAADTenant ? "" : x?.condition?.split("@Environment.CurrentUTCDateTime")[2]?.substring(
                x?.condition.split("@Environment.CurrentUTCDateTime")[2].indexOf("'") + 1,
                x?.condition.split("@Environment.CurrentUTCDateTime")[2].lastIndexOf("'")).replaceAll("\'", "");
            if (startSubString && endSubString) {
                var startSubStringDate: any = this.tenantData?.isExternalAADTenant ? new Date() : new Date(startSubString);
                var endSubStringDate: any = this.tenantData?.isExternalAADTenant ? new Date() : new Date(endSubString);
                var diff = endSubStringDate - startSubStringDate;
                var startDate = diff > 0 ? startSubString : endSubString;
                var endDate = diff > 0 ? endSubString : startSubString;
            }
            var splittedCondition = x.condition.split("||");
            var FiscalCyclesAttributeString = splittedCondition.filter(x => x.indexOf("FiscalCycles") > 0);
            var FiscalCyclesAttributeValue = FiscalCyclesAttributeString && FiscalCyclesAttributeString.length > 0 ? FiscalCyclesAttributeString[0].match(/\{(.*)\}/) : null;

            var companyAttributeString = splittedCondition.filter(x => x.indexOf("Company") > 0);
            var companyAttributeValue = companyAttributeString && companyAttributeString.length > 0 ? companyAttributeString[0].match(/\{(.*)\}/) : null;

            var poTypeAttributeString = splittedCondition.filter(x => x.indexOf("TypeResource") > 0);
            var poTypeAttributeValue = poTypeAttributeString && poTypeAttributeString.length > 0 ? poTypeAttributeString[0].match(/\{(.*)\}/) : null;

            var systemAttributeString = splittedCondition.filter(x => x.indexOf("System") > 0);
            var systemAttributeValue = systemAttributeString && systemAttributeString.length > 0 ? systemAttributeString[0].match(/\{(.*)\}/) : null;

            var lobAttributeString = splittedCondition.filter(x => x.indexOf("LineOfBusiness") > 0);
            var lobAttributeValue = lobAttributeString && lobAttributeString.length > 0 ? lobAttributeString[0].match(/\{(.*)\}/) : null;

            var areaAttributeString = splittedCondition.filter(x => x.indexOf("AreaResource") > 0);
            var areaAttributeValue = areaAttributeString && areaAttributeString.length > 0 ? areaAttributeString[0].match(/\{(.*)\}/) : null;

            var operationsDetailAttributeString = splittedCondition.filter(x => x.indexOf("OperationsDetail") > 0);
            var operationsDetailAttributeValue = operationsDetailAttributeString && operationsDetailAttributeString.length > 0 ? operationsDetailAttributeString[0].match(/\{(.*)\}/) : null;

            var countryAttributeString = splittedCondition.filter(x => x.indexOf("CountryResource") > 0);
            var countryAttributeValue = countryAttributeString && countryAttributeString.length > 0 ? countryAttributeString[0].match(/\{(.*)\}/) : null;

            var regionAttributeString = splittedCondition.filter(x => x.indexOf("RegionResource") > 0);
            var regionAttributeValue = regionAttributeString && regionAttributeString.length > 0 ? regionAttributeString[0].match(/\{(.*)\}/) : null;

            var DefaultReportingSubsidiaryAttributeString = splittedCondition.filter(x => x.indexOf("DefaultReportingSubsidiary") > 0);
            var DefaultReportingSubsidiaryAttributeValue = DefaultReportingSubsidiaryAttributeString && DefaultReportingSubsidiaryAttributeString.length > 0 ? DefaultReportingSubsidiaryAttributeString[0].match(/\{(.*)\}/) : null;

            var SubsidiaryAttributeString = splittedCondition.filter(x => x.indexOf(".SubsidiaryResource") > 0);
            var SubsidiaryAttributeValue = SubsidiaryAttributeString && SubsidiaryAttributeString.length > 0 ? SubsidiaryAttributeString[0].match(/\{(.*)\}/) : null;

            var BusinessAttributeString = splittedCondition.filter(x => x.indexOf("BusinessResource") > 0);
            var BusinessAttributeValue = BusinessAttributeString && BusinessAttributeString.length > 0 ? BusinessAttributeString[0].match(/\{(.*)\}/) : null;

            var ProgramFamilyAttributeString = splittedCondition.filter(x => x.indexOf("ProgramFamily") > 0);
            var ProgramFamilyAttributeValue = ProgramFamilyAttributeString && ProgramFamilyAttributeString.length > 0 ? ProgramFamilyAttributeString[0].match(/\{(.*)\}/) : null;

            var ProgramAttributeString = splittedCondition.filter(x => x.indexOf("ProgramResource") > 0);
            var ProgramAttributeValue = ProgramAttributeString && ProgramAttributeString.length > 0 ? ProgramAttributeString[0].match(/\{(.*)\}/) : null;

            var AccessTypeEUAttributeString = splittedCondition.filter(x => x.indexOf("AccessTypeEU") > 0);
            var AccessTypeEUAttributeValue = AccessTypeEUAttributeString && AccessTypeEUAttributeString.length > 0 ? AccessTypeEUAttributeString[0].match(/\{(.*)\}/) : null;

            var AccessTypeBUAttributeString = splittedCondition.filter(x => x.indexOf("AccessTypeBU") > 0);
            var AccessTypeBUAttributeValue = AccessTypeBUAttributeString && AccessTypeBUAttributeString.length > 0 ? AccessTypeBUAttributeString[0].match(/\{(.*)\}/) : null;

            var AccessTypeBIAttributeString = splittedCondition.filter(x => x.indexOf("AccessTypeBI") > 0);
            var AccessTypeBIAttributeValue = AccessTypeBIAttributeString && AccessTypeBIAttributeString.length > 0 ? AccessTypeBIAttributeString[0].match(/\{(.*)\}/) : null;

            var FunctionAttributeString = splittedCondition.filter(x => x.indexOf("Function") > 0);
            var FunctionAttributeValue = FunctionAttributeString && FunctionAttributeString.length > 0 ? FunctionAttributeString[0].match(/\{(.*)\}/) : null;

            var AccessTypeDataAttributeString = splittedCondition.filter(x => x.indexOf("AccessTypeData") > 0);
            var AccessTypeDataAttributeValue = AccessTypeDataAttributeString && AccessTypeDataAttributeString.length > 0 ? AccessTypeDataAttributeString[0].match(/\{(.*)\}/) : null;

            var AccessTypeReliaAttributeString = splittedCondition.filter(x => x.indexOf("AccessTypeRelia") > 0);
            var AccessTypeReliaAttributeValue = AccessTypeReliaAttributeString && AccessTypeReliaAttributeString.length > 0 ? AccessTypeReliaAttributeString[0].match(/\{(.*)\}/) : null;

            var AreaReliaAttributeString = splittedCondition.filter(x => x.indexOf("AreaRelia") > 0);
            var AreaReliaAttributeValue = AreaReliaAttributeString && AreaReliaAttributeString.length > 0 ? AreaReliaAttributeString[0].match(/\{(.*)\}/) : null;

            var AccessTypeSOAttributeString = splittedCondition.filter(x => x.indexOf("AccessTypeSO") > 0);
            var AccessTypeSOAttributeValue = AccessTypeSOAttributeString && AccessTypeSOAttributeString.length > 0 ? AccessTypeSOAttributeString[0].match(/\{(.*)\}/) : null;

            var AccessTypeFinanceAttributeString = splittedCondition.filter(x => x.indexOf("AccessTypeFinance") > 0);
            var AccessTypeFinanceAttributeValue = AccessTypeFinanceAttributeString && AccessTypeFinanceAttributeString.length > 0 ? AccessTypeFinanceAttributeString[0].match(/\{(.*)\}/) : null;

            var BusinessGroupAttributeString = splittedCondition.filter(x => x.indexOf("BusinessGroup") > 0);
            var BusinessGroupAttributeValue = BusinessGroupAttributeString && BusinessGroupAttributeString.length > 0 ? BusinessGroupAttributeString[0].match(/\{(.*)\}/) : null;
            var attributes = [];
            if (!this.state.isCSVExport && this.tenantData.TenantName == UIConstants.Tenant.Mercury) {
                if (Object.entries(x.attributes).length > 0) {
                    let values = [];
                    for (var attr of Object.values(x.attributes)[0] as any) {
                        values.push({ code: attr.split(':')[0], description: attr.split(':')[1], isRemove: false })
                    }
                    attributes.push({
                        attributeName: Object.keys(x.attributes)[0],
                        attributeValues: values
                    });
                }
            }
            else {
                if (FiscalCyclesAttributeValue && FiscalCyclesAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Fiscal Cycles',
                        attributeValues: FiscalCyclesAttributeValue[1]
                    });
                }
                if (companyAttributeValue && companyAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Company',
                        attributeValues: companyAttributeValue[1]
                    });
                } if (poTypeAttributeValue && poTypeAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Type',
                        attributeValues: poTypeAttributeValue[1]
                    });
                } if (systemAttributeValue && systemAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'System',
                        attributeValues: systemAttributeValue[1]
                    });
                } if (lobAttributeValue && lobAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Line Of Business',
                        attributeValues: lobAttributeValue[1]
                    });
                } if (areaAttributeValue && areaAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Area',
                        attributeValues: areaAttributeValue[1]
                    });
                } if (operationsDetailAttributeValue && operationsDetailAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'OperationsDetail',
                        attributeValues: operationsDetailAttributeValue[1]
                    });
                } if (countryAttributeValue && countryAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Country',
                        attributeValues: countryAttributeValue[1]
                    });
                }
                if (regionAttributeValue && regionAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Region',
                        attributeValues: regionAttributeValue[1]
                    });
                }
                if (DefaultReportingSubsidiaryAttributeValue && DefaultReportingSubsidiaryAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'DefaultReportingSubsidiary',
                        attributeValues: DefaultReportingSubsidiaryAttributeValue[1]
                    });
                }
                if (SubsidiaryAttributeValue && SubsidiaryAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Subsidiary',
                        attributeValues: SubsidiaryAttributeValue[1]
                    });
                }
                if (BusinessAttributeValue && BusinessAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Business',
                        attributeValues: BusinessAttributeValue[1]
                    });
                }
                if (BusinessGroupAttributeValue && BusinessGroupAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'BusinessGroup',
                        attributeValues: BusinessGroupAttributeValue[1]
                    });
                }
                if (ProgramFamilyAttributeValue && ProgramFamilyAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'ProgramFamily',
                        attributeValues: ProgramFamilyAttributeValue[1]
                    });
                }
                if (ProgramAttributeValue && ProgramAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Program',
                        attributeValues: ProgramAttributeValue[1]
                    });
                }
                if (AccessTypeEUAttributeValue && AccessTypeEUAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'AccessType',
                        attributeValues: AccessTypeEUAttributeValue[1]
                    });
                }
                if (AccessTypeBUAttributeValue && AccessTypeBUAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'AccessType',
                        attributeValues: AccessTypeBUAttributeValue[1]
                    });
                }
                if (AccessTypeBIAttributeValue && AccessTypeBIAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'AccessType',
                        attributeValues: AccessTypeBIAttributeValue[1]
                    });
                }
                if (FunctionAttributeValue && FunctionAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Function',
                        attributeValues: FunctionAttributeValue[1]
                    });
                }
                if (AccessTypeDataAttributeValue && AccessTypeDataAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'AccessType',
                        attributeValues: AccessTypeDataAttributeValue[1]
                    });
                }
                if (AccessTypeReliaAttributeValue && AccessTypeReliaAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'AccessType',
                        attributeValues: AccessTypeReliaAttributeValue[1]
                    });
                }
                if (AreaReliaAttributeValue && AreaReliaAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'Area',
                        attributeValues: AreaReliaAttributeValue[1]
                    });
                }
                if (AccessTypeSOAttributeValue && AccessTypeSOAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'AccessType',
                        attributeValues: AccessTypeSOAttributeValue[1]
                    });
                }
                if (AccessTypeFinanceAttributeValue && AccessTypeFinanceAttributeValue.length > 0) {
                    attributes.push({
                        attributeName: 'AccessType',
                        attributeValues: AccessTypeFinanceAttributeValue[1]
                    });
                }
                else {
                    attributes.push({
                        attributeValues: []
                    });
                }
            }
            var userAliasAttributeString = splittedCondition.filter(x => x.indexOf("UserAlias") > 0);
            var userAliasAttributeValue = userAliasAttributeString && userAliasAttributeString.length > 0 ? userAliasAttributeString[0].match(/\{'(.*)'\}/) : null;
            let userAlias = userAliasAttributeValue && userAliasAttributeValue.length > 0 ? userAliasAttributeValue[1] : " ";
            let filteredAuthActivity = authActivity?.filter(y => y.UserAlias == userAlias);
            return {
                externalAADRoleAssignmentID: this.tenantData.isExternalAADTenant ? x.id : '-',
                user: (this.tenantData?.isExternalAADTenant ? (x.attributes != null && x.attributes.UserAlias != null && x.attributes.UserAlias.length > 0 ? x.attributes.UserAlias[0]: " ") : userAlias),
                userId: x.principalId,
                roleAssignmentId: this.tenantData.isExternalAADTenant? '-': x?.id,
                nextPageUrl: x?.nextPageURl,
                role: x.roleName,
                businessGroup: (this.state.enableRoleGrouping) ? attributes?.find(x => x?.attributeName == 'BusinessGroup')?.attributeValues.replaceAll("'", "") : (this.tenantData?.isExternalAADTenant && x.attributes != null && x.attributes.BusinessGroup != null && x.attributes.BusinessGroup.length>0 ? x.attributes.BusinessGroup[0] : null),
                startDate: this.tenantData?.isExternalAADTenant ? new Date(x.startDate).toLocaleString().split(",")[0] : startDate ? new Date(startDate).toLocaleString().split(" ")[0].replace(",", ""):'NA',
                expiryDate: this.tenantData?.isExternalAADTenant ? new Date(x.endDate).toLocaleString().split(",")[0] :endDate ? new Date(endDate).toLocaleString().split(" ")[0].replace(",", ""):'NA',
                status: 'Complete',
                requestType: RequestType.Add,
                roleId: x.roleDefinitionId,
                attributes: (this.tenantData.TenantName != UIConstants.Tenant.Mercury) ? attributes[0].attributeValues.length === 0 ? 'NA' : attributes : (this.state.isCSVExport) ? attributes[0].attributeValues.length === 0 ? 'NA' : attributes : attributes,
                lastAccessedDate: filteredAuthActivity?.length == 0 ? '-' : new Date(filteredAuthActivity[0].LastAccessedDate).toLocaleString().split(" ")[0].replace(",", "")
            };
        });
    }

    private async getInProgressUsersForTenant(filter?: AdminViewFilter) {
        const { selectedGroup, vlAppsRoleGroupConfig, enableRoleGrouping, RoleDetails } = this.state;

        let tenantId = this.tenantData?.TenantId;
        if (tenantId) {
            filter = filter ? filter : this.dafaultFilter;
            filter.userCount = 0
            let inProgressUsers = AccessAPI.getInprogressRequestData(tenantId, filter);
            this.allInProgressItems = [];
            Promise.all([inProgressUsers]).then((responses) => {
                let inProgress = responses[0].map((x) => {
                    return {
                        user: x?.requestorAlias,
                        userUPN: x?.requestorUPN,
                        role: x?.role!=null ? x?.role?.roleName : null,
                        roleId: x?.role?.roleId,
                        userId: x?.requestorPrincipal,
                        requestDate: new Date(x.requestedDate).toLocaleString().split(",")[0],
                        status: x.status,
                        requestType: x.requestType,
                        requestedBy: x.requestedBy?.split("@")[0],
                        requestId: x.id,
                        attributes: x.role?.attributes,
                        businessGroup: (this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) ? x.role?.attributes.find(x => x.attributeName == 'BusinessGroup')?.attributeValues[0].code.replaceAll("'", "") : null,
                        properties: this.tenantData?.isExternalAADTenant ? x.properties : null,
                        requestedFor: (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) ? x.properties?.onBehalfExternalUser : null
                    }
                })

                this.allInProgressItems.push(...inProgress);
                if (this.tenantData.TenantName == UIConstants.Tenant.FDL) {
                    let data = this.allInProgressItems.filter(x => x.role != null)
                    this.setState({
                        allItems: data,
                        originalAllItems: data, dataLoaded: true,
                        columnCollection: this.getInProgressColumnDefintion()
                    });
                }
                else if (this.state.enableRoleGrouping) {
                    let inProgessItems = [];
                    let businessGroupName = filter.roleId != null && selectedGroup != '0' ? vlAppsRoleGroupConfig?.Data.businessGroups?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName : null;
                    let data = filter.roleId != null ? this.allInProgressItems?.filter(x => x.roleId == filter.roleId) : this.allInProgressItems;
                    inProgessItems = filter.roleId != null && businessGroupName != null ? data?.filter(x => x.businessGroup == businessGroupName) : data;
                    this.setState({
                        allItems: inProgessItems,
                        originalAllItems: inProgessItems, dataLoaded: true,
                        columnCollection: this.getInProgressColumnDefintion()
                    });
                } else {
                    this.setState({
                        allItems: this.allInProgressItems,
                        originalAllItems: this.allInProgressItems, dataLoaded: true,
                        columnCollection: this.getInProgressColumnDefintion()
                    });
                }
            });
        }
        else {
            //error
        }
    }
    private _onRenderItemColumn = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
        const itemClass = mergeStyles({
            selectors: {
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                },
            },
        });
        if (column.key === 'accessHistory') {
            return (
                <div className={itemClass}>
                    <Link role="button" aria-label={`${item.status} ${item.requestId}`} onClick={() => {
                        this.setState({ showHistoryModal: true, requestId: item.requestId, data: item })
                    }} > View Access History  </Link>
                </div>
            );
        }
        if (column.key === 'status') {
            return (
                <div className={itemClass}>
                     < Link role="button" aria-label={`${item.status} ${item.requestId}`} onClick={() => {
                        this.setState({ showModal: true, requestId: item.requestId, data: item, requestedBy: this.state.status == GridType.Approved ? null : (item?.requestedBy ?? item?.userUPN)?.split("@")[0] });
                    }} > View Details </Link>
                </div>
            );
        }

        if (column.key === 'permissions') {
            return (
                <div className={itemClass}>
                    < Link role="button" aria-label={'permissions'} onClick={() => {
                        this.setState({ showPermissionsModal: true, clickedBusinessGrp: item.businessGroup, clickedRoleName: item.role, clickedRoleId: item.roleId });
                    }} > View Permissions </Link>
                </div>
            );
        }

        if (column.key === 'remove') {
            return (
                <div hidden={!this.state.isAdmin}>
                    <Link role="button" componentRef={(componentRef) => {
                        this.removeBtnRefList[index] = componentRef
                    }} aria-label={`${UIConstants.ButtonText.Remove} ${item.role}`}
                        onClick={() => this.onRemoveClick(item, this.removeBtnRefList[index])} >{UIConstants.ButtonText.Remove}</Link>
                </div>

            );
        }

        if (column.key === 'removeRequest') {
            return (
                <div hidden={!this.state.isAdmin}>
                    <Link role="button" componentRef={(componentRef) => {
                        this.removeRequestBtnRefList[index] = componentRef
                    }} aria-label={`${UIConstants.ButtonText.Remove} ${item.requestId}`}
                        onClick={() => this.onRemoveRequestClick(item, this.removeRequestBtnRefList[index])} >{UIConstants.ButtonText.Remove}</Link>
                </div>

            );
        }
        if (column.key === 'user') {
            return (
                <div title={item.user}>{item.user}</div>
            );
        }

        if (column.key == 'requested for') {
            return (<div>{(!item.role.includes('Bulk Request') && item.requestedFor == null) ? item.requestedBy + '@microsoft.com' : (item.requestedFor != null) ? item.requestedFor : 'On Behalf (Bulk Request)'}</div>)
        }

        return item[column.key];
    };
    private renderMessage = () => {
        let { message, messageType, autoHideMessage } = this.state;
        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds
        if (!this.tenantData?.isExternalAADTenant) {
            setTimeout(() => {
                this.alertMsgCloseBtnRef.current.focus();
            }, 100) // set the focus to close button
        }
        return <div role="alert" aria-label={message} >
            <MessageBar key='Msg' style={{ fontSize: '.875rem' }}
                actions={
                    <div>
                        <IconButton componentRef={this.alertMsgCloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false, setAttributeFocus: false }) }} />
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{message}</span>
            </MessageBar>
        </div>
    };
    private onRemoveClick(item, currentRowRemoveBtnRef) {
        const { roleToRemove } = this.state;
        this.removeBtnRef = currentRowRemoveBtnRef;
        let msgText = item.role.split('-')[0] == UIConstants.PermissionGroups.PLPlanGroup ? UIConstants.Messages.PLPlanRemovalNote +' '+ UIConstants.Messages.RemoveRoleConfirmationAdmin : UIConstants.Messages.RemoveRoleConfirmationAdmin
            msgText = msgText.replace('#RoleName#', item.role).replace('#UserAlias#', item.user)
        this._modalDialogContent = {
            subText: msgText,
            title: UIConstants.MessageBoxTitle.RemoveAccess,
            okButtonText: UIConstants.ButtonText.Ok,
            type: DialogType.normal,
            okAction: () => this.removeRole(item),
            cancelAction: this.closeRemoveConfirmation,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }
        this.setState({ showRemoveConfirmation: true, roleToRemove: item, modalDialogContent: this._modalDialogContent });
    }

    private onRemoveRequestClick(item, currentRowRemoveBtnRef) {
        const { requestIdToRemove } = this.state;
        this.removeRequestBtnRef = currentRowRemoveBtnRef;
        let msgText = UIConstants.Messages.RemoveRoleConfirmationAdmin
            .replace('#RoleName#', item.role).replace('#UserAlias#', item.user)
        this._modalDialogContent = {
            subText: msgText,
            title: UIConstants.MessageBoxTitle.RemoveAccess,
            okButtonText: UIConstants.ButtonText.Ok,
            type: DialogType.normal,
            okAction: () => this.removeAccessRequest(item),
            cancelAction: this.closeRemoveConfirmation,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }

        this.setState({ showRemoveConfirmation: true, requestIdToRemove: item.requestId, modalDialogContent: this._modalDialogContent });
    }

    private removeAccessRequest(item) {
        let tenantId = this.tenantData?.TenantId;
        let requestId = item["requestId"];
        const { selectedRole, selectedPrincipalId } = this.state;
        if (requestId) {
            this.setState({ showRemoveConfirmation: false, dataLoaded: false, removeInProgress: true });

            AccessAPI.RemoveInProgressAccessRequestAdmin(tenantId, requestId, item["userId"]).then((data) => {
                if (data.status && data.status === 200) {
                    this.setState({
                        removeInProgress: false, messageType: MessageBarType.success,
                        message: UIConstants.Messages.RemoveSuccess,
                        showMessage: true, allItems: [], autoHideMessage: false
                    });
                    this.applyFilter(selectedRole, selectedPrincipalId);
                } else {
                    this.setState({
                        removeInProgress: false, messageType: MessageBarType.error,
                        message: UIConstants.Messages.RemoveFailure,
                        showMessage: true, autoHideMessage: false
                    });
                }
            });
        }
    };
    private removeRole(item) {
        const { selectedRole, selectedPrincipalId } = this.state;
        let tenantId = this.tenantData?.TenantId;
        if (item) {
            let requestData: AccessRequestRemovalByAdminModel =
            {
                tenantId : tenantId,
                roleId : item["roleId"],
                roleAssignmentId : item["roleAssignmentId"],
                userId: this.tenantData?.isExternalAADTenant && (item["userId"] === "" || item["userId"] === null) ? item["user"] : item["userId"],
                businessGroup: (this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) ? item["businessGroup"] : null
            };

            if (requestData.roleId.includes(UIConstants.PermissionGroups.TrialBalanceGroup)) {
                requestData.businessGroup = item["businessGroup"]
            }

            this.setState({ showRemoveConfirmation: false, dataLoaded: false, removeInProgress: true });
            AccessAPI.RemoveAccessRequestByAdmin(requestData).then((data) => {
                if (data.status && data.status === 200) {
                    this.setState({
                        removeInProgress: false, messageType: MessageBarType.success,
                        message: UIConstants.Messages.RemoveSuccess,
                        showMessage: true, allItems: [], autoHideMessage: false
                    });
                    this.applyFilter(selectedRole, selectedPrincipalId);
                } else {
                    if (data.message) {
                        this.setState({
                            removeInProgress: false, message: data.message, dataLoaded: true,
                            messageType: MessageBarType.error, showMessage: true, autoHideMessage: false
                        });
                    }
                    else {
                        this.setState({
                            removeInProgress: false, message: UIConstants.Messages.RemoveFailure, dataLoaded: true,
                            messageType: MessageBarType.error, showMessage: true, autoHideMessage: false
                        });
                    }
                }
            });
        }
    };
    onStatusChange = (event, option, index) => {
        if (option.key === 'Approved') {
            this.setState({
                filterText: GridType.Approved, showMessage: false
            });
        }
        else {
            this.setState({
                filterText: GridType.InProgress, showMessage: false,
            });
        }
    }
    onRoleChange = (event, option, index) => {
        const { originalAllItems, RoleOption } = this.state;
        let roleAssignments = [];
        if (option.key == '0') {
            roleAssignments = originalAllItems.filter(x => RoleOption.filter(y => y.key === x.roleId).length > 0);
        } else {
            roleAssignments = originalAllItems.filter(x => x.roleId === option.key);
        }

        this.setState({ selectedRoleValue: option.key, allItems: roleAssignments });
    }
    
    renderRoleFilter = () => {
        const { selectedRole, dataLoaded, selectedRoleValue, selectedMercuryRoleId, enableRoleGrouping, selectedGroup } = this.state;
        let roleId = this.tenantData?.TenantName == UIConstants.Tenant.Mercury ? selectedMercuryRoleId :  selectedRole.id;
        if (enableRoleGrouping || this.tenantData?.isExternalAADTenant) {
            return (<>
                <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg2"}>
                    <Label>{UIConstants.Group}</Label>
                    <select id={'drpGrp'} value={selectedGroup}
                        title={UIConstants.Group} required={true}
                        onChange={this.onBGDropDownChange}
                        disabled={!dataLoaded}
                        style={{ position: 'absolute', height: 38, width: '96%' }}>
                        <option value="0">All Business Groups</option>
                        {enableRoleGrouping && this.getBusinessGroup()?.map(x => { return <option key={'drpgrp' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                        {this.tenantData?.isExternalAADTenant && this.getExternalAADBGs()?.map(x => { return <option key={'drpgrp' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                    </select>
                </div>
                <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg2"}>
                    <Label>Role</Label>
                    <select id={'drpRole'} value={selectedRole.id}
                        title={UIConstants.Role} required={true}
                        onChange={this.onBGRoleDropDownChange}
                        disabled={!dataLoaded}
                        style={{ position: 'absolute', height: 38, width: '96%', }}>
                        <option value="0">--- Select ---</option>
                        {enableRoleGrouping && this.getGroupSpecificRolesForVlApps().map(x => { return <option key={'drpRole' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                        {this.tenantData?.isExternalAADTenant && this.getGroupSpecificExternalAADRoles()?.map(x => { return <option key={'drpRole' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                    </select>
                </div>
            </>);
        }
        else {
            return (<>
                <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg4"}>
                    <Label>Role</Label>
                    <select id={'drpRole'} value={roleId}
                        title={UIConstants.Role} required={true}
                        onChange={this.onRoleDropDownChange}
                        disabled={!dataLoaded}
                        style={{ position: 'absolute', height: 38, width: '96%', }}>
                        <option value="0">{"--- Select ---"}</option>
                        {this.getRoles().map(x => { return <option key={'drpRole' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                    </select>
                </div>
            </>);
        }

    }
    public render(): JSX.Element {
        const { showPermissionsModal, clickedBusinessGrp, clickedRoleName, allItems, filterText, dataLoaded, columnCollection, showModal, showHistoryModal, showMessage,
            selectedRole, RoleDetails, pickerCtrlKey, showRemoveConfirmation, removeInProgress, modalDialogContent,
            selectedPrincipalId, enableRoleGrouping, data, requestId, originalAllItems } = this.state;
        const StatusOptions: IDropdownOption[] = [
            { key: 'Approved', text: 'Approved' },
            { key: 'InProgress', text: 'InProgress' }];
        return (RoleDetails.IsDataLoaded ? <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 50, maxHeight:50 }}>
                {(!dataLoaded) && (!removeInProgress) && <ProgressIndicator label={UIConstants.Messages.Loading} description={UIConstants.Messages.LoadingDescription} />}
                {(removeInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
                {(showMessage) && (dataLoaded) && this.renderMessage()}
            </div>
            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg9"}>
                        <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.UserManagement}</h1></Label>
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg1"}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {!this.tenantData?.HideExport && <PrimaryButton style={{ width: 'max-content', position: 'absolute', marginLeft: window.innerWidth < 400 ? '-15%' : 0 }} title="Export to CSV file" onClick={() => this.exportData(this.tenantData.TenantId)} ><Icon iconName={'ExcelLogo'} />&nbsp;Export</PrimaryButton>}
                        </div>
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg1"}>
                        <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{'   '}</h1></Label>
                    </div>
                </div>
                <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg4"}>
                        <Label><Icon iconName="People" />
                            {'   '}User</Label>
                        {this.tenantData?.isExternalAADTenant
                            ? <TextField placeholder="Search for User Email" styles={{ fieldGroup: [{ height: 40 }] }} onChange={this._onUserEmailChange} value={selectedPrincipalId} />
                            : < PeoplePicker
                            key={pickerCtrlKey}
                            type={PeoplePickerType.Normal}
                            contentType={this.tenantData?.AllowExternalUsers ? PeoplePickerContentType.AllUser : ((this.tenantData?.TenantName != UIConstants.Tenant.MSSales && this.tenantData?.TenantName != UIConstants.Tenant.Mercury) ? PeoplePickerContentType.User : PeoplePickerContentType.NonSCUser)}
                            selectionMode={PeoplePickerSelectionMode.Single}
                            onchange={(items) => { this.onPeoplePickerChange(items) }}
                            isPickerDisabled={!dataLoaded}
                            defaultSelectedItems={[]}
                            {...this.props}
                            setFocusOnPicker={false}
                        ></PeoplePicker>}                            
                    </div>
                    {this.renderRoleFilter()}
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg2"}>
                        <Dropdown
                            selectedKey={filterText}
                            label="Status"
                            options={StatusOptions}
                            disabled={!dataLoaded}
                            onChange={this.onStatusChange}
                        />
                    </div>
                </div>
                <div key='grid-row-loadData' className={"ms-Grid-row"} style={{ paddingLeft: 10, paddingBottom: 10, paddingTop: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg12"}>
                        <PrimaryButton title="Search" onClick={() => { this.validateSearchRequest() }} disabled={!dataLoaded}><Icon iconName={'Search'} />&nbsp;Search</PrimaryButton>
                        <span>&nbsp;</span>
                        <DefaultButton title="Reset" disabled={!dataLoaded} onClick={() => { this.setState({ selectedMercuryRoleId: '', showMessage: false, message: '', selectedRole: { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }, pickerCtrlKey: pickerCtrlKey + 1, filterText: StatusOptions[0].key, allItems: [], selectedGroup: '0', isValidEmail: true, selectedPrincipalId: null }) }} ><Icon iconName={'Reply'} />&nbsp;Reset</DefaultButton>
                    </div>
                </div>
                <div key='grid-row-grid' id={'div-myaccess'} className={"ms-Grid-row"} >
                    <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg12"}>
                        <Label style={{ paddingLeft: 10 }}>To view 'Approved' access - <span style={{ fontSize: 14, fontWeight: 400 }}>{(enableRoleGrouping || this.tenantData?.isExternalAADTenant) ? "please select either a User or Business Group/Role - then click on 'Search' to refresh the search results." : "please select either a User or Role - then click on 'Search' to refresh the search results."}</span></Label>
                        <Label style={{ paddingLeft: 10 }}>To view all 'In Progress' access requests - <span style={{ fontSize: 14, fontWeight: 400 }}>please change the status to 'InProgress' - then click on 'Search' to refresh the search results.</span></Label>
                        <AdminViewGrid dataLoaded={dataLoaded} allItems={allItems}
                            onStatusClick={this.onStatusClick} columnCollection={columnCollection}
                            gridType={filterText}> </AdminViewGrid>
                    </div>
                </div>
                </div>
            {(showModal) && <AccessRequestDetails data={data} isAdminScreen={true} requestId={requestId} requestedBy={this.state.requestedBy} {...this.props} onDismiss={() => { this.setState({ showModal: false }) }} />}
            {(showPermissionsModal) && <ViewPermissions tenantId={this.tenantData?.TenantId} businessGroupName={clickedBusinessGrp} roleName={clickedRoleName} roleId={this.state.clickedRoleId} {...this.props} onDismiss={() => { this.setState({ showPermissionsModal: false }) }} />}
            {(showHistoryModal) && <AccessHistory data={data} {...this.props} onDismiss={() => { this.setState({ showHistoryModal: false }) }} />}
            <br />
            <ModalDialog hidden={!showRemoveConfirmation}
                onDismiss={this.closeRemoveConfirmation}
                dialogContentProps={modalDialogContent}
                modalProps={{
                    titleAriaId: this._labelId,
                    subtitleAriaId: this._subTextId,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                }}
            >
            </ModalDialog>
        </React.Fragment> : <Spinner style={{ position: 'fixed', top: 'calc(50vh - 90px)', left: 'calc(50vw - 90px)' }} size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />

        );


    }
    private closeRemoveConfirmation = (): void => {
        if (this.removeBtnRef) {
            this.removeBtnRef.focus();
        } else if (this.removeRequestBtnRef) {
            this.removeRequestBtnRef.focus();
        }

        this.setState({ showRemoveConfirmation: false, showRemoveRequestConfirmation: false, roleToRemove: null, requestIdToRemove: null });
    };
    private onPeoplePickerChange(items: any) {
        const { selectedRole } = this.state;
        let id = null;
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            id = parsedData.id;
        }
        this.setState({ selectedPrincipalId: id });
        this.setState({ selectedMercuryRoleId:'', selectedRole: { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }, selectedGroup: '0' })
    }
    private _onUserEmailChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        //let filter = /^\w+@[a-zA-Z_.0-9]+?\.[a-zA-Z]{2,3}$/;
        //let result = text.match(filter) != null
        this.setState({ selectedPrincipalId: text, isValidEmail: text.trim().length > 2 });
    };
    private getBusinessGroup = (): IDropdownOption[] => {
        const { vlAppsRoleGroupConfig } = this.state;
        let businessGroups = vlAppsRoleGroupConfig.Data.businessGroups.map(x => { return { key: x.businessGroupId, text: x.businessGroupName } });
        return businessGroups;
    };
    private getExternalAADBGs = (): IDropdownOption[] => {
        const { cosmosRoleDefinitions } = this.state;
        let businessGroups = cosmosRoleDefinitions?.map(x => { return { key: x.businessGroupId, text: x.businessGroupName } });
        businessGroups.sort((a, b) => a.text == "Global_Roles" ? -1 : b.text == "Global_Roles" ? 1 : (a.text.toUpperCase() < b.text.toUpperCase()) ? -1 : 1);
        let uniqueBusinessGroups = [];
        businessGroups.reduce((a, d) => {
            if (!a.includes(d['text'])) {
                uniqueBusinessGroups.push(d);
                a.push(d['text']);
            }
            return a;
        }, []);
        return uniqueBusinessGroups;
    };
    private getGroupSpecificRolesForVlApps = (): IDropdownOption[] => {
        const { RoleDetails, selectedGroup, vlAppsRoleGroupConfig, enableRoleGrouping } = this.state;
        if (selectedGroup!= '0') {
            let roles = enableRoleGrouping ? vlAppsRoleGroupConfig?.Data.businessGroups?.filter(x => x.businessGroupId == selectedGroup)[0]?.roles : [];
            this._roleDropdownItems = [];
            roles?.forEach(item => {
                let role = RoleDetails.Data.find(x => x.id == item.roleId);
                if (role) {
                    this._roleDropdownItems.push({ key: role.id, text: role.name })
                }
            })
        } else {
            this._roleDropdownItems = [];
        }
        return this._roleDropdownItems;
    };
    private getGroupSpecificExternalAADRoles = (): IDropdownOption[] => {
        const { selectedGroup, cosmosRoleDefinitions } = this.state;
        if (selectedGroup.length > 0) {
            this._roleDropdownItems = [];
            let bgName = cosmosRoleDefinitions?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName;
            cosmosRoleDefinitions?.filter(x => x.businessGroupName == bgName).forEach(item => {
                this._roleDropdownItems.push({ key: item.roleId, text: item.roleName })
            })
        } else {
            this._roleDropdownItems = [];
        }
        return this._roleDropdownItems;
    };
    private onStatusClick = (item) => {
        this.setState({ showModal: true, requestId: item.requestId, data: item })
    }
    private getRoles = (): IDropdownOption[] => {
        const { RoleDetails, tenantName, MercuryRoles } = this.state;
        if (tenantName === "Mercury") {
            this._roleDropdownItems = MercuryRoles.map(x => {
                return { key: x.permissionLevelID, text: x.permissionGroup != x.permissionLevel ? x.permissionGroup + "-" + x.permissionLevel : x.permissionGroup }
            });
        }
        else {
            this._roleDropdownItems = RoleDetails.Data.map(x => { return { key: x.id, text: x.name } });
        }
        return this._roleDropdownItems;
    };

    private onRoleDropDownChange = (event: any): void => {
        const { RoleDetails, originalAllItems, MercuryRoles, tenantName, selectedMercuryRoleId } = this.state;
        let currentRole = { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }
        // to hide and show attributes section
        if (event.target.value !== '0') {
            if (tenantName === "Mercury") {
                let role = MercuryRoles.filter(x => x.permissionLevelID == event.target.value)[0];
                this.setState({ selectedMercuryRoleId: role.permissionLevelID });
                currentRole = { id: role.permissionGroup == role.permissionLevel ? role.permissionGroup : role.permissionGroup +"-"+ role.permissionLevel, name: role.permissionGroup + "-" + role.permissionLevel, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }
            }
            else {
                currentRole = RoleDetails.Data.filter(x => x.id === event.target.value)[0];
            }
            // let filteredItems = originalAllItems.filter(x => x.role === currentRole.name);
            this.setState({
                selectedRole: currentRole,
                // allItems: filteredItems,
                searchBoxInput: ''
            });
        }
        else {
            this.setState({ allItems: originalAllItems, selectedRole: currentRole });
        }
    };
    private onBGRoleDropDownChange = (event: any): void => {
        const { RoleDetails, cosmosRoleDefinitions } = this.state;
        let currentRole = { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }
        if (event.target.value !== '0') {
            if (this.tenantData?.isExternalAADTenant) {
                let role = cosmosRoleDefinitions.filter(x => x.roleId == event.target.value)[0];
                currentRole = { id: role.roleId, name: role.roleName, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }
            } else {
                currentRole = RoleDetails.Data.filter(x => x.id === event.target.value)[0];
            }
        }
        this.setState({ selectedRole: currentRole })
    };
    private onBGDropDownChange = (event: any): void => {
        let currentRole = { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }
        this.setState({ selectedGroup: event.target.value, selectedRole: currentRole })
    };
    private applyFilter = (role, selectedPrincipalId) => {
        const { filterText } = this.state;
        this.setState({ dataLoaded: false, allItems: [], originalAllItems: [] });
        let filter: AdminViewFilter = {
            roleId: role.id === "0" ? null : role.id,
            principalId: selectedPrincipalId,
            userCount:1000
        };
        if (filterText === GridType.InProgress) {
            this.getInProgressUsersForTenant(filter);
        } else {
            this.getProvisionedUsersForTenant(filter);
        }
    }
    //private onRefreshClick() {
    //    const { selectedRole, RoleDetails, vlAppsRoleGroupConfig, filterText } = this.state;
    //    let currentRole = RoleDetails?.Data.find(x => x.name == "Administrator");
    //    let selectedBG = vlAppsRoleGroupConfig?.Data?.businessGroups?.filter(x => x.roles[0].roleName == "Administrator")[0].businessGroupId;
    //    if (filterText == GridType.InProgress) {
    //        currentRole = { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }
    //        selectedBG = '0';
    //    }
    //    this.setState({ dataLoaded: false, allItems: [], originalAllItems: [], pickerCtrlKey: this.state.pickerCtrlKey + 1, selectedPrincipalId: null, showMessage: false, message: '', selectedRole: currentRole, selectedGroup: selectedBG })
    //    this.applyFilter(currentRole, null);
    //}
    private validateSearchRequest() {
        const { selectedRole, selectedPrincipalId, enableRoleGrouping, filterText, isValidEmail } = this.state;
        this.setState({ showMessage: false, message: '', status: filterText })
        if (this.tenantData?.isExternalAADTenant && selectedPrincipalId != "" && !isValidEmail) {
            this.setState({ showMessage: true, message: "3 or more characters required for User Email", messageType: MessageBarType.warning, autoHideMessage: false })
        }
        else if (selectedRole.id == '0' && (selectedPrincipalId == null || selectedPrincipalId == "") && filterText == GridType.Approved) {
            if (enableRoleGrouping || this.tenantData?.isExternalAADTenant) {
                this.setState({ showMessage: true, message: "Please select either a User or Business Group/Role to proceed with the search.", messageType: MessageBarType.warning, autoHideMessage: false })
            }
            else {
                this.setState({ showMessage: true, message: "Please select either a User or Role to proceed with the search.", messageType: MessageBarType.warning, autoHideMessage: false })
            }
        }
        else {
            this.applyFilter(selectedRole, selectedPrincipalId);
        }
    }
    private exportData(tenantId: string) {
        const { allItems, enableRoleGrouping, selectedGroup, vlAppsRoleGroupConfig, status, isCSVExport } = this.state;
        this.setState({ isCSVExport:true, showMessage: true, message: "Please wait while we are exporting user access...", messageType: MessageBarType.warning, autoHideMessage: false, exportedItems: [] })
        let searchRequest = [];
        AccessAPI.getApprovalStatusDetailsForTenant(tenantId).then(res => {
            let approval = [];
            let approverNotes=[];
            res.Data && res.Data.requestData.forEach(item => {
                let approvalHistory = '';
                let notes = '';
                item.approvalRequest.approvalHierarchy?.forEach(history => {
                    approvalHistory += ("Level " + history.Level) + (history.IsApproved === null ? (" Pending with " + history.AssignedTo) : (history.IsApproved ? (" Approved by " + history.ActionByAlias + " Approver comments : " + history.ApproverComments) : (" Rejected by " + history.ActionByAlias + " Approver comments : " + history.ApproverComments)) + " on- " + history.ApprovalActionDate) + '\n'
                    notes += ("Level " + history.Level) + (history.Notes != null ? (" : " + history.Notes) : '') + '\n'
                });
                approval[item.requestId] = approvalHistory
                approverNotes[item.requestId] = notes
            });
            if (status === "Approved") {
                let filter: AdminViewFilter = {
                        roleId: null,
                        principalId: null,
                        userCount: 0,
                        isExport: true
                    };
                    let provisionedUsers = AccessAPI.getProvisionedRequestData(tenantId, filter);
                    let accessRequests = AccessAPI.getAccessRequests(this.tenantData.TenantId);
                    let allProvisionedItems = [];
                    Promise.all([provisionedUsers, accessRequests]).then((responses) => {
                        let allAccessRequests = (responses[1] == null) ? null : responses[1];
                        let provisionedUserDetails = (responses[0] == "null" || responses[0] == null) ? [] : responses[0];
                        let authActivity = [];
                        allAccessRequests?.Data?.forEach(item => {
                            if (item.status === "Complete") {
                                searchRequest[item.requestorPrincipal + item.role?.roleId] = searchRequest[item.requestorPrincipal + item.role?.roleId] ?? item.id;
                            }
                        });
                        this.setState({ approvalHistory: approval, searchRequest: searchRequest, approverNotes: approverNotes, accessRequests: allAccessRequests.Data });
                        let provisioned = this.getTransformedData(provisionedUserDetails, authActivity)
                        allProvisionedItems.push(...provisioned);
                        res.Data && this.getCSVfile(allProvisionedItems)
                        this.setState({ exportedItems: allProvisionedItems });
                    });
            }
            else {
                let filter: AdminViewFilter = {
                    roleId: null,
                    principalId: null,
                    userCount: 0
                };
                this.setState({ approvalHistory: approval, approverNotes: approverNotes });
                let inProgressUsers = AccessAPI.getInprogressRequestData(tenantId, filter);
                let allInProgressItems = [];
                Promise.all([inProgressUsers]).then((responses) => {
                    let inProgress = responses[0].map((x) => {
                        return {
                            user: x?.requestorAlias,
                            role: x?.role?.roleName,
                            roleId: x?.role?.roleId,
                            userEmail: x?.requestorEmail,
                            userUPN: x?.requestorUPN,
                            userId: x?.requestorPrincipal,
                            requestDate: new Date(x.requestedDate).toLocaleString().split(",")[0],
                            status: x.status,
                            requestType: x.requestType,
                            requestId: x.id,
                            requestedBy: x.requestedBy?.split("@")[0],
                            attributes: x.role.attributes,
                            businessGroup: (this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) ? x.role.attributes.find(x => x.attributeName == 'BusinessGroup')?.attributeValues[0].code.replaceAll("'", "") : null,
                            bulkRequestUserDetails: x.properties?.bulkRequestUserDetails
                        }
                    })

                    allInProgressItems.push(...inProgress);
                    if (this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) {
                        let inProgessItems = [];
                        let businessGroupName = filter.roleId != null && selectedGroup != '0' ? vlAppsRoleGroupConfig?.Data.businessGroups?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName : null;
                        let data = filter.roleId != null ? allInProgressItems?.filter(x => x.roleId == filter.roleId) : allInProgressItems;
                        inProgessItems = filter.roleId != null && businessGroupName != null ? data?.filter(x => x.businessGroup == businessGroupName) : data;
                        res.Data && this.getCSVfile(inProgessItems);
                    }
                    else {
                        res.Data && this.getCSVfile(allInProgressItems);
                    }
                });
            }
        });
    }
    private getCSVfile(items: any) {
        const { approvalHistory, searchRequest, approverNotes, accessRequests, status } = this.state;
        if (this.tenantData.isExternalAADTenant) {
            let externalAADData: Array<ExternalAADCSVDetails> = [];
            if (status === "Approved") {
                let bulkUploadEntry: AccessRequestModel = null;
                let internalUserRequest: AccessRequestModel = null;
                let approvedAccessRequests = accessRequests?.filter(x => x.status == 'Complete' && x.requestType == 'Add')
                items.forEach(item => {
                    bulkUploadEntry = approvedAccessRequests?.filter(x=>x.properties?.bulkRequestUserDetails?.filter(y => y.userUPN == item.user && y.roleName == item.role).length > 0)[0];
                    if (!bulkUploadEntry) {
                        internalUserRequest = approvedAccessRequests?.filter(x => x.role.roleName == item.role && x.requestorUPN?.split("@")[0] == item.user)[0]
                    }
                    let reqID = bulkUploadEntry ? bulkUploadEntry.id : internalUserRequest?.id
                    externalAADData.push({
                        RoleAssignmentID: item.externalAADRoleAssignmentID,
                        UserAlias: item.user,
                        UserEmail: item.user.includes('@') ? item.user : approvedAccessRequests?.filter(x => x.id == reqID)?.length > 0 ? approvedAccessRequests.filter(x => x.id == reqID)[0].requestorEmail : "NA",
                        RoleId: item.roleId,
                        Role: item.role,
                        AttributeName: 'BusinessGroup',
                        AttributeValue: item.businessGroup,
                        RequestedBy: (bulkUploadEntry) ? (bulkUploadEntry.requestedBy? bulkUploadEntry.requestedBy.split("@")[0] : 'Migrated User' ) : internalUserRequest?.requestedBy?.split("@")[0],
                        StartDate: new Date(item?.startDate).toLocaleString().split(" ")[0].replaceAll(",", ""),
                        EndDate: new Date(item?.expiryDate).toLocaleString().split(" ")[0].replaceAll(",", ""),
                        ApprovalHistory:  approvalHistory[reqID] ?? "NA",
                        Notes: approverNotes[reqID] ?? ''
                    })
                })
            }
            else {
                items.forEach(item => {
                    if (item.bulkRequestUserDetails) {
                        item.bulkRequestUserDetails.forEach(user => {
                            externalAADData.push({
                                RequestId: item.requestId,
                                UserAlias: user.userUPN,
                                UserEmail: user.userUPN,
                                RoleId: item.roleId,
                                Role: user.roleName,
                                AttributeName: 'BusinessGroup',
                                AttributeValue: item.businessGroup,
                                RequestedBy: item.requestedBy,
                                RequestDate: new Date(item?.requestDate).toLocaleString().split(" ")[0].replaceAll(",", ""),
                                ApprovalHistory: approvalHistory[item.requestId] ?? "NA",
                                Notes: approverNotes[item.requestId] ?? ''
                            })
                        })
                    }
                    else {
                        externalAADData.push({
                            RequestId: item.requestId,
                            UserAlias: item.user,
                            UserEmail: item.userEmail,
                            RoleId: item.roleId,
                            Role: item.role,
                            AttributeName: 'BusinessGroup',
                            AttributeValue: item.businessGroup,
                            RequestedBy: item.requestedBy,
                            RequestDate: new Date(item?.requestDate).toLocaleString().split(" ")[0].replaceAll(",", ""),
                            ApprovalHistory: approvalHistory[item.requestId] ?? "NA",
                            Notes: approverNotes[item.requestId] ?? ''
                        })
                    }
                })
            }
            externalAADData.length > 0 && JSONToCSVConvertor(externalAADData, `EUAExport_${this.tenantData.TenantName}_${status}`)
        }
        else {
            let data: Array<CSVDetails> = [];
            items.forEach(item => {
                if (item.status === "Complete") {
                    if (item.attributes === "NA") {
                        data.push({
                            RequestId: searchRequest[item.userId + item.roleId] ?? "Created From Authz",
                            RequestorPrincipal: item.userId,
                            RequestorAlias: item.user,
                            UserEmail: accessRequests?.filter(x => x.id == searchRequest[item.userId + item.roleId])?.length > 0 ? accessRequests.filter(x => x.id == searchRequest[item.userId + item.roleId])[0].requestorEmail : "NA",
                            UserUPN: accessRequests?.filter(x => x.id == searchRequest[item.userId + item.roleId])?.length > 0 ? accessRequests.filter(x => x.id == searchRequest[item.userId + item.roleId])[0].requestorUPN : "NA",
                            RoleId: item.roleId,
                            Role: item.role,
                            AttributeName: "NA",
                            AttributeValue: "NA",
                            RequestedBy: accessRequests?.filter(x => x.id == searchRequest[item.userId + item.roleId])?.length > 0 ? accessRequests.filter(x => x.id == searchRequest[item.userId + item.roleId])[0].requestedBy?.split("@")[0] : "NA",
                            RequestDate: accessRequests?.filter(x => x.id == searchRequest[item.userId + item.roleId])?.length > 0 ? new Date(accessRequests.filter(x => x.id == searchRequest[item.userId + item.roleId])[0].requestedDate).toLocaleString().split(" ")[0].replaceAll(",", "") : '-',
                            StartDate: item?.startDate != 'NA' ?  new Date(item?.startDate).toLocaleString().split(" ")[0].replaceAll(",", "") : 'NA',
                            EndDate: item?.expiryDate != 'NA' ? new Date(item?.expiryDate).toLocaleString().split(" ")[0].replaceAll(",", ""): 'NA',
                            Status: item.status,
                            ApprovalHistory: approvalHistory[searchRequest[item.userId + item.roleId]] ?? "NA",
                            Notes: approverNotes[searchRequest[item.userId + item.roleId]] ?? ''
                        })
                    }
                    else {
                        item.attributes.forEach(attributeItem => {
                            if (attributeItem.attributeValues.length > 0) {
                                data.push({
                                    RequestId: searchRequest[item.userId + item.roleId] ?? "Created From Authz",
                                    RequestorPrincipal: item.userId,
                                    RequestorAlias: item.user,
                                    UserEmail: accessRequests?.filter(x => x.id == searchRequest[item.userId + item.roleId])?.length > 0 ? accessRequests.filter(x => x.id == searchRequest[item.userId + item.roleId])[0].requestorEmail : "NA",
                                    UserUPN: accessRequests?.filter(x => x.id == searchRequest[item.userId + item.roleId])?.length > 0 ? accessRequests.filter(x => x.id == searchRequest[item.userId + item.roleId])[0].requestorUPN : "NA",
                                    RoleId: item.roleId,
                                    Role: item.role,
                                    AttributeName: attributeItem.attributeName ? attributeItem.attributeName : "NA",
                                    AttributeValue: attributeItem.attributeValues ? attributeItem.attributeValues?.replaceAll(",", ",\n").replaceAll("'", "") : "NA",
                                    RequestedBy: accessRequests?.filter(x => x.id == searchRequest[item.userId + item.roleId])?.length > 0 ? accessRequests.filter(x => x.id == searchRequest[item.userId + item.roleId])[0].requestedBy?.split("@")[0] : "NA",
                                    RequestDate: accessRequests?.filter(x => x.id == searchRequest[item.userId + item.roleId])?.length > 0 ? new Date(accessRequests.filter(x => x.id == searchRequest[item.userId + item.roleId])[0].requestedDate).toLocaleString().split(" ")[0].replaceAll(",", "") : '-',
                                    StartDate: item?.startDate != 'NA' ? new Date(item?.startDate).toLocaleString().split(" ")[0].replaceAll(",", "") : 'NA',
                                    EndDate: item?.expiryDate != 'NA' ? new Date(item?.expiryDate).toLocaleString().split(" ")[0].replaceAll(",", "") : 'NA',
                                    Status: item.status,
                                    ApprovalHistory: approvalHistory[searchRequest[item.userId + item.roleId]] ?? "NA",
                                    Notes: approverNotes[searchRequest[item.userId + item.roleId]] ?? ''
                                })
                            }
                        })
                    }
                }
                else {
                    if (item.attributes === "NA" || item.attributes == null || item.attributes?.length == 0) {
                        data.push({
                            RequestId: item.requestId,
                            RequestorPrincipal: item.userId,
                            RequestorAlias: item.user,
                            UserEmail: item.userEmail,
                            UserUPN: item.userUPN,
                            RoleId: item.roleId,
                            Role: item.role,
                            AttributeName: "NA",
                            AttributeValue: "NA",
                            RequestedBy: item.requestedBy,
                            RequestDate: new Date(item?.requestDate).toLocaleString().split(" ")[0].replaceAll(",", ""),
                            StartDate: '-',
                            EndDate: '-',
                            Status: item.status,
                            ApprovalHistory: approvalHistory[item.requestId],
                            Notes: approverNotes[item.requestId]
                        })
                    }
                    else {
                        item.attributes.forEach(attributeItem => {
                            if (item.attributes.length > 0) {
                                let attributes = '';
                                attributeItem.attributeValues.forEach(attributeItem => {
                                    attributes += (attributeItem.code == attributeItem.description) ? attributeItem.description + "," : attributeItem.code + ":" + attributeItem.description + ",";
                                });
                                data.push({
                                    RequestId: item.requestId,
                                    RequestorPrincipal: item.userId,
                                    RequestorAlias: item.user,
                                    UserEmail: item.userEmail,
                                    UserUPN: item.userUPN,
                                    RoleId: item.roleId,
                                    Role: item.role,
                                    AttributeName: attributeItem.attributeName,
                                    AttributeValue: attributes.replace(/,*$/, ""),
                                    RequestedBy: item.requestedBy,
                                    RequestDate: new Date(item?.requestDate).toLocaleString().split(" ")[0].replaceAll(",", ""),
                                    StartDate: '-',
                                    EndDate: '-',
                                    Status: item.status,
                                    ApprovalHistory: approvalHistory[item.requestId],
                                    Notes: approverNotes[item.requestId]
                                })
                            }
                        })
                    }
                }
            });
            data.length > 0 && JSONToCSVConvertor(data, `EUAExport_${this.tenantData.TenantName}_${status}`)
        }
        this.setState({ showMessage: false, message: '' })
    }

    /**
     * Initial onInit function - first entry point
     */
    private onInit = (tenantData: ITenant) => {
        this.setState({ tenantName: tenantData.TenantName, hasRoleBasedPermissions: tenantData?.HasRolePermissions || false })
        if (tenantData) {
            AccessAPI.CheckUserIsAdmin(this.tenantData.TenantId).then(adminResponse => {
                this.setState({ isAdmin: adminResponse });
            });
            if (tenantData.isExternalAADTenant) {
                AccessAPI.getCosmosRoleDefinitions(tenantData.TenantId).then(res => {
                    let roleDefinitions: ExternalAADRoles[] = res;
                    this.setState({ RoleDetails: { Data: roleMockData, IsSuccess: true, IsDataLoaded: true }, cosmosRoleDefinitions: roleDefinitions, hideBj: false })
                });
            }
            else if (tenantData.EnableRoleGrouping == true) {
                let getBusinessGroups = AccessAPI.getBusinessGroupByTenant(tenantData.TenantId);
                let roles = AccessAPI.requestRoleByTenant(tenantData.TenantId);
                Promise.all([getBusinessGroups, roles]).then((responses) => {
                    let rolesforTenant = (responses[1] == "null" || responses[1] == null) ? [] : responses[1];
                    let getBusinessGroupsforTenant = (responses[0] == "null" || responses[0] == null) ? [] : responses[0];
                    this.setState({ RoleDetails: rolesforTenant, vlAppsRoleGroupConfig: getBusinessGroupsforTenant, enableRoleGrouping: true });
                    //this.getProvisionedUsersForTenant(filter);
                });
            }
            else if (tenantData.TenantName.toUpperCase() === "FDL") {
                AccessAPI.requestRoleByTenant(tenantData.TenantId).then(res => {
                    let roleOption = [];
                    if (res.Data && res.Data.length > 0) {
                        res.Data.forEach(roleItem => {
                            if (roleOption.length === 0) {
                                roleOption.push({ id: '0', name: 'All Roles' });
                            }
                            roleOption.push(roleItem);
                        })
                    }
                    let roleOptionList = roleOption.map(x => { return { key: x.id, text: x.name } });
                    this.setState({ RoleDetails: res, RoleOption: roleOptionList });
                    //this.getProvisionedUsersForTenant(filter);
                })
            }

            else if (tenantData.TenantName === "Mercury") {
                let result = [];
                AccessAPI.getMercuryRoles().then(res =>{
                    res.forEach(x => result.push(x));
                    this.setState({ MercuryRoles: result })
                }); 
            }

            else {
                AccessAPI.requestRoleByTenant(tenantData.TenantId, true).then(res => {
                    let assetOption = [];
                    let roleOption = [];
                    if (res.Data && res.Data.length > 0) {
                        let permissionActions = res.Data.forEach(roleItem => {

                            let assetItems = roleItem.permissions[0].actions.filter(x => x.includes(UIConstants.RoleDefinition.UIDisplayFlag));
                            if (assetItems.length > 0) {
                                if (assetOption.length === 0) {
                                    assetOption.push({ id: '0', name: 'All Assets' });
                                }
                                assetOption.push(roleItem);
                            } else {
                                if (roleOption.length === 0) {
                                    roleOption.push({ id: '0', name: 'All Roles' });
                                }
                                roleOption.push(roleItem);
                            }
                        })
                    }
                    let roleOptionList = roleOption.map(x => { return { key: x.id, text: x.name } });
                    this.setState({ RoleDetails: res, RoleOption: roleOptionList });
                    //this.getProvisionedUsersForTenant(filter);
                });// Call API to Get Role
            }
        } else {
            window.location.href = "/";
        }
    };
}
interface AdminViewGridProps {
    gridType,
    allItems,
    onStatusClick,
    dataLoaded,
    columnCollection
}
export const AdminViewGrid: React.FunctionComponent<AdminViewGridProps> = (props) => {
    const [gridData, setgridData] = React.useState<any>([]);
    const [isGridLoading, setIsGridLoading] = React.useState<boolean>(true);
    const [colCollection, setColCollection] = React.useState<any>([]);

    const fetchData = (): void => {
        setIsGridLoading(true);
        setColCollection(props.columnCollection);
        if (props.dataLoaded) {
            setIsGridLoading(false);
            // Sort the items.
            let sortedItems = _copyAndSort(props.allItems, 'user', false);
            setgridData(sortedItems);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [props.allItems]);

    const _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        let isSortedDescending = column.isSortedDescending;
        let sortedItems = [];
        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(gridData, column.fieldName!, isSortedDescending);
        // Reset the items and columns to match the state.

        setgridData(sortedItems);
        let updatedColumnState = colCollection.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        setColCollection(updatedColumnState);

    };
    return (<React.Fragment>
        <Fabric id="admin-user-mgmt-grid" style={{
            display: 'block',
            margin: '0 auto',
        }} >
            <ShimmeredDetailsList
                setKey="items"
                columns={props.columnCollection}
                items={gridData}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="User Management List"
                listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                enableShimmer={isGridLoading}
                onColumnHeaderClick={_onColumnClick}
                detailsListStyles={{ root: { overflow: 'visible' } }}
            />
        </Fabric>
    </React.Fragment>
    );
}
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    if (columnKey === 'startDate' || columnKey === 'expiryDate' || columnKey === 'requestDate' || columnKey === 'lastAccessedDate') {
        return items.slice(0).sort(function (a: T, b: T) {
            var c: any = new Date(a[columnKey] == "-" ? "12/30/2099" : a[columnKey]);
            var d: any = new Date(b[columnKey] == "-" ? "12/30/2099" : b[columnKey]);
            return isSortedDescending ? d - c : c - d;
        });

    } else {
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}
