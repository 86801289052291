import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { TextField, ComboBox, IComboBoxOption, IComboBoxStyles, Label, Link, SelectableOptionMenuItemType, addYears, ShimmeredDetailsList, mergeStyles, IColumn, getId, Fabric, DayOfWeek, IconButton, ProgressIndicator, Text, IButton, MessageBarType, MessageBar, MessageBarButton, Checkbox, DatePicker, defaultDatePickerStrings, DialogType, IDropdownOption, IStyleSet, ILabelStyles, Spinner, SpinnerSize, PrimaryButton, Icon, DefaultButton, ILabelStyleProps, IStyleFunctionOrObject } from '@fluentui/react';
import { Role, RoleResponse } from "../../shared/models/Role.model"
import { useConst } from '@fluentui/react-hooks';
import { ReviewRequestSubmissionModel, RoleDetails, BusinessGroupModel, RoleModel } from '../../shared/models/UserAccess.model';
import { ModalDialog } from '../ModalDialog';
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { DialogModel } from "../../shared/models/Dialog.model";
import { ValidationResponse } from '../../shared/models/ValidationResponse.model';
import _ from "lodash";
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { BusinessGroupResponse } from "../../shared/models/BusinessGroup.model";
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import { Permission, PermissionRender } from '../../shared/models/Mercury';
const labelStyles: Partial<IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>> = {
    root: { marginTop: 7 }
};


const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 350, width: 350 } };
export interface ICreateReviewState {
    userDetails: IUser;
    RoleDetails: RoleResponse;
    selectedRole?: Array<RoleModel>;
    BusinessGroups: any;
    selectedGroup?: BusinessGroupModel[];
    selectedFY: any;
    selectedBusinessGroup: any;
    selectedQuarter: any;
    startDate: any;
    endDate: any;
    showModal: boolean;
    items: any;
    hideDialog: boolean;
    filteredItems: any;
    approvers: Array<string>;
    selectedFYValue: string;
    defaultComboboxValue: any;
    selectedRoleComboboxValue: Array<string>;
    selectedGroupComboboxValue: Array<string>;
    pickerCtrlKey: number;
    tenantData: ITenant;
    modalDialogContent: any;
    description?: string;
    isManagerChecked: boolean;
    isApproverSelected: boolean;
    saveInProgress: boolean;
    showMessage: boolean;
    enableRole: boolean;
    message: string;
    messageType: MessageBarType;
    autoHideMessage: boolean;
    Justification?: string;
    ReviewName?: string;
    enableRoleGrouping: boolean;
    selectedBusinessGroupRole: string;
    BusinessGroupRoleConfig: BusinessGroupResponse;
    permissionMaster: PermissionRender[];
    comboBoxKey1: number;
    comboBoxKey2: number;
    comboBoxKey3: number;
}
type AssetProps = ICreateReviewState &
    RouteComponentProps<{}>;
export type AssetGridDetails = {
    description: string,
    assetName: string,
    entityName: string,
    assetId: string,
    serviceTreeName: string,
    attributes: any,
    permissions: any,
    scopes: any,
    sortOrder: string,
    publisherName: string,
    registrationDate: string,
    entityGroup: any
}
export default class CreateReview extends React.Component<AssetProps> {
    private tenantData: ITenant = null;
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private _roleDropdownItems: IComboBoxOption[] = [];
    private _roleComboboxItems: IComboBoxOption[] = [];
    private _businessgroupDropdownItems: IDropdownOption[] = [];
    private alertMsgCloseBtnRef = React.createRef<IButton>();
    private tenantName = '';
    private tenantId = '';
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    }
    private alertMsg2CloseBtnRef = React.createRef<IButton>();
    public state: ICreateReviewState = {
        userDetails: null,
        RoleDetails: { Data: [], IsSuccess: false, IsDataLoaded: false },
        items: [],
        filteredItems: [],
        selectedRole: [],
        selectedGroup: [],
        BusinessGroups: [],
        selectedBusinessGroup: null,
        selectedFY: '',
        selectedQuarter: '',
        showModal: false,
        approvers: [],
        selectedFYValue: '',
        defaultComboboxValue: "0",
        selectedRoleComboboxValue: [],
        selectedGroupComboboxValue: [],
        tenantData: null,
        pickerCtrlKey: 0,
        modalDialogContent: this._modalDialogContent,
        description: '',
        isManagerChecked: false,
        isApproverSelected: false,
        hideDialog: true,
        saveInProgress: false,
        showMessage: false,
        enableRole: false,
        message: '',
        messageType: MessageBarType.info,
        autoHideMessage: true,
        Justification: '',
        ReviewName: '',
        startDate: '',
        endDate: '',
        enableRoleGrouping: false,
        selectedBusinessGroupRole: '',
        BusinessGroupRoleConfig: { Data: null, IsSuccess: false, IsDataLoaded: false },
        permissionMaster: [],
        comboBoxKey1: 0,
        comboBoxKey2: 100,
        comboBoxKey3: 50
    };

    public constructor(props) {
        super(props);
    }

    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        this.tenantName = (this.props.match.params as any).tenantName;
        this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === this.tenantName.toLowerCase())[0];
        this.tenantId = this.tenantData.TenantId;
        this.onInit(this.tenantData);
    }

    /**
     * UI Render
     */
    public render(): JSX.Element {
        const { showMessage, hideDialog, Justification, ReviewName, RoleDetails, enableRoleGrouping, enableRole, selectedBusinessGroupRole, BusinessGroupRoleConfig, selectedRole, selectedGroup, saveInProgress, startDate, endDate, isManagerChecked, selectedFY, isApproverSelected, description, selectedBusinessGroup, selectedQuarter, pickerCtrlKey } = this.state;
        let BGOptions: IComboBoxOption[] = []
        BGOptions.push({
            key: 'selectAll', text: 'Select All'

        });
        this.getBusinessGroup()?.forEach(attribute => {
            BGOptions.push({
                key: attribute.key, text: attribute.text
            });
        });
        this.getPermissionGroups()?.forEach(attribute => {
            BGOptions.push({
                key: attribute.key, text: attribute.text
            });
        });

        // BGOptions.sort((a, b) => ((a.text < b.text) ? -1 : 1));
        let RoleOptions: IComboBoxOption[] = []
        RoleOptions.push({
            key: 'selectAll', text: 'Select All'

        });
        this.getRoles()?.forEach(attribute => {
            RoleOptions.push({
                key: attribute.key, text: attribute.text
            });
        });
        //RoleOptions.sort((a, b) => ((a.text < b.text) ? -1 : 1));
        return <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 25 }}>
                {(RoleDetails?.IsDataLoaded && !RoleDetails?.IsSuccess) && this.renderMessage(UIConstants.Messages.FetchFailure, MessageBarType.error)}
                {(RoleDetails?.IsDataLoaded && RoleDetails?.IsSuccess && RoleDetails.Data.length === 0 && this.tenantData?.TenantName != UIConstants.Tenant.Mercury) && this.renderMessage(UIConstants.Messages.RoleNotConfigured, MessageBarType.warning)}
                {(showMessage) && this.renderMessage2()}
                {(saveInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
            </div>
            <div id={'div-req-section'} style={{ padding: '1%', paddingTop: '1%' }} >
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg8"}>
                            <Label style={{ paddingLeft: 5, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.CreateReview}</h1></Label>

                        </div>
                    </div>
                    <div key='grid-row-bj' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg8"}>
                            <div >
                                <Label required={true} styles={labelStyles} >{UIConstants.Review.Name}</Label><TextField styles={{ root: { marginTop: 10, width: 500 } }} maxLength={200} value={ReviewName}
                                    onChange={this.onReviewNameChange} required />
                                <Label styles={labelStyles} style={{ float: "left", marginLeft: 5, marginTop: 1 }} >(Recommended Format: FYXX QX Application Name Review - example: FY24 Q3 VL Central Review) </Label>
                                <div>
                                    <Label styles={labelStyles} style={{ float: "left", marginLeft: 5, marginTop: -40 }}>{UIConstants.Review.Description} </Label>
                                    <TextField styles={{ root: { marginTop: 70, width: 500, marginLeft: 5 } }} multiline rows={3} maxLength={500} required value={Justification}
                                        onChange={this.onJustificationChange}
                                        description={(500 - description.length) + ' / 500 Characters left'} />
                                </div>
                                <div key='selection-lbl-row-fiscal' className={"ms-Grid-row"} >
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>

                                        <Label styles={labelStyles} required={true}>{UIConstants.Review.FiscalYear} </Label>
                                        <select id={'drpGrp'} value={selectedFY}
                                            title={UIConstants.Review.FiscalYear} required={true}
                                            onChange={(event) => {
                                                this.setState({
                                                    selectedFY: event.target.value
                                                })
                                            }}
                                            style={{ height: 32, width: window.innerWidth < 400 ? 150 : 250 }}>
                                            <option value="0">-- Select --</option>
                                            <option value="FY24"> FY24 </option>
                                        </select>
                                    </div>
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ float: "left", position: 'absolute', marginLeft: 400 }} >
                                        <Label styles={labelStyles} required={true}>{UIConstants.Review.Quarter}</Label>
                                        <select id={'drpRole'} value={selectedQuarter} required={true}
                                            title={UIConstants.Review.Quarter}
                                            onChange={(event) => {
                                                this.setState({
                                                    selectedQuarter: event.target.value
                                                })
                                            }}
                                            style={{ height: 32, width: window.innerWidth < 400 ? 150 : 250 }}>
                                            <option value="0">-- Select --</option>
                                            <option value="Q1"> Q1 </option>
                                            <option value="Q2"> Q2 </option>
                                            <option value="Q3"> Q3 </option>
                                            <option value="Q4"> Q4 </option>

                                        </select>
                                    </div>

                                </div>
                                <div key='selection-lbl-row-date' className={"ms-Grid-row"} >
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                                        <Label required={true} style={{ marginLeft: 68 }} styles={labelStyles}></Label>
                                        <DatePicker

                                            label="Start Date"
                                            placeholder="Select a date..."
                                            ariaLabel="Select a date"
                                            style={{ width: 250, marginTop: -30 }}
                                            firstDayOfWeek={DayOfWeek.Sunday}
                                            strings={defaultDatePickerStrings}
                                            onSelectDate={date => this.setState({ startDate: date })}

                                            maxDate={addYears(new Date(Date.now()), 1)} value={startDate}
                                        //  onSelectDate={setSelectedDate as (date: Date | null | undefined) => void}
                                        />
                                    </div>
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ float: "left", position: 'absolute', marginLeft: 400 }} >
                                        <Label required={true} style={{ marginLeft: 64 }} styles={labelStyles}></Label>

                                        <DatePicker

                                            label="End Date"
                                            placeholder="Select a date..."
                                            ariaLabel="Select a date"
                                            style={{ width: 250, marginTop: -30 }}
                                            firstDayOfWeek={DayOfWeek.Sunday}
                                            strings={defaultDatePickerStrings}
                                            maxDate={addYears(new Date(Date.now()), 1)}
                                            onSelectDate={date => this.setState({ endDate: date })} value={endDate} />
                                    </div>
                                </div>
                                <div key='selection-lbl-row-perm' className={"ms-Grid-row"} >
                                    {enableRoleGrouping && <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>

                                        <Label styles={labelStyles}>{UIConstants.Group}</Label>
                                        <ComboBox
                                            key={this.state.comboBoxKey1 + 1}
                                            multiSelect
                                            options={BGOptions}
                                            allowFreeform={true}
                                            styles={comboBoxStyles}
                                            selectedKey={this.state.selectedGroupComboboxValue}
                                            defaultSelectedKey={this.state.defaultComboboxValue}
                                            onChange={(event, option) => this.onBGComboboxChange(event, option)}
                                        />

                                    </div>}
                                    {enableRoleGrouping && < div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ float: "left", position: 'absolute', marginLeft: 400 }}  >
                                        <Label styles={labelStyles}>{UIConstants.Role}</Label>
                                        <ComboBox
                                            key={this.state.comboBoxKey3 + 1}
                                            multiSelect
                                            disabled={!enableRole}
                                            options={this._roleComboboxItems}
                                            allowFreeform={true}
                                            styles={comboBoxStyles}
                                            selectedKey={this.state.selectedRoleComboboxValue}
                                            defaultSelectedKey={this.state.defaultComboboxValue}
                                            onChange={(event, option) => this.onRoleComboboxChange(event, option)}
                                        />

                                    </div>}

                                    {!enableRoleGrouping && RoleDetails.IsDataLoaded && this.tenantData?.TenantName != UIConstants.Tenant.Mercury && <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}  >
                                        <Label styles={labelStyles}>{UIConstants.Role}</Label>
                                        <ComboBox
                                            key={this.state.comboBoxKey2 + 1}
                                            multiSelect
                                            options={RoleOptions}
                                            allowFreeform={true}
                                            styles={comboBoxStyles}
                                            selectedKey={this.state.selectedRoleComboboxValue}
                                            defaultSelectedKey={this.state.defaultComboboxValue}
                                            onChange={(event, option) => this.onRoleComboboxChange(event, option)}
                                        />
                                    </div>}
                                    {!enableRoleGrouping && RoleDetails.IsDataLoaded && this.tenantData?.TenantName == UIConstants.Tenant.Mercury && <><div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}  >
                                        <Label styles={labelStyles}>{UIConstants.Role}</Label>
                                        <ComboBox
                                            key={this.state.comboBoxKey1 + 1}
                                            multiSelect
                                            options={BGOptions}
                                            allowFreeform={true}
                                            styles={comboBoxStyles}
                                            selectedKey={this.state.selectedRoleComboboxValue}
                                            defaultSelectedKey={this.state.defaultComboboxValue}
                                            onChange={(event, option) => this.onPermisisionGroupComboboxChange(event, option)}
                                        />
                                    </div>
                                    </>
                                    }
                                </div>
                                {/*  <Label styles={labelStyles} style={{ float: "left", position: 'absolute', marginLeft: 5 }} >(When no specific role or business group is chosen, all users will be included in the review)</Label>*/}
                                <div key='selection-lbl-row-terms' className={"ms-Grid-row"} >
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ marginTop: 50, width: '760px', border: '1px solid', borderColor: '#ddd', background: '#FAF9F9' }}>

                                        <Label styles={labelStyles} style={{ float: "left", marginLeft: 5 }} >(You have two options for this Review: either choose to opt in for the 'Manager Approval' or provide a list of Approvers)</Label>

                                        <div>
                                            <Checkbox styles={{ root: { paddingBottom: 10, width: 20, marginTop: 40 } }} label="Manager" checked={isManagerChecked} disabled={this.state.isApproverSelected}
                                                onChange={(ev, checked) => { this.setState({ isManagerChecked: checked, pickerCtrlKey: this.state.pickerCtrlKey + 1 }) }} />
                                        </div>
                                        <div style={{ marginTop: 2 }}><Label styles={labelStyles} style={{ float: "left", marginLeft: 5 }} >(OR)</Label></div>
                                        <div style={{ marginTop: 50, marginLeft: 5 }} >
                                            <Label ><Icon iconName="People" />
                                                {'   '}Approvers</Label>
                                            <PeoplePicker isPickerDisabled={this.state.isManagerChecked}
                                                key={pickerCtrlKey}
                                                type={PeoplePickerType.Normal}
                                                contentType={PeoplePickerContentType.User}
                                                selectionMode={PeoplePickerSelectionMode.Multiple}
                                                onchange={(items) => { this.onPeoplePickerChange(items) }}
                                                defaultSelectedItems={[]}
                                                {...this.props}
                                                setFocusOnPicker={false}
                                            ></PeoplePicker>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingTop: 50, paddingRight: 400 }}>
                                <DefaultButton onClick={this.onResetClick} > {UIConstants.ButtonText.Reset} </DefaultButton>
                                <span>&nbsp;</span>
                                <PrimaryButton onClick={this.onSubmitClick} >{UIConstants.ButtonText.Submit} </PrimaryButton>
                            </div>
                            <div>
                                <ModalDialog hidden={hideDialog}
                                    onDismiss={this.closeDialog}
                                    dialogContentProps={this.state.modalDialogContent}
                                    modalProps={{
                                        titleAriaId: this._labelId,
                                        subtitleAriaId: this._subTextId,
                                        isBlocking: false,
                                        styles: { main: { maxWidth: 450 } },
                                    }}
                                >
                                </ModalDialog>
                            </div>
                            <div style={{ float: "left", position: 'absolute', marginLeft: 5 }}>
                                <p>
                                    <Link href={`${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.Review}/` + this.tenantName}>
                                        &larr;Go to Access Review Management
                                    </Link>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </React.Fragment >
    };


    private renderMessage = (message: string, messageType: MessageBarType) => {
        setTimeout(() => {
            this.alertMsgCloseBtnRef.current.focus();
        }, 100) // set the focus to close button
        return <div>
            <MessageBar key='FetchMsg'
                actions={
                    <div>
                        <MessageBarButton componentRef={this.alertMsgCloseBtnRef} onClick={() => { this.setState({ showMessage: false }) }} >{UIConstants.ButtonText.Ok}</MessageBarButton>
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                {message}
            </MessageBar>
        </div>
    };

    private renderMessage2 = () => {
        let { message, messageType, autoHideMessage } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds     
        return <div role="alert" aria-label={message} >
            <MessageBar key='SaveMsg' style={{ fontSize: '.875rem' }}
                actions={
                    <div>
                        <IconButton componentRef={this.alertMsg2CloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false, setAttributeFocus: false }) }} />
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                {message}
            </MessageBar>
        </div>
    };
    private ResetUI = (): void => {
        this.setState({
            hideDialog: true,
            hideBj: true,
            showMessage: false,
            ReviewName: null,
            isManagerChecked: false,
            isApproverSelected: false,
            selectedBusinessGroup: null,
            selectedFY: '',
            selectedQuarter: '',
            selectedRole: [],
            Justification: '',
            selectedGroup: [],
            startDate: '',
            endDate: '',
            defaultComboboxValue: "0",
            selectedRoleComboboxValue: [],
            selectedGroupComboboxValue: [],
            selectedBusinessGroupRole: '',
            pickerCtrlKey: this.state.pickerCtrlKey + 1,
            comboBoxKey1: this.state.comboBoxKey1 + 1,
            comboBoxKey2: this.state.comboBoxKey2 + 1,
            comboBoxKey3: this.state.comboBoxKey3 + 1,
            approvers: []
        })
    }
    private onResetClick = (): void => {
        this._modalDialogContent = {
            subText: UIConstants.Messages.ResetConfirmation,
            title: UIConstants.MessageBoxTitle.Reset,
            okButtonText: UIConstants.ButtonText.Reset,
            okAction: this.ResetUI,
            cancelAction: this.closeDialog,
            type: DialogType.normal,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }
        this.setState({ hideDialog: false, modalDialogContent: this._modalDialogContent, defaultComboboxValue: "0" });
    };
    private onSubmitClick = (): void => {
        AccessAPI.IsDuplicateReviewName(this.tenantId, this.state.ReviewName).then(res => {
            if (res) { this.setState({ showMessage: true, messageType: MessageBarType.warning, message: UIConstants.Review.DuplicateNameErrorMsg, autoHideMessage: false }); }
            else {
                let validationResponse = this.ValidateData();

                if (validationResponse.isValid) {
                    this._modalDialogContent = {
                        subText: UIConstants.Messages.SaveConfirmation,
                        title: UIConstants.MessageBoxTitle.SaveAccess,
                        okButtonText: UIConstants.ButtonText.Submit,
                        type: DialogType.normal,
                        okAction: this.saveAccessRequest,
                        cancelAction: this.closeDialog,
                        closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
                    }
                    this.setState({ hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent });// Show confirmation pop-up      
                } else {
                    // Show Error message and skip submission
                    this.setState({ showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
                }
            }
        })
    }
    private onReviewNameChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue.length <= 200) {
            this.setState({ ReviewName: newValue || '', showMessage: false });
        } else {
            this.setState({ showMessage: true, message: UIConstants.Messages.ReviewNameMaxLength, messageBarType: MessageBarType.warning })
        }
    };
    private onJustificationChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue.length <= 1000) {
            this.setState({ Justification: newValue || '', showMessage: false });
        } else {
            this.setState({ showMessage: true, message: UIConstants.Messages.JustificationMaxLength, messageBarType: MessageBarType.warning })
        }
    };
    private ValidateData = (): ValidationResponse => {

        const { startDate, endDate, ReviewName, selectedFY, selectedQuarter, Justification, isApproverSelected, isManagerChecked } = this.state;
        let response: ValidationResponse = { isValid: true };
        let errorMessage = null;
        // Validation case 1 : check if dates are selected
        if (ReviewName == '') {
            errorMessage = UIConstants.Review.NameErrorMsg
        }
        else if (Justification == '') {
            errorMessage = UIConstants.Review.DescriptionErrorMsg
        }
        else if (selectedFY == '' && selectedQuarter == '') {
            errorMessage = UIConstants.Review.FiscalQuarterErrorMsg
        }
        else if (startDate == '' && endDate != '') {
            errorMessage = UIConstants.Review.StartDateErrorMsg
        }
        else if (endDate == '' && startDate != '') {
            errorMessage = UIConstants.Review.EndDateErrorMsg
        }
        else if (endDate == '' && startDate == '') {
            errorMessage = UIConstants.Review.StartEndDateErrorMsg
        }
        else if (endDate <= startDate) {
            errorMessage = UIConstants.Review.DateErrorMsg
        }
        else if (endDate.getDate() - startDate.getDate() < 3) {
            errorMessage = UIConstants.Review.DateDurationErrorMsg
        }
        else if (isApproverSelected == false && isManagerChecked == false) {
            errorMessage = UIConstants.Review.ApproversErrorMsg
        }
        if (errorMessage) {
            return { isValid: false, validationMessage: errorMessage }
        }
        else {
            return response; // Validation Success , Allow user to Submit
        }
    }
    private saveAccessRequest = (): void => {
        const { Justification, ReviewName, startDate, endDate, isManagerChecked, selectedGroup, selectedFY, selectedQuarter, selectedRole, approvers } = this.state
        this.setState({ hideDialog: true, saveInProgress: true, showMessage: false });
        let data: ReviewRequestSubmissionModel = {
            reviewName: ReviewName,
            role: selectedRole.length == 0 ? null : selectedRole,
            businessGroup: selectedGroup.length == 0 ? null : selectedGroup,
            fiscalYear: selectedFY,
            quarter: selectedQuarter,
            startDate: startDate,
            endDate: endDate,
            isManagerchecked: isManagerChecked,
            tenantId: this.tenantId,
            tenantName: this.tenantName,
            businessJustification: Justification,
            approvers: approvers,
            users: []
        };
        this.setState({ hideDialog: true, saveInProgress: true, showMessage: false });
        AccessAPI.saveReviewCreationAsync(data)
            .then(async res => {
                if (res.status && res.status === 200) {
                    this.setState({
                        saveInProgress: false,
                        messageType: MessageBarType.success,
                        message: UIConstants.Messages.ReviewSaveSuccess,
                        showMessage: true,
                        hideBj: true,
                        Justification: '',
                        autoHideMessage: false,
                        RoleDescription: null,
                        ReviewName: '',
                        startDate: '',
                        endDate: '',
                        isManagerChecked: false,
                        isApproverSelected: false,
                        selectedBusinessGroup: '',
                        selectedFY: '',
                        selectedQuarter: '',
                        selectedRole: [],
                        selectedGroup: [],
                        defaultComboboxValue: "0",
                        selectedRoleComboboxValue: [],
                        selectedGroupComboboxValue: [],
                        selectedBusinessGroupRole: [],
                        pickerCtrlKey: this.state.pickerCtrlKey + 1,
                        comboBoxKey1: this.state.comboBoxKey1 + 1,
                        comboBoxKey2: this.state.comboBoxKey2 + 1,
                        comboBoxKey3: this.state.comboBoxKey3 + 1,
                        approvers: []
                    });

                } else if ((res.status && res.status !== 200) || res.message) {
                    this.setState({
                        saveInProgress: false,
                        messageType: MessageBarType.warning,
                        message: UIConstants.Messages.SaveFailure,
                        showMessage: true,
                        hideBj: false,
                        Justification: Justification,
                        autoHideMessage: false
                    });

                    this.ResetUI(); // to reset the state of request screen
                }
            })
    }
    private setInitialState() {
        this.setState({
            startDate: '',
            endDate: '', showMessage: true, messageType: MessageBarType.success, message: "Hello", autoHideMessage: false
        })
    };
    private onBGComboboxChange: any = (event, val) => {
        let selectedValue: Array<string> = this.state.selectedGroupComboboxValue;
        if (val.selected) {
            if (val.key == 'selectAll') {
                if (this.state.selectedGroup.length > 0) { this.state.selectedGroup.splice(0, this.state.selectedGroup.length) }
                selectedValue.push(val.key);
                this.getBusinessGroup()?.forEach(attribute => {
                    let bg = new BusinessGroupModel;
                    bg.Id = attribute.key.toString();
                    bg.Name = attribute.text;
                    this.state.selectedGroup.push(bg)
                    selectedValue.push(attribute.key.toString());
                });
                this.setState({ selectedGroupComboboxValue: selectedValue });
            }
            else {
                let bg = new BusinessGroupModel;
                bg.Id = val.key;
                bg.Name = val.text;
                this.state.selectedGroup.push(bg)
                selectedValue.push(val.key);
                this.setState({ selectedGroupComboboxValue: selectedValue });
            }
        }
        else {
            if (val.key == 'selectAll') {
                this.state.selectedGroup.splice(0, this.state.selectedGroup.length)
                let selectedGroups = this.state.selectedGroupComboboxValue
                selectedGroups.splice(0, this.state.selectedGroupComboboxValue.length)
                this.setState({ selectedGroupComboboxValue: selectedGroups });
            }
            else {
                _.remove(this.state.selectedGroup, x => x.Id == val.key);
                _.remove(selectedValue, x => x == val.key)
                this.setState({ selectedGroupComboboxValue: selectedValue });
            }
        }
        if (this.state.selectedGroup.length == 1) { this.setState({ enableRole: true, comboBoxKey3: this.state.comboBoxKey3 + 1 }); this.getGroupSpecificRolesForBGApps(); }
        else { this.setState({ enableRole: false }) }
    };
    private onPermisisionGroupComboboxChange: any = (event, val) => {
        let selectedValue: Array<string> = this.state.selectedRoleComboboxValue;
        if (val.selected) {
            if (val.key == 'selectAll') {
                if (this.state.selectedRole.length > 0) { this.state.selectedRole.splice(0, this.state.selectedRole.length) }
                selectedValue.push(val.key);
                this.state.permissionMaster.forEach(perm => {
                    selectedValue.push(perm.permissionGroupID);
                    perm.role.forEach(role => {
                        if (perm.permissionGroup == 'P&L and P&L Plan') {
                            if (role.roleName != 'Informa') {
                                let bg = new RoleModel;
                                bg.Id = role.roleId;
                                bg.Name = perm.permissionGroup + '-' + perm.permissionLevel + '/ ' + role.roleName;
                                this.state.selectedRole.push(bg)
                            }
                        }
                        else {
                            let bg = new RoleModel;
                            bg.Id = role.roleId;
                            if (perm.permissionGroup == 'Trial Balance & Statutory')
                                bg.Name = perm.permissionGroup + '-' + perm.permissionLevel + '/ ' + role.roleName;
                            else
                                bg.Name = perm.permissionGroup
                            this.state.selectedRole.push(bg)
                        }
                    })
                })
                this.setState({ selectedRoleComboboxValue: selectedValue });
            }
            else {
                let perm = this.state.permissionMaster.filter(x => x.permissionGroupID == val.key)
                perm.forEach(permission => {
                    permission.role.forEach(role => {
                        if (permission.permissionGroup == 'P&L and P&L Plan') {
                            if (role.roleName != 'Informa') {
                                let bg = new RoleModel;
                                bg.Id = role.roleId;
                                bg.Name = permission.permissionGroup + '-' + permission.permissionLevel + '/ ' + role.roleName;
                                this.state.selectedRole.push(bg)
                            }
                        }
                        else {
                            let bg = new RoleModel;
                            bg.Id = role.roleId;
                            if (permission.permissionGroup == 'Trial Balance & Statutory')
                                bg.Name = permission.permissionGroup + '-' + permission.permissionLevel + '/ ' + role.roleName;
                            else
                                bg.Name = permission.permissionGroup
                            this.state.selectedRole.push(bg)
                        }
                    })
                })
                selectedValue.push(val.key);
                this.setState({ selectedRoleComboboxValue: selectedValue });
            }
        }
        else {
            if (val.key == 'selectAll') {
                this.state.selectedRole.splice(0, this.state.selectedRole.length)
                let selectedRoles = this.state.selectedRoleComboboxValue
                selectedRoles.splice(0, this.state.selectedRoleComboboxValue.length)
                this.setState({ selectedRoleComboboxValue: selectedRoles });
            }
            else {
                _.remove(this.state.selectedRole, x => x.Name.includes(val.text));
                _.remove(selectedValue, x => x == val.key)
                this.setState({ selectedRoleComboboxValue: selectedValue });
            }
        }
        //if (this.state.selectedRole.length == 1) { this.setState({ enableRole: true, comboBoxKey2: this.state.comboBoxKey2 + 1 }); }
        //else { this.setState({ enableRole: false }) }
    };
    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    }
    private onRoleDropDownChange = (value: any): void => {
        if (value == '0') {
            this.setState({
                selectedRole: { id: '1', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], comboBoxKey1: this.state.comboBoxKey1 + 1, comboBoxKey2: this.state.comboBoxKey2 + 1 }

            });
        }

        else {
            const { RoleDetails } = this.state;
            let currentRole = RoleDetails.Data.filter(x => x.id === value)[0];
            this.setState({
                selectedRole: { id: currentRole.id, name: currentRole.name, scopes: currentRole.scopes, description: currentRole.description, permissions: currentRole.permissions, sortOrder: currentRole.sortOrder, attributes: currentRole.attributes, comboBoxKey1: this.state.comboBoxKey1 + 1, comboBoxKey2: this.state.comboBoxKey2 + 1 }

            });
        }
    };
    private onRoleComboboxChange: any = (event, val) => {
        const { RoleDetails, selectedGroup, BusinessGroupRoleConfig, enableRoleGrouping } = this.state;
        let selectedValues: Array<string> = this.state.selectedRoleComboboxValue;
        let currentRole = RoleDetails.Data.filter(x => x.id === val.key)[0];
        if (val.selected) {
            if (val.key == 'selectAll') {
                if (this.state.selectedRole.length > 0) { this.state.selectedRole.splice(0, this.state.selectedRole.length) }
                selectedValues.push(val.key);
                if (enableRoleGrouping) {
                    let roles = BusinessGroupRoleConfig?.Data.businessGroups?.filter(x => x.businessGroupId == selectedGroup[0].Id)[0]?.roles;
                    roles?.forEach(item => {
                        let bg = new RoleModel;
                        bg.Id = item.roleId;
                        bg.Name = item.roleName;
                        this.state.selectedRole.push(bg)
                        selectedValues.push(item.roleId);
                    })
                }
                else {
                    this.getRoles()?.forEach(role => {
                        let bg = new RoleModel;
                        bg.Id = role.key.toString();
                        bg.Name = role.text;
                        this.state.selectedRole.push(bg);
                        selectedValues.push(role.key.toString());
                    });
                }
                this.setState({ selectedRoleComboboxValue: selectedValues, comboBoxKey3: this.state.comboBoxKey3 + 1 });
            }
            else {
                let bg = new RoleModel;
                bg.Id = currentRole.id;
                bg.Name = currentRole.name;
                this.state.selectedRole.push(bg)
                selectedValues.push(val.key);
                this.setState({ selectedRoleComboboxValue: selectedValues, comboBoxKey3: this.state.comboBoxKey3 + 1 });
            }
        }
        else {
            if (val.key == 'selectAll') {
                this.state.selectedRole.splice(0, this.state.selectedRole.length)
                let selectedRoles = this.state.selectedRoleComboboxValue
                selectedRoles.splice(0, this.state.selectedRoleComboboxValue.length)
                this.setState({ selectedRoleComboboxValue: selectedRoles });
            }
            else {
                _.remove(this.state.selectedRole, x => x.Id == val.key);
                _.remove(selectedValues, x => x == val.key)
                this.setState({ selectedRoleComboboxValue: selectedValues, comboBoxKey3: this.state.comboBoxKey3 + 1 });
            }
            //if (this.state.selectedRole.length == 1) { this.setState({ comboBoxKey2: this.state.comboBoxKey2 + 1 }); }
        }

    };
    private onInit = (tenantData: ITenant) => {
        this.setState({
            enableRoleGrouping: false
        });
        if (this.tenantData?.TenantName == UIConstants.Tenant.Mercury) {
            let mercuryData = AccessAPI.GetMercuryData();
            Promise.all([mercuryData]).then((responses) => {
                let permissionData: Permission[] = [...responses[0]];
                let permissionMasterList: PermissionRender[] = [];
                permissionData.forEach(permissionTypeItem => {
                    permissionTypeItem.permission.forEach(permGrpItem => {
                        permGrpItem.permissionLevels.forEach(permLevelItem => {
                            permissionMasterList.push({
                                permissionTypeID: permissionTypeItem.permissionTypeID,
                                permissionType: permissionTypeItem.permissionType,
                                permissionTypesortOrder: permissionTypeItem.sortOrder,
                                permissionGroupID: permGrpItem.permissionGroupID,
                                permissionGroup: permGrpItem.permissionGroup,
                                permissionGroupDescription: permGrpItem.permissionGroupDescription,
                                permissionGroupsortOrder: permGrpItem.sortOrder,
                                permissionLevelID: permLevelItem.permissionLevelID,
                                permissionLevel: permLevelItem.permissionLevel,
                                permissionLevelSortOrder: permLevelItem.sortOrder,
                                permissionLevelDescription: permLevelItem.permissionLevelDescription,
                                allowEdit: permLevelItem.allowEdit,
                                approvalPolicy: permLevelItem.approvalPolicy,
                                attribute: permLevelItem.attribute,
                                isActive: permLevelItem.isActive,
                                role: permLevelItem.role
                            })
                        });
                    })
                });
                permissionMasterList = _.orderBy(permissionMasterList, ['permissionTypesortOrder', 'permissionGroupsortOrder', 'permissionLevelSortOrder']);
                this.setState({ permissionMaster: permissionMasterList, RoleDetails: { Data: [], IsSuccess: true, IsDataLoaded: true } });
            });
        }
        else {
            AccessAPI.requestRoleByTenant(tenantData.TenantId).then(roleDetails => {
                this.setState({ RoleDetails: roleDetails });
                if (tenantData.EnableRoleGrouping == true) {
                    roleDetails && AccessAPI.getBusinessGroupByTenant(tenantData.TenantId).then(res => {
                        let groupName = new URLSearchParams(window.location.search.toLowerCase()).get("groupname");
                        let roleName = new URLSearchParams(window.location.search.toLowerCase()).get("rolename");
                        if (groupName != null && roleName != null) {
                            let selectedGroupId = res?.Data?.businessGroups.filter(x => x.businessGroupName.toLowerCase() == groupName)[0]?.businessGroupId;
                            let roles = res?.Data?.businessGroups.filter(x => x.businessGroupName.toLowerCase() == groupName)[0]?.roles;
                            let selectedRoleId = roles?.filter(x => x.roleName.toLowerCase() == roleName)[0]?.roleId;
                            let selectedRole = roleDetails.Data.filter(x => x.id == selectedRoleId)[0] ?? { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] };
                            selectedRoleId && this.onRoleDropDownChange(selectedRoleId)
                            this.setState({
                                selectedBusinessGroupRole: selectedRoleId ?? '0', selectedGroup: selectedGroupId ?? '0', scope: this.tenantData.TenantName,
                                selectedRole: { id: selectedRole.id, name: selectedRole.name, scopes: selectedRole.scopes, description: selectedRole.description, permissions: selectedRole.permissions, sortOrder: selectedRole.sortOrder, attributes: selectedRole.attributes, businessGroup: res?.Data?.businessGroups.filter(x => x.businessGroupName.toLowerCase() == groupName)[0]?.businessGroupName }
                            });
                        }
                        this.setState({ BusinessGroupRoleConfig: res, hideBj: false, enableRoleGrouping: true });
                    });
                    AccessAPI.getBusinessGroupByTenant(tenantData.TenantId).then(res => {
                        this.setState({ BusinessGroups: res.Data.businessGroups });
                    });
                }
            });
        }
    }
    private getBusinessGroup = (): IDropdownOption[] => {
        const { BusinessGroups } = this.state;
        this._businessgroupDropdownItems = BusinessGroups.map(x => { return { key: x.businessGroupId, text: x.businessGroupName } })
        return this._businessgroupDropdownItems;
    };
    private getPermissionGroups = (): IDropdownOption[] => {
        const { permissionMaster } = this.state;
        let permissionGroupList = permissionMaster.map(y => { return { key: y.permissionGroupID, text: y.permissionGroup } });
        permissionGroupList = _.uniqBy(permissionGroupList, 'key');
        return permissionGroupList;
    };
    private getGroupSpecificRolesForBGApps = (): IComboBoxOption[] => {
        const { RoleDetails, selectedGroup, BusinessGroupRoleConfig, enableRoleGrouping } = this.state;
        if (selectedGroup.length == 1) {
            let roles = enableRoleGrouping ? BusinessGroupRoleConfig?.Data.businessGroups?.filter(x => x.businessGroupId == selectedGroup[0].Id)[0]?.roles : [];
            this._roleComboboxItems = [];
            this._roleComboboxItems.push({
                key: 'selectAll', text: 'Select All'

            });
            roles?.forEach(item => {
                let role = RoleDetails.Data.find(x => x.id == item.roleId);
                if (role) {
                    this._roleComboboxItems.push({ key: role.id, text: role.name })
                }
            })
        }
        else {
            this._roleComboboxItems = [];
        }

        return this._roleComboboxItems;
    };
    private getRoles = (): IDropdownOption[] => {
        const { RoleDetails } = this.state;
        this._roleDropdownItems = RoleDetails.Data.map(x => { return { key: x.id, text: x.name } });
        return this._roleDropdownItems;
    };
    //private onGroupspecificRoleDropDownChange: any = (event, val) => {
    //    const { selectedGroup, RoleDetails } = this.state;
    //    if (event.target.value == '0') {
    //        this.setState({
    //            selectedBusinessGroupRole: '1', selectedRole: { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }
    //        });
    //    }

    //    else {
    //        this.setState({ attributesLoaded: false });
    //        const { BusinessGroupRoleConfig } = this.state;
    //        this.onRoleDropDownChange(event.target.value);
    //        let roles = selectedGroup.length > 0 ? BusinessGroupRoleConfig?.Data?.businessGroups.filter(x => x.businessGroupId == selectedGroup[0].Id)[0].roles : [];
    //        let filteredRoles = (roles.length > 0) ? roles?.filter(x => x.isActive === true) : [];
    //        let currentRole = filteredRoles.length > 0 ? filteredRoles.filter(x => x.roleId == event.target.value) : [];
    //        let role = RoleDetails.Data.find(x => x.id == event.target.value)
    //        this.setState({
    //            selectedBusinessGroupRole: currentRole[0].roleId,
    //            scope: this.tenantData.TenantName,
    //            selectedRole: { id: currentRole[0].roleId, name: role.name, scopes: role.scopes, description: role.description, permissions: role.permissions, sortOrder: role.sortOrder, attributes: role.attributes, businessGroup: BusinessGroupRoleConfig?.Data?.businessGroups.filter(x => x.businessGroupId == selectedGroup[0].Id)[0].businessGroupName },
    //        });
    //    }
    //};


    private onPeoplePickerChange(items: any) {
        const { isApproverSelected, approvers, isManagerChecked } = this.state;
        if (items && items.length > 0) {
            let validApprovers = [];
            items.forEach(x => {
                let id = null;
                let userProperties = x.tertiaryText;
                let parsedData = JSON.parse(userProperties);
                id = parsedData.email + ':' + parsedData.id
                validApprovers.push(id);
            });

            this.setState({
                approvers: validApprovers, isApproverSelected: true, showMessage: false
            });
        }
        else {
            this.setState({
                isApproverSelected: false
            });
        }

    }
    private _onRenderItemColumn = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
        const { RoleDetails } = this.state
        const itemClass = mergeStyles({
            selectors: {
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                },
            },
        });
        if (column.key === 'status') {
            return (
                <div className={itemClass}>
                    <Link role="button" aria-label={`${item.status}`} onClick={() => {
                        this.setState({ showModal: true, selectedRole: RoleDetails.Data.filter(x => x.name === item.assetName), approvers: item.approvers })
                    }} > View Details </Link>
                </div>
            );
        }
        return item[column.key];
    };

}