import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { mergeStyleSets, FontSizes, Label, ProgressIndicator, MessageBarType, DefaultButton, MessageBar, IconButton, PrimaryButton, IButton, DialogType, getId, Text, Spinner, SpinnerSize, Checkbox, TextField, IStyleSet, Link, ILabelStyles, ILabelStyleProps, IStyleFunctionOrObject } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import { ModalDialog } from '../ModalDialog';
import { DialogModel } from '../../shared/models/Dialog.model';
import AccessAPI from '../../store/AccessAPI';
import { AccessRequestSubmissionModel, AttributeValues, Attributes, RequestDetailsResponse, RoleDetails, UpdateApprover } from '../../shared/models/UserAccess.model';
import CheckboxTree from 'react-checkbox-tree';
import _ from 'lodash';
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import { Role } from '../../shared/models/Role.model';
import { MyAccessResponse } from '../../shared/models/MyCurrentAccess.model';
import { ValidationResponse } from '../../shared/models/ValidationResponse.model';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import PeoplePicker from '../PeoplePicker';
import { EmployeeDetails } from '../../shared/models/EmployeeDetails';
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const labelStyles: Partial<IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>> = {
    root: { marginTop: 7 }
};
export interface IRequestRenewalState {
    saveInProgress: boolean;
    showMessage: boolean;
    autoHideMessage: boolean;
    message: string;
    messageType: MessageBarType;
    hideDialog: boolean;
    modalDialogContent: any;
    subsidaryList: any,
    subsidiaryChecked: any,
    subsidiaryExpanded: any,
    businessList: any,
    businessChecked: any,
    businessExpanded: any,
    subsidiariesConfig: any;
    defaultReportingSubsidiariesConfig: any;
    selectedDefaultReportingSubsidiary: string;
    businessConfig: any;
    isTermsAgreed: boolean;
    Signature: string;
    selectedRole?: Role;
    MyAccess: MyAccessResponse;
    selectedAttributes: any;
    userDetails: IUser;
    businessJustification: string;
    ManagerAlias: EmployeeDetails;
    isAlternateManager: string;
    alternateManagerAlias?: string;
    isAlternateManagerChecked: boolean;
    pickerCtrlKey: number;
}
export interface IRequestRenewaltProperty {
    isLoadingGlobal?: boolean;
}

type RequestRenewalProps = IRequestRenewalState & IRequestRenewaltProperty &
    RouteComponentProps<{}>;

export default class RequestRenewal extends React.Component<RequestRenewalProps> {
    private alertMsgCloseBtnRef = React.createRef<IButton>();
    private tenantData: ITenant = null;
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    }
    public state: IRequestRenewalState = {
        saveInProgress: false,
        showMessage: false,
        autoHideMessage: false,
        message: null,
        messageType: MessageBarType.info,
        hideDialog: true,
        modalDialogContent: this._modalDialogContent,
        subsidiaryChecked: [],
        subsidiaryExpanded: [],
        businessChecked: [],
        businessExpanded: [],
        subsidaryList: [],
        businessList: [],
        subsidiariesConfig: [],
        defaultReportingSubsidiariesConfig: [],
        selectedDefaultReportingSubsidiary: "0",
        businessConfig: [],
        isTermsAgreed: false,
        selectedRole: { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] },
        MyAccess: { IsDataLoaded: false, IsSuccess: false, Data: null },
        selectedAttributes: [],
        userDetails: null,
        businessJustification: '',
        ManagerAlias: { id: '', name: '', displayName: '', email: '', upn: '', surname: '', domain: '', jobTitle: '' },
        alternateManagerAlias: '',
        isAlternateManager: '',
        isAlternateManagerChecked: false,
        pickerCtrlKey: 0,
        Signature: ''
    };
    public constructor(props) {
        super(props);

    }
    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let tenantName = (this.props.match.params as any).tenantName;
        tenantName = tenantName.toUpperCase();
        setTimeout(() => {
            let TenantData = Utility.GetTenantData();
            this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
            this.setState({ userDetails: user, tenantData: this.tenantData, Signature: '' });
            this.ensureDataFetched(this.tenantData.TenantId);
        }, 500);
    }

    public render(): JSX.Element {
        const { showMessage, saveInProgress, MyAccess, selectedRole } = this.state;
        return <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 25 }}>
                {(showMessage) && this.renderMessage()}
                {(saveInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
            </div>
            <div id={'div-req-section'} style={{ padding: '1%', paddingTop: '2%' }} >
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg10"}>
                            <Label style={{ paddingLeft: 0, fontSize: 20 }} > <h1 role="heading" aria-label={UIConstants.PageTitle.AccessRequest} aria-level={1} className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}> {UIConstants.PageTitle.RequestRenewal}</h1></Label>
                        </div>
                    </div>
                </div>
                <div key="work-area" >
                    {(MyAccess.IsDataLoaded) ? <div>
                        {selectedRole.id != '0' ?
                            <div>
                                {this.renderEditAccess()}
                            </div> : <div style={{ "position": "fixed", top: "50%", left: "50%", fontSize: 18, fontWeight: 600 }}>{'No active access to renew'}</div>
                        }
                    </div> : <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />}
                </div>
            </div>
        </React.Fragment>
    };
    private renderEditAccess = () => {
        const { hideDialog, subsidiariesConfig, subsidiaryChecked, subsidiaryExpanded, businessChecked, businessConfig, businessExpanded, isTermsAgreed, Signature, selectedDefaultReportingSubsidiary, selectedRole, businessJustification, defaultReportingSubsidiariesConfig,
            isAlternateManager, isAlternateManagerChecked, pickerCtrlKey, ManagerAlias, alternateManagerAlias } = this.state;
        return (
            <React.Fragment>
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-role' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                            <div>
                                <Label required={true} styles={labelStyles}>{UIConstants.Role}</Label>
                                <select id={'drpRole'} defaultValue={selectedRole.name} disabled={true}
                                    title={UIConstants.Role} required={true}
                                    style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                    <option value="0">{selectedRole.name}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div key='grid-row-bj' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg8"}>
                            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                                <div key={'div-attr-' + 'Default Reporting Subsidiary'}>
                                    <Label required={true} styles={labelStyles}>{'Default Reporting Subsidiary'}</Label>
                                    <select id={'div-attr-' + 'Default Reporting Subsidiary'} defaultValue={selectedDefaultReportingSubsidiary}
                                        title={'Default Reporting Subsidiary'} required={true}
                                        onChange={(event) => {
                                            let val = event.target.value;
                                            this.setState({ selectedDefaultReportingSubsidiary: event.target.value });
                                            let attrValue = [{ code: val, description: val, id: Number.parseInt(subsidiariesConfig.find(x => x.children.find(y => y.label.replace(/'/g, '') == val.replace(/'/g, '')))?.children.find(x => x.label.replace(/'/g, '') == val.replace(/'/g, '')).value) }];
                                            this.updateAttributeValues('Default Reporting Subsidiary', attrValue)
                                        }}
                                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }} >
                                        <option value="0">--- Select ---</option>
                                        {defaultReportingSubsidiariesConfig.map(x => { return <option key={'drpattr-' + x} value={x}>{x}</option> })}
                                    </select>
                                </div>
                                <div key='selection-lbl-row' className={"ms-Grid-row"} style={{ paddingBottom: 5 }}>
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                                        <Label required styles={{ root: { marginTop: 7, fontSize: 17 } }}>Subsidiary Selection</Label>
                                        <Text style={{ fontStyle: 'italic' }}>(Area / Subsidiary)</Text></div>
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0 }}>
                                        <Label required styles={{ root: { marginTop: 7, fontSize: 17 } }}>Business Selection</Label>
                                        <Text style={{ fontStyle: 'italic' }}>(Business Group / Business)</Text></div>
                                </div>
                                <div key='tree-ctrl-row' className={"ms-Grid-row"} style={{ paddingLeft: 0, minHeight: 100, maxHeight: 300, overflowY: 'auto', visibility: 'visible' }}>
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                                        <CheckboxTree
                                            nodes={subsidiariesConfig}
                                            checked={subsidiaryChecked}
                                            expanded={subsidiaryExpanded}
                                            onCheck={(checked, node) => {
                                                if (node.checked) {
                                                    if (node['isParent']) {
                                                        node.children.forEach(item => {
                                                            if (subsidiaryChecked.indexOf(item.value) == -1) {
                                                                subsidiaryChecked.push(item.value)
                                                            }
                                                        }); // push all children
                                                        if (subsidiaryChecked.indexOf(node.value) == -1) {
                                                            subsidiaryChecked.push(node.value) // push parent
                                                        }
                                                    } else {
                                                        if (subsidiaryChecked.indexOf(node.value) == -1) {
                                                            subsidiaryChecked.push(node.value) // push child
                                                        }
                                                    }
                                                } else {
                                                    if (node['isParent']) {
                                                        const index = subsidiaryChecked.indexOf(node.value);
                                                        if (index > -1) {
                                                            subsidiaryChecked.splice(index, 1);
                                                        }
                                                        node.children.forEach(item => {
                                                            const index = subsidiaryChecked.indexOf(item.value);
                                                            if (index > -1) {
                                                                subsidiaryChecked.splice(index, 1);
                                                            }
                                                        });
                                                    } else {
                                                        const index = subsidiaryChecked.indexOf(node.value);
                                                        if (index > -1) {
                                                            subsidiaryChecked.splice(index, 1);
                                                        }
                                                    }
                                                }
                                                this.setState({ subsidiaryChecked: subsidiaryChecked })
                                            }}
                                            onExpand={(expanded, node) => {
                                                if (node.expanded) {
                                                    subsidiaryExpanded.push(node.value);
                                                } else {
                                                    const index = subsidiaryExpanded.indexOf(node.value);
                                                    if (index > -1) {
                                                        subsidiaryExpanded.splice(index, 1);
                                                    }
                                                }
                                                this.setState({ subsidiaryExpanded: subsidiaryExpanded })
                                            }}
                                            nativeCheckboxes={true}
                                            icons={{
                                                expandClose: <i className="fa fa-plus-square" aria-hidden="true"></i>,
                                                expandOpen: < i className="fa fa-minus-square" aria-hidden="true"></i>,
                                                parentClose: null,
                                                parentOpen: null,
                                                leaf: null
                                            }}
                                        />
                                    </div>
                                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                                        <CheckboxTree
                                            nodes={businessConfig}
                                            checked={businessChecked}
                                            expanded={businessExpanded}
                                            onCheck={(checked, node) => {
                                                if (node.checked) {
                                                    if (node['isParent']) {
                                                        node.children.forEach(item => {
                                                            if (businessChecked.indexOf(item.value) == -1) {
                                                                businessChecked.push(item.value)
                                                            }
                                                        }); // push all children
                                                        if (businessChecked.indexOf(node.value) == -1) {
                                                            businessChecked.push(node.value) // push parent
                                                        }
                                                    } else {
                                                        if (businessChecked.indexOf(node.value) == -1) {
                                                            businessChecked.push(node.value) // push child
                                                        }
                                                    }
                                                } else {
                                                    if (node['isParent']) {
                                                        const index = businessChecked.indexOf(node.value);
                                                        if (index > -1) {
                                                            businessChecked.splice(index, 1);
                                                        }
                                                        node.children.forEach(item => {
                                                            const index = businessChecked.indexOf(item.value);
                                                            if (index > -1) {
                                                                businessChecked.splice(index, 1);
                                                            }
                                                        });
                                                    } else {
                                                        const index = businessChecked.indexOf(node.value);
                                                        if (index > -1) {
                                                            businessChecked.splice(index, 1);
                                                        }
                                                    }
                                                }
                                                this.setState({ businessChecked: businessChecked })
                                            }}
                                            onExpand={(expanded, node) => {
                                                if (node.expanded) {
                                                    businessExpanded.push(node.value);
                                                } else {
                                                    const index = businessExpanded.indexOf(node.value);
                                                    if (index > -1) {
                                                        businessExpanded.splice(index, 1);
                                                    }
                                                }
                                                this.setState({ businessExpanded: businessExpanded })
                                            }}
                                            nativeCheckboxes={true}
                                            icons={{
                                                expandClose: <i className="fa fa-plus-square" aria-hidden="true"></i>,
                                                expandOpen: < i className="fa fa-minus-square" aria-hidden="true"></i>,
                                                parentClose: null,
                                                parentOpen: null,
                                                leaf: null
                                            }}
                                        />
                                    </div>
                                </div>
                                <TextField
                                    styles={{ root: { marginTop: 10 } }} label={UIConstants.BusinessJustfication} multiline rows={3} maxLength={1000} value={businessJustification}
                                    onChange={(ev,newValue) => { this.setState({ businessJustification: newValue || '' }) } } required
                                    description={(1000 - businessJustification.length) + ' / 1000 Characters left'}
                                />
                                <Label style={{ fontWeight: "normal" }}>All users must include adequate business justification for Archive access, referencing the following considerations:
                                    <li>The user's area of responsibility and audience with whom the data will be distributed or shared.</li>
                                    <li>Explanation for access level requested, including reports and downstream scorecards.</li></Label>
                                <Label required={true} styles={labelStyles}>Terms & Conditions</Label>
                                <Label style={{ fontWeight: "normal" }}>(All of the below terms & conditions requires acceptance to submit this access request)</Label>
                                <div style={{ display: 'inline-flex', "alignItems": 'center' }}>
                                    <span><Checkbox styles={{ root: { width: 20 } }} checked={isTermsAgreed} onChange={(ev, checked) => { this.setState({ isTermsAgreed: checked }) }} />
                                    </span><span><Label style={{ fontWeight: "normal" }}><span>
                                        &nbsp;I have read and agree to the <Link href="https://microsoft.sharepoint.com/teams/MS_Sales/SitePages/MS-Sales-Access-Policy.aspx" target="_blank" underline>
                                            MS Sales Security Policy
                                        </Link>.
                                    </span></Label>
                                    </span>
                                </div>
                                <TextField
                                    styles={{ root: { marginTop: 10, width: 300 } }} label={UIConstants.Signature} maxLength={100} value={Signature}
                                    onChange={this.onSignatureChange} required
                                />
                                <Label style={{ fontWeight: "normal", fontStyle: 'italic' }}>
                                    Type your  "&lt;First Name&gt; &lt;Last Name&gt;"
                                    <br />
                                </Label>
                                <div >
                                    <div className={"ms-Grid-row"} style={{ paddingTop: 10, display: 'inline-flex', "alignItems": 'baseline', width: 'fit-content' }}>
                                        <span style={{ width: '40%' }}>
                                            <Label style={{ fontWeight: "normal", marginTop: 10, width: '100%' }}>
                                                Your request will be routed to your manager for review
                                                <br />
                                                &nbsp;&nbsp;&nbsp;Manager alias for approval: <span style={{ color: "#0064BF", fontWeight: "bold", fontSize: 13 }}>{ManagerAlias.upn.split('@')[0]}</span>
                                                <br />
                                                &nbsp;&nbsp;&nbsp;Manager name: <span style={{ color: "#0064BF", fontWeight: "bold", fontSize: 13 }}>{ManagerAlias?.name?.split('(')[0]}</span>
                                                <br />
                                            </Label>
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{ width: '85%', border: '1px solid', borderColor: '#ddd', padding: 10, background: 'aliceblue' }}>
                                            <Label style={{ fontWeight: "normal" }}>
                                                <span style={{ fontWeight: "bold" }}>(Optional)</span>
                                                <span> If your Manager is on a vacation (or on a long leave), you can route this request to an alternate approver</span>
                                            </Label>
                                            <div style={{ display: 'inline-flex', "alignItems": 'baseline', float: 'right' }}>
                                                <span>
                                                    <Checkbox styles={{ root: { paddingBottom: 10, width: 20, marginTop: 4 } }} checked={isAlternateManagerChecked}
                                                        onChange={(ev, checked) => { this.setState({ isAlternateManagerChecked: checked, isAlternateManager: '', alternateManagerAlias: '', pickerCtrlKey: pickerCtrlKey + 1 }) }} />
                                                </span>
                                                <span>
                                                    <Label style={{ fontWeight: "normal" }}>
                                                        &nbsp;  Select an alternate FTE approver for request review:  &nbsp;
                                                    </Label>
                                                </span>
                                                <span>
                                                    {!isAlternateManagerChecked ? <TextField styles={{ root: { width: 275 }, field: { height: 38 } }} placeholder={"Search for an alternate FTE approver"} disabled={!isAlternateManagerChecked} /> :
                                                        <div style={{ width: 275 }}><PeoplePicker
                                                            key={pickerCtrlKey}
                                                            type={PeoplePickerType.Normal}
                                                            contentType={PeoplePickerContentType.FTEUser}
                                                            selectionMode={PeoplePickerSelectionMode.Single}
                                                            onchange={(items) => { this.onPeoplePickerChange(items) }}
                                                            defaultSelectedItems={[]}
                                                            {...this.props}
                                                            setFocusOnPicker={false}
                                                            hideDirectFTE={true}
                                                        ></PeoplePicker></div>}
                                                </span>
                                            </div>
                                            {isAlternateManager.includes("Validating Data") ? <><br /><Spinner size={SpinnerSize.small} label="Validating..." ariaLive="assertive" labelPosition="right" style={{ display: 'inline-flex' }} /></> : <div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right' }}>{isAlternateManager ?? ''}</div>}
                                        </span>
                                    </div>
                                </div>
                                <br />

                            </div>
                            <div className="float-right">
                                <div>
                                    <DefaultButton onClick={this.onResetClick} > {UIConstants.ButtonText.Reset} </DefaultButton>
                                    <span>&nbsp;</span>
                                    <PrimaryButton disabled={ManagerAlias.jobTitle.toLowerCase() == "ceo"} onClick={this.onSubmitClick} >{UIConstants.ButtonText.Submit} </PrimaryButton>
                                </div>
                            </div>
                            <br />
                            <div>
                                <ModalDialog hidden={hideDialog}
                                    onDismiss={this.closeDialog}
                                    dialogContentProps={this.state.modalDialogContent}
                                    modalProps={{
                                        titleAriaId: this._labelId,
                                        subtitleAriaId: this._subTextId,
                                        isBlocking: false,
                                        styles: { main: { maxWidth: 450 } },
                                    }}
                                >
                                </ModalDialog>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    private onSignatureChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue.length <= 100) {
            this.setState({ Signature: newValue || '' });
        } else {
            this.setState({ showMessage: true, message: UIConstants.Signature, messageBarType: MessageBarType.warning })
        }
    };
    private onResetClick = (): void => {
        this._modalDialogContent = {
            subText: UIConstants.Messages.ResetConfirmation,
            title: UIConstants.MessageBoxTitle.Reset,
            okButtonText: UIConstants.ButtonText.Reset,
            okAction: this.ResetUI,
            cancelAction: this.closeDialog,
            type: DialogType.normal,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }

        this.setState({ hideDialog: false, modalDialogContent: this._modalDialogContent });
    };
    private ResetUI = (): void => {
        this.setState({
            hideDialog: true,
            businessJustification: '',
            showMessage: false,
            message: '',
            isTermsAgreed: false,
            Signature: ''
        });
    };
    private renderMessage = () => {
        let { message, messageType, autoHideMessage } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds

        return <MessageBar key='SaveMsg' style={{ fontSize: '.875rem' }}
            actions={
                <div role="alert" aria-label={message} >
                    <IconButton componentRef={this.alertMsgCloseBtnRef} iconProps={{ iconName: 'Clear' }}
                        title="Close" ariaLabel="close" onClick={() => { this.setState({ showMessage: false, setFocusOnPicker: true }) }} />
                </div>
            }
            messageBarType={messageType}
            isMultiline={false}
        >
            <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{message}</span>
        </MessageBar>
    };
    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };
    private ensureDataFetched(tenantId) {
        AccessAPI.GetFTEManager(this.state.userDetails.Alias).then(res => {
            res && AccessAPI.GetFTEManager(res[0].upn).then(response => {
                response && this.setState({ alternateManagerAlias: response[0].upn.split('@')[0], ManagerAlias: res[0] });
            });
        });
        let subsidiariesConfig = AccessAPI.getSubsidiaries();
        let businessConfig = AccessAPI.getBusiness();
        let myAccess = AccessAPI.getMyAccess(tenantId);
        Promise.all([subsidiariesConfig, businessConfig, myAccess]).then((responses) => {
            let defaultReportingSubsidiaries = responses[0].map(x => x.subsidiaryName);
            let uniqueDefaultReportingSubsidiaries = new Set();
            defaultReportingSubsidiaries.forEach(item => {
                item.map(x => uniqueDefaultReportingSubsidiaries.add(x));
            });
            defaultReportingSubsidiaries = Array.from(uniqueDefaultReportingSubsidiaries).sort();
            let subsidiaries = responses[0].map((x) => {
                return {
                    value: "SubsidiaryGroup-" + x.subsidiaryGroupID.toString(),
                    label: x.subsidiaryGroupName,
                    children: x.subsidiaries.map(y => { return { value: y.subsidiaryId.toString(), label: y.subsidiaryName } })
                }
            });
            let business = responses[1].map((x) => {
                return {
                    value: "BusinessGroup-" + x.businessGroupID.toString(),
                    label: x.businessGroupName,
                    children: x.businesses.map(y => { return { value: y.businessID.toString(), label: y.businessName } })
                }
            });
            this.setState({ defaultReportingSubsidiariesConfig: defaultReportingSubsidiaries, subsidiariesConfig: subsidiaries, businessConfig: business });
            const { IsDataLoaded, IsSuccess, Data } = responses[2] || {};
            var data = Data?.approvedAccess?.filter(x => x.attributes != null && x.attributes.DefaultReportingSubsidiary != null);
            if (data != null && data.length>0) {
                var sub = data[0].attributes.Subsidiary
                var bus = data[0].attributes.Business;

                let uniqueSubsidiaryExpandedValues = new Set();
                let subsidiaryExpanded = [];
                let subsidiaryChecked = sub.map(z => { return subsidiaries.find(x => x.children.find(y => y.label.replace(/'/g, '') == z.split(":")[1]))?.children.find(x => x.label.replace(/'/g, '') == z.split(":")[1]).value })
                sub.forEach(x => { uniqueSubsidiaryExpandedValues.add(subsidiaries.find(y => y.label == x.split(":")[0])?.value) })
                subsidiaryExpanded = Array.from(uniqueSubsidiaryExpandedValues);

                let uniqueBusinessExpandedValues = new Set();
                let businessExpanded = [];
                let businessChecked = bus.map(z => { return business.find(x => x.children.find(y => y.label.replace(/'/g, '') == z.split(":")[1]))?.children.find(x => x.label.replace(/'/g, '') == z.split(":")[1]).value })
                bus.forEach(x => { uniqueBusinessExpandedValues.add(business.find(y => y.label == x.split(":")[0])?.value) })
                businessExpanded = Array.from(uniqueBusinessExpandedValues);
                let roleData = {
                    id: data[0].roleDefinitionId,
                    name: data[0].roleName,
                    scopes: null,
                    description: data[0].roleDescription,
                    permissions: null,
                    sortOrder: '',
                    attributes: data[0].attributes
                }
                let defaultReportingSubsidiary = subsidiaries.find(x => x.children.find(y => y.label.replace(/'/g, '') == data[0].attributes.DefaultReportingSubsidiary[0]))?.children.find(x => x.label.replace(/'/g, '') == data[0].attributes.DefaultReportingSubsidiary[0]).label;
                let attributeData = [{
                    attributeName: "Default Reporting Subsidiary",
                    attributeValues: [{ code: defaultReportingSubsidiary, description: defaultReportingSubsidiary, id: Number.parseInt(subsidiaries.find(x => x.children.find(y => y.label.replace(/'/g, '') == data[0].attributes.DefaultReportingSubsidiary[0].replace(/'/g, '')))?.children.find(x => x.label.replace(/'/g, '') == data[0].attributes.DefaultReportingSubsidiary[0].replace(/'/g, '')).value) }]
                }]
                let attributes: Attributes[] = JSON.parse(JSON.stringify(attributeData));
                this.setState({
                    MyAccess: responses[2], showModal: false, showSubsidiary: true, editRequest: true, approvedaccess: data, showMessage: false,
                    showMSSalesTermsAndConditions: true, hideBj: false, subsidiaryChecked: subsidiaryChecked, subsidiaryExpanded: subsidiaryExpanded,
                    businessChecked: businessChecked, businessExpanded: businessExpanded, updateRequest: false,
                    selectedRole: roleData,
                    selectedAttributes: attributes, scope: this.tenantData.TenantName, selectedDefaultReportingSubsidiary: defaultReportingSubsidiary
                });
            }
            else {
                this.setState({ MyAccess: responses[2] });
            }
        });
    }
    private getAreaSubsidairies(config, subsidiarycheck) {
        let values = []
        for (var value in subsidiarycheck) {
            for (var x in config) {
                for (var child in config[x].children) {
                    if (config[x].children[child].value === subsidiarycheck[value]) {
                        values.push({ GroupId: Number.parseInt(config[x].value.split("SubsidiaryGroup-")[1]), GroupCode: config[x].label, GroupDescription: config[x].label, Id: Number.parseInt(config[x].children[child].value), code: config[x].children[child].label, description: config[x].children[child].label })
                    }
                }
            }
        }
        values.sort((a, b) => (a.code.toUpperCase() < b.code.toUpperCase()) ? -1 : 1);
        return values.sort((a, b) => (a.GroupCode.toUpperCase() < b.GroupCode.toUpperCase()) ? -1 : 1);
    };
    private getBusinessSubsidairies(config, businessCheck) {
        let values = []
        for (var value in businessCheck) {
            for (var x in config) {
                for (var child in config[x].children) {
                    if (config[x].children[child].value === businessCheck[value]) {
                        values.push({ GroupId: Number.parseInt(config[x].value.split("BusinessGroup-")[1]), GroupCode: config[x].label, GroupDescription: config[x].label, Id: Number.parseInt(config[x].children[child].value), code: config[x].children[child].label, description: config[x].children[child].label })
                    }
                }
            }
        }
        values.sort((a, b) => (a.code.toUpperCase() < b.code.toUpperCase()) ? -1 : 1);
        return values.sort((a, b) => (a.GroupCode.toUpperCase() < b.GroupCode.toUpperCase()) ? -1 : 1);
    }
    private ValidateData = (): ValidationResponse => {
        const { isTermsAgreed, Signature, userDetails, selectedAttributes, businessJustification, isAlternateManagerChecked, alternateManagerAlias } = this.state;
        let response: ValidationResponse = { isValid: true };
        let errorMessage = null;

        // Validation case 1 : check if T&C applicable if yes validate
        if (isTermsAgreed === false) {
            errorMessage = errorMessage == null ? UIConstants.TermsAndConditions : `${errorMessage}, ${UIConstants.TermsAndConditions}`
        }

        // Validation case 2 : check if Signature is correct
        if (Signature.toLowerCase() != userDetails.Name?.split(" (")[0].toLowerCase()) {
            errorMessage = errorMessage == null ? UIConstants.Signature : `${errorMessage}, ${UIConstants.Signature}`
        }

        if (this.state.businessChecked.length == 0 && this.state.subsidiaryChecked.length == 0)
            errorMessage = errorMessage == null ? UIConstants.Subsidaries : `${errorMessage}, ${UIConstants.Subsidaries}`
        else if (this.state.businessChecked.length == 0)
            errorMessage = errorMessage == null ? UIConstants.BusinessSelection : `${errorMessage}, ${UIConstants.BusinessSelection}`
        else if (this.state.subsidiaryChecked.length == 0)
            errorMessage = errorMessage == null ? UIConstants.SubsidiarySelection : `${errorMessage}, ${UIConstants.SubsidiarySelection}`
        if (!selectedAttributes.find(x => x.attributeName == 'Default Reporting Subsidiary')) {
            errorMessage = errorMessage == null ? UIConstants.DefaultReportingSubsidiary : `${errorMessage}, ${UIConstants.DefaultReportingSubsidiary}`
        }

        // Validation case 5 : check if Business Justification is empty
        if (businessJustification.trim().length === 0) {
            errorMessage = errorMessage == null ? UIConstants.BusinessJustfication : `${errorMessage}, ${UIConstants.BusinessJustfication}`
        }

        // Validation case : check if alternate Manager is correct
        if (isAlternateManagerChecked) {
            if (alternateManagerAlias == '') {
                errorMessage = errorMessage == null ? UIConstants.AlternateManager : `${errorMessage}, ${UIConstants.AlternateManager}`
            }
        }

        if (errorMessage) {
            return { isValid: false, validationMessage: ` ${errorMessage} is required.` }
        }
        else {
            return response; // Validation Success , Allow user to Submit
        }
    };

    private onSubmitClick = async (): Promise<void> => {
        const { subsidiaryChecked, businessChecked, subsidiariesConfig, businessConfig, selectedAttributes } = this.state
        let validationResponse = this.ValidateData();
        if (validationResponse.isValid) {
            let attributes: Attributes[] = JSON.parse(JSON.stringify(selectedAttributes));
            if (attributes.find(x => x.attributeName == "Subsidiary")) {
                attributes.find(x => x.attributeName == "Subsidiary").attributeValues = this.getAreaSubsidairies(subsidiariesConfig, subsidiaryChecked);
                this.setState({ subsidiarySelectedAttributes: attributes });
            }
            else {
                let newSubsidiaryAttributeValues: Attributes = { attributeName: "Subsidiary", attributeValues: this.getAreaSubsidairies(subsidiariesConfig, subsidiaryChecked) };
                attributes.push(newSubsidiaryAttributeValues);
            }
            if (attributes.find(x => x.attributeName == "Business")) {
                attributes.find(x => x.attributeName == "Business").attributeValues = this.getBusinessSubsidairies(businessConfig, businessChecked);;
            }
            else {
                let newBusinessAttributeValues: Attributes = { attributeName: "Business", attributeValues: this.getBusinessSubsidairies(businessConfig, businessChecked) };
                attributes.push(newBusinessAttributeValues);
            }
            this.setState({ selectedAttributes: attributes });
            this._modalDialogContent = {
                subText: UIConstants.Messages.SaveConfirmation,
                title: UIConstants.MessageBoxTitle.SaveAccess,
                okButtonText: UIConstants.ButtonText.Submit,
                type: DialogType.normal,
                okAction: this.saveAccessRequest,
                cancelAction: this.closeDialog,
                closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
            }
            this.setState({ hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent });// Show confirmation pop-up      
        }
        else {
            // Show Error message and skip submission
            this.setState({ showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
        }
    };

    private saveAccessRequest = (): void => {
        const { Signature, isTermsAgreed, businessJustification, ManagerAlias, isAlternateManagerChecked, alternateManagerAlias } = this.state;
        let t = this;
        let data: AccessRequestSubmissionModel = {
            editRequest: false,
            tenantId: this.tenantData.TenantId,
            tenantName: this.tenantData.TenantName,
            businessJustification: businessJustification,
            scope: this.tenantData.TenantName,
            role: this.getRolesDetails(),
            requestType: UIConstants.RequestType.Renew,
            signatureVerified: true,
            termsAndConditionsApplied: isTermsAgreed,
            alternateMangerAlias: isAlternateManagerChecked ? alternateManagerAlias?.split('@')[0] : null,
            managerAlias: ManagerAlias?.upn != "Manager not found" ? ManagerAlias?.upn.split('@')[0] : null
        };
        this.setState({ hideDialog: true, saveInProgress: true, showMessage: false });
        AccessAPI.saveAccessRenewalAsync(data)
            .then(async res => {
                if (res.status && res.status === 200) {
                    t.setState({
                        saveInProgress: false,
                        messageType: MessageBarType.success,
                        message: UIConstants.Messages.SaveSuccess,
                        showMessage: true,
                        hideBj: true,
                        autoHideMessage: false,
                        RoleDescription: null,
                        Signature: '',
                        businessJustification: ''
                    });
                } else if ((res.status && res.status !== 200) || res.message) {
                    t.setState({
                        saveInProgress: false,
                        messageType: MessageBarType.warning,
                        message: res.message,
                        showMessage: true,
                        hideBj: false,
                        autoHideMessage: false,
                        Signature: Signature,
                        isTermsAgreed: isTermsAgreed
                    });
                }
            });
    };
    private getRolesDetails = (): RoleDetails => {
        let { selectedRole, selectedAttributes } = this.state;
        let role: RoleDetails = {
            roleId: selectedRole.id,
            roleName: selectedRole.name,
            roleDescription: selectedRole.description,
            attributes: selectedAttributes,
            actions: selectedRole.permissions && selectedRole.permissions.length > 0 ? selectedRole.permissions[0].actions : []
        };
        return role;
    };
    private updateAttributeValues = (attributeName: string, attributeValue: AttributeValues[]) => {
        const { selectedAttributes } = this.state;
        let updatedAttributes: Attributes[] = JSON.parse(JSON.stringify(selectedAttributes));
        let filteredAttribute: Attributes[] = updatedAttributes.filter(x => x.attributeName === attributeName);

        if (filteredAttribute.length > 0) { // If present update existing value
            if (attributeValue.length == 0 || (attributeValue.length > 0 && attributeValue[0].code == '0')) { // reset attribute if -- Select -- 
                updatedAttributes = updatedAttributes.filter(x => x.attributeName != attributeName);
            } else {
                updatedAttributes.forEach(x => {
                    if (x.attributeName === attributeName) {
                        x.attributeValues = attributeValue
                    }
                });
            }

        } else {// else add new value
            let newAttributeValues: Attributes = { attributeName: attributeName, attributeValues: attributeValue };
            updatedAttributes.push(newAttributeValues);
        }
        this.setState({ selectedAttributes: updatedAttributes, setAttributeFocus: false });
        if (this.state.message == '') {
            this.setState({ showMessage: false });
        }
    };
    private onPeoplePickerChange(items: any) {
        this.setState({ isAlternateManager: '', alternateManagerAlias: '' });
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            this.setState({ alternateManagerAlias: parsedData.upn, isAlternateManager: 'Validating Data' });
            AccessAPI.ValidateUserJobTitle(parsedData.upn).then(res => {
                if (res) {
                    this.setState({ isAlternateManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
                }
                else {
                    this.setState({ isAlternateManager: '' });
                }
            });
        }
    }
}