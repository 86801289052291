import React from 'react';
import { Modal, IconButton, mergeStyleSets, getTheme, getId, FontSizes, FontWeights, Link, Icon, ActivityItem, Label, MessageBar, MessageBarType, SpinnerSize, Spinner } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import AccessAPI from '../../store/AccessAPI';
import { ApproverDetailsResponse } from '../../shared/models/ApproverDetails.model';
import _ from 'lodash';
import { Approvers, Properties } from '../../shared/models/UserAccess.model';

const theme = getTheme();
const iconButtonStyles = mergeStyleSets({
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
});
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 8px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            }
        }
    }
});

const classNames = mergeStyleSets({
    activityRoot: {
        marginTop: "20px"
    },
    nameText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium
    }, actionText: {
        fontSize: FontSizes.medium
    }, actionByText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium,
        color: '#0064BF'
    },
    lblText: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        // -webkit-font-smoothing: antialiased;
        fontSize: 14,
        fontWeight: 600,
        color: 'rgb(23, 23, 23)',
        boxSizing: 'border-box',
        boxShadow: 'none',
        margin: 0,
        display: 'block',
        padding: '5px 0px',
        overflowWrap: 'break-word',
    }
});

interface IRequestDetailsProps {
    isAdminScreen: boolean;
    properties: Properties;
    onDismiss: Function;
    requestId: string;
    approvers: Approvers;
    requestType: string;
    businessJustification: string;
    mode: string;
    migratedApprovalHistory: any;
}
interface IAccessRequestDetailState {
    showModal: boolean,
    AppproverDetails: ApproverDetailsResponse
}
export default class RequestDetails extends React.Component<IRequestDetailsProps, IAccessRequestDetailState> {
    private _titleId: string = getId('title');
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: true,
            AppproverDetails: null
        };

    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    private ensureDataFetched() {
        AccessAPI.getApprovalStatusDetails(this.props.requestId).then(res => {
            this.setState({ AppproverDetails: res });
        });
    }
    public render() {
        const { requestId, properties, businessJustification, mode, approvers } = this.props;
        const { AppproverDetails, showModal } = this.state;
        const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
        const { migrationData, requestData } = Data || {};

        return <React.Fragment>
            <Modal titleAriaId={this._titleId}
                isOpen={showModal} onDismiss={this._closeModal}
                isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ position: 'absolute', right: 10, paddingTop: 20, paddingBottom: 12, marginTop: 20 }} > <IconButton tabIndex={0} styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close Access Details dialog" onClick={this._closeModal as any} /> </span>
                    <span style={{ position: 'absolute', left: 25, paddingTop: 20, paddingBottom: 12, marginTop: 20 }}   >
                        {!mode && <> <h2 role='heading' className={"h2"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.MessageBoxTitle.RegistrationDetails}</h2></>}
                        {mode && <> <h2 role='heading' className={"h2"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.MessageBoxTitle.AccessDetails}</h2></>}
                    </span>
                </div>
                <div role='presentation' className={contentStyles.body}>
                    <div style={{
                        height: '60vh',
                        width: '70vw',
                        overflow: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        marginTop: 30
                    }}
                    >
                        <div className={"ms-Grid"} dir="ltr">
                            <div className={"ms-Grid-row"}>
                                <div className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>
                                    <label>
                                        <div className={classNames.lblText} >Request Id: {requestId ?
                                            requestId : requestData?.requestId ? requestData?.requestId : migrationData?.id}</div>
                                    </label>
                                    <div style={{ width: '100%' }}>
                                        {this.RenderApprovalStatus()}
                                    </div>

                                </div>
                                <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg6"}>
                                    <div id={'dv-asset-details'}>
                                        <table className="tb-reg-req-detail">
                                            <tbody>
                                                <tr key={'tr-ast-id'} >
                                                    <td key={'td-ast-id-key'} ><span className={classNames.nameText}>{' Business Justification '} </span></td>
                                                    <td key={'td-ast-id-val'} ><span className={classNames.actionText}>{businessJustification}</span></td>
                                                </tr>
                                                {!mode && <><tr key={'tr-ast-id'} >
                                                    <td key={'td-ast-id-key'} ><span className={classNames.nameText}>{' Data Owners '} </span></td>
                                                    <td key={'td-ast-id-val'} ><span className={classNames.actionText}>{approvers.DataOwners.join(";").toString()}</span></td>
                                                </tr>
                                                    <tr key={'tr-ast-id'} >
                                                        <td key={'td-ast-id-key'} ><span className={classNames.nameText}>{' Publisher Privacy Leads '} </span></td>
                                                        <td key={'td-ast-id-val'} ><span className={classNames.actionText}>{approvers.PublisherPrivacyLeads == null ?'-' :approvers.PublisherPrivacyLeads.join(";").toString()}</span></td>
                                                    </tr></>}
                                                <tr key={'tr-ast-id'} >
                                                    <td key={'td-ast-id-key'} ><span className={classNames.nameText}>{' Publisher Name '} </span></td>
                                                    <td key={'td-ast-id-val'} ><span className={classNames.actionText}>{properties.publisherName}</span></td>
                                                </tr>
                                                {!mode && <><tr key={'tr-ast-name'} >
                                                    <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Publisher Request Id '} </span></td>
                                                    <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{properties.publisherRequestId}</span></td>
                                                </tr>
                                                    <tr key={'tr-ast-ops'} >
                                                        <td key={'td-ast-ops-key'} > <span className={classNames.nameText}>{' OpsEngAlias '} </span></td>
                                                        <td key={'td-ast-ops-val'} > <span className={classNames.actionText}>{properties.opsEngAlias}</span></td>
                                                    </tr>
                                                    <tr key={'tr-ast-dev'} >
                                                        <td key={'td-ast-dev-key'} > <span className={classNames.nameText}>{' DevEngAlias '} </span></td>
                                                        <td key={'td-ast-dev-val'} > <span className={classNames.actionText}>{properties.devEngAlias}</span></td>
                                                    </tr></>}
                                                {properties.ppeRequestId && < tr key={'tr-ast-name'} >
                                                    <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Ppe Request Id '} </span></td>
                                                    <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{properties.ppeRequestId}</span></td>
                                                </tr>}
                                                <tr key={'tr-ast-desc'} >
                                                    <td key={'td-ast-desc-key'} > <span className={classNames.nameText}>{mode ? 'Consumer Service Tree Name' :'Publisher Service Tree Name'} </span></td>
                                                    <td key={'td-ast-desc-val'} > <span className={classNames.actionText}>{properties.serviceTreeName}</span></td>
                                                </tr>
                                                <tr key={'tr-ast-pubname'} >
                                                    <td key={'td-ast-pubname-key'} > <span className={classNames.nameText}>{mode ? 'Consumer Service Tree Id' : 'Publisher Service Tree Id'} </span></td>
                                                    <td key={'td-ast-pubname-val'} > <span className={classNames.actionText}>{properties.servicetreeId}</span></td>
                                                </tr>
                                                <tr key={'tr-ast-sox'} >
                                                    <td key={'td-ast-sox-key'} > <span className={classNames.nameText}>{' IsSOX '} </span></td>
                                                    <td key={'td-ast-sox-val'} >
                                                        <span className={classNames.actionText}>
                                                            {properties.isSOX ? 'Yes' : 'No'}
                                                        </span></td>
                                                </tr>
                                                {(!mode || (mode && this.props.migratedApprovalHistory==null))&&
                                                    <tr key={'tr-ast-cpl'} >
                                                        <td key={'td-ast-cpl-key'} > <span className={classNames.nameText}>{' IsCplusAI '} </span></td>
                                                        <td key={'td-ast-cpl-val'} > <span className={classNames.actionText}>{properties.isCplusAI ? 'Yes' : 'No'}</span></td>
                                                    </tr>
                                                }
                                                <tr key={'tr-ast-link'} >
                                                    <td key={'td-ast-link-key'} > <span className={classNames.nameText}>{' Data Contract Links '} </span></td>
                                                    <td key={'td-ast-link-val'} > <span className={classNames.actionText}>
                                                        {
                                                            (() => {
                                                                let links = properties.dataContractLinks;
                                                                if (links.length > 1) {
                                                                    return links.map((item, idx) => (
                                                                        <React.Fragment key='multi-link'>
                                                                            <Link key={'link-' + idx} target="_blank" href={item} >View Data Contract {idx + 1}</Link>
                                                                            {idx != links.length - 1 ? ', ' : ''}
                                                                        </React.Fragment>))
                                                                } else {
                                                                    return <React.Fragment key='single-link'>
                                                                        <Link key={'link'} target="_blank" href={links[0]} >View Data Contract </Link>
                                                                    </React.Fragment>
                                                                }
                                                            })()
                                                        }
                                                    </span></td>
                                                </tr>
                                                <tr key={'tr-ast-data'} >
                                                    <td key={'td-ast-data-key'} > <span className={classNames.nameText}>{' HasClassifiedData '} </span></td>
                                                    <td key={'td-ast-data-val'} > <span className={classNames.actionText}>{properties.hasClassifiedData ? 'Yes' : 'No'}</span></td>
                                                </tr>
                                                <tr key={'tr-ast-pii'} >
                                                    <td key={'td-ast-pii-key'} > <span className={classNames.nameText}>{' Account Id '} </span></td>
                                                    <td key={'td-ast-pii-val'} > <span className={classNames.actionText}>{properties?.accountId}</span></td>
                                                </tr>
                                                <tr key={'tr-ast-pii'} >
                                                    <td key={'td-ast-pii-key'} > <span className={classNames.nameText}>{' Account Id Type '} </span></td>
                                                    <td key={'td-ast-pii-val'} > <span className={classNames.actionText}>{properties?.accountIdType}</span></td>
                                                </tr>
                                                <tr key={'tr-ast-data'} >
                                                    <td key={'td-ast-pii-key'} > <span className={classNames.nameText}>{' HasPersonalData '} </span></td>
                                                    <td key={'td-ast-pii-val'} > <span className={classNames.actionText}>{properties.hasPersonalData ? 'Yes' : 'No'}</span></td>
                                                </tr>
                                                {mode && <><tr key={'tr-ast-consumer'}>
                                                    <td key={'td-ast-consumer-key'}> <span className={classNames.nameText}>{' Consumer Request Id '} </span></td>
                                                    <td key={'td-ast-consumer-val'}><span className={classNames.actionText}>{properties.consumerRequestId}</span></td>
                                                </tr><tr key={'tr-ast-consumer'}>
                                                        <td key={'td-ast-consumer-key'}> <span className={classNames.nameText}>{' Publisher Service Tree Id '} </span></td>
                                                        <td key={'td-ast-consumer-val'}><span className={classNames.actionText}>{properties.publisherServiceTreeId}</span></td>
                                                    </tr><tr key={'tr-ast-consumer'}>
                                                        <td key={'td-ast-consumer-key'}> <span className={classNames.nameText}>{' Publisher Service Tree Name '} </span></td>
                                                        <td key={'td-ast-consumer-val'}><span className={classNames.actionText}>{properties.publisherServiceTreeName}</span></td>
                                                    </tr><tr key={'tr-ast-consumer'}>
                                                        <td key={'td-ast-consumer-key'}> <span className={classNames.nameText}>{' Consumer Service Name '} </span></td>
                                                        <td key={'td-ast-consumer-val'}><span className={classNames.actionText}>{properties.serviceName}</span></td>
                                                    </tr><tr key={'tr-ast-consumer'}>
                                                        <td key={'td-ast-consumer-key'}> <span className={classNames.nameText}>{' Compute Type '} </span></td>
                                                        <td key={'td-ast-consumer-val'}><span className={classNames.actionText}>{properties.computeType}</span></td>
                                                    </tr><tr key={'tr-ast-consumer'}>
                                                        <td key={'td-ast-consumer-key'}> <span className={classNames.nameText}>{' Security group '} </span></td>
                                                        <td key={'td-ast-consumer-val'}><span className={classNames.actionText}>{properties.securityGroup}</span></td>
                                                    </tr><tr key={'tr-ast-consumer'}>
                                                        <td key={'td-ast-consumer-key'}> <span className={classNames.nameText}>{' Consumer Owners '} </span></td>
                                                        <td key={'td-ast-consumer-val'}><span className={classNames.actionText}>{properties.consumerOwners?.join("; ")}</span></td>
                                                    </tr>
                                                </>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    };

    private _closeModal = (): void => {
        this.setState({ showModal: false });
        this.props.onDismiss();
    };

    private renderMessage = (message: string, messageType: MessageBarType) => {
        return <MessageBar key='FetchMsg' messageBarType={messageType} isMultiline={false}> {message} </MessageBar>
    };
    private renderRequestHistory = () => {
        const { requestId, requestType, migratedApprovalHistory } = this.props;
        const { AppproverDetails } = this.state;
        let { migrationData, requestData } = AppproverDetails?.Data || {};
        let ApprovalRequestDetails = requestData?.approvalRequest;
        let Histogram = [];
        if (migratedApprovalHistory != null) {
            var Histogram1 = []
            Histogram1 = migratedApprovalHistory.map(approvalItem => {
                return {
                    key: 'item-root-' + approvalItem.Level,
                    activityDescription: [
                        <span key={'item-status-' + approvalItem.Level} className={classNames.actionText}>
                            {approvalItem.IsApproved === null ? '' : approvalItem.Level}
                            {approvalItem.IsApproved === null ? '' : ' Approved by'} </span>,
                        <span key={approvalItem.Level} className={classNames.actionByText}>
                            {approvalItem.IsApproved === null ? '' : approvalItem.ActionByAlias}
                        </span>,
                        <span key={'item-levelCategory-' + approvalItem.Level} className={classNames.actionText}>{approvalItem.ApprovalCategory ? ' from ' + approvalItem.ApprovalCategory + ' category ' : ''}</span>,
                        <span key={'item-timestamp-' + approvalItem.Level} className={classNames.actionText}>{approvalItem.ApprovalActionDate ?
                            ' On - ' + new Date(approvalItem.ApprovalActionDate).toLocaleString() : ''}
                        </span>
                    ],
                    activityIcon: <Icon style={{ color: approvalItem.IsApproved === null ? 'white' : 'green' }}
                        iconName={approvalItem.IsApproved === null ? '' : 'SkypeCircleCheck' } />,
                    comments: [
                        <span key={'item-comments-' + approvalItem.Level}>{approvalItem.ApproverComments}</span>
                    ],
                }
            })
            Histogram1.reverse().forEach(element => {
                if (element != '') {
                    Histogram.push(element)
                }
            });
        }
        else {
            if (requestType.toUpperCase() === 'REMOVE' || requestType.toUpperCase() === 'AUTO EXPIRED') {
                Histogram = [{
                    key: 'Item-root-remove',
                    activityDescription: [
                        <span key={'item-status-remove'}> {requestId} </span>,
                        <span key={'item-level-remove'} className={classNames.nameText}>
                            {requestType.toUpperCase() === 'REMOVE' ? UIConstants.Labletext.Removed : UIConstants.Labletext.Expired} </span>,],
                    activityIcon: <Icon iconName={'Trash'} />,
                    timeStamp: new Date().toLocaleString()
                }]
            }
            else {
                var Histogram1 = []
                Histogram1 = ApprovalRequestDetails.approvalHierarchy.map(approvalItem => {
                    if (approvalItem.IsApproved == null)
                        return {
                            activityDescription: [
                                <span key={'item-status-' + approvalItem.Level} className={classNames.actionText}>
                                    { 'Level ' + approvalItem.Level} </span>,
                                <span className={classNames.actionText}>{' Pending with '} </span>,
                                <span className={classNames.actionByText}>{approvalItem.AssignedTo.replaceAll(";", "; ")}</span>,
                                <span key={'item-levelCategory-' + approvalItem.Level} className={classNames.actionText}>{approvalItem.LevelCategory ? ' from ' + approvalItem.LevelCategory + ' category ' : ''}</span>,
                            ],
                            activityIcon: <Icon style={{ color: '#0078d4' }} iconName={'SyncStatusSolid'} />,
                            comments: [
                                <span key={'item-comments-' + approvalItem.Level}>{approvalItem.Notes !== null ? "Notes : " + approvalItem.Notes : ''}</span>
                            ],
                        }
                    else {
                        return {
                            key: 'item-root-' + approvalItem.Level,
                            activityDescription: [
                                <span key={'item-status-' + approvalItem.Level} className={classNames.actionText}>
                                    {approvalItem.IsApproved === null ? '' : 'Level ' + approvalItem.Level}
                                    {approvalItem.IsApproved === null ? '' : approvalItem.IsApproved ? ' Approved by' : ' Rejected by'} </span>,
                                <span key={approvalItem.Level} className={classNames.actionByText}>
                                    {approvalItem.IsApproved === null ? '' : approvalItem.ActionByAlias}
                                </span>,
                                <span key={'item-levelCategory-' + approvalItem.Level} className={classNames.actionText}>{approvalItem.LevelCategory ? ' from ' + approvalItem.LevelCategory +' category ': ''}</span>,
                                <span key={'item-timestamp-' + approvalItem.Level} className={classNames.actionText}>{approvalItem.ApprovalActionDate ?
                                    ' On - ' + new Date(approvalItem.ApprovalActionDate).toLocaleString() : ''}
                                </span>
                            ],
                            activityIcon: <Icon style={{ color: approvalItem.IsApproved === null ? 'white' : approvalItem.IsApproved ? 'green' : 'red' }}
                                iconName={approvalItem.IsApproved === null ? '' : approvalItem.IsApproved ? 'SkypeCircleCheck' : 'SkypeCircleMinus'} />,
                            comments: [
                                <span key={'item-comments-' + approvalItem.Level}> {approvalItem.Notes !== null ? "Notes : " + approvalItem.Notes : ''}</span>,
                                <span key={'item-comments-' + approvalItem.Level}>{approvalItem.Notes !== null ? < br /> : ''}</span>,
                                <span key={'item-comments-' + approvalItem.Level}>{"Comments : " + approvalItem.ApproverComments}</span>
                            ],
                        }
                    }
                })
                Histogram1.reverse().forEach(element => {
                    if (element != '') {
                        Histogram.push(element)
                    }
                });
            }
        }
        return Histogram;
    };
    private RenderApprovalStatus = (): JSX.Element => {
        const { requestType, migratedApprovalHistory } = this.props;
        const { AppproverDetails } = this.state;
        const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
        const { migrationData, requestData } = Data || {};

        let ApprovalRequestDetails = requestData?.approvalRequest;
        let ApprovalResponse = AppproverDetails;
        if (requestType.toUpperCase() === UIConstants.RequestType.Remove.toUpperCase() || requestType.toUpperCase() === 'AUTO EXPIRED') {
            return <div>{this.renderRequestHistory().map(
                (item: { key: string | number }) => (<ActivityItem {...item} key={item.key} className={classNames.activityRoot} />)
            )}</div>
        }
        else if (ApprovalRequestDetails) {
            return <div>
                <Label>Approval Stage : {ApprovalRequestDetails.currentLevel} / {ApprovalRequestDetails.approvalLevels}</Label>
                <Label>Submitted On : {new Date(requestData.requestedDate).toLocaleString()}</Label>
                {this.renderRequestHistory().map(
                    (item: { key: string | number }) => (
                        <ActivityItem
                            {...item}
                            key={item.key}
                            className={classNames.activityRoot}
                        />
                    )
                )}
            </div>
        } else {
            if (migratedApprovalHistory != null) {
                return <div>
                    <Label>Type : Migration</Label>
                    {this.renderRequestHistory().map(
                        (item: { key: string | number }) => (
                            <ActivityItem
                                {...item}
                                key={item.key}
                                className={classNames.activityRoot}
                            />
                        )
                    )}
                </div>
            }
            else if (ApprovalResponse?.IsDataLoaded) {
                if (ApprovalResponse?.IsSuccess && migrationData) {
                    return <>
                        <Label>Migrated On : <span key={'item-timestamp-mig'} className={classNames.actionText}>{migrationData?.requestedDate ?
                            new Date(migrationData?.requestedDate).toLocaleString() : 'N/A'}
                        </span> </Label>

                    </>
                } else {
                    return <div>{this.renderMessage(UIConstants.Messages.RequestStatusNotFound, MessageBarType.error)}</div>
                }
            } else {
                return <Spinner size={SpinnerSize.xSmall} label="loading..." ariaLive="assertive" labelPosition="bottom" />
            }
        }
    };
}