import * as React from 'react';
import { RouteComponentProps } from 'react-router';
export default class AccessDenied extends React.PureComponent<RouteComponentProps> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return <React.Fragment>
            <div style={{ "position": "absolute", top: "50%", left: "40%", fontSize: 18, fontWeight: 600 }}>
                No access to External/AAD Guest Account Users
            </div>
        </React.Fragment>;
    };
}