import axios from "axios";
import { AuthResponse } from 'msal';
import { msalAuth,msalConfig } from '../auth/MsalAuthProvider';

export const axioClient = axios.create()

// Add a request interceptor
axioClient.interceptors.request.use(
    async config => {
        config.headers['Content-Type'] = 'application/json-patch+json';

        const accessTokenRequest = { scopes: [msalConfig.clientId] };
        var authResponse: AuthResponse = null;
        try {
            authResponse = await msalAuth.acquireTokenSilent(accessTokenRequest);
        }
        catch (error) {
            console.log("Token Expired, redirecting to login", error);
            msalAuth.loginRedirect({});
        }

        let accessToken = authResponse && authResponse.accessToken ? authResponse.accessToken : authResponse.idToken.rawIdToken;
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        console.log('Error in axios interceptors')
        Promise.reject(error)
    });