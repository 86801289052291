import React from 'react';
import {
    IColumn,
    Fabric,
    DetailsListLayoutMode,
    SelectionMode,
    ShimmeredDetailsList,
    mergeStyleSets,
    Label,
    Link,
    Modal,
    getId,
    getTheme,
    FontSizes,
    FontWeights,
    IconButton,
    mergeStyles,
    IPlainCardProps,
    DirectionalHint,
    HoverCard,
    HoverCardType
} from '@fluentui/react';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { MyAccessRequestCollection } from '../../shared/models/MyAccessRequest.model';
import { UIConstants } from '../../shared/models/Constants';
import AccessHistoryStatus from './AccessHistoryStatus'
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { MyAccessRequestResponse } from '../../shared/models/UserAccess.model';
import _ from 'lodash';

interface AccessHistoryList {
    key: number;
    userName: string;
    role: string;
    requestedOn: string;
    status: string;
    remarks: string;
}
interface AccessHistoryProps {
    data: MyAccessRequestCollection;
    onDismiss: Function;
  }
export interface AccessHistoryState {
    items: AccessHistoryList[];
    selectedPageIndex: number;
    adminUserDetails: IUser;
    showModal: boolean;
    data: MyAccessRequestCollection,
    AccessData: MyAccessRequestResponse,
    tenantData : ITenant,
}
const theme = getTheme();
const iconButtonStyles = mergeStyleSets({
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
});
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 8px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            }
        }
    }
});

export default class AccessHistory extends React.Component<AccessHistoryProps, AccessHistoryState>  {
    private _allItems: AccessHistoryList[] = [];
    private tenantData: ITenant = null;
    private _titleId: string = getId('title');
    public async componentDidMount() {
        let loggedInUserAccount = msalAuth.getAccount();
        let adminUser: IUser = { PrincipalId: loggedInUserAccount.accountIdentifier, Alias: loggedInUserAccount.userName, Name: loggedInUserAccount.name };
        this.setState({ adminUserDetails: adminUser });

        let urlPathName = window.location.pathname
        let urlValues = urlPathName.split('/');
        let tenantName = _.last(urlValues).toUpperCase();
        let TenantData = Utility.GetTenantData();
        tenantName = tenantName.toUpperCase();
        this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];

        this.ensureDataFetched();

        this.setState({ tenantData: this.tenantData });
    }
    private ensureDataFetched() {
        const { data } = this.props;
        let tenantId = this.tenantData?.TenantId;
        let userId = this.tenantData?.isExternalAADTenant && (data?.userId == null || data?.userId == '') ? data?.user : data?.userId;
        if (tenantId && userId) {
            AccessAPI.getRequestHistory(tenantId, userId).then(res => {
                this.setState({ AccessData: res });
            });
        } else {
            // Error
        }
    }
    constructor(props: any) {
        super(props);
        this.state = {
            items: this._allItems,
            selectedPageIndex: 0,
            adminUserDetails: null,
            showModal: true,
            data: null,
            AccessData: { Data: [], IsDataLoaded: false, IsSuccess: true },
            tenantData : this.tenantData
        };
    }
    public state: AccessHistoryState = {
        adminUserDetails: null,
        items: this._allItems,
        selectedPageIndex: 0,
        showModal: false,
        data: null,
        AccessData: null,
        tenantData : null
    }

    public render(): JSX.Element {
        const { AccessData, showModal , tenantData } = this.state;
        const { data } = this.props;

        return (<React.Fragment>
            <Modal titleAriaId={this._titleId} styles={{ scrollableContent: { overflowX: 'auto' } }}
                isOpen={showModal} onDismiss={this._closeModal}
                isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ position: 'absolute', right: 10, paddingTop: 20, paddingBottom: 12, marginTop: 20 }}>
                        <IconButton tabIndex={0} styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close Access Details dialog" onClick={this._closeModal as any} />
                    </span>
                    <span style={{ position: 'absolute', left: 25, paddingTop: 20, paddingBottom: 12, marginTop: 20 }}   >
                        <h2 role='heading' className={"h2"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>
                            {UIConstants.MessageBoxTitle.AccessHistory}</h2>
                    </span>
                </div>
                <div id={'div-myaccess'}>
                    <Fabric style={{
                        height: '60vh',
                        width: '77vw',
                        display: 'block',
                        margin: '0 auto',
                        marginTop: 20,
                        padding: 20
                    }} >
                        <Label style={{ paddingLeft: 10, fontSize: 14 }} >
                            <h3 className={"h3"} style={{ paddingLeft: 0, fontSize: 14, margin: 0 }}>
                                User : {data.user}
                                </h3>
                        </Label>

                        {tenantData &&
                            <AccessHistoryGrid
                                dataLoaded={AccessData?.IsDataLoaded}
                                allItems={this.getItems()}
                                columnCollection={this.getColumnDefintion()}>  
                            </AccessHistoryGrid>  
                        }
                    </Fabric>
                </div>
            </Modal>
        </React.Fragment>
        );
    }
    private _onRenderPlainCard = (item: any): JSX.Element => {
        return <AccessHistoryStatus requestItem={item} {...this.props} ></AccessHistoryStatus>
    };
    private _onRenderItemColumn = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
        if (column.key === 'completedDate') {
            return (
                <span role="cell" aria-roledescription={item.completedDate === '-' ? 'Not Applicable' : item.completedDate}>{item.completedDate}</span>
            );
        }
        if (column.key === 'status') {
            const itemClass = mergeStyles({
                selectors: {
                    '&:hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    },
                },
            });
            const hoverCardClass = mergeStyles({
                selectors: {
                    '&:focus': {
                        outline: '-webkit-focus-ring-color auto 1px'
                    },
                },
            });
            const plainCardProps: IPlainCardProps = {
                onRenderPlainCard: this._onRenderPlainCard,
                renderData: item,
                directionalHint: DirectionalHint.bottomCenter,
                calloutProps: {
                    isBeakVisible: true
                }
            };
            return (
                <HoverCard className={hoverCardClass} plainCardProps={plainCardProps} instantOpenOnClick={true} type={HoverCardType.plain}>
                    <div className={itemClass} style={{ 'color': '#0078d4' }} aria-label={`${item.status} ${item.requestId}`} >
                        {item.status}
                    </div>
                </HoverCard>
            );
        }
        return item[column.key];
    };
    private _closeModal = (): void => {
        this.setState({ showModal: false });
        this.props.onDismiss();
    };
    private getItems() {
        const { AccessData } = this.state;
        let items: Array<MyAccessRequestCollection> = [];
        AccessData.Data && AccessData.Data.forEach(accessItem => {
            let role = accessItem.role;
            if (role !== null) {
                if (this.tenantData?.TenantName != UIConstants.Tenant.Mercury || (this.tenantData?.TenantName == UIConstants.Tenant.Mercury && role.group != null)) {
                    items.push({
                        requestId: accessItem.id,
                        roleDesc: role.roleDescription,
                        roleId: role.roleId,
                        role: accessItem.tenantName == UIConstants.Tenant.Mercury && role.group != role.roleName ? role.group + "-" + role.roleName : role.roleName,
                        requestedBy: (accessItem.requestedBy?.split("@")[0] ?? accessItem.requestorUPN)?.split("@")[0],
                        requestType: accessItem.requestType,
                        attributes: !role.attributes ? 'NA' : role.attributes.length === 0 ? 'NA' : role.attributes,
                        requestDate: new Date(accessItem.requestedDate).toLocaleString(),
                        completedDate: accessItem.completedDate ? new Date(accessItem.completedDate).toLocaleString() : "Not Applicable",
                        status: accessItem.status,
                        user: accessItem.requestorUPN,
                        userId: accessItem.requestorPrincipal
                    })
                }
            }
        });
        let sortedItems = _copyAndSort(items, 'completedDate', true);
        return sortedItems;
    }
    private getColumnDefintion() {
        const { tenantData } = this.state;
        let columns = [
            { key: 'requestId', name: 'Request ID', fieldName: 'requestId', minWidth: 250, maxWidth: 250, isResizable: false },
            { key: 'role', name: 'Role', fieldName: 'role', minWidth: 250, maxWidth: 250, isResizable: true },
            { key: 'requestType', name: 'Request Type', fieldName: 'requestType', minWidth: 110, maxWidth: 110, isResizable: false },
            { key: 'requestDate', name: 'Requested Date', fieldName: 'requestDate', minWidth: Utility.getDynamicColumnSize(120), maxWidth: Utility.getDynamicColumnSize(200), isResizable: true },
            { key: 'completedDate', name: 'Completed Date', fieldName: 'completedDate', minWidth: Utility.getDynamicColumnSize(120), maxWidth: Utility.getDynamicColumnSize(200), isResizable: true },
            { key: 'status', name: 'Status', fieldName: 'status', minWidth: 130, maxWidth: 130, isResizable: false, onRender: this._onRenderItemColumn },
        ];
        if (tenantData.EnableRequestOnBehalf) {
            columns.splice(3, 0, { key: 'requestedBy', name: 'Requested By', fieldName: 'requestedBy', minWidth: 200, maxWidth: 200, isResizable: false });
        }
        return columns;
    }
}

interface AccessHistoryGridProps {
    allItems,
    dataLoaded,
    columnCollection
}
export const AccessHistoryGrid: React.FunctionComponent<AccessHistoryGridProps> = (props) => {
    const [gridData, setgridData] = React.useState<any>([]);
    const [isGridLoading, setIsGridLoading] = React.useState<boolean>(true);
    const [colCollection, setColCollection] = React.useState<any>([]);

    const fetchData = (): void => {
        setIsGridLoading(true);
        setColCollection(props.columnCollection);
        if (props.dataLoaded) {
            setIsGridLoading(false);
            // Sort the items.
            let sortedItems = _copyAndSort(props.allItems, 'completedDate', true);
            setgridData(sortedItems);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [props.allItems]);

    const _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        let isSortedDescending = column.isSortedDescending;
        let sortedItems = [];
        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(gridData, column.fieldName!, isSortedDescending);
        // Reset the items and columns to match the state.

        setgridData(sortedItems);
        let updatedColumnState = colCollection.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        setColCollection(updatedColumnState);

    };
    return (<React.Fragment>
        <Fabric id="admin-user-mgmt-grid" style={{
            display: 'block',
            margin: '0 auto',
        }} >
            <ShimmeredDetailsList
                setKey="items"
                columns={props.columnCollection}
                items={gridData}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Request History details"
                listProps={{ renderedWindowsAhead: 10, renderedWindowsBehind: 10 }}
                enableShimmer={isGridLoading}
                onColumnHeaderClick={_onColumnClick}
                detailsListStyles={{ root: { maxHeight: '55vh' } }}
            />
        </Fabric>
    </React.Fragment>
    );
}
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    if (columnKey === 'completedDate' || columnKey === 'createdDate') {
        return items.slice(0).sort(function (a: T, b: T) {
            var c: any = new Date(a[columnKey]);
            var d: any = new Date(b[columnKey]);
            return isSortedDescending ? d - c : c - d;
        });

    } else {
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}