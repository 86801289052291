import React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, getId } from '@fluentui/react';
import { DialogModel } from '../shared/models/Dialog.model';
import { UIConstants } from '../shared/models/Constants';
export class ModalDialog extends React.Component<
    {
        hidden: boolean;
        onDismiss: any;
        dialogContentProps: DialogModel;
        modalProps: any;
    },
    {}
    > {
    public state = {
        hideDialog: true,
    };
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');

    public render(): JSX.Element {
        const { dialogContentProps } = this.props;
        return (
            <div>
                <Dialog
                    hidden={this.props.hidden}
                    onDismiss={() => {
                        if (dialogContentProps.okAction) {
                            dialogContentProps.cancelAction();
                        }
                    }}
                    dialogContentProps={dialogContentProps}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => {
                            if (dialogContentProps.okAction) {
                                dialogContentProps.okAction();
                            }
                        }} text={dialogContentProps.okButtonText ? dialogContentProps.okButtonText : UIConstants.ButtonText.Ok} />
                        <DefaultButton onClick={() => {
                            if (dialogContentProps.cancelAction) {
                                dialogContentProps.cancelAction();
                            }
                        }} text={dialogContentProps.cancelButtonText ? dialogContentProps.cancelButtonText : UIConstants.ButtonText.Cancel} />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}